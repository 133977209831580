import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getFloors, getBuildings, getSpaces } from 'containers/app/selectors'
import InfoText from 'containers/quickView/InfoText'
import ModalRow from 'containers/quickView/modal/ModalRow'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const Info = styled.div`
  ${Icon} {
    font-size: 32px;
    margin-left: 8px;
    margin-right: 16px;
  }
`

const UserPosition = ({ floorName, buildingName, spaceName }) => (
  <Fragment>
    {(floorName || buildingName) && (
      <ModalRow>
        <Info>
          <Icon name={icons.FILLED_USER} />
        </Info>
        <InfoText>
          <span>
            {floorName}
            {spaceName && ','} {spaceName}
          </span>
          <span>{buildingName}</span>
        </InfoText>
      </ModalRow>
    )}
  </Fragment>
)

UserPosition.propTypes = {
  buildingName: PropTypes.string,
  floorName: PropTypes.string,
  spaceName: PropTypes.string,
}

const mapStateToProps = (state, { user }) => {
  const userSpace = user.loc ? user.loc.spaceId : null
  const space = userSpace ? getSpaces(state).get(`${userSpace}`) : null
  const spaceName = space ? space.getIn(['info', 'name']) : null
  const floorId = space ? space.getIn(['floorId']) : null
  const floor = getFloors(state).get(`${floorId}`)
  const buildingId = floor ? floor.get('buildingId') : null
  const building = buildingId ? getBuildings(state).get(`${buildingId}`) : null
  const floorName = floor ? floor.getIn(['info', 'name']) : null
  const buildingName = building ? building.getIn(['info', 'name']) : null

  return {
    floorName,
    buildingName,
    spaceName,
  }
}

export default connect(mapStateToProps)(UserPosition)
