import { Map, fromJS } from 'immutable'

// loads the state that is stored in local storage, if any
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

// saves a state to local storage
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.warn('Could not save to local storage.')
  }
}

// sets the value at path on the stored state and saves it
export const updateState = (path, value) => {
  const prevState = fromJS(loadState()) || Map()
  if (prevState.getIn(path) !== value) {
    const nextState = prevState.setIn(path, value)
    saveState(nextState)
  }
}
