import { takeEvery, all, select, call, put, delay } from 'redux-saga/effects'

import * as argus from 'api/argus'
import { authenticateFailure } from 'containers/app/actions'
import { FETCH_UPDATE_SUCCESS } from 'containers/app/constants'
import {
  fetchSchedulesSuccess,
  fetchSchedulesFailure,
} from 'containers/dashboard/actions'
import {
  getCurrentBuildingId,
  getFloorIdsOnBuildings,
} from 'containers/app/selectors'
import { getShowDashboard } from 'containers/dashboard/selectors'
import { SHOW_DASHBOARD, FETCH_SCHEDULES } from 'containers/dashboard/constants'
import { MEETING_SUCCESS } from 'containers/quickView/spaceModal/bookingFlow/constants'

function* fetchSpaceSchedule({ floorId }) {
  const query = `/secure/dashboard/calendars/floor/${floorId}`
  try {
    const response = yield call(argus.fetchData, query)
    return response
  } catch ({ error, response }) {
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* fetchSpaceSchedules() {
  const panel = yield select(getShowDashboard)
  if (panel !== 'swimlanes') return

  yield delay(200)
  const currentBuildingId = yield select(getCurrentBuildingId)
  const floorIdsOnBuildings = yield select(getFloorIdsOnBuildings)
  const floorIdsOnCurrentBuilding = floorIdsOnBuildings
    .get(`${currentBuildingId}`)
    .toJS()
  const promises = floorIdsOnCurrentBuilding.map((floorId) =>
    call(fetchSpaceSchedule, { floorId }),
  )
  const result = yield all(promises)
  if (result.includes(undefined)) {
    yield put(fetchSchedulesFailure())
    return
  }
  yield put(fetchSchedulesSuccess(result))
}

function* watchFetchSpaceSchedules() {
  yield takeEvery(
    [SHOW_DASHBOARD, FETCH_SCHEDULES, FETCH_UPDATE_SUCCESS, MEETING_SUCCESS],
    fetchSpaceSchedules,
  )
}

export default function* dashboardSagas() {
  yield all([watchFetchSpaceSchedules()])
}
