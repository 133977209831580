export const UNITY_INIT_REQUEST = 'containers/unityLoader/UNITY_INIT_REQUEST'
export const UNITY_INIT_SUCCESS = 'containers/unityLoader/UNITY_INIT_SUCCESS'
export const UNITY_INIT_FAILURE = 'containers/unityLoader/UNITY_INIT_FAILURE'
export const UNITY_READY = 'containers/unityLoader/UNITY_READY'
export const UNITY_COMMAND_DONE = 'containers/unityLoader/UNITY_COMMAND_DONE'
export const UNITY_SELECTED_FLOOR =
  'containers/unityLoader/UNITY_SELECTED_FLOOR'
export const UNITY_PANNED_FLOOR = 'containers/unityLoader/UNITY_PANNED_FLOOR'
export const UNITY_CLICKED_ASSET = 'containers/unityLoader/UNITY_CLICKED_ASSET'
export const UNITY_DESELECTED_ASSET =
  'containers/unityLoader/UNITY_DESELECTED_ASSET'
export const UNITY_LONG_CLICKED = 'containers/unityLoader/UNITY_LONG_CLICKED'
export const UNITY_UNFOLLOWED = 'containers/unityLoader/UNITY_UNFOLLOWED'
export const UNITY_WARNING = 'containers/unityLoader/UNITY_WARNING'
