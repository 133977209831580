import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'
import { hideDashboard } from 'containers/dashboard/actions'

const PanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconContainer = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-right: 6px;
`

const Header = (props) => {
  const { title } = props

  const dispatch = useDispatch()

  const closeDashboard = (e) => {
    e.stopPropagation()
    dispatch(hideDashboard())
  }

  return (
    <PanelContainer>
      {title ?? <p />}
      <IconContainer>
        <Icon onClick={(e) => closeDashboard(e)} name={icons.CLOSE} />
      </IconContainer>
    </PanelContainer>
  )
}

Header.propTypes = {
  title: PropTypes.object,
}

export default Header
