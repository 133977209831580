import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import QuickViewBackdrop from 'containers/quickView/QuickViewBackdrop'
import Modal from 'containers/quickView/Modal'
import { clearQuickView } from 'containers/quickView/actions'
import toJS from 'components/toJS'
import ModalContent from 'containers/quickView/modal/ModalContent'
import ModalStatus from 'containers/quickView/modal/ModalStatus'
import UserInfo from 'containers/quickView/userModal/UserInfo'
import UserActions from 'containers/quickView/userModal/UserActions'
import UserPosition from 'containers/quickView/userModal/UserPosition'
import UserSpaces from 'containers/quickView/userModal/UserSpaces'
import { deselect } from 'api/unity'
import { getIsFuture } from 'containers/searchPanel/filter/selectors'
import WrapperDrawerController from 'containers/quickView/modal/WrapperDrawerController'
import {
  getSelectedUserDetails,
  getIsFetching,
} from 'containers/quickView/selectors'

class UserModal extends Component {
  handleClose = () => {
    const { clearQuickView } = this.props
    clearQuickView()
    deselect()
  }

  render() {
    const {
      changeModalPosition,
      isFuture,
      userDetails,
      isFetching,
      user,
    } = this.props
    if (isFetching) return null
    return (
      <WrapperDrawerController>
        <QuickViewBackdrop />
        <Modal changemodalposition={changeModalPosition}>
          <ModalStatus onClick={this.handleClose} />
          <UserInfo user={user} />
          {!isFuture && <UserPosition user={user} />}
          <ModalContent>
            <UserSpaces user={user} />
            <UserActions user={user} />
          </ModalContent>
        </Modal>
      </WrapperDrawerController>
    )
  }
}

UserModal.propTypes = {
  clearQuickView: PropTypes.func,
  user: PropTypes.object,
  changeModalPosition: PropTypes.object,
  isFuture: PropTypes.bool,
  userDetails: PropTypes.object,
  isFetching: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isFuture: getIsFuture(state),
  userDetails: getSelectedUserDetails(state),
  isFetching: getIsFetching(state),
})

export default connect(mapStateToProps, { clearQuickView })(toJS(UserModal))
