import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  SchedulerItem,
  SchedulerItemContent,
} from '@progress/kendo-react-scheduler'
import { Tooltip } from '@progress/kendo-react-tooltip'

import { setMeetingTime } from 'containers/dashboard/actions'
import { selectSpaceId } from 'containers/quickView/actions'
import { setDateFilter } from 'containers/searchPanel/filter/actions'

const Event = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 4px;
`

const CustomItem = (props) => {
  const dispatch = useDispatch()

  const handleDoubleClick = () => {
    dispatch(setDateFilter(props.start))
    dispatch(setMeetingTime(props.start, props.end))
    dispatch(selectSpaceId(props.group.resources[0].value))
  }

  return (
    <SchedulerItem
      {...props}
      editable={false}
      style={{
        ...props.style,
        backgroundColor: '#c5daf0',
        color: 'black',
        minHeight: '36px',
        marginTop: props.order > 0 ? '6px' : 0,
      }}
      onDoubleClick={handleDoubleClick}
    >
      <SchedulerItemContent>
        <Tooltip anchorElement="pointer" parentTitle position="top">
          <Event title={props.title}>{props.title}</Event>
        </Tooltip>
      </SchedulerItemContent>
    </SchedulerItem>
  )
}

CustomItem.propTypes = {
  dataItem: PropTypes.object,
  end: PropTypes.object,
  group: PropTypes.object,
  order: PropTypes.number,
  start: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string,
}

export default CustomItem
