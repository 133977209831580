import React from 'react'
import { Translate } from 'react-localize-redux'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import StatusIndicator from 'components/StatusIndicator'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const StyledIndicator = styled(StatusIndicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  height: 40px;
  text-align: center;
  padding: 8px 48px;
  font-weight: 500;
  position: relative;
  border-radius: 3px 3px 0 0;
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 21px;
  margin: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  background: none;
  outline: none;
  color: inherit;
  height: 100%;

  ${Icon} {
    font-size: 24px;
    margin: 0;
  }
`

const MultiSelectModalBanner = ({ onClick, children }) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledIndicator>
          {translate('quickView.multipleBooking')}
          {children}
          <StyledButton onClick={onClick}>
            <Icon name={icons.CLOSE} />
          </StyledButton>
        </StyledIndicator>
      )}
    </Translate>
  )
}

MultiSelectModalBanner.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string,
}

export default MultiSelectModalBanner
