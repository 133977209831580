import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import { icons } from 'components/icon/icons'

const FlowPlannerPicker = () => {
  return (
    <PanelDrawer
      iconName={icons.FLOWPLANNER}
      label={'FlowPlanner'}
      allowToOpen
      flowPlannerData
    />
  )
}

FlowPlannerPicker.propTypes = {}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(FlowPlannerPicker)
