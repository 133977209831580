import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'

import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import LocaleItem from 'containers/mainPanel/localePicker/LocaleItem'
import { clearInnerDrawer } from 'containers/mainPanel/panelDrawer/actions'
import { icons } from 'components/icon/icons'

class LocalePicker extends Component {
  selectLocale = (languageCode) => {
    const { setActiveLanguage, clearInnerDrawer } = this.props
    clearInnerDrawer()
    setActiveLanguage(languageCode)
    window.localStorage.setItem('language', languageCode)
  }

  render() {
    const { activeLanguage, languages } = this.props
    const activeIndex = languages.findIndex((id) => id === activeLanguage)

    return (
      <PanelDrawer
        iconName={icons.FLAG}
        label={activeLanguage && activeLanguage.name}
        activeIndex={activeIndex}
        size={languages.length}
      >
        {languages.map((language) => (
          <LocaleItem
            language={language}
            key={language.code}
            active={language.active}
            onClick={this.selectLocale}
          />
        ))}
      </PanelDrawer>
    )
  }
}

LocalePicker.propTypes = {
  setActiveLanguage: PropTypes.func,
  languages: PropTypes.arrayOf(PropTypes.object),
  activeLanguage: PropTypes.object,
  clearInnerDrawer: PropTypes.func,
}

export default withLocalize(connect(null, { clearInnerDrawer })(LocalePicker))
