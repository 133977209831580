import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import React, { Component } from 'react'

import { sortByString } from 'utils/utilsFunctions'
import SiteCheckboxRow from 'pages/buildingSelector/SiteCheck'

const SitesListHolder = styled.div`
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 3;
  background-color: white;
  border: 1px solid white;
`
const ResetFilter = styled.p`
  &:before {
    content: '';
    background-image: url(./restart.svg);
    width: 12px;
    height: 12px;
    position: absolute;
    left: 23px;
    background-size: cover;
  }
  margin: 0 0 40px 40px;
  font-size: 12px;
  color: #383333;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const HeaderFilter = styled.p`
  margin: 25px 0 14px 23px;
  font-size: 18px;
  font-weight: bold;
  color: #3e3e3e;
`
const ButtonsHolder = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  flex-direction: column;
  padding-top: 20px;
  background-color: white;
  z-index: 2;
  padding-bottom: 20px;
  box-shadow: 0 -3px 7px rgb(0 0 0 / 10%);
`
const CountriesHolder = styled.div`
  position: relative;
  width: 100%;
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
    border-radius: 70px;
    min-height: 70px;
    width: 5px;
    border: 5px solid white;
  }
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`
const ConfirmButton = styled.button`
  display: block;
  width: 150px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  font-family: Roboto;
  align-self: center;
  cursor: pointer;
  ${(props) =>
    props.save &&
    css`
      color: white;
      border-radius: 4px;
      border: 1px solid #0178d4;
      background-color: #0178d4;
      &:hover {
        filter: brightness(0.6);
      }
    `}
  ${(props) =>
    props.cancel &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`

class SitesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedSite: localStorage.getItem('selectedSite'),
    }
    this.handleSiteSelection = this.handleSiteSelection.bind(this)
    this.handleSaveSiteSelection = this.handleSaveSiteSelection.bind(this)
    this.resetFilter = this.resetFilter.bind(this)
  }

  handleSiteSelection(siteId) {
    if (this.state.selectedSite === siteId) {
      this.setState({ selectedSite: '' })
    } else {
      this.setState({ selectedSite: siteId })
    }
  }

  handleSaveSiteSelection() {
    const { handleCloseFIlter } = this.props
    localStorage.setItem('selectedSite', this.state.selectedSite)
    handleCloseFIlter()
  }

  resetFilter() {
    this.setState({ selectedSite: '' })
  }

  render() {
    const { sites, handleCloseFIlter } = this.props
    const activeSite = this.state.selectedSite

    return (
      <SitesListHolder>
        <HeaderFilter>
          <Translate id="space.status.selectCountry" />
        </HeaderFilter>
        <ResetFilter onClick={this.resetFilter}>
          <Translate id="filter.resetFilter" />
        </ResetFilter>
        <CountriesHolder>
          {sites
            .sort((siteA, siteB) => sortByString(siteA.name, siteB.name))
            .map((site, index) => {
              return (
                <SiteCheckboxRow
                  key={index}
                  active={
                    activeSite ? activeSite.toString() === site.code : false
                  }
                  id={site.code}
                  name={site.name}
                  handleSiteSelection={this.handleSiteSelection}
                />
              )
            })}
        </CountriesHolder>
        <ButtonsHolder>
          <ConfirmButton save onClick={this.handleSaveSiteSelection}>
            <Translate id="billboard.mainPanel.removeBookingConfirmation.button.save" />
          </ConfirmButton>
          <ConfirmButton cancel onClick={handleCloseFIlter}>
            <Translate id="billboard.mainPanel.removeBookingConfirmation.button.no" />
          </ConfirmButton>
        </ButtonsHolder>
      </SitesListHolder>
    )
  }
}

SitesList.propTypes = {
  sites: PropTypes.array,
  handleCloseFIlter: PropTypes.func,
}
export default SitesList
