export const FETCH_PERSONAL_CALENDAR_REQUEST =
  'containers/results/FETCH_PERSONAL_CALENDAR_REQUEST'
export const FETCH_PERSONAL_CALENDAR_SUCCESS =
  'containers/results/FETCH_PERSONAL_CALENDAR_SUCCESS'
export const FETCH_PERSONAL_CALENDAR_FAILURE =
  'containers/results/FETCH_PERSONAL_CALENDAR_FAILURE'
export const DELETE_PERSONAL_EVENT_REQUEST =
  'containers/results/DELETE_PERSONAL_EVENT_REQUEST'
export const DELETE_PERSONAL_EVENT_SUCCESS =
  'containers/results/DELETE_PERSONAL_EVENT_SUCCESS'
export const DELETE_PERSONAL_EVENT_FAILURE =
  'containers/results/DELETE_PERSONAL_EVENT_FAILURE'
export const OPEN_CONFIRMATION_POPUP =
  'containers/results/OPEN_CONFIRMATION_POPUP'
export const CLOSE_CONFIRMATION_POPUP =
  'containers/results/CLOSE_CONFIRMATION_POPUP'
export const SEND_CONFIRMATION_POPUP =
  'containers/results/SEND_CONFIRMATION_POPUP'
