import {
  SET_DATASET,
  CLEAR_DATASET,
  SET_AVAILABILITY_FILTER,
  SET_BOOKABLE_FILTER,
  SET_PROP_FILTER,
  CLEAR_FILTER,
  SET_NUM_SEATS_FILTER,
  SET_DURATION_FILTER,
  SET_DATE,
  SET_MAP_INTERVAL,
} from 'containers/searchPanel/filter/constants'

export const setDataset = (dataset) => ({
  type: SET_DATASET,
  dataset,
})

export const clearDataset = () => ({
  type: CLEAR_DATASET,
})

export const setAvailablityFilter = (value) => ({
  type: SET_AVAILABILITY_FILTER,
  value,
})

export const setBookableFilter = (value) => ({
  type: SET_BOOKABLE_FILTER,
  value,
})

export const setPropFilter = (key, value) => ({
  type: SET_PROP_FILTER,
  key,
  value,
})

export const setNumSeatsFilter = (value) => ({
  type: SET_NUM_SEATS_FILTER,
  value,
})

export const setDurationFilter = (value) => ({
  type: SET_DURATION_FILTER,
  value,
})

export const setDateFilter = (value) => ({
  type: SET_DATE,
  value,
})

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})

export const setMapInterval = (value) => ({
  type: SET_MAP_INTERVAL,
  value,
})
