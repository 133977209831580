import styled from 'styled-components'

import { ALLOW_TO_OPEN_MODAL } from 'utils/appVars'

const QuickViewContainer = styled.div.attrs({ className: ALLOW_TO_OPEN_MODAL })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`

export default QuickViewContainer
