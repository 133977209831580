import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Translate, getTranslate } from 'react-localize-redux'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import {
  withStyles,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import styled, { withTheme } from 'styled-components'
import { format } from 'date-fns'

import toJS from 'components/toJS'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalButton from 'containers/quickView/modal/ModalButton'
import Icon from 'components/Icon'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import {
  getIsFetching,
  getMeetingSlots,
  getDesiredMeetingSlot,
} from 'containers/quickView/spaceModal/bookingFlow/selectors'
import { setBookingFlowMeetingDuration } from 'containers/quickView/spaceModal/bookingFlow/actions'
import QuickViewInput from 'containers/quickView/components/QuickViewInput'
import { icons } from 'components/icon/icons'
import { getSubmitDisabled } from 'containers/quickView/selectors'
const MIN_TO_MS = 60000

const SliderContainer = styled.div`
  background-color: ${(props) => props.theme.panelBgLight};
  color: ${(props) => props.theme.panelActiveBg};
  padding: 16px;
  width: 100%;
`

const createCustomTheme = (props) => {
  const { theme } = props

  return createTheme({
    palette: {
      primary: {
        main: theme.panelActiveBg,
      },
    },
    typography: {
      useNextVariants: true,
    },
  })
}

class BookingSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTimeSlot: 0,
      meetingSubject: '',
    }
  }

  componentDidMount() {
    this.updateValues(0)
  }

  handleBooking = () => {
    const { onBooking, desiredMeetingSlot } = this.props
    const meetingDate = new Date(desiredMeetingSlot.duration * MIN_TO_MS)
    let diff = differenceInMinutes(meetingDate, Date.now())
    const diffSecs = differenceInSeconds(meetingDate, Date.now())
    const subject = this.currentSubject()

    if (diffSecs / 60 > diff) diff += 1

    onBooking(diff, subject)
  }

  handleChangeDuration = (_event, sliderValue) => {
    this.updateValues(sliderValue)
  }

  handleChangeSubject = (_event) => {
    this.setState({ meetingSubject: _event.target.value })
  }

  currentSubject = () => {
    const { meetingSubject } = this.state
    const { translate } = this.props
    return meetingSubject
      ? meetingSubject
      : translate('quickView.defaultMeetingSubject')
  }

  calculateNumSteps = () => {
    const { meetingSlots } = this.props
    return meetingSlots.range ? meetingSlots.range.length - 1 : 0
  }

  convertToHumanTime = (timestamp) => {
    const date = new Date(timestamp.duration * MIN_TO_MS)
    const diff = differenceInMinutes(date, Date.now())

    return {
      until: format(date, 'HH:mm'),
      duration: diff,
    }
  }

  updateValues = (timeslot) => {
    const { setBookingFlowMeetingDuration, meetingSlots } = this.props
    this.setState({ selectedTimeSlot: timeslot })
    if (meetingSlots && meetingSlots.range)
      setBookingFlowMeetingDuration(meetingSlots.range[timeslot])
  }

  render() {
    const {
      onClose,
      isFetching,
      desiredMeetingSlot,
      translate,
      submitDisabled,
    } = this.props

    return (
      <React.Fragment>
        <ModalRow flat>
          <ModalTitle>
            <span>
              <Translate id="quickView.meetingSubject" />
            </span>
          </ModalTitle>
          <QuickViewInput
            type="text"
            name="subject"
            value={this.state.meetingSubject}
            onChange={this.handleChangeSubject}
            placeholder={translate('quickView.defaultMeetingSubject')}
          />
        </ModalRow>
        <ModalRow>
          <ModalButtonRow>
            <ModalButton
              wide
              primary
              onClick={this.handleBooking}
              disabled={isFetching || submitDisabled}
            >
              {isFetching && <Icon name={icons.SPINNER} rotating />}
              <span>
                <Translate id="quickView.button.book" />
              </span>
            </ModalButton>
          </ModalButtonRow>
        </ModalRow>
      </React.Fragment>
    )
  }
}

BookingSelect.propTypes = {
  desiredMeetingSlot: PropTypes.object,
  isFetching: PropTypes.bool,
  meetingSlots: PropTypes.object,
  onBooking: PropTypes.func,
  onClose: PropTypes.func,
  setBookingFlowMeetingDuration: PropTypes.func,
  translate: PropTypes.func,
  submitDisabled: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  meetingSlots: getMeetingSlots(state),
  desiredMeetingSlot: getDesiredMeetingSlot(state),
  translate: getTranslate(state.get('localize')),
  submitDisabled: getSubmitDisabled(state),
})

const withConnect = connect(mapStateToProps, { setBookingFlowMeetingDuration })

export default withStyles({})(
  compose(withConnect, withTheme)(toJS(BookingSelect)),
)
