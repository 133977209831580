import React from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import weekData from 'cldr-core/supplemental/weekData.json'
import deDateFields from 'cldr-dates-full/main/de/dateFields.json'
import deGregorian from 'cldr-dates-full/main/de/ca-gregorian.json'
import noDateFields from 'cldr-dates-full/main/nb/dateFields.json'
import noGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json'
import svDateFields from 'cldr-dates-full/main/sv/dateFields.json'
import svGregorian from 'cldr-dates-full/main/sv/ca-gregorian.json'
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json'
import frGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json'
import esDateFields from 'cldr-dates-full/main/es/dateFields.json'
import esGregorian from 'cldr-dates-full/main/es/ca-gregorian.json'
import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from '@progress/kendo-react-intl'
import { TimelineView } from '@progress/kendo-react-scheduler'
import { Day } from '@progress/kendo-date-math'
import '@progress/kendo-theme-default/dist/all.css'

import CustomItem from 'containers/dashboard/swimlanes/components/CustomItem'
import CustomEditSlot from 'containers/dashboard/swimlanes/components/CustomEditSlot'
import CustomScheduler from 'containers/dashboard/swimlanes/components/CustomScheduler'
import deMessages from 'locales/de-de.json'
import noMessages from 'locales/no-no.json'
import svMessages from 'locales/se-sv.json'
import frMessages from 'locales/fr-fr.json'
import esMessages from 'locales/es-es.json'
import { getLocale } from 'utils/utilsFunctions'
import {
  DEFAULT_START_HOUR,
  DEFAULT_END_HOUR,
  DEFAULT_START_MINS_ROOMS,
  DEFAULT_END_MINS_ROOMS,
} from 'utils/appVars'

load(
  deDateFields,
  deGregorian,
  noGregorian,
  noDateFields,
  svGregorian,
  svDateFields,
  frGregorian,
  frDateFields,
  esGregorian,
  esDateFields,
  weekData,
)

loadMessages(deMessages, 'de')
loadMessages(noMessages, 'nb')
loadMessages(svMessages, 'sv')
loadMessages(frMessages, 'fr')
loadMessages(esMessages, 'es')

const Calendar = (props) => {
  const { activeLanguage, resources, selectedDate } = props

  const [view, setView] = React.useState('day')

  const handleViewChange = React.useCallback(
    (event) => {
      setView(event.value)
    },
    [setView],
  )

  const customModelFields = {
    id: 'eventId',
    title: 'subject',
    start: 'start',
    end: 'end',
  }
  const columnWidth = 80

  const locale = getLocale(activeLanguage.code)
  const localeAbbr = locale.code.split('-')[0]

  return (
    <LocalizationProvider language={locale.code}>
      <IntlProvider locale={localeAbbr}>
        <CustomScheduler
          data={resources.events}
          view={view}
          onViewChange={handleViewChange}
          date={selectedDate}
          group={{
            resources: ['Rooms'],
            orientation: 'vertical',
          }}
          editable={{ edit: true, select: true, remove: true }}
          resources={[
            {
              name: 'Rooms',
              data: resources.rooms,
              field: 'roomId',
              valueField: 'value',
              textField: 'text',
              colorField: 'color',
            },
          ]}
          item={CustomItem}
          editSlot={CustomEditSlot}
          height="100%"
          width="100%"
          modelFields={customModelFields}
        >
          <TimelineView
            name="day"
            title={<Translate id="scheduler.dayViewTitle" />}
            numberOfDays={1}
            columnWidth={columnWidth}
            slotDuration={30}
            slotDivisions={1}
            workDayStart={`${DEFAULT_START_HOUR}:${DEFAULT_START_MINS_ROOMS}`}
            workDayEnd={`${DEFAULT_END_HOUR}:${DEFAULT_END_MINS_ROOMS}`}
            workWeekStart={Day.Monday}
            workWeekEnd={Day.Sunday}
          />
          <TimelineView
            name="week"
            title={<Translate id="scheduler.weekViewTitle" />}
            numberOfDays={7}
            columnWidth={columnWidth}
            slotDuration={60}
            slotDivisions={1}
            workDayStart={`${DEFAULT_START_HOUR}:${DEFAULT_START_MINS_ROOMS}`}
            workDayEnd={`${DEFAULT_END_HOUR}:${DEFAULT_END_MINS_ROOMS}`}
            workWeekStart={Day.Monday}
            workWeekEnd={Day.Sunday}
          />
          <TimelineView
            name="month"
            title={<Translate id="scheduler.monthViewTitle" />}
            numberOfDays={30}
            columnWidth={columnWidth}
            slotDuration={720}
            slotDivisions={1}
            workDayStart={`${DEFAULT_START_HOUR}:${DEFAULT_START_MINS_ROOMS}`}
            workDayEnd={`${DEFAULT_END_HOUR}:${DEFAULT_END_MINS_ROOMS}`}
            workWeekStart={Day.Monday}
            workWeekEnd={Day.Sunday}
            currentTimeMarker={false}
          />
          <TimelineView
            name="quarter"
            title={<Translate id="scheduler.quarterViewTitle" />}
            numberOfDays={90}
            columnWidth={columnWidth}
            slotDuration={720}
            slotDivisions={1}
            workDayStart={`${DEFAULT_START_HOUR}:${DEFAULT_START_MINS_ROOMS}`}
            workDayEnd={`${DEFAULT_END_HOUR}:${DEFAULT_END_MINS_ROOMS}`}
            workWeekStart={Day.Monday}
            workWeekEnd={Day.Sunday}
            currentTimeMarker={false}
          />
        </CustomScheduler>
      </IntlProvider>
    </LocalizationProvider>
  )
}

Calendar.propTypes = {
  activeLanguage: PropTypes.object,
  schedulerData: PropTypes.object,
  resources: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  createNewEvent: PropTypes.func,
}

export default withLocalize(Calendar)
