import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'

import { logoutRequest } from 'containers/app/actions'
import PanelButton from 'containers/mainPanel/PanelButton'
import { icons } from 'components/icon/icons'

class LogoutButton extends Component {
  handleClick = () => {
    const { logoutRequest } = this.props
    if (
      window.confirm(this.props.translate('billboard.mainPanel.logoutConfirm'))
    ) {
      logoutRequest()
    }
  }

  render() {
    return (
      <PanelButton iconName={icons.LOGOUT} onClick={this.handleClick}>
        <Translate id="billboard.mainPanel.logout" />
      </PanelButton>
    )
  }
}

LogoutButton.propTypes = {
  logoutRequest: PropTypes.func,
  translate: PropTypes.func,
}

export default withLocalize(connect(null, { logoutRequest })(LogoutButton))
