import styled from 'styled-components'

import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'

const WrapperDrawerController = styled.div.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  > div {
    z-index: 3;
  }
`

export default WrapperDrawerController
