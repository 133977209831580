import styled from 'styled-components'

const ModalContent = styled.div`
  overflow: auto;
  max-height: 100%;
  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid #f5f5f5;
    background-color: #a9a9a9;
    border-radius: 6px;
  }
`

export default ModalContent
