import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import React from 'react'
import styled, { css } from 'styled-components'

import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'

const StatisticsRow = styled.p`
  span {
    margin-right: 20px;
  }
`

const BuildingIcon = styled(Icon)`
  display: inline-block;
  margin: 0 0.2em;
  text-align: center;
  position: relative;
  bottom: 0;
  background-image: url(./same_building.svg);
  background-repeat: no-repeat;
  height: 0.9em;
  width: 1em;
`

const MainStatistics = ({ values }) => {
  return (
    <StatisticsRow>
      <span>
        <Translate id="flowPlanner.capacity" />:
      </span>
      <span>
        <Icon name={icons.DESK} /> {values.desks}{' '}
        <Translate id="flowPlanner.spaces" />
      </span>
      <span>
        <BuildingIcon /> {values.capacity} <Translate id="flowPlanner.people" />
      </span>
    </StatisticsRow>
  )
}

MainStatistics.propTypes = {
  values: PropTypes.object,
}

export default MainStatistics
