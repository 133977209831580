import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Popper from '@material-ui/core/Popper'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import DeskActionsButton from 'containers/quickView/spaceModal/claimFlow/DeskActionsButton'

const TooltipPopper = styled(Popper)`
  & .MuiTooltip-tooltip {
    font-size: 1.5rem !important;
  }
`

const TooltipContent = styled.div`
  display: flex;
  flex: 1;
`

const ErrorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: '1rem',
  },
}))(Tooltip)

const DeskClaimOnBehalfButton = ({ isFetching, hasPermission, onClaim }) => (
  <ModalRow bottomButton>
    <ModalButtonRow>
      <Translate>
        {({ translate }) => (
          <ErrorTooltip
            placement="top"
            title={
              !hasPermission
                ? translate('quickView.button.noReservePermission')
                : ''
            }
            PopperComponent={TooltipPopper}
          >
            <TooltipContent>
              <DeskActionsButton
                text
                boboBtn
                disabled={isFetching || !hasPermission}
                handleClick={onClaim}
                translation={<>{translate('quickView.bookOnBehalfOf')}</>}
              />
            </TooltipContent>
          </ErrorTooltip>
        )}
      </Translate>
    </ModalButtonRow>
  </ModalRow>
)

DeskClaimOnBehalfButton.propTypes = {
  isFetching: PropTypes.bool,
  hasPermission: PropTypes.bool,
  showError: PropTypes.bool,
  dateSelected: PropTypes.instanceOf(Date),
  onHideError: PropTypes.func,
  onClaim: PropTypes.func,
}

export default DeskClaimOnBehalfButton
