import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import CoverImage from 'components/CoverImage'
import { getProfilePictures } from 'containers/searchPanel/results/selectors'
import { buildInitialsString } from 'utils/utilsFunctions'
import {
  selectUser,
  selectUserDetailsRequest,
} from 'containers/quickView/actions'
import { changeModalPosition } from 'containers/flowPlanner/actions'

class ColleaguePicture extends Component {
  render() {
    const {
      fn,
      ln,
      profilePicture,
      user,
      selectUser,
      selectUserDetailsRequest,
      changeModalPosition,
    } = this.props

    if (profilePicture) {
      return <CoverImage src={profilePicture} />
    }
    return buildInitialsString(fn, ln)
  }
}

ColleaguePicture.propTypes = {
  fn: PropTypes.string,
  id: PropTypes.number,
  ln: PropTypes.string,
  profilePicture: PropTypes.string,
  user: PropTypes.object,
  selectUser: PropTypes.func,
  selectUserDetailsRequest: PropTypes.func,
  changeModalPosition: PropTypes.func,
}

const mapStateToProps = (state, props) => ({
  profilePicture: getProfilePictures(state).get(`${props.id}`),
})

export default connect(mapStateToProps, {
  selectUser,
  selectUserDetailsRequest,
  changeModalPosition,
})(ColleaguePicture)
