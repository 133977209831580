import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import Paper from '@material-ui/core/Paper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import { rgba } from 'polished'

import ProfilePicture from 'components/ProfilePicture'
import InfoSymbol from 'containers/quickView/InfoSymbol'
import { ALLOW_TO_OPEN_MODAL } from 'utils/appVars'

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
  font-size: 1.2rem;
`

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
`

const StyledInput = styled.input`
  padding: 12px 10px;
  border-radius: 3px;
  font-size: 14px;
  font-family: inherit;
  border: 1px solid ${(props) => props.theme.inputColor};
  color: ${(props) => props.theme.inputColor};
  box-sizing: border-box;
  letter-spacing: 0.03em;
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.panelBgLight};
  margin: 0;
  display: flex;
  position: relative;
  &:focus {
    outline-color: #424d61;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.darkText};
    opacity: 1;
  }
  :-ms-input-placeholder {
    opacity: 1;
    color: ${(props) => props.theme.darkText};
  }
`

const ListContainer = styled.div.attrs({
  className: ALLOW_TO_OPEN_MODAL,
})`
  font-size: 1.2rem !important;
  margin-top: -10px !important;

  & .MuiAutocomplete-option {
    &[data-focus='true'] {
      background-color: ${(props) =>
        rgba(props.theme.statusFree, 0.2)} !important;
    }
  }
`

const Listbox = styled.div`
  padding: 1em;
`

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const OptionTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
`

const OptionSubtitle = styled.span`
  font-size: 1.2rem;
`

const ListboxComponent = ({ children }) => (
  <Listbox>
    <Paper elevation={3}>
      <ul>{children}</ul>
    </Paper>
  </Listbox>
)

ListboxComponent.propTypes = {
  children: PropTypes.element,
}

const UserSearchAutocomplete = ({
  placeholder,
  options,
  noResultsText,
  getOptionLabel,
  onChange,
  onDebouncedInputValueChange,
}) => {
  // eslint-disable-next-line
  const debounceInputChange = useCallback(
    debounce((value) => onDebouncedInputValueChange(value), 400),
    [],
  ) // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e, inputProps) => {
    inputProps.onChange(e)
    debounceInputChange(e.target.value)
  }

  return (
    <StyledAutocomplete
      autoHighlight
      onChange={onChange}
      options={options}
      noOptionsText={noResultsText}
      getOptionLabel={getOptionLabel}
      PaperComponent={(props) => (
        <ListContainer as={Paper} {...props} elevation={5} />
      )}
      renderOption={(user) => (
        <OptionWrapper>
          <InfoSymbol small>
            <ProfilePicture fn={user.fn} ln={user.ln} />
          </InfoSymbol>
          <OptionContent>
            <OptionTitle>{user.name}</OptionTitle>
            <OptionSubtitle>{user.mail}</OptionSubtitle>
          </OptionContent>
        </OptionWrapper>
      )}
      renderInput={(params) => (
        <InputWrapper ref={params.InputProps.ref}>
          <StyledInput
            type="text"
            placeholder={placeholder}
            {...params.inputProps}
            onChange={(e) => handleInputChange(e, params.inputProps)}
          />
        </InputWrapper>
      )}
    />
  )
}

UserSearchAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  onDebouncedInputValueChange: PropTypes.func,
}

UserSearchAutocomplete.defaultProps = {
  getOptionLabel: (option) => option.title,
  onChange: () => {},
  onDebouncedInputValueChange: () => {},
}

export default UserSearchAutocomplete
