import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import ModalButton from 'containers/quickView/modal/ModalButton'
import { getIsFetching } from 'containers/quickView/spaceModal/claimFlow/selectors'
import { icons } from 'components/icon/icons'
import { getSubmitDisabled } from 'containers/quickView/selectors'
class DeskActionsButton extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    handleClick: PropTypes.func,
    translation: PropTypes.object,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    text: PropTypes.bool,
    disabled: PropTypes.bool,
    greyBgrDisabled: PropTypes.bool,
    boboBtn: PropTypes.bool,
    submitDisabled: PropTypes.bool,
  }

  render() {
    const {
      isFetching,
      handleClick,
      translation,
      primary,
      secondary,
      text,
      disabled,
      boboBtn,
      greyBgrDisabled,
      submitDisabled,
    } = this.props
    return (
      <ModalButton
        wide
        greybgr={greyBgrDisabled || false}
        primary={primary}
        boboBtn={boboBtn || false}
        secondary={secondary}
        text={text}
        disabled={isFetching || disabled || submitDisabled}
        onClick={() => handleClick()}
      >
        {isFetching && <Icon name={icons.SPINNER} rotating />}
        {translation}
      </ModalButton>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  submitDisabled: getSubmitDisabled(state),
})

export default connect(mapStateToProps, null)(DeskActionsButton)
