import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'
import { format, set, getHours, getMinutes, endOfDay, addDays } from 'date-fns'

import TimePicker from 'containers/quickView/TimePicker'
import { registerSlot, clearRegisterSlot } from 'containers/quickView/actions'
import {
  timeOptions,
  checkIfToearlierThanFrom,
  getWorkingHour,
  getStartMinutesOfInterval,
  addDayIfPastMidday,
  getEndOfDayTime,
} from 'utils/utilsFunctions'
import {
  getIsFuture,
  getDateFilter,
  getSelectedInterval,
} from 'containers/searchPanel/filter/selectors'
import {
  getMeetingTime,
  getShowDashboard,
} from 'containers/dashboard/selectors'
import {
  getDefaultStart,
  getDefaultEnd,
  getClientFeatures,
  getSelfId,
} from 'containers/app/selectors'
import DatePickerCom from 'containers/quickView/DatePickerCom'
import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'
import {
  getHasClaimOnBehalfPermission,
  getMaxDaysAllowed,
  getMaxDurationAllowed,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  DEFAULT_SPACE_START_TIME,
  DEFAULT_SPACE_END_TIME,
  DEFAULT_START_HOUR_ROOMS,
  DEFAULT_END_HOUR_ROOMS,
  DEFAULT_START_MINS_ROOMS,
  DEFAULT_END_MINS_ROOMS,
  DEFAULT_INTERVAL_SPACE,
  DEFAULT_INTERVAL_ROOM,
  DEFAULT_MEETING_DURATION,
  DEFAULT_ROUND_MINUTES,
  DEFAULT_OPTIONS_START_HOUR,
  DEFAULT_OPTIONS_START_MINUTES,
  LAST_TIME_PICKER_HOUR,
} from 'utils/appVars'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'

const TimePickers = styled.div`
  margin-top: 17px;
  color: ${(props) => props.theme.darkText};
  margin-bottom: ${(props) => (props.isSpace ? '10px' : '0px')};
  display: flex;
  justify-content: space-between;
  padding: 0 45px;
`
const TimePickerItem = styled.div`
  width: 125px;
  > span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }
`

const StyledDatePickerCom = styled(DatePickerCom)`
  line-height: 40px;
`
const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  & > div > div {
    width: 12rem;
    border: none !important;

    div,
    svg {
      font-size: 14px !important;
      margin: 0;
      color: ${(props) => props.theme.darkText};
    }
  }
`

const TimePickerArea = ({
  registerSlot,
  type,
  eventsPerAsset,
  hasClaimOnBehalfPermission,
  maxDaysAllowed,
  maxDurationAllowed,
  defaultSpaceStartTime = DEFAULT_SPACE_START_TIME,
  defaultSpaceEndTime = DEFAULT_SPACE_END_TIME,
  selfId,
  selectedInterval,
  timeFormat,
  meetingTime,
  showDashboard,
  clearRegisterSlot,
}) => {
  useEffect(() => {
    return () => clearRegisterSlot()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const selectedDate = useSelector((state) => getDateFilter(state))
  const [selectedFromDate, setSelectedFromDate] = useState(selectedDate)
  const [selectedToDate, setSelectedToDate] = useState(selectedFromDate)
  const [clickFrom, setClickFrom] = useState(false)
  const [clickТо, setClickТо] = useState(false)
  const isFuture =
    useSelector((state) => getIsFuture(state)) ||
    selectedFromDate > selectedDate

  const isSpace = type === 'SPACE'
  const isRoom = type === 'ROOM'
  const intervalInMinutes = isSpace
    ? DEFAULT_INTERVAL_SPACE
    : DEFAULT_INTERVAL_ROOM
  const currentDayRoundMinutes = DEFAULT_ROUND_MINUTES
  const currentMinute = getStartMinutesOfInterval(
    new Date().getMinutes(),
    currentDayRoundMinutes,
  )
  const currentHour = new Date().getHours()

  // #region meetingTime coming from the swim lanes time slot selection
  const meetingHrStart = meetingTime ? meetingTime.startDate.getHours() : null
  const meetingMinStart = meetingTime
    ? meetingTime.startDate.getMinutes()
    : null
  const meetingHrEnd = meetingTime ? meetingTime.endDate.getHours() : null
  const meetingMinEnd = meetingTime ? meetingTime.endDate.getMinutes() : null

  // #endregion

  let startMinute
  let startHour
  let endMinute
  let endHour

  // #region defining default times

  if (isSpace) {
    startMinute = currentMinute
    startHour = currentHour
    endMinute = defaultSpaceEndTime % 60
    endHour = Math.trunc(defaultSpaceEndTime / 60)
    if (isFuture) {
      startMinute = defaultSpaceStartTime % 60
      startHour = Math.trunc(defaultSpaceStartTime / 60)
    }
    if (startHour >= endHour) {
      endHour = getHours(endOfDay(selectedDate))
      endMinute = getMinutes(endOfDay(selectedDate))
    }
  }

  if (isRoom) {
    startHour = meetingHrStart ?? currentHour
    startMinute = meetingMinStart ?? currentMinute
    endHour = meetingHrEnd ?? startHour + DEFAULT_MEETING_DURATION
    endMinute = meetingMinEnd ?? currentMinute

    if (isFuture) {
      startHour = meetingHrStart ?? DEFAULT_START_HOUR_ROOMS
      startMinute = meetingMinStart ?? DEFAULT_START_MINS_ROOMS
      endHour = meetingHrEnd ?? DEFAULT_END_HOUR_ROOMS
      endMinute = meetingMinEnd ?? DEFAULT_END_MINS_ROOMS
    }
    if (endHour > 23) {
      endHour = getHours(endOfDay(selectedDate))
      endMinute = getMinutes(endOfDay(selectedDate))
    }
  }
  //#endregion

  // #region Adding time filter dependency

  if (selectedInterval.manualSelect && !showDashboard) {
    startMinute = selectedInterval?.start?.getMinutes() ?? startMinute
    startHour = selectedInterval?.start?.getHours() ?? startHour
    endMinute = selectedInterval?.end?.getMinutes() ?? endMinute
    endHour = selectedInterval?.end?.getHours() ?? endHour
  }
  //#endregion

  const defaultStartTime = getWorkingHour(
    startHour,
    startMinute,
    selectedDate,
    timeFormat,
  )
  const defaultEndTime = getWorkingHour(
    endHour,
    endMinute,
    selectedDate,
    timeFormat,
  )

  const defineToOptions = (newFromValue) => {
    setValueFrom(newFromValue)
    const time = new Date(newFromValue.value)
    const hasLastHourStartTime =
      newFromValue.value.getHours() >= LAST_TIME_PICKER_HOUR
    const isOverlapping = checkIfToearlierThanFrom(valueTo.value, time)
    let endTime = valueTo.value

    if ((isOverlapping && isSpace) || hasLastHourStartTime) {
      endTime = getEndOfDayTime(time)
    } else if (isRoom) {
      endTime = time.setHours(
        time.getHours() + DEFAULT_MEETING_DURATION,
        time.getMinutes(),
      )
    }
    endTime = new Date(endTime)
    setToValue(
      getWorkingHour(
        endTime.getHours(),
        endTime.getMinutes(),
        endTime,
        timeFormat,
      ),
    )

    setSelectedSlot({
      start: newFromValue,
      end: { value: endTime },
    })
    registerSlot({
      start: newFromValue,
      end: { value: endTime },
    })

    const optionsTo = timeOptionsАvailableFrom.slice(1)

    setТimeOptionsАvailableTo(optionsTo)
  }

  const showDatePicker = hasClaimOnBehalfPermission
  const [selectedSlot, setSelectedSlot] = useState({
    start: defaultStartTime,
    end: defaultEndTime,
  })
  const [valueFrom, setValueFrom] = useState(selectedSlot.start)
  const [valueTo, setValueTo] = useState(selectedSlot.end)
  const currentTimeOptions = timeOptions(
    intervalInMinutes,
    selectedFromDate,
    isFuture ? DEFAULT_OPTIONS_START_HOUR : startHour,
    isFuture
      ? DEFAULT_OPTIONS_START_MINUTES
      : getStartMinutesOfInterval(startMinute, intervalInMinutes),
    timeFormat,
  )
  const [timeOptionsАvailableFrom, setТimeOptionsАvailableFrom] = useState(
    currentTimeOptions,
  )
  const [timeOptionsАvailableTo, setТimeOptionsАvailableTo] = useState(
    currentTimeOptions,
  )

  useEffect(() => {
    setSelectedFromDate(selectedDate)
    setSelectedToDate(selectedDate)
    defineToOptions(defaultStartTime)
    setValueTo(defaultEndTime)
  }, [selectedDate, type]) // eslint-disable-line react-hooks/exhaustive-deps

  const setToValue = (newToTime) => {
    const toDateAndTime = JSON.parse(JSON.stringify(newToTime))
    toDateAndTime.value = set(selectedToDate, {
      hours: getHours(newToTime.value),
      minutes: getMinutes(newToTime.value),
    })
    setValueTo(toDateAndTime)
    setSelectedSlot({
      start: selectedSlot.start,
      end: toDateAndTime,
    })
    registerSlot({
      start: {
        value: set(selectedFromDate, {
          hours: getHours(selectedSlot.start.value),
          minutes: getMinutes(selectedSlot.start.value),
        }),
      },
      end: {
        value: toDateAndTime.value,
      },
    })
  }

  useEffect(() => {
    setТimeOptionsАvailableFrom(currentTimeOptions)
    setТimeOptionsАvailableTo(currentTimeOptions)
    defineToOptions(valueFrom)
    setToValue(valueTo)
  }, [selectedFromDate, selectedToDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const getLastDay = (fromDate) => {
    return maxDurationAllowed ? addDays(fromDate, maxDurationAllowed) : null
  }

  const handleNewFromDate = (newFromDate) => {
    const newEndOfAvailablePeriod = getLastDay(newFromDate)
    setSelectedFromDate(newFromDate)
    if (newFromDate > selectedToDate) setSelectedToDate(newFromDate)
    if (newEndOfAvailablePeriod && selectedToDate > newEndOfAvailablePeriod) {
      setSelectedToDate(newEndOfAvailablePeriod)
    }
  }

  const handleNewToDate = (newToDate) => {
    const endOfAvailablePeriod = getLastDay(selectedFromDate)
    if (newToDate < selectedFromDate) {
      setSelectedToDate(selectedFromDate)
    } else if (endOfAvailablePeriod && newToDate > endOfAvailablePeriod) {
      setSelectedToDate(endOfAvailablePeriod)
    } else {
      setSelectedToDate(newToDate)
    }
  }
  maxDaysAllowed = addDayIfPastMidday(maxDaysAllowed)
  return (
    <TimePickers isSpace={isSpace}>
      <TimePickerItem>
        <span>
          <Translate id="quickView.form.start" />
        </span>
        {showDatePicker && (
          <StyledDatePickerCom
            elId={`from`}
            minDateValue={new Date()}
            maxDateValue={
              maxDaysAllowed ? addDays(new Date(), maxDaysAllowed) : null
            }
            preSelectedValue={selectedFromDate}
            selectDate={handleNewFromDate}
            events={eventsPerAsset}
            currentUserId={selfId}
          >
            <p>{format(selectedFromDate, 'yyyy - MM - dd')}</p>
          </StyledDatePickerCom>
        )}
        <StyledTimePicker
          value={valueFrom}
          options={timeOptionsАvailableFrom}
          selectValue={defineToOptions}
          openMenu={clickFrom}
        >
          <Icon name={icons.CLOCK} onClick={() => setClickFrom(!clickFrom)} />
        </StyledTimePicker>
      </TimePickerItem>
      <TimePickerItem>
        <span>
          <Translate id="quickView.form.end" />
        </span>
        {showDatePicker && (
          <StyledDatePickerCom
            elId={`to`}
            minDateValue={selectedFromDate}
            maxDateValue={
              maxDurationAllowed
                ? getLastDay(selectedFromDate)
                : addDays(new Date(), maxDaysAllowed)
            }
            selectDate={handleNewToDate}
            events={eventsPerAsset}
            preSelectedValue={selectedToDate}
            currentUserId={selfId}
          >
            <p>{format(selectedToDate, 'yyyy - MM - dd')}</p>
          </StyledDatePickerCom>
        )}
        <StyledTimePicker
          value={valueTo}
          options={timeOptionsАvailableTo}
          selectValue={setToValue}
          openMenu={clickТо}
        >
          <Icon name={icons.CLOCK} onClick={() => setClickТо(!clickТо)} />
        </StyledTimePicker>
      </TimePickerItem>
    </TimePickers>
  )
}

TimePickerArea.propTypes = {
  cancel: PropTypes.func,
  selectedDate: PropTypes.string,
  registerSlot: PropTypes.func,
  clearRegisterSlot: PropTypes.func,
  buildingId: PropTypes.number,
  type: PropTypes.string,
  clientFeatures: PropTypes.object,
  futureSpace: PropTypes.bool,
  eventsPerAsset: PropTypes.array,
  hasClaimOnBehalfPermission: PropTypes.bool,
  maxDaysAllowed: PropTypes.number,
  maxDurationAllowed: PropTypes.number,
  defaultSpaceStartTime: PropTypes.string,
  defaultSpaceEndTime: PropTypes.string,
  selfId: PropTypes.number,
  selectedInterval: PropTypes.object,
  timeFormat: PropTypes.string,
  meetingTime: PropTypes.object,
  showDashboard: PropTypes.string,
}

const mapStateToProps = (state) => ({
  defaultSpaceStartTime: getDefaultStart(state),
  defaultSpaceEndTime: getDefaultEnd(state),
  clientFeatures: getClientFeatures(state),
  hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
  maxDaysAllowed: getMaxDaysAllowed(state),
  maxDurationAllowed: getMaxDurationAllowed(state),
  selfId: getSelfId(state),
  selectedInterval: getSelectedInterval(state),
  timeFormat: getTimeFormat(state),
  meetingTime: getMeetingTime(state),
  showDashboard: getShowDashboard(state),
})

export default connect(mapStateToProps, { registerSlot, clearRegisterSlot })(
  TimePickerArea,
)
