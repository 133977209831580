import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Translate } from 'react-localize-redux'
import {
  withStyles,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

import { getNumSeatsFilter } from 'containers/searchPanel/filter/selectors'
import { setNumSeatsFilter } from 'containers/searchPanel/filter/actions'
import toJS from 'components/toJS'
import { getSpaces } from 'containers/app/selectors'

const SliderContainer = styled.div`
  background-color: ${(props) => props.theme.panelBgLight};
  color: ${(props) => props.theme.panelActiveBg};
  padding: 0;
  padding-bottom: 16px;
`

const SliderCaption = styled.div`
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
`

const createCustomTheme = (props) => {
  const { theme } = props

  return createTheme({
    palette: {
      primary: {
        main: theme.panelActiveBg,
      },
    },
    typography: {
      useNextVariants: true,
    },
  })
}

class MinSeatsFilter extends Component {
  handleChangeSeats = (event, sliderValue) => {
    const { setNumSeatsFilter } = this.props
    setNumSeatsFilter(sliderValue)
  }

  getCurrentSeatFilter = () => {
    const { numSeats } = this.props
    if (numSeats === 0) {
      return <Translate id="filter.anySeat" />
    } else return numSeats
  }

  calculateMaxSeats = () => {
    const { spaces } = this.props
    return Math.max.apply(
      Math,
      Object.values(spaces).map((space) => {
        return space.info.cap
      }),
    )
  }

  render() {
    const { numSeats } = this.props
    return (
      <SliderContainer>
        <SliderCaption>
          <Translate id="filter.minNumberOfSeats" />{' '}
          {this.getCurrentSeatFilter()}
        </SliderCaption>
        <MuiThemeProvider theme={createCustomTheme(this.props)}>
          <Slider
            value={numSeats}
            onChange={this.handleChangeSeats}
            min={0}
            max={this.calculateMaxSeats()}
            step={2}
          />
        </MuiThemeProvider>
      </SliderContainer>
    )
  }
}

MinSeatsFilter.propTypes = {
  numSeats: PropTypes.number,
  setNumSeatsFilter: PropTypes.func,
  spaces: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    numSeats: getNumSeatsFilter(state),
    spaces: getSpaces(state),
  }
}

const withConnect = connect(mapStateToProps, { setNumSeatsFilter })

export default withStyles({})(
  compose(withConnect, withTheme)(toJS(MinSeatsFilter)),
)
