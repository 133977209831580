import PropTypes from 'prop-types'
import React from 'react'
import { withLocalize } from 'react-localize-redux'

import PanelButton from 'containers/mainPanel/PanelButton'
import toJS from 'components/toJS'

const TimeFormatItem = ({ format, active, onClick }) => (
  <PanelButton active={active} onClick={() => !active && onClick(format.value)}>
    {format.label}
  </PanelButton>
)

TimeFormatItem.propTypes = {
  active: PropTypes.bool,
  format: PropTypes.object,
  onClick: PropTypes.func,
}

export default withLocalize(toJS(TimeFormatItem))
