import { combineReducers } from 'redux-immutable'

import {
  OPEN_DRAWER,
  CLEAR_DRAWER,
  OPEN_INNER_DRAWER,
  CLEAR_INNER_DRAWER,
} from 'containers/mainPanel/panelDrawer/constants'
import { SET_FLOOR, SET_BUILDING } from 'containers/app/constants'

const active = (state = '', action) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return action.name
    case CLEAR_DRAWER:
    case SET_FLOOR:
    case SET_BUILDING:
      return ''
    default:
      return state
  }
}

const innerActive = (state = '', action) => {
  switch (action.type) {
    case OPEN_INNER_DRAWER:
      return action.name
    case CLEAR_INNER_DRAWER:
    case SET_FLOOR:
    case SET_BUILDING:
      return ''
    default:
      return state
  }
}

export default combineReducers({
  active,
  innerActive,
})
