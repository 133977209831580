import styled from 'styled-components'

const Main = styled.main`
  flex: 1 1 auto;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
`

export default Main
