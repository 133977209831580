import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Row from 'components/form/Row'
import Label from 'components/form/Label'

const OAuthSuccess = () => {
  const history = useHistory()

  useEffect(() => {
    window.localStorage.setItem('oauthSuccess', true)
    history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row>
      <Label>OAuth Success!</Label>
    </Row>
  )
}

export default OAuthSuccess
