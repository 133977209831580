import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import FilterItemPNG from 'containers/searchPanel/filter/FilterItemPNG'
import {
  setPropFilter,
  clearFilter,
  setAvailablityFilter,
  clearDataset,
} from 'containers/searchPanel/filter/actions'
import { getPropFilters } from 'containers/searchPanel/filter/selectors'
import { getDeskFiltersEquip } from 'containers/app/selectors'
import toJS from 'components/toJS'
import {
  getEquipmentInfo,
  getEquipmentIcons,
} from 'containers/searchPanel/results/selectors'
import FilterItemGrid from 'containers/searchPanel/filter/FilterItemGrid'
import FilterContainer from 'containers/searchPanel/filter/FilterContainer'
import FilterButton from 'containers/searchPanel/filter/FilterButton'
import FilterButtonRow from 'containers/searchPanel/filter/FilterButtonRow'
import { propsMap } from 'containers/searchPanel/filter/constants'

const DeskFilters = (props) => {
  const { propFilters, clearFilter, clearDataset, deskEquipment, icons } = props

  const handleClick = (id) => {
    const { setPropFilter, propFilters } = props
    const key = id
    const subFilter = get(propFilters, key)
    setPropFilter(key, !subFilter)
  }
  return (
    <FilterContainer>
      <Translate>
        {({ translate }) => (
          <FilterItemGrid>
            {deskEquipment.map((deskMetaProp) => (
              <FilterItemPNG
                key={deskMetaProp.uid}
                active={get(propFilters, deskMetaProp.uid)}
                onClick={() => handleClick(deskMetaProp.uid)}
                value={deskMetaProp.uid}
                icon={icons[deskMetaProp.uid]}
                caption={
                  propsMap[deskMetaProp.name]
                    ? translate(propsMap[deskMetaProp.name])
                    : deskMetaProp.name
                }
              />
            ))}
          </FilterItemGrid>
        )}
      </Translate>
      <FilterButtonRow>
        <FilterButton dark onClick={() => clearFilter()}>
          <Translate id="filter.title.clear" />
        </FilterButton>
        <FilterButton onClick={() => clearDataset()}>
          <Translate id="filter.title.hide" />
        </FilterButton>
      </FilterButtonRow>
    </FilterContainer>
  )
}

DeskFilters.propTypes = {
  setPropFilter: PropTypes.func,
  propFilters: PropTypes.object,
  clearFilter: PropTypes.func,
  clearDataset: PropTypes.func,
  deskEquipment: PropTypes.array,
  icons: PropTypes.object,
}

const mapStateToProps = (state) => {
  const deskEquipmentIds = getDeskFiltersEquip(state)
  const equipment = getEquipmentInfo(state)
  const deskEquipment = deskEquipmentIds.map((id) => equipment.get(`${id}`))
  const icons = getEquipmentIcons(state)
  return {
    propFilters: getPropFilters(state),
    deskEquipment,
    icons,
  }
}

export default connect(mapStateToProps, {
  setPropFilter,
  clearFilter,
  clearDataset,
  setAvailablityFilter,
})(toJS(DeskFilters))
