import styled from 'styled-components'
import { Scheduler } from '@progress/kendo-react-scheduler'

const CustomScheduler = styled(Scheduler)`
  background-color: ${(props) => props.theme.panelBgLight};
  color: ${(props) => props.theme.darkBgr};
  border: 0;

  div.k-button-group.k-scheduler-navigation {
    display: none;
  }
  div.k-toolbar,
  div.k-scheduler-head,
  div.k-scheduler-body,
  div.k-sticky-cell,
  div.k-nonwork-hour {
    background-color: ${(props) => props.theme.panelBgLight};
    border-color: ${(props) => props.theme.darkBgr};
  }

  div.k-scheduler-toolbar,
  div.k-scheduler-footer {
    padding-left: 32px;
    padding-right: 36px;
  }

  div.k-scheduler-cell {
    min-height: 20px;

    &.k-heading-cell {
      font-weight: normal;
      justify-content: left;
      padding-left: 1em;
    }
  }

  div.k-group-content .k-scheduler-row:last-child .k-heading-cell {
    justify-content: left;
    padding-left: 4px;
  }

  .k-resource-row .k-scheduler-row {
    cursor: pointer;
  }

  .k-button,
  .k-button.k-nav-current {
    background: 0;
    color: ${(props) => props.theme.darkBgr};
    border: 0;
    padding: 6px 11px;

    &:hover {
      color: ${(props) => props.theme.darkBgr};
      background-image: none;
      background-color: #d1d3d4;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px rgb(255 255 255 / 13%);
    }
  }

  span.k-icon.k-svg-icon.k-svg-i-calendar.k-button-icon {
    display: none;
  }

  button.k-button.k-button-md.k-button-flat.k-button-flat-base.k-rounded-md.k-nav-current {
    pointer-events: none;
  }

  /* Use flowyfont icons */
  .k-button span.k-icon {
    font-family: 'flowyfont' !important;

    &.k-i-arrow-60-left:before {
      content: '\\e93e';
    }
    &.k-i-arrow-60-right:before {
      content: '\\e941';
    }
    &.k-i-calendar:before {
      content: '\\e946';
    }
    &.k-i-clock:before {
      content: '\\e924';
    }
  }

  div.k-event {
    margin-top: 0 !important;
  }

  .k-event .k-event-actions span.k-icon {
    font-family: 'flowyfont' !important;
    font-weight: 600;

    &.k-i-close:before {
      content: '\\e834';
    }
  }

  div.k-scheduler-views {
    background-color: ${(props) => props.theme.panelBgLight};
    border-color: ${(props) => props.theme.panelBgLight};
    border-radius: 2px;
    padding: 2px;

    & .k-button {
      width: 72px;
      background-color: ${(props) => props.theme.panelBgLight};
      &:hover {
        background-image: none;
        background-color: transparent;
        text-decoration: underline;
      }

      &:focus {
        box-shadow: initial;
      }

      &.k-state-selected {
        background-image: none;
        background-color: ${(props) => props.theme.panelBgLight};
      }

      &:not(:last-of-type):after {
        content: ' |';
        color: ${(props) => props.theme.darkBgr};
        font-size: 18px;
        display: inline;
        opacity: 1;
        left: auto;
        top: auto;
        right: -2px;
        bottom: auto;
        z-index: 3;
      }
    }
  }
`

export default CustomScheduler
