import React from 'react'
import { useSelector } from 'react-redux'

import Header from 'containers/dashboard/Header'
import Swimlanes from 'containers/dashboard/Swimlanes'
import { getShowDashboard } from 'containers/dashboard/selectors'
import {
  DashboardContainer,
  Modal,
} from 'containers/dashboard/swimlanes/components/DashboardContainer'

const Dashboard = () => {
  const showDashboard = useSelector((state) => getShowDashboard(state))
  if (!showDashboard) return null

  const swimlanes = () => {
    return (
      <Modal>
        <DashboardContainer>
          <Header />
          <Swimlanes />
        </DashboardContainer>
      </Modal>
    )
  }

  if (showDashboard === 'swimlanes') {
    return swimlanes()
  }
}

export default Dashboard
