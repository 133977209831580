import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import StatusIndicator from 'components/StatusIndicator'

const Wrapper = styled.div`
  position: relative;
  margin-right: 18px;
  width: 36px;
  height: 36px;
  font-size: 16px;
  font-weight: 500;

  ${Icon} {
    margin: 0;
    font-size: 22px;
  }

  ${StatusIndicator} {
    height: 12px;
    width: 12px;
    position: absolute;
    right: 14.58%;
    bottom: 14.58%;
    transform: translate(50%, 50%);
    border-radius: 50%;
  }
`

const Circle = styled.div`
  border-radius: 50%;
  background-color: #999;
  color: ${(props) => props.theme.globalBG};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: ${(props) => props.theme.holidayResult};
`

class ResultSymbol extends Component {
  render() {
    const { status, children } = this.props

    return (
      <Wrapper>
        <Circle>{children}</Circle>
        <StatusIndicator status={status} />
      </Wrapper>
    )
  }
}

ResultSymbol.propTypes = {
  status: PropTypes.oneOf([
    'OFFLINE',
    'CLAIMED',
    'FREE',
    'BOOKED',
    'BUSY',
    'NONE',
    'AWAY',
  ]),
  children: PropTypes.any,
}

export default ResultSymbol
