import styled, { css } from 'styled-components'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

const SearchInputHolder = styled.div`
  width: 100%;
  display: flex;
`
const SearchInput = styled.input.attrs({
  type: 'search',
})`
  width: 500px;
  height: 44px;
  line-height: 44px;
  margin: 45px auto 34px auto;
  border: 1px solid #525252;
  font-family: Roboto;
  font-size: 16px;
  color: #5b5757;
  padding-left: 8px;
`
export const SearchInputFilter = styled(SearchInput)`
  width: 446px;
  margin-left: 16px;
`
const SearchIcon = styled.button`
  width: 40px;
  height: 40px;
  line-height: 32px;
  background-color: transparent;
  border: none;
  background-image: url(./Filter_light.svg);
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: center;
  margin: 48px 19px 34px 0;
  position: relative;
  cursor: pointer;
  &:hover {
    background-image: url(./filter2.svg);
  }
  ${(props) =>
    props.appliedFilter &&
    css`
      &:before {
        background-color: #0178d4;
        border-color: #0178d4;
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 12px;
      }
    `}
`

class SearchForBuilding extends Component {
  render() {
    const {
      onChange,
      handleOpenFilter,
      selectFloor,
      appliedFilter,
      hideFilter,
    } = this.props

    return (
      <SearchInputHolder>
        <Translate>
          {({ translate }) =>
            selectFloor || hideFilter ? (
              <SearchInput
                placeholder={translate('billboard.searchPanel.search')}
                onChange={(event) => onChange(event.target.value)}
              />
            ) : (
              <SearchInputFilter
                placeholder={translate('billboard.searchPanel.search')}
                onChange={(event) => onChange(event.target.value)}
              />
            )
          }
        </Translate>
        {!(selectFloor || hideFilter) && (
          <SearchIcon
            appliedFilter={appliedFilter}
            onClick={handleOpenFilter}
          />
        )}
      </SearchInputHolder>
    )
  }
}

SearchForBuilding.propTypes = {
  onChange: PropTypes.func,
  handleOpenFilter: PropTypes.func,
  selectFloor: PropTypes.bool,
  appliedFilter: PropTypes.bool,
  hideFilter: PropTypes.bool,
}

export default SearchForBuilding
