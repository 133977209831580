import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { isFuture } from 'date-fns'

import {
  getSpaceResults,
  getUserResults,
} from 'containers/searchPanel/search/selectors'
import SpaceResults from 'containers/searchPanel/results/SpaceResults'
import SearchResults from 'containers/searchPanel/results/SearchResults'
import UserResults from 'containers/searchPanel/results/UserResults'
import {
  getActiveDataset,
  getDateFilter,
} from 'containers/searchPanel/filter/selectors'
import { getShowSearchResults } from 'containers/searchPanel/results/selectors'
import NoResults from 'containers/searchPanel/results/NoResults'
import { getFutureDeskbookingEnabled } from 'containers/app/selectors'

const Results = ({
  spaceResults,
  userResults,
  activeDataset,
  showSearchResults,
  selectedDate,
  deskBookingEnabled,
}) => {
  if (showSearchResults) {
    if (spaceResults.size || userResults.size) {
      return <SearchResults />
    } else {
      return (
        <NoResults>
          <Translate id="filter.noResultsFound" />
        </NoResults>
      )
    }
  }
  if (deskBookingEnabled && isFuture(selectedDate)) {
    return <SpaceResults />
  }
  switch (activeDataset) {
    case 'users':
      return <UserResults />
    default:
      return <SpaceResults />
  }
}

const mapStateToProps = (state) => ({
  spaceResults: getSpaceResults(state),
  userResults: getUserResults(state),
  activeDataset: getActiveDataset(state),
  showSearchResults: getShowSearchResults(state),
  selectedDate: getDateFilter(state),
  deskBookingEnabled: getFutureDeskbookingEnabled(state),
})

Results.propTypes = {
  spaceResults: PropTypes.object,
  userResults: PropTypes.object,
  activeDataset: PropTypes.oneOf(['desks', 'users', 'rooms', '']),
  showSearchResults: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  deskBookingEnabled: PropTypes.bool,
}

export default connect(mapStateToProps)(Results)
