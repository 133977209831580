import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

import ModalRow from 'containers/quickView/modal/ModalRow'
import SpacePropGrid from 'containers/quickView/spaceModal/SpacePropGrid'
import SpaceProp from 'containers/quickView/spaceModal/SpaceProp'

const ScrollableModalRow = styled(ModalRow)`
  max-height: 100%;
`

const ScrollableSpacePropGrid = styled(SpacePropGrid)`
  max-height: 20rem;
  overflow-y: auto;
`
class SpaceProps extends Component {
  render() {
    const { spaceProps } = this.props
    if (!spaceProps) {
      return null
    }
    return (
      <ScrollableModalRow>
        <ScrollableSpacePropGrid>
          {spaceProps.map((prop) => {
            return <SpaceProp key={prop} id={prop} />
          })}
        </ScrollableSpacePropGrid>
      </ScrollableModalRow>
    )
  }
}

SpaceProps.propTypes = {
  spaceProps: PropTypes.array,
}

export default SpaceProps
