import styled from 'styled-components'

const ResultText = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.theme.panelColor};
  font-size: 14px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > span:first-child {
    color: ${(props) => props.theme.panelBgLight};
    font-size: 16px;
    font-weight: 500;
  }
`

export default ResultText
