import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

const CheckboxLabel = styled.label`
  &:before {
    width: 19px;
    content: '';
    height: 19px;
    display: inline-block;
    background-color: transparent;
    position: absolute;
    left: 23px;
    border-radius: 2px;
    border: solid 1px #979797;
  }
  ${(props) =>
    props.active &&
    css`
      &:before {
        background-color: #0178d4;
        border-color: #0178d4;
        background-image: url(./checkmark_white.svg);
        fill: white;
        background-size: cover;
      }
    `}
  margin: 20px 0 20px 55px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  display: block;
`
const CheckboxInput = styled.input`
  display: none;
`
class SiteCheckboxRow extends Component {
  render() {
    const { id, name, active, handleSiteSelection } = this.props

    return (
      <>
        <CheckboxLabel htmlFor={id} active={active}>
          {name}
        </CheckboxLabel>
        <CheckboxInput
          type="checkbox"
          onChange={() => handleSiteSelection(id)}
          defaultChecked={active}
          id={id}
        />
      </>
    )
  }
}
SiteCheckboxRow.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  handleSiteSelection: PropTypes.func,
}
export default SiteCheckboxRow
