import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import createSagaMiddleware from 'redux-saga'

import rootReducer from 'reducers'
import rootSaga from 'sagas'
import storeObserver from 'utils/storeObserver'
import { loadState, updateState } from 'utils/localStorage'

const sagaMiddleware = createSagaMiddleware()

const store = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const middlewares = [sagaMiddleware]

  const persistedState = fromJS(loadState())

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  )

  sagaMiddleware.run(rootSaga)

  storeObserver(
    store,
    (state) => state.getIn(['app', 'auth', 'token']),
    (token) => token && updateState(['app', 'auth', 'token'], token),
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('reducers', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

export default store
