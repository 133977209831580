import fetch from 'isomorphic-fetch'

import { config } from 'api/public'

export const getSelectedColleagues = async () => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}/secure/v2/favorite`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const addColleagueToSelected = async (userId) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}/secure/v2/favorite/${userId}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  })
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const removeColleagueFromSelected = async (userId) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}/secure/v2/favorite/${userId}`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return Promise.resolve(response)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}
