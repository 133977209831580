import { all } from 'redux-saga/effects'

import updatesSagas from 'containers/app/sagas/updates'
import wayfindingSagas from 'containers/app/sagas/wayfinding'
import clientConfigSagas from 'containers/app/sagas/clientConfig'
import logoutSagas from 'containers/app/sagas/logout'
import consentSagas from 'containers/app/sagas/consentSagas'
import locateSagas from 'containers/app/sagas/locate'
import filterSagas from 'containers/app/sagas/filter'

function* sagas() {
  yield all([
    updatesSagas(),
    wayfindingSagas(),
    clientConfigSagas(),
    logoutSagas(),
    consentSagas(),
    locateSagas(),
    filterSagas(),
  ])
}

export default sagas
