import PropTypes from 'prop-types'
import React from 'react'
import { withLocalize } from 'react-localize-redux'

import PanelButton from 'containers/mainPanel/PanelButton'
import toJS from 'components/toJS'

const LocaleItem = ({ language, active, onClick }) => (
  <PanelButton
    active={active}
    onClick={() => !active && onClick(language.code)}
  >
    {language.name}
  </PanelButton>
)

LocaleItem.propTypes = {
  active: PropTypes.bool,
  language: PropTypes.object,
  onClick: PropTypes.func,
}

export default withLocalize(toJS(LocaleItem))
