import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'

import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import { clearInnerDrawer } from 'containers/mainPanel/panelDrawer/actions'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'
import { icons } from 'components/icon/icons'
import TimeFormatItem from 'containers/mainPanel/timeFormats/TimeFormatItem'
import { setTimeFormat } from 'containers/mainPanel/timeFormats/actions'
import { InnerDrawerWidth } from 'utils/appVars'

const timeFormats = [
  { label: 'AM/PM', value: 'hh:mm a' },
  { label: '24 hr', value: 'HH:mm' },
]

class TimeFormatPicker extends Component {
  selectTimeFormat = (format) => {
    const { setTimeFormat, clearInnerDrawer } = this.props
    setTimeFormat(format)
    localStorage.setItem('timeFormat', format)
    clearInnerDrawer()
  }

  render() {
    const { currentTimeFormat } = this.props
    const selectedFormat = timeFormats.find(
      (format) => format.value === currentTimeFormat,
    )

    return (
      <PanelDrawer
        iconName={icons.CLOCK}
        label={selectedFormat.label}
        size={timeFormats.length}
        width={InnerDrawerWidth}
      >
        {timeFormats.map((format) => (
          <TimeFormatItem
            key={format.label}
            active={currentTimeFormat === format.value}
            format={format}
            onClick={this.selectTimeFormat}
          />
        ))}
      </PanelDrawer>
    )
  }
}
TimeFormatPicker.propTypes = {
  timeFormats: PropTypes.arrayOf(PropTypes.object),
  clearInnerDrawer: PropTypes.func,
  setTimeFormat: PropTypes.func,
  currentTimeFormat: PropTypes.string,
}

const mapStateToProps = (state) => ({
  currentTimeFormat: getTimeFormat(state),
})

export default withLocalize(
  connect(mapStateToProps, { clearInnerDrawer, setTimeFormat })(
    TimeFormatPicker,
  ),
)
