import { makeSelector } from 'containers/app/selectors'

const profilePictures = (state) => state.getIn(['results', 'profilePictures'])
const equipmentInfo = (state) => state.getIn(['results', 'equipmentInfo'])
const equipmentIcons = (state) => state.getIn(['results', 'equipmentIcons'])
const activeByIndex = (state) => state.getIn(['results', 'activeByIndex'])
const showSearchResults = (state) =>
  state.getIn(['results', 'showSearchResults'])
const selectedColleagues = (state) =>
  state.getIn(['results', 'getSelectedColleagues'])

export const getProfilePictures = makeSelector(profilePictures)
export const getActiveByIndex = makeSelector(activeByIndex)
export const getShowSearchResults = makeSelector(showSearchResults)
export const getSelectedColleagues = makeSelector(selectedColleagues)
export const getEquipmentInfo = makeSelector(equipmentInfo)
export const getEquipmentIcons = makeSelector(equipmentIcons)
