import { combineReducers } from 'redux-immutable'
import { Map } from 'immutable'

import { FETCH_DESK_BOOKINGS_SUCCESS } from '../constants'

import { CLEAR_UPDATES } from 'containers/app/constants'

export const createByIdReducer = (persistant = false) => (
  state = Map(),
  action,
) => {
  if (!persistant && action.type === CLEAR_UPDATES) {
    return new Map()
  }
  if (action.type === FETCH_DESK_BOOKINGS_SUCCESS) {
    return action.response.bookings
  }

  return state
}

export default combineReducers({
  deskBookings: createByIdReducer(true),
})
