import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import QuickViewBackdrop from 'containers/quickView/QuickViewBackdrop'
import Modal from 'containers/quickView/Modal'
import { clearQuickView } from 'containers/quickView/actions'
import { getSigns } from 'containers/app/selectors'
import toJS from 'components/toJS'
import ModalStatus from 'containers/quickView/modal/ModalStatus'
import { deselect } from 'api/unity'
import SignInfo from 'containers/quickView/signModal/SignInfo'
import SignActions from 'containers/quickView/signModal/SignActions'

class SignModal extends Component {
  handleClose = () => {
    const { clearQuickView } = this.props
    clearQuickView()
    deselect()
  }

  render() {
    const { sign } = this.props

    return (
      <Fragment>
        <QuickViewBackdrop />
        <Modal>
          <ModalStatus onClick={this.handleClose}>
            <Translate id="quickView.pointOfInterest" />
          </ModalStatus>
          <SignInfo sign={sign} />
          <SignActions sign={sign} />
        </Modal>
      </Fragment>
    )
  }
}

SignModal.propTypes = {
  clearQuickView: PropTypes.func,
  sign: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  const sign = getSigns(state).get(`${props.id}`)

  return {
    sign,
  }
}

export default connect(mapStateToProps, { clearQuickView })(toJS(SignModal))
