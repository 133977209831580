import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import TouchActionsWrapper from 'containers/touchActions/TouchActionsWrapper'
import FloatingActionButton from 'containers/touchActions/FloatingActionButton'
import FlexSpacer from 'components/FlexSpacer'
import { rotateCamera, zoomCamera } from 'api/unity'
import { stopWayfind } from 'containers/app/actions'
import { getIsWayfinding } from 'containers/unityLoader/selectors'
import { icons } from 'components/icon/icons'
class TouchActions extends Component {
  render() {
    const { isWayfinding, stopWayfind } = this.props

    return (
      <TouchActionsWrapper>
        <FloatingActionButton
          iconName={icons.MINUS}
          onClick={() => zoomCamera(15)}
        />
        <FloatingActionButton
          iconName={icons.PLUS}
          onClick={() => zoomCamera(-15)}
        />
        <FlexSpacer />
        <FloatingActionButton
          iconName={icons.CLOSE}
          type="cancel"
          onClick={() => stopWayfind()}
          visible={isWayfinding}
        >
          <span>
            <Translate id="billboard.stopWayfinding" />
          </span>
        </FloatingActionButton>

        <FlexSpacer />
        <FloatingActionButton
          iconName={icons.ROTATE_LEFT}
          onClick={() => rotateCamera(-30)}
        />
        <FloatingActionButton
          iconName={icons.ROTATE_RIGHT}
          onClick={() => rotateCamera(30)}
        />
      </TouchActionsWrapper>
    )
  }
}

TouchActions.propTypes = {
  isWayfinding: PropTypes.bool,
  stopWayfind: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isWayfinding: getIsWayfinding(state),
})

export default connect(mapStateToProps, { stopWayfind })(TouchActions)
