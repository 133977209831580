export const SEARCH_REQUEST = 'containers/searchPanel/SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'containers/searchPanel/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'containers/searchPanel/SEARCH_FAILURE'
export const SEARCH_CLEAR = 'containers/searchPanel/SEARCH_CLEAR'
export const SEARCH_SET_QUERY = 'containers/searchPanel/SEARCH_SET_QUERY'
export const SEARCH_CLEAR_QUERY = 'containers/searchPanel/SEARCH_CLEAR_QUERY'
export const FUTURE_SEARCH_REQUEST =
  'containers/searchPanel/FUTURE_SEARCH_REQUEST'
export const FUTURE_SEARCH_SUCCESS =
  'containers/searchPanel/FUTURE_SEARCH_SUCCESS'
