import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { SchedulerEditSlot } from '@progress/kendo-react-scheduler'

import { setMeetingTime } from 'containers/dashboard/actions'
import { selectSpaceId } from 'containers/quickView/actions'
import { setDateFilter } from 'containers/searchPanel/filter/actions'

const CustomEditSlot = (props) => {
  const dispatch = useDispatch()

  const [formItem, setFormItem] = useState(null)

  const handleSlotClick = () => {
    const startTime = new Date(props.start)
    const endTime = new Date(props.end)
    dispatch(setDateFilter(props.start))
    dispatch(setMeetingTime(startTime, endTime))
    dispatch(selectSpaceId(props.group.resources[0].value))
  }

  return (
    <SchedulerEditSlot
      {...props}
      formItem={formItem}
      onDoubleClick={handleSlotClick}
    />
  )
}

CustomEditSlot.propTypes = {
  isWorkDay: PropTypes.bool,
  isWorkHour: PropTypes.bool,
  start: PropTypes.object,
  end: PropTypes.object,
  group: PropTypes.object,
}

export default CustomEditSlot
