import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Button from 'components/Button'
import PanelHead from 'components/panel/PanelHead'
import Panel from 'components/Panel'
import WaveBackground from 'components/Wave'
import { approveUserConsent } from 'containers/app/actions'
import { getUserConsent } from 'containers/app/selectors'

const ConsentPanel = styled(Panel)`
  position: absolute;
  width: 900px;
  max-height: 580px;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`
const ConsentRow = styled.div`
  max-height: 500px;
  width: 870px;
  overflow-y: auto;
  margin: 16px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fff;
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`

const ConsentText = styled.div`
  padding: 16px;
  font-size: 16px;
  left: 0;
  right: 0;
  margin: auto;
`

const ConsentButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 16px;
`

class UserConsent extends Component {
  componentDidMount() {}

  handleConsent = () => {
    const { approveUserConsent } = this.props
    approveUserConsent()
  }

  render() {
    const { userConsent } = this.props

    return (
      <Fragment>
        <WaveBackground />
        <ConsentPanel>
          <PanelHead>
            <b>Flowscape</b> Webapp
          </PanelHead>
          <ConsentRow>
            <ConsentText>{userConsent && userConsent.consentText}</ConsentText>
          </ConsentRow>
          <ConsentButtonRow>
            <Button onClick={this.handleConsent}>I accept</Button>
          </ConsentButtonRow>
        </ConsentPanel>
      </Fragment>
    )
  }
}

UserConsent.propTypes = {
  approveUserConsent: PropTypes.func,
  userConsent: PropTypes.object,
}

const mapStateToProps = (state) => ({
  userConsent: getUserConsent(state),
})

export default connect(mapStateToProps, { approveUserConsent })(UserConsent)
