import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import ErrorDialogButton from 'containers/quickView/errorDialog/ErrorDialogButton'
import ErrorMessage from 'containers/quickView/errorDialog/ErrorMessage'
import ErrorMessageHeader from 'containers/quickView/errorDialog/ErrorMessageHeader'
import ErrorPopup from 'containers/quickView/errorDialog/ErrorPopup'
import { clearQuickView } from 'containers/quickView/actions'
import { deselect } from 'api/unity'

const ErrorDialog = ({ clearQuickView }) => {
  const handleClick = () => {
    clearQuickView()
    deselect()
  }
  return (
    <ErrorPopup>
      <ErrorMessage>
        <ErrorMessageHeader>
          <Translate id="quickView.dialog.sorry" />
        </ErrorMessageHeader>
        <Translate id="quickView.dialog.noPermission" />
      </ErrorMessage>
      <ErrorDialogButton onClick={handleClick}>
        <Translate id="quickView.button.confirm" />
      </ErrorDialogButton>
    </ErrorPopup>
  )
}

ErrorDialog.propTypes = {
  clearQuickView: PropTypes.func,
}
export default connect(null, { clearQuickView })(ErrorDialog)
