import {
  CLAIM_ON_BEHALF_SELECT,
  CLOSE_CLAIM_UI,
  CLAIM_SPACE_REQUEST,
  CLAIM_SPACE_FAILURE,
  CLAIM_SPACE_SUCCESS,
  CLEAR_CLAIM_SPACE_ERROR,
  RELEASE_SPACE_REQUEST,
  RELEASE_SPACE_FAILURE,
  RELEASE_SPACE_SUCCESS,
  CHANGE_CLAIMED_SPACE_REQUEST,
  CHANGE_BOOKED_DESK_REQUEST,
  BOOK_DESK_REQUEST,
  BOOK_DESK_SUCCESS,
  BOOK_DESK_FAILURE,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST,
  GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS,
  GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE,
  GET_ASSET_CALENDAR_REQUEST,
  GET_ASSET_CALENDAR_SUCCESS,
  GET_ASSET_CALENDAR_FAILURE,
  SET_DELETE_ON_BEHALF_REQUEST,
  GET_МAX_ALLOWED_DAYS_SUCCESS,
  GET_МAX_ALLOWED_DURATION_SUCCESS,
  GET_CANCEL_ON_BEHALF_PERMISSION_REQUEST,
  GET_CANCEL_ON_BEHALF_PERMISSION_SUCCESS,
  GET_CANCEL_ON_BEHALF_PERMISSION_FAILURE,
} from 'containers/quickView/spaceModal/claimFlow/constants'

export const openClaimOnBehalfUI = () => ({
  type: CLAIM_ON_BEHALF_SELECT,
})

export const closeClaimUI = () => ({
  type: CLOSE_CLAIM_UI,
})

export const changeClaimedSpace = (fromSpace, toSpace) => ({
  type: CHANGE_CLAIMED_SPACE_REQUEST,
  fromSpace,
  toSpace,
})

export const changeBookedDesk = (fromBookingId, toSpaceId) => ({
  type: CHANGE_BOOKED_DESK_REQUEST,
  fromBookingId,
  toSpaceId,
})

export const releaseSpace = (space) => ({
  type: RELEASE_SPACE_REQUEST,
  space,
})

export const releaseSpaceFailed = (message) => ({
  type: RELEASE_SPACE_FAILURE,
  message,
})

export const releaseSpaceSuccess = () => ({
  type: RELEASE_SPACE_SUCCESS,
})

export const claimSpace = (space) => ({
  type: CLAIM_SPACE_REQUEST,
  space,
})

export const claimSpaceFailed = (message) => ({
  type: CLAIM_SPACE_FAILURE,
  message,
})

export const claimSpaceSuccess = () => ({
  type: CLAIM_SPACE_SUCCESS,
})

export const clearClaimSpaceError = () => ({
  type: CLEAR_CLAIM_SPACE_ERROR,
})

export const bookDesk = (spaceId, userId, slot) => ({
  type: BOOK_DESK_REQUEST,
  spaceId,
  userId,
  slot,
})
export const bookDeskSuccess = () => ({
  type: BOOK_DESK_SUCCESS,
})

export const bookDeskFailure = () => ({ type: BOOK_DESK_FAILURE })

export const cancelDeskBooking = (bookingId) => ({
  type: CANCEL_BOOKING_REQUEST,
  bookingId,
})
export const cancelDeskBookingSuccess = () => ({
  type: CANCEL_BOOKING_SUCCESS,
})

export const getClaimOnBehalfPermission = (spaceId) => ({
  type: GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST,
  spaceId,
})

export const getClaimOnBehalfPermissionSuccess = (hasPermission) => ({
  type: GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS,
  hasPermission,
})
export const getMaxAllowedDaysSuccess = (maxDays) => ({
  type: GET_МAX_ALLOWED_DAYS_SUCCESS,
  maxDays,
})
export const getMaxBookingDurationSuccess = (maxDuration) => ({
  type: GET_МAX_ALLOWED_DURATION_SUCCESS,
  maxDuration,
})

export const getClaimOnBehalfPermissionFailed = (message) => ({
  type: GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE,
  message,
})

export const getAssetCalendar = (spaceId) => ({
  type: GET_ASSET_CALENDAR_REQUEST,
  spaceId,
})

export const getAssetCalendarSuccess = (assetsEvents) => ({
  type: GET_ASSET_CALENDAR_SUCCESS,
  assetsEvents,
})

export const getAssetCalendarFailed = (message) => ({
  type: GET_ASSET_CALENDAR_FAILURE,
  message,
})

export const setDeleteOnBehalfPermission = (spaceId, eventId, start, end) => ({
  type: SET_DELETE_ON_BEHALF_REQUEST,
  spaceId,
  eventId,
  start,
  end,
})

export const getCancelOnBehalfPermission = (spaceId) => ({
  type: GET_CANCEL_ON_BEHALF_PERMISSION_REQUEST,
  spaceId,
})

export const getCancelOnBehalfPermissionSuccess = (hasPermission) => ({
  type: GET_CANCEL_ON_BEHALF_PERMISSION_SUCCESS,
  hasPermission,
})

export const getCancelOnBehalfPermissionFailed = (message) => ({
  type: GET_CANCEL_ON_BEHALF_PERMISSION_FAILURE,
  message,
})
