import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import ResultContainer from 'containers/searchPanel/results/ResultContainer'
import ResultText from 'containers/searchPanel/results/ResultText'
import ResultSymbol from 'containers/searchPanel/results/ResultSymbol'
import SelectColleague from 'containers/searchPanel/results/SelectColleague'
import ProfilePicture from 'components/ProfilePicture'
import {
  selectUser,
  selectUserDetailsRequest,
} from 'containers/quickView/actions'
import { locate } from 'api/unity'

class UserItem extends Component {
  handleClick = () => {
    const { selectUserDetailsRequest, user, onClick, selectUser } = this.props
    onClick && onClick()
    selectUser(user)
    if (
      user.get('id') !== 0 &&
      user.get('id') !== undefined &&
      user.get('floorId') !== undefined
    ) {
      locate(user.get('floorId'), 'user', user.get('id'))
    }
  }

  render() {
    const { user, active } = this.props

    return (
      <ResultContainer onClick={this.handleClick} active={active}>
        <ResultSymbol status={user.getIn(['status', 'state'])}>
          <ProfilePicture
            id={user.get('id')}
            fn={user.getIn(['info', 'fn'])}
            ln={user.getIn(['info', 'ln'])}
          />
        </ResultSymbol>
        <ResultText>
          <span>
            {user.getIn(['info', 'fn'])} {user.getIn(['info', 'ln'])}
          </span>
          <span>{user.getIn(['info', 'title'])}</span>
        </ResultText>
        <SelectColleague id={user.get('id')} />
      </ResultContainer>
    )
  }
}

UserItem.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  selectUser: PropTypes.func,
  selectUserDetailsRequest: PropTypes.func,
  user: PropTypes.object,
}

export default connect(null, { selectUserDetailsRequest, selectUser })(UserItem)
