import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import DevActions from 'containers/unityLoader/DevActions'
import {
  initInterface,
  removeInterface,
} from 'containers/unityLoader/unityInterface'
import { getUnityHasLoaded } from 'containers/unityLoader/selectors'

const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const UnityContainer = styled.div.attrs({
  id: process.env.REACT_APP_UNITY_ID,
})`
  height: 100%;

  canvas {
    height: 100%;
    width: 100%;
  }
`

class UnityLoader extends Component {
  componentDidMount() {
    initInterface()
  }

  componentWillUnmount() {
    removeInterface()
  }

  render() {
    const { unityHasLoaded } = this.props

    return (
      <Fragment>
        <UnityContainer />
        {process.env.NODE_ENV === 'development' && !unityHasLoaded && (
          <Centered>
            UnityLoader.jsx
            <br />
            <DevActions />
          </Centered>
        )}
      </Fragment>
    )
  }
}

UnityLoader.propTypes = {
  unityHasLoaded: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  unityHasLoaded: getUnityHasLoaded(state),
})

export default connect(mapStateToProps)(UnityLoader)
