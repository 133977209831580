import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'

import PanelHead from 'components/panel/PanelHead'
import WaveBackground from 'components/Wave'
import Country from 'pages/buildingSelector/Country'
import Building from 'pages/buildingSelector/Building'
import {
  BuildingSelectRow,
  BuildingSelectPanel,
} from 'pages/buildingSelector/BuildingUI'
import { updateRequest, setBuilding } from 'containers/app/actions'
import {
  getSites,
  getBuildings,
  getBuildingIdsOnSites,
} from 'containers/app/selectors'
import { sortByString } from 'utils/utilsFunctions'
import Icon from 'components/Icon'
import SearchForBuilding from 'pages/buildingSelector/SearchForBuilding'
import CloseButton from 'pages/buildingSelector/CloseButton'
import { getCountryName } from 'utils/localeFuntions'

countries.registerLocale(english)

class BuildingSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedBuilding: null,
      floorSearchCriteria: '',
      buildingSearchCriteria: '',
      countriesToLoad: [],
    }
    this.selectSiteBtn = true
    this.handleSearchForBuilding = this.handleSearchForBuilding.bind(this)
    this.stepBack = this.stepBack.bind(this)
    this.handleSearchForFloor = this.handleSearchForFloor.bind(this)
    this.groupByCountry = this.groupByCountry.bind(this)
  }

  componentDidMount() {
    const { updateRequest } = this.props
    updateRequest()
    this.rearangeSitesIntoCountries()
  }

  componentDidUpdate() {
    this.rearangeSitesIntoCountries()
  }

  handleSelectBuilding = (buildingId) => {
    this.setState({
      selectedBuilding: buildingId,
    })
  }

  handleSelectFloor = (floorId) => {
    const { setBuilding } = this.props
    setBuilding(this.state.selectedBuilding, floorId)
    window.location.reload()
  }

  handleSearchForBuilding(searchedCriteria = '') {
    const filteredBySite = this.getCountriesToShow()

    this.setState({ buildingSearchCriteria: searchedCriteria })

    if (searchedCriteria.length) {
      this.setSitesInState(
        filteredBySite.filter((site) => {
          if (
            site.name.toUpperCase().indexOf(searchedCriteria.toUpperCase()) > -1
          ) {
            return site
          } else if (
            this.findBuildinginSide(site, searchedCriteria) &&
            this.findBuildinginSide(site, searchedCriteria).length
          ) {
            return site
          } else {
            return false
          }
        }),
      )
    } else {
      return this.setSitesInState(filteredBySite)
    }
  }

  handleSearchForFloor(searchedCriteria = '') {
    this.setState({ floorSearchCriteria: searchedCriteria })
  }
  rearangeSitesIntoCountries() {
    const { sites } = this.props
    if (this.state.countriesToLoad.length === 0 && sites.size > 0) {
      this.loadCountries()
    }
  }
  loadCountries = () => {
    this.setState({ countriesToLoad: this.getCountriesToShow() })
  }
  findBuildinginSide = (site, searchedCriteria) => {
    const { buildings } = this.props
    const buildingsOnSite = site.buildingsForCountry

    if (!buildingsOnSite) return null
    return buildingsOnSite.filter((buildingId) => {
      return (
        buildings
          .getIn([`${buildingId}`])
          .getIn(['info', 'name'])
          .toUpperCase()
          .indexOf(searchedCriteria.toUpperCase()) > -1
      )
    })
  }

  setSitesInState(foundSites) {
    this.setState({
      countriesToLoad: foundSites,
    })
  }

  stepBack() {
    this.handleSearchForBuilding('')
    this.setState({ selectedBuilding: null, floorSearchCriteria: '' })
  }

  getCountriesToShow() {
    const savedSelection = localStorage.getItem('selectedSite')

    return this.groupByCountry().filter(
      (country) => country.code === savedSelection || !savedSelection,
    )
  }
  groupByCountry() {
    const { sites } = this.props
    const countriesToLoad = []
    const getAssetCountryCode = (asset) => asset.getIn(['info', 'country_code'])
    const countryCounter = {}
    const selectedLanguage = window.localStorage.getItem('language')

    let countryCode
    let countryName

    sites.forEach((site) => {
      countryCode = getAssetCountryCode(site) || 'unassigned'
      countryCode !== 'unassigned'
        ? (countryName = getCountryName(countryCode, selectedLanguage))
        : (countryName = 'Unassigned')

      if (countryCounter[countryCode]) {
        countryCounter[countryCode] += 1
        countriesToLoad.forEach((countryObject) => {
          if (countryObject.code === countryCode) {
            countryObject.values.push(site)
            countryObject.buildingsForCountry.push(
              ...this.countryValuesToShow(site),
            )
          }
        })
      } else {
        countryCounter[countryCode] = 1
        countriesToLoad.push({
          code: countryCode,
          name: countryName,
          values: [site],
          buildingsForCountry: [...this.countryValuesToShow(site)],
        })
      }
    })
    return countriesToLoad
  }

  countryValuesToShow(site) {
    const { buildingsForSite } = this.props

    return buildingsForSite.getIn([`${site.getIn(['id'])}`])
  }

  renderBuildings = () => {
    const selectFloor = false

    return (
      <Fragment>
        <PanelHead popup>Select Building</PanelHead>
        <SearchForBuilding
          onChange={this.handleSearchForBuilding}
          selectFloor={selectFloor}
          hideFilter
        />
        <BuildingSelectRow selectSitePopup>
          {this.state.countriesToLoad
            .sort((countryA, countryB) =>
              sortByString(countryA.name, countryB.name),
            )
            .map((country, index) => (
              <Country
                selectSiteBtn={this.selectSiteBtn}
                siteId={country.code}
                searchBy={this.state.buildingSearchCriteria}
                siteName={country.name}
                key={index}
                buildingsForSite={country.buildingsForCountry}
                onClick={this.handleSelectBuilding}
              />
            ))}
        </BuildingSelectRow>
      </Fragment>
    )
  }
  renderFloors = () => {
    return (
      <>
        <PanelHead popup>Select Floor</PanelHead>
        <CloseButton backBtn onClick={this.stepBack}>
          <Icon backBtn name="back" />
          <span>Back</span>
        </CloseButton>
        <SearchForBuilding onChange={this.handleSearchForFloor} selectFloor />

        <BuildingSelectRow>
          <Building
            selectSiteBtn={this.selectSiteBtn}
            buildingId={this.state.selectedBuilding}
            onClick={this.handleSelectFloor}
            searchFloor={this.state.floorSearchCriteria}
          />
        </BuildingSelectRow>
      </>
    )
  }

  render() {
    const { sites } = this.props
    if (!sites) return null

    return (
      <Fragment>
        <WaveBackground />
        <BuildingSelectPanel>
          {!this.state.selectedBuilding && this.renderBuildings()}
          {this.state.selectedBuilding && this.renderFloors()}
        </BuildingSelectPanel>
      </Fragment>
    )
  }
}

BuildingSelector.propTypes = {
  buildings: PropTypes.object,
  buildingsForSite: PropTypes.object,
  setBuilding: PropTypes.func,
  sites: PropTypes.object,
  updateRequest: PropTypes.func,
}

const mapStateToProps = (state) => ({
  sites: getSites(state),
  buildings: getBuildings(state),
  buildingsForSite: getBuildingIdsOnSites(state),
})

export default connect(mapStateToProps, { updateRequest, setBuilding })(
  BuildingSelector,
)
