import { makeSelector } from 'containers/app/selectors'

const isFetching = (state) =>
  state.getIn(['quickView', 'bookingFlow', 'isFetching'])
const status = (state) => state.getIn(['quickView', 'bookingFlow', 'status'])
const error = (state) => state.getIn(['quickView', 'bookingFlow', 'error'])
const currentMeeting = (state) =>
  state.getIn(['quickView', 'bookingFlow', 'currentMeeting'])
const meetingsSlots = (state) =>
  state.getIn(['quickView', 'bookingFlow', 'meetingSlots'])
const desiredMeetingSlot = (state) =>
  state.getIn(['quickView', 'bookingFlow', 'desiredMeetingLength'])

export const getIsFetching = makeSelector(isFetching)
export const getStatus = makeSelector(status)
export const getError = makeSelector(error)
export const getCurrentMeeting = makeSelector(currentMeeting)
export const getMeetingSlots = makeSelector(meetingsSlots)
export const getDesiredMeetingSlot = makeSelector(desiredMeetingSlot)
