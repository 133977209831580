import styled, { css } from 'styled-components'

const Row = styled.div`
  margin: 20px 0;
  position: relative;
  display: flex;
  padding: 0 20px;
  max-width: 640px;
  align-items: center;

  ${(props) =>
    props.buttons &&
    css`
      margin-top: 50px;
      flex-direction: row-reverse;

      button,
      a {
        margin: 0 0 0 12px;
      }
    `};
`

export default Row
