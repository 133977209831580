import styled from 'styled-components'

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
  flex: 1 1 100%;

  &:last-child {
    padding-bottom: 0;
  }
`

export default ModalTitle
