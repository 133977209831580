import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import styled from 'styled-components'
import PickersDay from '@mui/lab/PickersDay'
import {
  createTheme,
  ThemeProvider,
  styled as MUIstyled,
} from '@mui/material/styles'
import { fromUnixTime, isSameDay, isWithinInterval, startOfDay } from 'date-fns'
import { withLocalize } from 'react-localize-redux'

import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'
import { getLocale } from 'utils/utilsFunctions'

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 14,
      textTransform: 'capitalize',
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
  },
})

const CustomPickersDay = MUIstyled(PickersDay, {
  shouldForwardProp: false,
})(
  ({
    theme,
    dayisbetween,
    isfirstday,
    islastday,
    isonlyday,
    currentuserevent,
  }) => {
    return {
      ...{
        fontSize: '1.25rem',
      },
      ...(dayisbetween && {
        borderRadius: 0,
        backgroundColor: currentuserevent
          ? 'rgba(15, 157, 119, 0.2)'
          : '#ffcccc',
        color: 'black',
        '&:hover, &:focus': {
          backgroundColor: '#ff6666',
        },
      }),
      ...(isfirstday && {
        backgroundColor: currentuserevent ? '#0F9D77' : '#e50000',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: '#cc0000',
        },
      }),
      ...(islastday && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        backgroundColor: currentuserevent ? '#0F9D77' : '#e50000',
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: '#cc0000',
        },
      }),
      ...(isonlyday && {
        borderRadius: '50%',
        backgroundColor: currentuserevent ? '#0F9D77' : '#e50000',
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: '#cc0000',
        },
      }),
    }
  },
)

const IconHolder = styled.div`
  position: relative;
  > i {
    cursor: pointer;
  }
  > div {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
`
const DPHolder = styled.div.attrs(({ elId }) => ({
  id: `dp-${elId}`,
  className: 'DPHolder',
}))`
  display: flex;
  height: 40px;
  > i {
    display: block;
    line-height: 40px;
    margin-right: 5px;
  }
  > p {
    margin: 0 0 0 5px;
    line-height: 40px;
    font-size: 14px;
  }
  :hover {
    cursor: pointer;
  }
`

const DatePickerCom = ({
  elId = '1',
  selectDate,
  minDateValue,
  maxDateValue,
  preSelectedValue = new Date(),
  className,
  activeLanguage,
  events,
  children,
  currentUserId,
}) => {
  const [dateValue, setDateValue] = useState(preSelectedValue)
  const [openDialog, setOpenDialog] = useState(false)
  let clickedClose = false

  const currentLocale = getLocale(activeLanguage ? activeLanguage.code : 'en')

  const getFromUnixTime = (timestamp) => fromUnixTime(new Date(timestamp * 60))

  const renderCalendar = (date, selectedDates, pickersDayProps) => {
    if (!dateValue) {
      return <PickersDay {...pickersDayProps} />
    }
    const currentDay = startOfDay(new Date())

    let start, end
    const existingEvent = events.find((event) => {
      start = getFromUnixTime(event.start)
      end = getFromUnixTime(event.end)

      const startDay = startOfDay(start)
      return date >= currentDay && date >= startDay && date <= end
    })

    const currentUserEvent = existingEvent
      ? +existingEvent.organizer?.id === +currentUserId
      : false
    const dayIsBetween = existingEvent
      ? isWithinInterval(date, { start, end })
      : false
    const isFirstDay = existingEvent ? isSameDay(date, start) : false
    const isLastDay = existingEvent ? isSameDay(date, end) : false

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayisbetween={dayIsBetween ? 'true' : null}
        isonlyday={isFirstDay && isLastDay ? 'true' : null}
        isfirstday={isFirstDay && !isLastDay ? 'true' : null}
        islastday={isLastDay && !isFirstDay ? 'true' : null}
        currentuserevent={currentUserEvent}
      />
    )
  }
  const closeDialog = () => {
    setOpenDialog(false)
  }

  return (
    <DPHolder
      className={className}
      elId={elId}
      onClick={(e) => {
        if (clickedClose) {
          setOpenDialog(false)
          clickedClose = false
        } else if (!e.target.closest(`.DPHolder`)) {
          setOpenDialog(true)
        } else {
          setOpenDialog(!openDialog)
        }
      }}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={currentLocale}
        >
          <IconHolder>
            <Icon name={icons.CALENDAR_B} />

            <DatePicker
              label="Enter date"
              value={dateValue}
              minDate={minDateValue}
              maxDate={maxDateValue}
              open={openDialog}
              onChange={(newDateValue) => {
                setDateValue(newDateValue)
                selectDate(newDateValue)
              }}
              onClose={() => {
                clickedClose = true
                closeDialog()
              }}
              renderInput={(params) => <TextField {...params} />}
              renderDay={renderCalendar}
            />
          </IconHolder>
        </LocalizationProvider>
      </ThemeProvider>
      {children}
    </DPHolder>
  )
}

DatePickerCom.propTypes = {
  elId: PropTypes.string,
  selectDate: PropTypes.func,
  minDateValue: PropTypes.object,
  preSelectedValue: PropTypes.object,
  className: PropTypes.string,
  events: PropTypes.array,
  activeLanguage: PropTypes.object,
  maxDateValue: PropTypes.object,
  children: PropTypes.node,
  currentUserId: PropTypes.number,
}

export default withLocalize(DatePickerCom)
