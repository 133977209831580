import { List, fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'

import {
  SEARCH_SUCCESS,
  SEARCH_CLEAR,
  SEARCH_SET_QUERY,
  SEARCH_CLEAR_QUERY,
  FUTURE_SEARCH_SUCCESS,
} from 'containers/searchPanel/search/constants'
import { SET_DATE } from 'containers/searchPanel/filter/constants'

const spaces = (state = List([]), action) => {
  switch (action.type) {
    case SEARCH_SUCCESS:
      return List(action.response.result.spaces)
    case FUTURE_SEARCH_SUCCESS:
      return List(action.result)
    case SEARCH_CLEAR:
      return List([])
    default:
      return state
  }
}

const users = (state = List([]), action) => {
  switch (action.type) {
    case SEARCH_SUCCESS:
      return fromJS(action.response.result.users)
    case SEARCH_CLEAR:
      return List([])
    default:
      return state
  }
}

const query = (state = '', action) => {
  switch (action.type) {
    case SEARCH_SET_QUERY:
      return action.query
    case SEARCH_CLEAR_QUERY:
    case SEARCH_CLEAR:
    case SET_DATE:
      return ''
    default:
      return state
  }
}

export default combineReducers({
  result: combineReducers({
    spaces,
    users,
  }),
  query,
})
