import PropTypes from 'prop-types'
import React, { Component } from 'react'

import InfoSymbol from 'containers/quickView/InfoSymbol'
import InfoText from 'containers/quickView/InfoText'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ProfilePicture from 'components/ProfilePicture'
import SelectColleague from 'containers/searchPanel/results/SelectColleague'

class UserInfo extends Component {
  render() {
    const { user } = this.props
    const { id } = user
    const { title, phone, mail, fn, ln } = user.info ? user.info : user

    return (
      <ModalRow rightPadding5px>
        <InfoSymbol status={user.status?.state}>
          <ProfilePicture id={id} fn={fn} ln={ln} />
        </InfoSymbol>

        <InfoText>
          <span>
            {fn} {ln}
            <SelectColleague id={id} modalStar />
          </span>
          <span>{title}</span>
          <span>{phone}</span>
          <span>{mail}</span>
        </InfoText>
      </ModalRow>
    )
  }
}

UserInfo.propTypes = {
  user: PropTypes.object,
}

export default UserInfo
