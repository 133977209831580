import React from 'react'
import { useSelector } from 'react-redux'

import SearchPanelWrapper from 'containers/searchPanel/SearchPanelWrapper'
import Search from 'containers/searchPanel/Search'
import Results from 'containers/searchPanel/Results'
import Filter from 'containers/searchPanel/Filter'
import { getIsFuture } from 'containers/searchPanel/filter/selectors'

const SearchPanel = () => {
  const isFuture = useSelector((state) => getIsFuture(state))

  return (
    <SearchPanelWrapper>
      <Search />
      <Filter />
      <Results />
    </SearchPanelWrapper>
  )
}

export default SearchPanel
