import { combineReducers } from 'redux-immutable'

const homeFloorId = (state = 1) => {
  return state
}

const homeBuildingId = (state = 1) => {
  return state
}

export default combineReducers({
  homeFloorId,
  homeBuildingId,
})
