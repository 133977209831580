import {
  SHOW_DASHBOARD,
  HIDE_DASHBOARD,
  SET_START_TIME,
  FETCH_SCHEDULES,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
} from 'containers/dashboard/constants'

export const showDashboard = (panel) => ({
  type: SHOW_DASHBOARD,
  panel,
})

export const hideDashboard = () => ({
  type: HIDE_DASHBOARD,
})

export const setMeetingTime = (startDate, endDate) => ({
  type: SET_START_TIME,
  startDate,
  endDate,
})

export const fetchSchedulesSuccess = (data) => ({
  type: FETCH_SCHEDULE_SUCCESS,
  data,
})

export const fetchSchedulesFailure = () => ({
  type: FETCH_SCHEDULE_FAILURE,
})

export const refreshSchedules = () => ({
  type: FETCH_SCHEDULES,
})
