import React from 'react'
import styled, { css } from 'styled-components'
import Paper from '@material-ui/core/Paper'

const ElevatedPaper = (props) => <Paper {...props} elevation={10} />

const Modal = styled(ElevatedPaper)`
  position: absolute;
  left: 50%;
  bottom: 115px;
  max-height: 85%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.panelBgLight};
  border-radius: 5px;
  z-index: 2;
  min-width: ${(props) => (props.asset ? '800px' : '500px')};
  overflow: visible;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  font-size: 16px;
  ${(props) =>
    props.changemodalposition &&
    css`
      bottom: ${props.changemodalposition.bottom ?? ''};
      z-index: ${props.changemodalposition['z-index'] ?? ''};
    `};
`

export default Modal
