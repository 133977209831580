import styled from 'styled-components'

const NoResults = styled.span`
  display: block;
  padding: 18px;
  font-size: 14px;
  color: ${(props) => props.theme.panelColor};
`

export default NoResults
