import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { getFloorIdsOnBuildings, getFloors } from 'containers/app/selectors'
import { SelectButton } from 'pages/buildingSelector/BuildingUI'

class Building extends Component {
  render() {
    const { floorIds, floors, onClick, searchFloor, selectSiteBtn } = this.props
    if (!floorIds) return null

    const getFloorNameById = (id) => {
      if (!searchFloor || !searchFloor.length) {
        return floors.get(`${id}`).getIn(['info', 'name'])
      } else if (
        floors.get(`${id}`).getIn(['info', 'name']).indexOf(searchFloor) > -1
      ) {
        return floors.get(`${id}`).getIn(['info', 'name'])
      } else {
        return
      }
    }

    const getFloorLevelById = (id) =>
      floors.get(`${id}`).getIn(['info', 'level'])

    return (
      <Fragment>
        {floorIds
          .sort((idA, idB) => getFloorLevelById(idA) - getFloorLevelById(idB))
          .map((floorId) => {
            return getFloorNameById(floorId) ? (
              <SelectButton
                selectSiteBtn={selectSiteBtn}
                key={floorId}
                onClick={() => onClick(floorId)}
              >
                {getFloorNameById(floorId)}
              </SelectButton>
            ) : null
          })}
      </Fragment>
    )
  }
}

Building.propTypes = {
  floorIds: PropTypes.object,
  floors: PropTypes.object,
  onClick: PropTypes.func,
  searchFloor: PropTypes.any,
  selectSiteBtn: PropTypes.bool,
}

const mapStateToProps = (state, props) => ({
  floorIds: getFloorIdsOnBuildings(state).get(`${props.buildingId}`),
  floors: getFloors(state),
})

export default connect(mapStateToProps)(Building)
