import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
export const StyledFilterItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: 0;
  font: inherit;
  font-size: 12px;
  cursor: pointer;
  border: none;
  padding: 4px;
  background: none;
  color: ${(props) => props.theme.darkText};

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.panelBg};
      color: ${(props) => props.theme.panelBgLight};
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 40%;
      pointer-events: none;
    `}

  span {
    display: block;
    pointer-events: none;
  }
`

const StyledImg = styled.img`
  height: 25px;
  background-color: transparent;
  background-position: center;
  margin-bottom: 5px;
  -webkit-filter: ${(props) => (props.active ? 'invert(100%) !important' : '')};
`
class FilterItem extends PureComponent {
  render() {
    const { caption, icon, ...rest } = this.props

    return (
      <StyledFilterItem {...rest}>
        <StyledImg src={icon} {...rest} alt="icon" />
        <span>{caption}</span>
      </StyledFilterItem>
    )
  }
}

FilterItem.propTypes = {
  icon: PropTypes.object,
  caption: PropTypes.string,
}

export default FilterItem
