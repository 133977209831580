import { combineReducers } from 'redux-immutable'
import { Map } from 'immutable'

import {
  FETCH_PERSONAL_CALENDAR_SUCCESS,
  FETCH_PERSONAL_CALENDAR_FAILURE,
  DELETE_PERSONAL_EVENT_FAILURE,
  DELETE_PERSONAL_EVENT_SUCCESS,
  DELETE_PERSONAL_EVENT_REQUEST,
  OPEN_CONFIRMATION_POPUP,
  CLOSE_CONFIRMATION_POPUP,
  SEND_CONFIRMATION_POPUP,
} from 'containers/mainPanel/bookedResources/constants'
import {
  DELETE_ATTENDANCE_REQUEST,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAILURE,
} from 'containers/flowPlanner/constants'

const personalCalendar = (state = [], action) => {
  switch (action.type) {
    case FETCH_PERSONAL_CALENDAR_SUCCESS:
      return action.response
    default:
      return state
  }
}

const openPopup = (state = {}, action) => {
  switch (action.type) {
    case OPEN_CONFIRMATION_POPUP:
      return action.bookingData
    case CLOSE_CONFIRMATION_POPUP:
      return {}
    default:
      return state
  }
}

const sendDeletionConfirmation = (state = {}, action) => {
  switch (action.type) {
    case SEND_CONFIRMATION_POPUP:
      return action.bookingData
    case CLOSE_CONFIRMATION_POPUP:
      return {}
    default:
      return state
  }
}

const IsFetching = (state = false, action) => {
  switch (action.type) {
    case DELETE_PERSONAL_EVENT_REQUEST:
    case DELETE_ATTENDANCE_REQUEST:
      return true
    case DELETE_PERSONAL_EVENT_FAILURE:
    case DELETE_PERSONAL_EVENT_SUCCESS:
    case DELETE_ATTENDANCE_SUCCESS:
    case DELETE_ATTENDANCE_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  personalCalendar,
  openPopup,
  sendDeletionConfirmation,
  IsFetching,
})
