import { takeLatest, take, all, select, put } from 'redux-saga/effects'
import { isSameDay } from 'date-fns'

import { getUnityHasLoaded } from 'containers/unityLoader/selectors'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import { SET_DATE, CLEAR_FILTER } from 'containers/searchPanel/filter/constants'
import { startFilter } from 'containers/app/actions'
import { FILTER_SUCCESS } from 'containers/app/constants'

function* search() {
  const selectedDate = yield select(getDateFilter)
  const unityHasLoaded = yield select(getUnityHasLoaded)
  if (isSameDay(Date.now(), selectedDate)) {
    if (unityHasLoaded) {
      yield put(startFilter('show_all'))
      yield take(FILTER_SUCCESS)
    }
    return
  }
}

function* watchSearch() {
  yield takeLatest(SET_DATE, search)
}

function* watchClearFilter() {
  yield takeLatest(CLEAR_FILTER, search)
}

function* bookingsListSagas() {
  yield all([watchSearch(), watchClearFilter()])
}

export default bookingsListSagas
