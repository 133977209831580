import { makeSelector } from 'containers/app/selectors'

const personalCalendar = (state) =>
  state.getIn(['personalData', 'personalCalendar'])
const openPopup = (state) => state.getIn(['personalData', 'openPopup'])
const sendDeletionConfirmation = (state) =>
  state.getIn(['personalData', 'sendDeletionConfirmation'])
const IsFetching = (state) => state.getIn(['personalData', 'IsFetching'])

export const getPersonalCalendar = makeSelector(personalCalendar)
export const getOpenPopup = makeSelector(openPopup)
export const getDeletionConfirmation = makeSelector(sendDeletionConfirmation)
export const getIsFetching = makeSelector(IsFetching)
