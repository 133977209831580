import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'

import MultiSelectItemList from 'containers/quickView/multiSelectModal/MultiSelectItemList'
import { BookingInnerDivider } from 'containers/mainPanel/bookingsList/BookingDividers'
import MultiSelectItem from 'containers/quickView/multiSelectModal/MultiSelectItem'
import QuickViewBackdrop from 'containers/quickView/QuickViewBackdrop'
import Modal from 'containers/quickView/Modal'
import {
  clearQuickView,
  deleteBookingMessageToShow,
  setBookingMessageToShow,
} from 'containers/quickView/actions'
import TimePickerArea from 'containers/quickView/TimePickerArea'
import { getDisableTimeSelection } from 'containers/app/selectors'
import toJS from 'components/toJS'
import MultiSelectModalBanner from 'containers/quickView/multiSelectModal/MultiSelectModalBanner'
import { deselect } from 'api/unity'
import ModalRow from 'containers/quickView/modal/ModalRow'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import {
  checkSlotInterval,
  checkMultiSelectAvailability,
} from 'utils/utilsFunctions'
import { closeClaimUI } from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  getEventsPerAsset,
  getHasClaimOnBehalfPermission,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  getBookingMessage,
  getRegisteredSlot,
  getMultiSelectedSpaces,
  getIsFetching,
} from 'containers/quickView/selectors'
import AttendanceInfoBox from 'containers/flowPlanner/AttendanceInfoBox'
import WrapperDrawerController from 'containers/quickView/modal/WrapperDrawerController'
import { closeConfirmationPopup } from 'containers/mainPanel/bookedResources/actions'
import DeskSelect from 'containers/quickView/spaceModal/claimFlow/DeskSelect'
import MultiSelectInfo from 'containers/quickView/multiSelectModal/MultiSelectInfo'
import { multipleBookingRequest } from 'containers/quickView/spaceModal/bookingFlow/actions'
import { INVALID_BOOKINGS } from 'utils/infoMessages'
const DailyBookingsHeader = styled.span`
  padding: 35px 0 0 25px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  color: ${(props) => props.theme.darkText};
  display: block;
`
const StyledModaRow = styled(ModalRow)`
  padding: 6px 5px 6px 8px;
`
const StyledModalRowNoItems = styled(ModalRow)`
  border: none !important;
  padding-left: 9px;
`
const StyledMultiSelectInfo = styled(MultiSelectInfo)`
  padding-top: 35px !important;
  margin-bottom: ${(props) => (props.noTimePickers ? '120px' : '0')};
`
const InfoBoxWrapper = styled.div`
  position: absolute;
  bottom: 145px;
  top: initial;
  left: 5px;
`

class MultiSelectModal extends Component {
  state = {
    showPopup: false,
    popupData: {},
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isFetching) {
      return false
    }
    return true
  }
  handleClose = () => {
    const { clearQuickView } = this.props
    clearQuickView()
    deselect()
  }

  render() {
    const {
      selectedDate,
      eventsPerAsset,
      deleteBookingMessageToShow,
      bookingMessage,
      disableTimeSelection,
      isFetching,
      multiSelectedSpaces,
    } = this.props

    if (bookingMessage.length > 0) {
      setTimeout(() => {
        deleteBookingMessageToShow()
      }, 4000)
    }

    const handleMultipleBookings = (userId) => {
      const {
        multiSelectedSpaces,
        slot,
        setBookingMessageToShow,
        multipleBookingRequest,
      } = this.props
      const isSpacesAvailable = checkMultiSelectAvailability(
        multiSelectedSpaces,
        slot,
      )
      userId = userId?.id
      if (isSpacesAvailable) {
        setBookingMessageToShow(INVALID_BOOKINGS)
      } else {
        multipleBookingRequest(userId, multiSelectedSpaces, slot)
      }
    }
    return (
      <WrapperDrawerController>
        <QuickViewBackdrop />
        <Modal asset>
          <MultiSelectModalBanner onClick={this.handleClose} />
          <Grid container>
            <Grid item xs={7}>
              <StyledMultiSelectInfo noTimePickers={disableTimeSelection} />
              {!disableTimeSelection && (
                <TimePickerArea
                  key={selectedDate}
                  type={'SPACE'}
                  eventsPerAsset={eventsPerAsset}
                />
              )}
              {bookingMessage && (
                <InfoBoxWrapper>
                  <AttendanceInfoBox
                    isBookingMessage
                    showMessage={bookingMessage}
                  />
                </InfoBoxWrapper>
              )}
              <DeskSelect
                isFetching={isFetching}
                onClose={closeClaimUI}
                onBooking={handleMultipleBookings}
                disableBookingButtonByDefault={false}
              />
            </Grid>
            <>
              <Divider
                orientation="vertical"
                flexItem
                style={{ marginRight: '-1px' }}
              />
              <Grid item xs={5}>
                <DailyBookingsHeader>
                  <Translate id="quickView.selectedSpaces" />
                </DailyBookingsHeader>
                <MultiSelectItemList max400px>
                  {Object.keys(multiSelectedSpaces).length === 0 ? (
                    <StyledModalRowNoItems>
                      <Translate id="quickView.noSelectedItems" />
                    </StyledModalRowNoItems>
                  ) : (
                    Object.keys(multiSelectedSpaces).map((id) => (
                      <StyledModaRow key={id} multiSelect>
                        <MultiSelectItem
                          bookings={multiSelectedSpaces[id]}
                          id={id}
                        />
                        <BookingInnerDivider />
                      </StyledModaRow>
                    ))
                  )}
                </MultiSelectItemList>
              </Grid>
            </>
          </Grid>
        </Modal>
      </WrapperDrawerController>
    )
  }
}

MultiSelectModal.propTypes = {
  clearQuickView: PropTypes.func,
  selectedDate: PropTypes.PropTypes.instanceOf(Date),
  eventsPerAsset: PropTypes.array,
  closeClaimUI: PropTypes.func,
  deleteBookingMessageToShow: PropTypes.func,
  bookingMessage: PropTypes.string,
  disableTimeSelection: PropTypes.bool,
  isFetching: PropTypes.bool,
  slot: PropTypes.object,
  multiSelectedSpaces: PropTypes.object,
  multipleBookingRequest: PropTypes.func,
  setBookingMessageToShow: PropTypes.func,
}

const mapStateToProps = (state) => {
  const selectedDate = getDateFilter(state)
  const disableTimeSelection = getDisableTimeSelection(state)
  return {
    selectedDate,
    eventsPerAsset: getEventsPerAsset(state),
    bookingMessage: getBookingMessage(state),
    disableTimeSelection,
    isFetching: getIsFetching(state),
    hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
    slot: getRegisteredSlot(state),
    multiSelectedSpaces: getMultiSelectedSpaces(state),
  }
}

export default connect(mapStateToProps, {
  clearQuickView,
  closeClaimUI,
  deleteBookingMessageToShow,
  closeConfirmationPopup,
  multipleBookingRequest,
  setBookingMessageToShow,
})(toJS(MultiSelectModal))
