import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import french from 'i18n-iso-countries/langs/fr.json'
import swedish from 'i18n-iso-countries/langs/sv.json'
import norvegian from 'i18n-iso-countries/langs/no.json'
import german from 'i18n-iso-countries/langs/de.json'
import spanish from 'i18n-iso-countries/langs/es.json'

import { getCountryName } from 'utils/localeFuntions'
import PanelHead from 'components/panel/PanelHead'
import WaveBackground from 'components/Wave'
import Country from 'pages/buildingSelector/Country'
import Building from 'pages/buildingSelector/Building'
import {
  SelectHeader,
  BuildingSelectRow,
  InnerBuildingSelector,
  CoverPanel,
} from 'pages/buildingSelector/BuildingUI'
import { updateRequest, setBuilding } from 'containers/app/actions'
import {
  getSites,
  getBuildings,
  getBuildingIdsOnSites,
} from 'containers/app/selectors'
import { sortByString } from 'utils/utilsFunctions'
import Icon from 'components/Icon'
import SearchForBuilding from 'pages/buildingSelector/SearchForBuilding'
import CloseButton from 'pages/buildingSelector/CloseButton'
import SitesList from 'pages/buildingSelector/SitesList'

countries.registerLocale(english)
countries.registerLocale(french)
countries.registerLocale(swedish)
countries.registerLocale(norvegian)
countries.registerLocale(german)
countries.registerLocale(spanish)

class BuildingSelectorPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedBuilding: null,
      floorSearchCriteria: '',
      buildingSearchCriteria: '',
      activeFilterBySite: false,
      countriesToLoad: this.getCountriesToShow(),
    }
    this.selectSiteBtn = true
    this.handleSearchForBuilding = this.handleSearchForBuilding.bind(this)
    this.stepBack = this.stepBack.bind(this)
    this.handleSearchForFloor = this.handleSearchForFloor.bind(this)
    this.handleOpenFilter = this.handleOpenFilter.bind(this)
    this.handleCloseFIlter = this.handleCloseFIlter.bind(this)
    this.groupByCountry = this.groupByCountry.bind(this)
  }
  componentDidMount() {
    const { updateRequest } = this.props
    updateRequest()
  }

  handleSelectBuilding = (buildingId) => {
    this.setState({
      selectedBuilding: buildingId,
    })
  }

  handleSelectFloor = (floorId) => {
    const { setBuilding } = this.props
    setBuilding(this.state.selectedBuilding, floorId)
  }

  handleOpenFilter() {
    this.setState({ activeFilterBySite: true })
  }

  handleCloseFIlter() {
    this.setState({
      activeFilterBySite: false,
      countriesToLoad: this.getCountriesToShow(),
    })
  }

  handleSearchForBuilding(searchedCriteria = '') {
    const filteredBySite = this.getCountriesToShow()

    this.setState({ buildingSearchCriteria: searchedCriteria })

    if (searchedCriteria.length) {
      this.setSitesInState(
        filteredBySite.filter((site) => {
          if (
            site.name.toUpperCase().indexOf(searchedCriteria.toUpperCase()) > -1
          ) {
            return site
          } else if (
            this.findBuildinginSide(site, searchedCriteria) &&
            this.findBuildinginSide(site, searchedCriteria).length
          ) {
            return site
          } else {
            return false
          }
        }),
      )
    } else {
      return this.setSitesInState(filteredBySite)
    }
  }

  handleSearchForFloor(searchedCriteria = '') {
    this.setState({ floorSearchCriteria: searchedCriteria })
  }

  findBuildinginSide = (site, searchedCriteria) => {
    const { buildings } = this.props
    const buildingsOnSite = site.buildingsForCountry

    if (!buildingsOnSite) return null
    return buildingsOnSite.filter((buildingId) => {
      return (
        buildings
          .getIn([`${buildingId}`])
          .getIn(['info', 'name'])
          .toUpperCase()
          .indexOf(searchedCriteria.toUpperCase()) > -1
      )
    })
  }

  setSitesInState(foundSites) {
    this.setState({
      countriesToLoad: foundSites,
    })
  }

  stepBack() {
    this.handleSearchForBuilding('')
    this.setState({ selectedBuilding: null, floorSearchCriteria: '' })
  }

  getCountriesToShow() {
    const savedSelection = localStorage.getItem('selectedSite')

    return this.groupByCountry().filter(
      (country) => country.code === savedSelection || !savedSelection,
    )
  }
  groupByCountry() {
    const { sites } = this.props
    const countriesToLoad = []
    const getAssetCountryCode = (asset) => asset.getIn(['info', 'country_code'])
    const countryCounter = {}
    const selectedLanguage = window.localStorage.getItem('language')

    let countryCode
    let countryName

    sites.forEach((site) => {
      countryCode = getAssetCountryCode(site) || 'unassigned'
      countryCode !== 'unassigned'
        ? (countryName = getCountryName(countryCode, selectedLanguage))
        : (countryName = 'Unassigned')

      if (countryCounter[countryCode]) {
        countryCounter[countryCode] += 1
        countriesToLoad.forEach((countryObject) => {
          if (countryObject.code === countryCode) {
            countryObject.values.push(site)
            countryObject.buildingsForCountry.push(
              ...this.countryValuesToShow(site),
            )
          }
        })
      } else {
        countryCounter[countryCode] = 1
        countriesToLoad.push({
          code: countryCode,
          name: countryName,
          values: [site],
          buildingsForCountry: [...this.countryValuesToShow(site)],
        })
      }
    })
    return countriesToLoad
  }

  countryValuesToShow(site) {
    const { buildingsForSite } = this.props

    return buildingsForSite.getIn([`${site.getIn(['id'])}`])
  }

  renderBuildings = () => {
    const { buildings, buildingsForSite, closePopup, sites } = this.props
    const selectFloor = false
    const appliedFilter = localStorage.getItem('selectedSite')

    return (
      <>
        {this.state.activeFilterBySite && <CoverPanel />}
        <PanelHead popup>
          <Translate id="space.status.selectBuilding" />
        </PanelHead>
        <CloseButton onClick={closePopup}>&#x2715;</CloseButton>
        <SearchForBuilding
          onChange={this.handleSearchForBuilding}
          handleOpenFilter={this.handleOpenFilter}
          appliedFilter={!!appliedFilter}
          selectFloor={selectFloor}
        />
        {this.state.activeFilterBySite && (
          <SitesList
            sites={this.groupByCountry()}
            handleCloseFIlter={this.handleCloseFIlter}
          />
        )}
        <BuildingSelectRow selectSitePopup>
          {this.state.countriesToLoad
            .sort((countryA, countryB) =>
              sortByString(countryA.name, countryB.name),
            )
            .map((country, index) => (
              <Country
                selectSiteBtn={this.selectSiteBtn}
                siteId={country.code}
                searchBy={this.state.buildingSearchCriteria}
                siteName={country.name}
                key={index}
                buildingsForSite={country.buildingsForCountry}
                onClick={this.handleSelectBuilding}
              />
            ))}
        </BuildingSelectRow>
      </>
    )
  }

  renderFloors = () => {
    const { closePopup } = this.props
    const selectFloor = true

    return (
      <>
        <PanelHead popup>
          <Translate id="space.status.selectFloor" />
        </PanelHead>
        <CloseButton onClick={closePopup}>&#x2715;</CloseButton>
        <CloseButton backBtn onClick={this.stepBack}>
          <Icon backBtn name="back" />
          <span>
            <Translate id="space.status.back" />
          </span>
        </CloseButton>
        <SearchForBuilding onChange={this.handleSearchForFloor} selectFloor />

        <BuildingSelectRow>
          <Building
            selectSiteBtn={this.selectSiteBtn}
            buildingId={this.state.selectedBuilding}
            onClick={this.handleSelectFloor}
            searchFloor={this.state.floorSearchCriteria}
          />
        </BuildingSelectRow>
      </>
    )
  }

  render() {
    const { sites } = this.props
    if (!sites) return null

    return (
      <InnerBuildingSelector>
        {!this.state.selectedBuilding && this.renderBuildings()}
        {this.state.selectedBuilding && this.renderFloors()}
      </InnerBuildingSelector>
    )
  }
}

BuildingSelectorPopup.propTypes = {
  buildings: PropTypes.object,
  buildingsForSite: PropTypes.object,
  setBuilding: PropTypes.func,
  sites: PropTypes.object,
  updateRequest: PropTypes.func,
  closePopup: PropTypes.func,
}

const mapStateToProps = (state) => ({
  sites: getSites(state),
  buildings: getBuildings(state),
  buildingsForSite: getBuildingIdsOnSites(state),
})

export default connect(mapStateToProps, { updateRequest, setBuilding })(
  BuildingSelectorPopup,
)
