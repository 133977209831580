import { take, takeEvery, all, select, call, put } from 'redux-saga/effects'
import set from 'lodash/set'
import { getUnixTime, fromUnixTime } from 'date-fns'
import Alert from 'react-s-alert'

import * as unity from 'api/unity'
import * as argus from 'api/argus'
import { config } from 'api/public'
import {
  getIsFuture,
  getDateFilter,
  getSelectedInterval,
} from 'containers/searchPanel/filter/selectors'
import {
  getSpaces,
  getMetaTypes,
  getDeskIdsOnFloors,
  getRoomIdsOnFloors,
  getAuthToken,
  getCurrentBuildingId,
  getCurrentFloorId,
  getUsers,
  getSelfId,
  getClientAuthentication,
  getBuildings,
  getFloors,
  getTokenForUnity,
} from 'containers/app/selectors'
import {
  startFilter,
  updateRequest,
  fetchFutureViewSuccess,
  authenticateFailure,
  updateDeskBookingsFailure,
  fetchFutureViewRequest,
  locationRequest,
} from 'containers/app/actions'
import { initUnitySuccess } from 'containers/unityLoader/actions'
import {
  BOOK_DESK_SUCCESS,
  CANCEL_BOOKING_SUCCESS,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import {
  UNITY_INIT_REQUEST,
  UNITY_INIT_SUCCESS,
  UNITY_READY,
  UNITY_COMMAND_DONE,
  UNITY_CLICKED_ASSET,
  UNITY_DESELECTED_ASSET,
} from 'containers/unityLoader/constants'
import {
  FETCH_UPDATE_SUCCESS,
  FETCH_CLIENT_CONFIG_SUCCESS,
  SET_FLOOR,
  SET_BUILDING,
  FETCH_FUTURE_VIEW_REQUEST,
} from 'containers/app/constants'
import { getUnityHasLoaded } from 'containers/unityLoader/selectors'
import { UpdateCache } from 'containers/unityLoader/utils/updateCache'
import defaultSettings from 'containers/unityLoader/settings'
import {
  selectSpaceId,
  clearQuickView,
  selectSignId,
  selectUser,
  multiSelectRequest,
} from 'containers/quickView/actions'
import { getEndTimeValue } from 'utils/utilsFunctions'
import { setMapInterval } from 'containers/searchPanel/filter/actions'
import {
  STATUS_QUERY_INTERVAL_MINUTE,
  ASSET_TYPES,
  SECONDS_PER_MINUTE,
} from 'utils/appVars'
const contentUpdateCache = new UpdateCache()

const commandDone = (command) => (action) =>
  action.type === UNITY_COMMAND_DONE && action.payload.command === command

function* startUnity() {
  yield all([take(FETCH_UPDATE_SUCCESS), take(FETCH_CLIENT_CONFIG_SUCCESS)])
  const authOptions = yield select(getClientAuthentication)
  //Checks to see if the session is stored in a cookie or in state
  let authToken = ''
  if (
    authOptions.getIn(['authOptions', '0', 'id']) !==
    ('oauth2-microsoft' || 'oauth2-google')
  ) {
    authToken = yield select(getAuthToken)
  }

  if (process.env.NODE_ENV === 'development') {
    yield take(UNITY_INIT_REQUEST)
  }

  yield call(unity.startMount, process.env.REACT_APP_UNITY_ID)

  yield take(UNITY_READY)
  const settings = yield call(getSettings)
  yield call(unity.startSettings, settings)

  yield take(commandDone('settings'))
  const selfId = yield select(getSelfId)
  yield call(unity.startUser, selfId, authToken) //TODO: Send unique and token to Unity

  yield take(commandDone('init_me'))
  yield call(contentUpdateCache.load, unity.contentUpdate)

  yield take(commandDone('contentUpdate'))
  const currentBuildingId = yield select(getCurrentBuildingId)
  const currentFloorId = yield select(getCurrentFloorId)

  yield call(unity.showBuilding, currentBuildingId)
  //TODO: Make sure "show building" completes with Unity callback before sending "show floor"
  yield call(unity.showFloor, currentFloorId)

  yield put(initUnitySuccess())
}

function* updateUnity({ payload }) {
  if (payload) {
    const unityHasLoaded = yield select(getUnityHasLoaded)
    if (unityHasLoaded) {
      yield call(unity.contentUpdate, payload)
    } else {
      contentUpdateCache.add(payload)
    }
  }
}

function* getSettings() {
  const settings = { ...defaultSettings }
  const argus_url = yield call(config, 'argus_url')
  const unityAccessToken = yield select(getTokenForUnity)
  set(settings, 'serverURL', argus_url)
  set(settings, 'camera.defaultRot', 0)
  set(settings, 'token', unityAccessToken)
  set(
    settings,
    'camera.defaultAngle',
    '3d', //TODO: Use latest angle from local storage
  )
  set(settings, 'application.showAreas', true)
  set(settings, 'application.showUsers', true)
  set(settings, 'application.showDesks', true)
  return settings
}

function* showFloor({ floorId, forced = false }) {
  yield call(unity.showFloor, floorId, forced)
}

function* showBuilding({ buildingId, floorId }) {
  yield call(unity.showBuilding, buildingId)
  yield call(showFloor, { floorId })
}

function* updatePosition(params) {
  const payload = params.payload
  if (!payload) return

  if (payload.self && payload.self.loc) {
    const floorId = yield params.payload.self.loc.spaceId & 0x00000fff
    const position = yield params.payload.self.loc.pos
    yield call(unity.updatePosition, position, floorId)
  } else {
    yield call(unity.positionRemove)
  }
}

function* selectAsset({ payload }) {
  const { SPACE, SIGN, USER } = ASSET_TYPES
  if (Object.keys(payload).length === 0) {
    yield call(unity.deselect)
    yield put(clearQuickView())
    return
  }
  const [firstAsset] = Object.values(payload)
  const { assetType, id } = firstAsset
  switch (assetType) {
    case SPACE:
      const spaceIds = Object.values(payload)
        .filter((item) => item.assetType === SPACE)
        .map((item) => item.id)
      if (spaceIds.length === 1) {
        yield put(selectSpaceId(spaceIds[0]))
      } else {
        yield put(multiSelectRequest(spaceIds))
      }
      break
    case USER:
      const users = yield select(getUsers)
      const selectedUser = users.get(`${id}`)
      yield put(selectUser(selectedUser))
      break
    case SIGN:
      yield put(selectSignId(id))
      break
    default:
      break
  }
}

function* updateContentUpdate() {
  const isFuture = yield select(getIsFuture)
  const currentFloorId = yield select(getCurrentFloorId)

  isFuture
    ? yield put(fetchFutureViewRequest())
    : yield put(updateRequest([currentFloorId]))
}

function* updateFutureUnity() {
  const currentFloorId = yield select(getCurrentFloorId)
  const currentBuildingId = yield select(getCurrentBuildingId)
  const buildings = yield select(getBuildings)
  const floors = yield select(getFloors)
  const selectedInterval = yield select(getSelectedInterval)
  const selectedDate = yield select(getDateFilter)
  const filterInterval = Object.values(selectedInterval).length > 0

  const selectedDateObject = new Date(selectedDate)
  let start = getUnixTime(selectedDateObject) / SECONDS_PER_MINUTE
  let end =
    getUnixTime(selectedDateObject) / SECONDS_PER_MINUTE +
    STATUS_QUERY_INTERVAL_MINUTE
  if (filterInterval) {
    start = getUnixTime(selectedInterval.start) / SECONDS_PER_MINUTE
    end =
      getUnixTime(
        getEndTimeValue(
          selectedInterval.start,
          selectedInterval.end,
          selectedDate,
        ),
      ) / SECONDS_PER_MINUTE
    const mapInterval = {
      start: fromUnixTime(start * SECONDS_PER_MINUTE),
      end: fromUnixTime(end * SECONDS_PER_MINUTE),
    }
    yield put(setMapInterval(mapInterval))
  }

  start = Math.trunc(start)
  end = Math.trunc(end)

  try {
    const response = yield call(
      argus.fetchFutureUpdate,
      currentFloorId,
      start,
      end,
    )
    const dataToShow = JSON.parse(response.contentUpdate)
    // Should always send arrays for add, update and remove
    if (dataToShow.spaces && dataToShow.spaces.length > 0) {
      dataToShow.spaces.forEach((floor) => {
        floor.add = floor.add ?? []
        floor.update = floor.update ?? []
        floor.remove = floor.remove ?? []
      })
    }
    yield call(unity.contentUpdate, dataToShow)
    yield put(fetchFutureViewSuccess(response))
  } catch ({ error, response }) {
    yield put(updateDeskBookingsFailure(error.message))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
    if (response.status === 404) {
      const currentBuilding = buildings.get(`${currentBuildingId}`)
      const currentFloor = floors.get(`${currentFloorId}`)
      if (!currentBuilding || !currentFloor) {
        yield put(locationRequest())
      }
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* watchBookDeskSuccess() {
  yield takeEvery(
    [BOOK_DESK_SUCCESS, CANCEL_BOOKING_SUCCESS],
    updateContentUpdate,
  )
}

function* deselectAsset({ payload }) {
  if (payload.type === 'UserInput') {
    yield put(clearQuickView())
  }
}

function* watchUpdateUnity() {
  yield takeEvery([FETCH_UPDATE_SUCCESS], updateUnity)
}

function* watchUpdateFutureUnity() {
  yield takeEvery([FETCH_FUTURE_VIEW_REQUEST], updateFutureUnity)
}

function* watchShowFloor() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(SET_FLOOR, showFloor)
}

function* watchShowBuilding() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(SET_BUILDING, showBuilding)
}

function* watchSelectAsset() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(UNITY_CLICKED_ASSET, selectAsset)
}

function* watchDeselectAsset() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(UNITY_DESELECTED_ASSET, deselectAsset)
}

function* watchUpdatePosition() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(FETCH_UPDATE_SUCCESS, updatePosition)
}

export default function* unitySagas() {
  yield all([
    startUnity(),
    watchShowFloor(),
    watchShowBuilding(),
    watchUpdateUnity(),
    watchSelectAsset(),
    watchDeselectAsset(),
    watchUpdatePosition(),
    watchUpdateFutureUnity(),
    watchBookDeskSuccess(),
  ])
}
