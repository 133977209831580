import { takeEvery, all, call, put } from 'redux-saga/effects'

import * as argus from 'api/argus'
import { LOGOUT_REQUEST, LOGOUT_SUCCESS } from 'containers/app/constants'
import {
  logoutSuccess,
  logoutFailure,
  authenticateFailure,
} from 'containers/app/actions'

function* logout() {
  //Force auth failure to get taken back to login screen faster upon logout
  yield call(authenticateFailure, null)

  try {
    const response = yield call(argus.logoutArgus, '/secure/self/logout')
    yield put(logoutSuccess(response))
  } catch ({ error, response }) {
    yield put(logoutFailure(error.message))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* logoutUser() {
  window.localStorage.setItem('oauthSuccess', false)
  yield put(authenticateFailure('Logout Successful'))
}

function* watchLogoutRequest() {
  yield takeEvery(LOGOUT_REQUEST, logout)
}

function* watchLogoutSuccess() {
  yield takeEvery(LOGOUT_SUCCESS, logoutUser)
}

function* logoutSagas() {
  yield all([watchLogoutRequest(), watchLogoutSuccess()])
}

export default logoutSagas
