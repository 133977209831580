import { all } from 'redux-saga/effects'

import unitySagas from 'containers/unityLoader/sagas'
import searchSagas from 'containers/searchPanel/search/sagas'
import meetingSagas from 'containers/quickView/spaceModal/bookingFlow/sagas'
import claimSagas from 'containers/quickView/spaceModal/claimFlow/sagas'
import profilePicturesSagas from 'containers/searchPanel/results/sagas'
import personalData from 'containers/mainPanel/bookedResources/sagas'
import quickViewSagas from 'containers/quickView/sagas'
import loginSagas from 'pages/login/sagas'
import deskBookingSagas from 'containers/mainPanel/bookingsList/sagas'
import updateSagas from 'containers/app/sagas'
import flowPlannerSagas from 'containers/flowPlanner/sagas'
import dashboardSagas from 'containers/dashboard/sagas'

export default function* rootSaga() {
  yield all([
    unitySagas(),
    searchSagas(),
    meetingSagas(),
    claimSagas(),
    profilePicturesSagas(),
    quickViewSagas(),
    loginSagas(),
    deskBookingSagas(),
    updateSagas(),
    flowPlannerSagas(),
    personalData(),
    dashboardSagas(),
  ])
}
