import PropTypes from 'prop-types'
import React, { Component, Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import PanelButton from 'containers/mainPanel/PanelButton'
import { setCameraAngle, buildingView } from 'api/unity'
import { getCurrentFloorId } from 'containers/app/selectors'
import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import { clearInnerDrawer } from 'containers/mainPanel/panelDrawer/actions'
import { icons } from 'components/icon/icons'

const AngleButtons = ({ angle, setAngle }) => {
  return (
    <PanelDrawer
      label={angle.toUpperCase()}
      iconName={angle === '3d' ? 'd-view2' : 'd-view'}
      activeIndex={angle}
    >
      <PanelButton
        iconName={icons.TWO_D_VIEW}
        onClick={() => setAngle('2d')}
        active={angle === '2d'}
      >
        <Translate id="billboard.mainPanel.angleButtons.2d" />
      </PanelButton>
      <PanelButton
        iconName={icons.THREE_D_VIEW}
        onClick={() => setAngle('3d')}
        active={angle === '3d'}
      >
        <Translate id="billboard.mainPanel.angleButtons.3d" />
      </PanelButton>
    </PanelDrawer>
  )
}

AngleButtons.propTypes = {
  clearInnerDrawer: PropTypes.func,
  currentFloorId: PropTypes.number,
  angle: PropTypes.string,
  setAngle: PropTypes.func,
}

const mapStateToProps = (state) => ({
  currentFloorId: getCurrentFloorId(state),
})

export default connect(mapStateToProps, { clearInnerDrawer })(AngleButtons)
