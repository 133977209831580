import React from 'react'
import styled from 'styled-components'

import wave from 'components/waveBackground/wave.svg'

const WaveImg = styled.img`
  position: absolute;
  bottom: 0;
  z-index: -1;
`

const WaveBackground = () => {
  return <WaveImg src={wave} alt="" />
}

export default WaveBackground
