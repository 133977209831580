import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import toJS from 'components/toJS'
import ResultContainer from 'containers/searchPanel/results/ResultContainer'
import { getSpaces, getBuildings } from 'containers/app/selectors'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const HeaderText = styled.p`
  display: flex;
  flex-direction: row;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.theme.panelBgLight};
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const SpanText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

const ResultHeader = ({ zone, children, building }) => {
  const [showChildren, setShowChildren] = useState(true)
  const zoneOrBuilding = zone || building || null
  if (!zoneOrBuilding) return null

  return (
    <>
      <ResultContainer active onClick={() => setShowChildren(!showChildren)}>
        <HeaderText>
          <SpanText>{zoneOrBuilding.info.name}</SpanText>
          <Icon name={showChildren ? icons.DOWN : icons.RIGHT} />
        </HeaderText>
      </ResultContainer>
      {showChildren && children}
    </>
  )
}

ResultHeader.propTypes = {
  id: PropTypes.string,
  zone: PropTypes.object,
  children: PropTypes.any,
  building: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  const zone = getSpaces(state).get(`${props.id}`)
  const building = getBuildings(state).get(`${props.id}`)

  return {
    zone,
    building,
  }
}

export default connect(mapStateToProps)(toJS(ResultHeader))
