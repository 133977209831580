import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

import { shadow6dp } from 'utils/elevations'
import {
  getOpenPopup,
  getIsFetching,
} from 'containers/mainPanel/bookedResources/selectors'
import {
  closeConfirmationPopup,
  sendConfirmation,
} from 'containers/mainPanel/bookedResources/actions'
import StaticPopover from 'components/StaticPopover'

const StyledDiv = styled.div`
  ${shadow6dp};
  max-height: ${(props) => props.maxHeight};
  overflow-y: ${(props) => (props.innerMenu ? 'inherit' : 'auto')};
  overflow-x: ${(props) => (props.fetchCalendarData ? 'visible' : 'hidden')};
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.panelBg} !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid ${(props) => props.theme.panelBg};
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`
const ContainerDiv = styled.div`
  position: absolute;
  margin-left: 4px;
  display: flex;
  left: 100%;
  bottom: 0px;
`

class DrawerContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bottom: -100,
    }
    this.drawer = React.createRef()
  }

  componentDidMount() {
    const { heightIndex } = this.props
    const defaultIndex = 0.4
    const multiplyByIndex = heightIndex ? heightIndex : defaultIndex
    this.setState({
      maxHeight: window.innerHeight * multiplyByIndex,
    })
  }

  render() {
    const {
      children,
      style,
      fetchCalendarData,
      showConfirmEventMessage,
      isFetching,
      popupData,
      closeConfirmationPopup,
      sendConfirmation,
      innerMenu,
    } = this.props
    const { top, maxHeight } = this.state
    const showPopup = !!popupData.id
    return (
      <ContainerDiv>
        <StyledDiv
          fetchCalendarData={fetchCalendarData}
          innerMenu={innerMenu}
          style={{ ...style, top, maxHeight }}
          ref={this.drawer}
        >
          {children}
        </StyledDiv>
        {showConfirmEventMessage && (
          <StaticPopover
            closeConfirmationPopup={closeConfirmationPopup}
            sendConfirmation={sendConfirmation}
            popupData={popupData}
            showPopup={showPopup}
            isFetching={isFetching}
            deleteReservationMessage={showConfirmEventMessage}
          />
        )}
      </ContainerDiv>
    )
  }
}

DrawerContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  style: PropTypes.object,
  fetchCalendarData: PropTypes.bool,
  showConfirmEventMessage: PropTypes.bool,
  isFetching: PropTypes.bool,
  popupData: PropTypes.object,
  closeConfirmationPopup: PropTypes.func,
  sendConfirmation: PropTypes.func,
  innerMenu: PropTypes.bool,
  heightIndex: PropTypes.number,
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  popupData: getOpenPopup(state),
})

export default connect(mapStateToProps, {
  closeConfirmationPopup,
  sendConfirmation,
})(DrawerContent)
