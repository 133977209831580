import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  getCurrentBuildingId,
  getCurrentFloorId,
  getFloors,
  getBuildings,
} from 'containers/app/selectors'
import InfoText from 'containers/quickView/InfoText'
import ModalRow from 'containers/quickView/modal/ModalRow'
import toJS from 'components/toJS'

const InfoHeader = styled.div`
  font-weight: 500;
  font-size: 18px;
`
const StyledInfoText = styled(InfoText)`
  font-weight: normal;
`

const MultiSelectInfo = ({ className, currentBuilding, currentFloor }) => {
  return (
    <ModalRow className={className}>
      <StyledInfoText>
        <InfoHeader>
          <span>{currentBuilding.info.name}</span>
        </InfoHeader>
        <span>{currentFloor.info.name}</span>
      </StyledInfoText>
    </ModalRow>
  )
}

MultiSelectInfo.propTypes = {
  className: PropTypes.string,
  currentBuilding: PropTypes.object,
  currentFloor: PropTypes.object,
}

const mapStateToProps = (state) => {
  const currentFloorId = getCurrentFloorId(state)
  const currentBuildingId = getCurrentBuildingId(state)

  const buildings = getBuildings(state)
  const floors = getFloors(state)

  const currentFloor = floors.get(`${currentFloorId}`)
  const currentBuilding = buildings.get(`${currentBuildingId}`)

  return {
    currentFloor,
    currentBuilding,
  }
}

export default connect(mapStateToProps)(toJS(MultiSelectInfo))
