import styled, { css } from 'styled-components'

const OpenList = styled.div`
  width: 20px;
  margin: 0 10px 0 0;
  transform: rotate(270deg);
  padding-top: 10px;
  font-size: 23px;
  height: 100%;
  :hover {
    cursor: pointer;
  }
  ${(props) =>
    props.isOpen &&
    css`
      i {
        transform: rotate(180deg);
      }
    `};
`

export default OpenList
