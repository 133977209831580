import React from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { format, isFuture } from 'date-fns'
import englishLocale from 'date-fns/locale/en-US'
import * as locales from 'date-fns/locale'
import { enUS, fr } from 'date-fns/esm/locale'

import StatusIndicator from 'components/StatusIndicator'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'
import { getStatus } from 'containers/quickView/spaceModal/claimFlow/selectors'

const StyledIndicator = styled(StatusIndicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  height: 40px;
  text-align: center;
  padding: 8px 48px;
  font-weight: 500;
  position: relative;
  border-radius: 3px 3px 0 0;

  ${(props) =>
    (props.status === 'BUSY' ||
      props.status === 'OFFLINE' ||
      props.status === 'INFO' ||
      props.status === 'AWAY') &&
    css`
      color: ${(props) => props.theme.darkText};
    `};

  ${(props) =>
    (props.status === 'FREE' || props.status === 'NONE') &&
    css`
      background-color: ${props.theme.panelBg};
    `}
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 21px;
  margin: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  background: none;
  outline: none;
  color: inherit;
  height: 100%;

  ${Icon} {
    font-size: 24px;
    margin: 0;
  }

  ${(props) =>
    props.backBtn &&
    css`
      left: 0;
      font-weight: 400;
      font-size: 1.4rem;
      padding: 0 10px;
      max-width: 85px;
      justify-content: left;
    `}
`

const WiderIcon = styled(Icon)`
  width: 1.5em;
`

const statusMap = {
  FREE: 'space.status.free',
  BUSY: 'space.status.busy',
  AWAY: 'space.status.away',
  BOOKED: 'space.status.booked',
  CLAIMED: 'space.status.claimed',
  TAKEN: 'space.status.busy',
  OOO: 'space.status.offline',
  OFFLINE: 'space.status.offline',
  INFO: 'space.status.information',
  NONE: 'space.status.none',
}

const localeMap = {
  en: englishLocale,
  sv: locales.sv,
  no: locales.nb,
  fr: locales.fr,
  de: locales.de,
  es: locales.es,
}

const dateFormatMap = {
  en: 'MMMM do',
  sv: 'd MMMM',
  no: 'do MMMM',
  fr: 'd MMMM',
  de: 'do MMMM',
  es: 'do MMMM',
}

const ModalStatus = ({
  status,
  onClick,
  onBack,
  selectedDate,
  children,
  getBOBOStatus,
  activeLanguage,
}) => {
  const getDateValues = () => {
    return format(selectedDate, dateFormatMap[activeLanguage.code], {
      locale: localeMap[activeLanguage.code],
    })
  }

  return (
    <Translate>
      {({ translate }) => (
        <StyledIndicator status={status}>
          {getBOBOStatus && (
            <StyledButton backBtn onClick={onBack}>
              <WiderIcon backBtn name="back" />
              {translate('space.status.back')}
            </StyledButton>
          )}
          {statusMap[status] && translate(statusMap[status])}
          {selectedDate && isFuture(selectedDate) && ` ${getDateValues()}`}
          {children}
          <StyledButton onClick={onClick}>
            <Icon name={icons.CLOSE} />
          </StyledButton>
        </StyledIndicator>
      )}
    </Translate>
  )
}

ModalStatus.propTypes = {
  status: PropTypes.oneOf([
    'FREE',
    'BUSY',
    'AWAY',
    'BOOKED',
    'CLAIMED',
    'TAKEN',
    'OOO',
    'OFFLINE',
    'INFO',
    'NONE',
  ]),
  onClick: PropTypes.func,
  onBack: PropTypes.func,
  children: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  getBOBOStatus: PropTypes.bool,
  activeLanguage: PropTypes.object,
}

const mapStateToProps = (state) => ({
  getBOBOStatus: getStatus(state) === 'selectOnBehalf',
})

export default withLocalize(connect(mapStateToProps)(ModalStatus))
