import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  setBuilding,
  updateRequest,
  locationRequest,
  updateDeskBookingsRequest,
  fetchFutureViewRequest,
} from 'containers/app/actions'
import { setMapInterval } from 'containers/searchPanel/filter/actions'
import {
  getHasFetched,
  getPollingActive,
  getCurrentFloorId,
  getCurrentBuildingId,
  getFloors,
  getBuildings,
} from 'containers/app/selectors'
import { getUnityHasLoaded } from 'containers/unityLoader/selectors'
import {
  getIsFuture,
  getDateFilter,
  getSelectedInterval,
} from 'containers/searchPanel/filter/selectors'
import UIWrapper from 'components/UIWrapper'
import MainPanel from 'containers/MainPanel'
import SearchPanel from 'containers/SearchPanel'
import TouchActions from 'containers/TouchActions'
import UnityLoader from 'containers/UnityLoader'
import QuickView from 'containers/QuickView'
import Dashboard from 'containers/dashboard/Dashboard'

class Billboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrevDateInFuture: false,
    }
  }
  componentDidMount() {
    const { setBuilding, locationRequest } = this.props

    const buildingId = window.localStorage.getItem('buildingId')
    const floorId = window.localStorage.getItem('floorId')

    if (!buildingId || !floorId) {
      locationRequest()
    } else {
      setBuilding(parseInt(buildingId, 10), parseInt(floorId, 10))
    }
    this.contentPollInterval = setInterval(this.pollContent, 3 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.contentPollInterval)
  }

  pollContent = async () => {
    const {
      isFuture,
      updateRequest,
      pollingActive,
      pollingStart,
      pollingEnd,
      fetchFutureViewRequest,
      currentFloorId,
      validLocation,
      unityHasLoaded,
      locationRequest,
    } = this.props
    setMapInterval({ start: pollingStart, end: pollingEnd })
    if (!validLocation) {
      locationRequest()
    }
    if (pollingActive && unityHasLoaded) {
      const { isPrevDateInFuture } = this.state
      if (isFuture) {
        this.setState({ isPrevDateInFuture: true })
        fetchFutureViewRequest()
      } else if (isPrevDateInFuture) {
        const resetMap = true
        updateRequest([currentFloorId], resetMap, isPrevDateInFuture)
        this.setState({ isPrevDateInFuture: false })
      } else {
        updateRequest([currentFloorId])
      }
    }
    updateDeskBookingsRequest()
  }

  render() {
    const { hasFetched, unityHasLoaded } = this.props
    if (!hasFetched) return null
    return (
      <Fragment>
        <UnityLoader />
        <QuickView />
        <Dashboard />
        {!(process.env.NODE_ENV !== 'development' && !unityHasLoaded) && (
          <UIWrapper>
            <MainPanel />
            <SearchPanel />
            <TouchActions />
          </UIWrapper>
        )}
      </Fragment>
    )
  }
}

Billboard.propTypes = {
  currentFloorId: PropTypes.number,
  hasFetched: PropTypes.bool,
  locationRequest: PropTypes.func,
  pollingActive: PropTypes.bool,
  setBuilding: PropTypes.func,
  unityHasLoaded: PropTypes.bool,
  updateRequest: PropTypes.func,
  isFuture: PropTypes.bool,
  fetchFutureViewRequest: PropTypes.func,
  updateDeskBookingsRequest: PropTypes.func,
  setMapInterval: PropTypes.func,
  pollingStart: PropTypes.instanceOf(Date),
  pollingEnd: PropTypes.instanceOf(Date),
  validLocation: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const hasFetched = getHasFetched(state)
  const unityHasLoaded = getUnityHasLoaded(state)
  const pollingActive = getPollingActive(state)
  const currentFloorId = getCurrentFloorId(state)
  const currentBuildingId = getCurrentBuildingId(state)
  const isFuture = getIsFuture(state)
  const dateFilter = getDateFilter(state)
  const selectedInterval = getSelectedInterval(state)
  const useDefaultTimes =
    !selectedInterval || Object.values(selectedInterval).length <= 0
  const pollingStart = useDefaultTimes
    ? new Date(dateFilter.setHours(6, 0, 0, 0))
    : selectedInterval.start
  const pollingEnd = useDefaultTimes
    ? new Date(dateFilter.setHours(18, 0, 0, 0))
    : selectedInterval.end
  const buildings = getBuildings(state)
  const floors = getFloors(state)
  const currentFloor = floors.get(`${currentFloorId}`)
  const currentBuilding = buildings.get(`${currentBuildingId}`)
  const validLocation = !!currentFloor && !!currentBuilding

  return {
    hasFetched,
    unityHasLoaded,
    pollingActive,
    isFuture,
    pollingStart,
    pollingEnd,
    validLocation,
    currentFloorId,
  }
}

export default connect(mapStateToProps, {
  updateRequest,
  setBuilding,
  locationRequest,
  fetchFutureViewRequest,
  updateDeskBookingsRequest,
  setMapInterval,
})(Billboard)
