import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'

import toJS from 'components/toJS'
import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'
import InfoSymbol from 'containers/quickView/InfoSymbol'
import StatusIndicator from 'components/StatusIndicator'
import {
  getSpaceBookingStatus,
  checkForFullDayBooking,
  convertToUnixMinutes,
} from 'utils/utilsFunctions'
import {
  getSpaces,
  getMetaTypes,
  getDisableTimeSelection,
} from 'containers/app/selectors'
import { getRegisteredSlot } from 'containers/quickView/selectors'
import { removeMultiSelectDesk } from 'containers/quickView/actions'
import { ASSET_STATUSES } from 'utils/appVars'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'

const MultiSelectItemContainer = styled.div`
  display: flex;
  min-height: 48px;
  padding: 6px 0;
  align-items: center;
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.holidayPanelItem};
  font-size: 1.5em;
`
const MultiSelectItemText = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 22px;
  flex: 1;
  max-width: 250px;
  color: ${(props) => props.theme.darkText};
  > span:first-child {
    font-size: 16px !important;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const StyledIcon = styled(Icon)`
  margin-left: 15px;
  position: absolute;
  right: -10px;
  top: 8px;
  font-size: 0.8em;
  font-weight: 900;
`
const statusMap = {
  FREE: 'space.status.free',
  BOOKED: 'space.status.unavailable',
  UNAVAILABLE_NON_BOOKABLE: 'space.status.unavailable_nonBookable',
  UNAVAILABLE_BLOCKED: 'space.status.unavailable_blocked',
}

const MultiSelectItem = ({
  bookings,
  space,
  slot,
  id,
  removeMultiSelectDesk,
  type,
  disableTimeSelection,
  selectedDate,
}) => {
  const getSpaceStatus = () => {
    const { mode: spaceStatus } = type.space
    if (spaceStatus === ASSET_STATUSES.TAKE) {
      return ASSET_STATUSES.UNAVAILABLE_NON_BOOKABLE
    }
    if (spaceStatus === ASSET_STATUSES.NONE) {
      return ASSET_STATUSES.UNAVAILABLE_BLOCKED
    } else {
      let bookingStatus
      if (!disableTimeSelection) {
        bookingStatus = checkForFullDayBooking(bookings, selectedDate)
      } else {
        bookingStatus = getSpaceBookingStatus(bookings, slot)
      }
      return bookingStatus
    }
  }
  const [status, setStatus] = useState(getSpaceStatus())

  useEffect(() => {
    const newStatus = getSpaceStatus()
    setStatus(newStatus)
  }, [bookings, slot]) // eslint-disable-line react-hooks/exhaustive-deps

  const locateDeskFromBooking = () => {
    if (!bookings) return
  }
  const handleRemoveDeskIconClick = () => {
    removeMultiSelectDesk(id)
  }
  return (
    <MultiSelectItemContainer onClick={() => locateDeskFromBooking()}>
      <InfoSymbol hideIcon multiSelect>
        <Icon name={icons.DESK} />
        <StatusIndicator status={status} />
      </InfoSymbol>
      <MultiSelectItemText>
        <span> {space.info.name} </span>
        <span>
          <Translate>
            {({ translate }) => translate(statusMap[status])}
          </Translate>
        </span>
      </MultiSelectItemText>
      <StyledIcon
        name={icons.CLOSE}
        clickable
        onClick={handleRemoveDeskIconClick}
      />
    </MultiSelectItemContainer>
  )
}

MultiSelectItem.propTypes = {
  bookings: PropTypes.array,
  space: PropTypes.object,
  slot: PropTypes.object,
  id: PropTypes.string,
  removeMultiSelectDesk: PropTypes.func,
  type: PropTypes.object,
  disableTimeSelection: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
}

const mapStateToProps = (state, props) => {
  const space = getSpaces(state).get(`${props.id}`)
  const typeId = space.get('typeId')
  const type = getMetaTypes(state).get(`${typeId}`)
  const slot = getRegisteredSlot(state)
  const disableTimeSelection = getDisableTimeSelection(state)
  const selectedDate = getDateFilter(state)

  return {
    space,
    type,
    slot,
    disableTimeSelection,
    selectedDate,
  }
}

export default connect(mapStateToProps, { removeMultiSelectDesk })(
  toJS(MultiSelectItem),
)
