import { combineReducers } from 'redux-immutable'

import { UNITY_INIT_SUCCESS } from 'containers/unityLoader/constants'
import {
  START_WAYFINDING_SUCCESS,
  START_WAYFINDING_FAILURE,
  STOP_WAYFINDING_SUCCESS,
  STOP_WAYFINDING_FAILURE,
} from 'containers/app/constants'

const hasLoaded = (state = false, action) => {
  switch (action.type) {
    case UNITY_INIT_SUCCESS:
      return true
    default:
      return state
  }
}

const isWayfinding = (state = false, action) => {
  switch (action.type) {
    case START_WAYFINDING_SUCCESS:
      return true

    case STOP_WAYFINDING_SUCCESS:
    case START_WAYFINDING_FAILURE:
    case STOP_WAYFINDING_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({ hasLoaded, isWayfinding })
