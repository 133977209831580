import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Translate } from 'react-localize-redux'
import {
  withStyles,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

import { getDurationFilter } from 'containers/searchPanel/filter/selectors'
import { setDurationFilter } from 'containers/searchPanel/filter/actions'
import toJS from 'components/toJS'
import { getSpaces } from 'containers/app/selectors'
const SliderContainer = styled.div`
  background-color: ${(props) => props.theme.panelBgLight};
  color: ${(props) => props.theme.panelActiveBg};
  padding: 0;
  padding-bottom: 16px;
`

const SliderCaption = styled.div`
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
`

const createCustomTheme = (props) => {
  const { theme } = props

  return createTheme({
    palette: {
      primary: {
        main: theme.panelActiveBg,
      },
    },
    typography: {
      useNextVariants: true,
    },
  })
}

class DurationFilter extends Component {
  handleChangeDuration = (event, sliderValue) => {
    const { setDurationFilter } = this.props
    setDurationFilter(sliderValue)
  }

  calculateMaxSeats = () => {
    const { spaces } = this.props
    return Math.max.apply(
      Math,
      Object.values(spaces).map((space) => {
        return space.info.cap
      }),
    )
  }
  formatDuration = () => {
    const { minDuration } = this.props

    const hours = Math.floor(minDuration / 60)
    const minutes = minDuration - hours * 60

    const noFilter = <Translate id="filter.anyDuration" />
    if (hours === 0 && minutes === 0) return noFilter

    return (
      <Fragment>
        {hours > 0 && (
          <Translate
            id="filter.specifiedDurationHours"
            data={{ hours: hours }}
          />
        )}
        {minutes > 0 && (
          <Translate
            id="filter.specifiedDurationMinutes"
            data={{ minutes: minutes }}
          />
        )}
      </Fragment>
    )
  }

  render() {
    const { minDuration } = this.props
    return (
      <SliderContainer>
        <SliderCaption>
          <Translate id="filter.minDuration" /> {this.formatDuration()}
        </SliderCaption>
        <MuiThemeProvider theme={createCustomTheme(this.props)}>
          <Slider
            value={minDuration}
            onChange={this.handleChangeDuration}
            min={0}
            max={180}
            step={15}
          />
        </MuiThemeProvider>
      </SliderContainer>
    )
  }
}

DurationFilter.propTypes = {
  minDuration: PropTypes.number,
  setDurationFilter: PropTypes.func,
  spaces: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    minDuration: getDurationFilter(state),
    spaces: getSpaces(state),
  }
}

const withConnect = connect(mapStateToProps, { setDurationFilter })

export default withStyles({})(
  compose(withConnect, withTheme)(toJS(DurationFilter)),
)
