import { select, call, take, takeEvery, all, put } from 'redux-saga/effects'

import * as unity from 'api/unity'
import { getCurrentFloorId } from 'containers/app/selectors'
import { FILTER_REQUEST } from 'containers/app/constants'
import { UNITY_COMMAND_DONE } from 'containers/unityLoader/constants'
import { filterSuccess } from 'containers/app/actions'
import { getIsFuture } from 'containers/searchPanel/filter/selectors'
const commandDone = (command) => (action) =>
  action.type === UNITY_COMMAND_DONE && action.payload.command === command

function* filter({ filterType, showSpaceIds, hideSpaceIds, hideUserIds }) {
  const currentFloorId = yield select(getCurrentFloorId)
  const isFuture = yield select(getIsFuture)
  const shouldShowIssues = !isFuture
  const payload = {
    floorId: currentFloorId,
    filterType: filterType,
    issues: shouldShowIssues,
    show: {
      spaceIds: showSpaceIds,
    },
    hide: {
      spaceIds: hideSpaceIds,
      userIds: hideUserIds,
    },
  }
  yield call(unity.filter, payload)
  yield take(commandDone('filter'))
  yield put(filterSuccess())
}

function* watchFilter() {
  yield takeEvery(FILTER_REQUEST, filter)
}

export default function* filterSagas() {
  yield all([watchFilter()])
}
