import { takeEvery, all, call, take, put, select } from 'redux-saga/effects'

import * as unity from 'api/unity'
import { LOCATE_REQUEST, FETCH_UPDATE_SUCCESS } from 'containers/app/constants'
import {
  UNITY_COMMAND_DONE,
  UNITY_SELECTED_FLOOR,
} from 'containers/unityLoader/constants'
import {
  locateFailure,
  locateSuccess,
  setFloor,
  startFilter,
  setBuilding,
} from 'containers/app/actions'
import { selectSpaceId } from 'containers/quickView/actions'
import {
  getCurrentBuildingId,
  getCurrentFloorId,
  getFloors,
} from 'containers/app/selectors'

const commandDone = (command) => (action) =>
  action.type === UNITY_COMMAND_DONE && action.payload.command === command

function* locate({ floorId, assetType, assetId }) {
  const currentBuildingId = yield select(getCurrentBuildingId)
  const currentFloorId = yield select(getCurrentFloorId)
  const floors = yield select(getFloors)
  const floor = floors.get(`${floorId}`)
  const buildingId = floor.get('buildingId')
  if (buildingId && currentBuildingId !== buildingId) {
    yield put(startFilter('show_all'))
    yield put(setBuilding(buildingId, floorId))
    yield all([
      take(UNITY_SELECTED_FLOOR),
      take(FETCH_UPDATE_SUCCESS),
      take(commandDone('show_floor')),
    ])
  }
  if (currentFloorId !== floorId) {
    yield put(startFilter('show_all'))
    yield put(setFloor(floorId, true))
    yield all([
      take(UNITY_SELECTED_FLOOR),
      take(FETCH_UPDATE_SUCCESS),
      take(commandDone('show_floor')),
    ])
  }
  yield call(unity.locate, floorId, assetType, assetId)
  const response = yield take(commandDone('locate'))
  if (response.payload.successful) {
    yield put(locateSuccess())
    yield put(selectSpaceId(assetId))
  } else {
    yield put(locateFailure())
  }
}

function* watchLocate() {
  yield takeEvery(LOCATE_REQUEST, locate)
}

function* locateSagas() {
  yield all([watchLocate()])
}

export default locateSagas
