import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

const SearchButton = styled.button`
  background: transparent;
  border: none;
  color: #5c5c5c;
  padding: 0;
  padding-right: 24px;
  font: inherit;
  outline: none;
  position: absolute;
  right: 0;
  height: 100%;

  ${(props) =>
    props.clickthrough &&
    css`
      pointer-events: none;
    `}

  ${Icon} {
    font-size: 18px;
    margin: 0;
  }
`

export default SearchButton
