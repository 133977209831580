import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getTranslate } from 'react-localize-redux'

import InfiniteScrollHolder from 'containers/flowPlanner/InfiniteScrollHolder'
import ListHolder from 'containers/flowPlanner/ListHolder'
import MetaHolder from 'containers/flowPlanner/MetaHolder'
import StatisticsItem from 'containers/flowPlanner/StatisticsItem'
import DetailedInfoCard from 'containers/flowPlanner/DetailedInfoCard'
import { getBuildings, getCurrentBuildingId } from 'containers/app/selectors'
import { transformData } from 'containers/flowPlanner/flowplannerServices'

const ColleagueSearchList = ({
  buildingAttendance,
  fetchMoreData,
  loadMorePages,
  buildings,
  translate,
  currentBuilding,
}) => {
  const getBuildingName = (buildingId) => {
    return buildings.get(`${buildingId}`).getIn(['info', 'name'])
  }

  buildingAttendance = buildingAttendance
    ? transformData(buildingAttendance, currentBuilding)
    : []
  const maxHeight = '510px'
  return (
    <ListHolder>
      <MetaHolder>
        <StatisticsItem
          value={`${translate('flowPlanner.colleagues')} (${
            buildingAttendance.length
          })`}
          hasIcon
          iconName={'USER'}
        />
      </MetaHolder>
      <InfiniteScrollHolder height={maxHeight}>
        <InfiniteScroll
          dataLength={buildingAttendance.length}
          next={fetchMoreData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          hasMore={loadMorePages}
          scrollableTarget="scrollableDiv"
        >
          {buildingAttendance.map((attendance, index) => {
            return (
              <DetailedInfoCard
                key={index}
                user={attendance.userInfo}
                location={getBuildingName(attendance.buildingId)}
                type={attendance.type}
                space={attendance.space}
              />
            )
          })}
        </InfiniteScroll>
      </InfiniteScrollHolder>
    </ListHolder>
  )
}

ColleagueSearchList.propTypes = {
  icon: PropTypes.string,
  buildingAttendance: PropTypes.array,
  fetchMoreData: PropTypes.func,
  loadMorePages: PropTypes.bool,
  buildings: PropTypes.object,
  translate: PropTypes.func,
  currentBuilding: PropTypes.number,
}
const mapStateToProps = (state) => ({
  buildings: getBuildings(state),
  translate: getTranslate(state.get('localize')),
  currentBuilding: getCurrentBuildingId(state),
})

export default connect(mapStateToProps, {})(ColleagueSearchList)
