import styled from 'styled-components'

import Icon from 'components/Icon'

const ButtonContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  ${Icon} {
    flex-shrink: 0;
    margin: 0 0 0 8px;
  }
`

export default ButtonContent
