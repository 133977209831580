import styled, { css } from 'styled-components'

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelBgLight};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadow};
  overflow: hidden;

  ${(props) =>
    props.isForm &&
    css`
      margin: 20px;
      max-width: 640px;
    `};
`

export default Panel
