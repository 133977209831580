import styled, { css } from 'styled-components'

const ResultContainer = styled.div`
  display: flex;
  padding: 10px 30px 10px 15px;
  border-bottom: 1px solid ${(props) => props.theme.divider};
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.holidayPanelItem};
  margin-right: -20px;
  font-size: 13px;
  overflow: hidden;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.logoBg};
    `};
`

export default ResultContainer
