import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import { StyledFilterItem } from 'containers/searchPanel/filter/FilterItem'

const StyledDatasetItem = styled(StyledFilterItem)`
  background: none;
  color: ${(props) => props.theme.panelBgLight};
  padding-bottom: 12px;

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.panelBgLight};
      color: ${(props) => props.theme.darkText};
    `};
`

class DatasetItem extends PureComponent {
  render() {
    const { iconName, caption, ...rest } = this.props
    return (
      <StyledDatasetItem {...rest}>
        <Icon name={iconName} />
        <span>{caption}</span>
      </StyledDatasetItem>
    )
  }
}

DatasetItem.propTypes = {
  caption: PropTypes.string,
  iconName: PropTypes.string,
}

export default DatasetItem
