import { combineReducers } from 'redux-immutable'

import {
  SHOW_DASHBOARD,
  HIDE_DASHBOARD,
  SET_START_TIME,
  FETCH_SCHEDULES,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
} from 'containers/dashboard/constants'
import { CLEAR_QUICK_VIEW } from 'containers/quickView/constants'
import { MEETING_SUCCESS } from 'containers/quickView/spaceModal/bookingFlow/constants'

const showDashboard = (state = null, action) => {
  switch (action.type) {
    case SHOW_DASHBOARD:
      return action.panel
    case HIDE_DASHBOARD:
      return null
    default:
      return state
  }
}

const meetingTime = (state = null, action) => {
  switch (action.type) {
    case SET_START_TIME:
      return { startDate: action.startDate, endDate: action.endDate }
    case CLEAR_QUICK_VIEW:
    case HIDE_DASHBOARD:
      return null
    default:
      return state
  }
}

const scheduleData = (state = [], action) => {
  switch (action.type) {
    case FETCH_SCHEDULE_SUCCESS:
      return action.data
    case HIDE_DASHBOARD:
      return []
    default:
      return state
  }
}

const renderSwimlanes = (state = 'no', action) => {
  switch (action.type) {
    case FETCH_SCHEDULE_SUCCESS:
      return 'yes'
    case FETCH_SCHEDULE_FAILURE:
      return 'error'
    case HIDE_DASHBOARD:
    case FETCH_SCHEDULES:
    case MEETING_SUCCESS:
      return 'no'
    default:
      return state
  }
}

export default combineReducers({
  showDashboard,
  meetingTime,
  scheduleData,
  renderSwimlanes,
})
