import { combineReducers } from 'redux-immutable'
import { Map } from 'immutable'

import { UPDATE_AVAILABLE_DESKS } from '../constants'

import { CLEAR_UPDATES } from 'containers/app/constants'

export const createByIdReducer = (persistant = false) => (
  state = Map(),
  action,
) => {
  if (!persistant && action.type === CLEAR_UPDATES) {
    return new Map()
  }
  if (action.type === UPDATE_AVAILABLE_DESKS) {
    return action.response.assets ? action.response.assets : Map()
  }

  return state
}

export default combineReducers({
  availableDesks: createByIdReducer(true),
})
