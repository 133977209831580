import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startWayfinding } from 'containers/app/actions'
import { getFloors, getSelfLocation } from 'containers/app/selectors'
import { getHomeBuildingId } from 'containers/userSettings/selectors'

class WayFindButton extends Component {
  static propTypes = {
    buildingId: PropTypes.number,
    defaultBuildingId: PropTypes.number,
    floorId: PropTypes.number,
    hasPosition: PropTypes.any,
    itemId: PropTypes.number.isRequired,
    startWayfinding: PropTypes.any,
    type: PropTypes.string.isRequired,
  }

  handleWayfinding = () => {
    const { type, itemId, floorId, startWayfinding } = this.props
    startWayfinding(floorId, type, itemId)
  }

  canWayFind = () => {
    const { defaultBuildingId, buildingId, hasPosition } = this.props
    return defaultBuildingId === buildingId && hasPosition
  }

  render() {
    return null
    /*(
      <ModalButton
        wide
        onClick={this.handleWayfinding}
        disabled={!this.canWayFind()}
      >
        <Icon name={icons.WAYFIND} />
        <span>
          <Translate id="quickView.wayfinding" />
        </span>
      </ModalButton>
    )*/
  }
}

const mapStateToProps = (state, props) => {
  const floorId = props.floorId
  const floors = getFloors(state)
  const buildingId = floorId ? floors.get(`${floorId}`).get('buildingId') : null
  const type = props.type
  const itemId = props.itemId
  const defaultBuildingId = getHomeBuildingId(state)
  const hasPosition = getSelfLocation(state) !== null
  return { type, buildingId, defaultBuildingId, itemId, floorId, hasPosition }
}

export default connect(mapStateToProps, { startWayfinding })(WayFindButton)
