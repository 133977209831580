import {
  takeEvery,
  takeLatest,
  all,
  select,
  call,
  put,
  delay,
} from 'redux-saga/effects'
import Alert from 'react-s-alert'
import { getUnixTime, format } from 'date-fns'
import fetch from 'isomorphic-fetch'

import { config } from 'api/public'
import * as argus from 'api/argus'
import {
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAILURE,
  REGISTER_ATTENDANCE_REQUEST,
  FETCH_REGISTERED_ATTENDANCE_REQUEST,
  DELETE_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_DETAILS_FAILURE,
  FETCH_ATTENDANCE_DETAILS_SUCCESS,
  FETCH_ATTENDANCE_DETAILS_REQUEST,
  FETCH_CAPACITY_REQUEST,
  FETCH_UNIQUE_IMAGES,
} from 'containers/flowPlanner/constants'
import { authenticateFailure, updateRequest } from 'containers/app/actions'
import {
  fetchAttendanceОverviewRequest,
  fetchAttendanceОverviewSuccess,
  fetchAttendanceОverviewFailure,
  fetchAttendanceFailure,
  fetchAttendanceSuccess,
  registerAttendanceFailed,
  fetchSelfAttendance,
  fetchAttendanceCapacitySuccess,
  fetchAttendanceDetailsFailure,
  fetchAttendanceDetailsSuccess,
  fetchAttendanceCapacityFailure,
  fetchAttendanceFilteredSuccess,
  setMessageToShow,
  deleteAttendanceSuccess,
  deleteAttendanceFailure,
} from 'containers/flowPlanner/actions'
import { fetchProfilePicture } from 'containers/searchPanel/results/actions'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import {
  allAttendanceData,
  allFavoritesData,
  filteredAttendanceData,
} from 'containers/flowPlanner/mockedData'
import { uniqueIds } from 'utils/utilsFunctions'
import { getProfilePictures } from 'containers/searchPanel/results/selectors'
import {
  WRONG_SLOT,
  ERROR_APPEARED,
  REACHED_CAPACITY,
  ATTENDANCE_REGISTERED,
  ATTENDANCE_REMOVED,
} from 'utils/infoMessages'
import { closeConfirmationPopup } from 'containers/mainPanel/bookedResources/actions'
import { getClientFeatures } from 'containers/app/selectors'

function* fetchAttendanceCapacity({ buildingId }) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/v2/planner/overview/${buildingId}`,
    )

    yield put(fetchAttendanceCapacitySuccess(response))
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(fetchAttendanceCapacityFailure(error.message))

    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* fetchAttendanceОverview({ buildingId, pageNumber }) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/v2/planner/overview/${buildingId}/days?page=${pageNumber}`,
    )

    yield put(fetchAttendanceОverviewSuccess(response))
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(fetchAttendanceОverviewFailure(error.message))

    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* fetchAttendanceDetails({ buildingId, pageNumber, searchQuery }) {
  const dateSelected = yield select(getDateFilter)
  const date = format(new Date(dateSelected), 'yyyy-MM-dd')
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/v2/planner/details/${buildingId}/attendance?date=${date}&page=${pageNumber}&search=${searchQuery}`,
    )

    if (searchQuery.length > 0) {
      yield put(fetchAttendanceFilteredSuccess(response))
    } else {
      yield put(fetchAttendanceDetailsSuccess(response))
    }
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(fetchAttendanceDetailsFailure(error.message))

    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}
function* registerAttendance({ userInfo }) {
  const start = getUnixTime(new Date(userInfo.userTimeSlot.start)) / 60
  const end = getUnixTime(new Date(userInfo.userTimeSlot.end)) / 60
  const buildingId = userInfo.buildingId
  const type = userInfo.attendanceType

  try {
    const response = yield call(
      argus.postData,
      `/secure/v2/attendance/registration`,
      {
        body: { start, end, buildingId, type },
      },
      false,
    )

    yield delay(1000)
    yield put(setMessageToShow(ATTENDANCE_REGISTERED))
    yield put(fetchSelfAttendance())
  } catch ({ error, response }) {
    if (error.code === 400) {
      yield put(setMessageToShow(WRONG_SLOT))
    }
    if (error.code === 500) {
      yield put(setMessageToShow(ERROR_APPEARED))
    }
    if (error.code === 409) {
      yield put(setMessageToShow(REACHED_CAPACITY))
    }
    yield put(registerAttendanceFailed(error.message))
  }
}

const removeAttendanceRegistration = async (eid) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/v2/attendance/registration/${eid}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    return Promise.resolve(response)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

function* deleteAttendance({ eid, buildingId }) {
  try {
    yield call(removeAttendanceRegistration, eid)
    yield delay(1000)
    yield all([
      put(setMessageToShow(ATTENDANCE_REMOVED)),
      put(fetchSelfAttendance()),
      put(closeConfirmationPopup()),
      put(deleteAttendanceSuccess()),
    ])
  } catch ({ error, response }) {
    yield put(put(deleteAttendanceFailure()))
    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}
function* fetchAttendance() {
  const clientFeatures = yield select(getClientFeatures)
  const showThisFeature = clientFeatures
    ? clientFeatures.getIn(['attendance', 'enabled'])
    : false

  if (showThisFeature)
    try {
      const response = yield call(
        argus.fetchData,
        `/secure/v2/attendance/registration`,
      )

      yield put(fetchAttendanceSuccess(response))
    } catch ({ error, response }) {
      console.error(error.message)
      yield put(fetchAttendanceFailure(error.message))

      if (response.status === 401) {
        yield put(authenticateFailure())
      } else {
        Alert.error(`ERROR: ${error.message}`)
      }
    }
  return null
}
function* fetchUniqueImages({ arrayIds }) {
  const arrayToFetch = uniqueIds(arrayIds)
  for (const id of arrayToFetch) {
    const profilePictures = yield select(getProfilePictures)

    if (profilePictures.get(id) === undefined)
      yield put(fetchProfilePicture(id))
  }
}

function* watchАttendanceОverview() {
  yield takeEvery(FETCH_ATTENDANCE_REQUEST, fetchAttendanceОverview)
}
function* watchАttendanceCapacity() {
  yield takeEvery(FETCH_CAPACITY_REQUEST, fetchAttendanceCapacity)
}

function* watchRegisterAttendance() {
  yield takeEvery(REGISTER_ATTENDANCE_REQUEST, registerAttendance)
}
function* watchDeleteAttendance() {
  yield takeEvery(DELETE_ATTENDANCE_REQUEST, deleteAttendance)
}
function* watchfetchAttendance() {
  yield takeEvery(FETCH_REGISTERED_ATTENDANCE_REQUEST, fetchAttendance)
}
function* watchfetchAttendanceDetails() {
  yield takeEvery(FETCH_ATTENDANCE_DETAILS_REQUEST, fetchAttendanceDetails)
}
function* watchFetchUniqueImages() {
  yield takeEvery(FETCH_UNIQUE_IMAGES, fetchUniqueImages)
}

export default function* flowPlannerSagas() {
  yield all([
    watchАttendanceОverview(),
    watchRegisterAttendance(),
    watchDeleteAttendance(),
    watchfetchAttendance(),
    watchfetchAttendanceDetails(),
    watchАttendanceCapacity(),
    watchFetchUniqueImages(),
  ])
}
