import fetch from 'isomorphic-fetch'

import { config } from 'api/public'
import { getClientTime } from 'utils/argusTimeUtils'

const joinParamArray = (name, arr) => arr.map((i) => `${name}=${i}`).join('&')

const parseData = (data) =>
  Object.keys(data).reduce(
    (acc, key) => ({
      ...acc,
      [key]: JSON.stringify(data[key]),
    }),
    {},
  )

export const logoutArgus = async (endpoint) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}${endpoint}`, {
    credentials: 'include',
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    ...parseData({}),
  })
  if (response.ok) {
    const data = 'Logout Successful'
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const fetchData = async (endpoint) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}${endpoint}`, {
    credentials: 'include',
    method: 'GET',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
  if (response.ok) {
    if (response.status === 204) return Promise.resolve()
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const fetchBlob = async (endpoint) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}${endpoint}`, {
    credentials: 'include',
    method: 'GET',
  })

  if (response.status === 200) {
    const objectURL = response.blob()
    return Promise.resolve(objectURL)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const fetchUpdate = async (
  stamp,
  floorIds = [],
  floorStamps = [],
  resetMap,
) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/update?status=${stamp}&${joinParamArray(
      'fId',
      floorIds,
    )}&${joinParamArray('fStatic', floorStamps)}&${joinParamArray(
      'fStatus',
      floorStamps,
    )}&date=${getClientTime()}&refresh=${resetMap}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  )

  if (response.ok) {
    if (response.status === 204) return Promise.resolve()
    else if (response.status === 205) {
      // Argus have restarted and everything needs to be reloaded from scratch
      window.location.reload(true)
    }
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const fetchFutureUpdate = async (
  floorId,
  start,
  end,
  includeDesks = true,
  includeRooms = true,
) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/status/floorId/${floorId}?start=${start}&end=${end}&desks=${includeDesks}&rooms=${includeRooms}`,

    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    },
  )
  if (response.ok) {
    if (response.status === 204) return Promise.resolve()
    else if (response.status === 205) {
      // Argus have restarted and everything needs to be reloaded from scratch
      window.location.reload(true)
    }
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const fetchPublicData = async (endpoint) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}${endpoint}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const postData = async (endpoint, data, getsJSON = true) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}${endpoint}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...parseData(data),
  })
  if (response.ok) {
    if (getsJSON) {
      const data = await response.json()
      return Promise.resolve(data)
    }
    return Promise.resolve(response.status)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}
