import {
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAILURE,
  REGISTER_ATTENDANCE_REQUEST,
  REGISTER_ATTENDANCE_FAILURE,
  FETCH_REGISTERED_ATTENDANCE_REQUEST,
  FETCH_REGISTERED_ATTENDANCE_SUCCESS,
  FETCH_REGISTERED_ATTENDANCE_FAILURE,
  DELETE_ATTENDANCE_REQUEST,
  DELETE_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAILURE,
  FETCH_ATTENDANCE_DETAILS_FAILURE,
  FETCH_ATTENDANCE_DETAILS_SUCCESS,
  FETCH_ATTENDANCE_DETAILS_REQUEST,
  FETCH_CAPACITY_FAILURE,
  FETCH_CAPACITY_REQUEST,
  FETCH_CAPACITY_SUCCESS,
  FETCH_ATTENDANCE_FILTERED_SUCCESS,
  CLEAR_SEARCH_STATE,
  SET_MESSAGE,
  DELETE_MESSAGE,
  FETCH_UNIQUE_IMAGES,
  CHANGE_MODAL_STYLE,
  CLEAR_STATE,
  CLEAR_DAILY_STATE,
  CLOSE_ATTENDANCE_POPUP,
  OPEN_ATTENDANCE_POPUP,
} from 'containers/flowPlanner/constants'

export const fetchAttendanceCapacityRequest = (buildingId) => ({
  type: FETCH_CAPACITY_REQUEST,
  buildingId,
})
export const fetchAttendanceCapacitySuccess = (response) => ({
  type: FETCH_CAPACITY_SUCCESS,
  attendanceData: response,
})
export const fetchAttendanceCapacityFailure = (message) => ({
  type: FETCH_CAPACITY_FAILURE,
  message,
})

export const fetchAttendanceОverviewRequest = (buildingId, pageNumber = 1) => ({
  type: FETCH_ATTENDANCE_REQUEST,
  buildingId,
  pageNumber,
})
export const fetchAttendanceОverviewSuccess = (response) => ({
  type: FETCH_ATTENDANCE_SUCCESS,
  attendanceData: response,
})

export const fetchAttendanceОverviewFailure = (message) => ({
  type: FETCH_ATTENDANCE_FAILURE,
  message,
})

export const registerAttendanceRequest = (userInfo) => ({
  type: REGISTER_ATTENDANCE_REQUEST,
  userInfo,
})

export const registerAttendanceFailed = (message) => ({
  type: REGISTER_ATTENDANCE_FAILURE,
  message,
})

export const deleteAttendance = (eid, buildingId) => ({
  type: DELETE_ATTENDANCE_REQUEST,
  eid,
  buildingId,
})
export const deleteAttendanceSuccess = () => ({
  type: DELETE_ATTENDANCE_SUCCESS,
})

export const deleteAttendanceFailure = () => ({
  type: DELETE_ATTENDANCE_FAILURE,
})

export const fetchSelfAttendance = () => ({
  type: FETCH_REGISTERED_ATTENDANCE_REQUEST,
})

export const fetchAttendanceSuccess = (response) => ({
  type: FETCH_REGISTERED_ATTENDANCE_SUCCESS,
  selfAttendance: response,
})

export const fetchAttendanceFailure = (message) => ({
  type: FETCH_REGISTERED_ATTENDANCE_FAILURE,
  message,
})

export const fetchAttendanceDetails = (
  buildingId,
  pageNumber = 1,
  searchQuery = '',
) => ({
  type: FETCH_ATTENDANCE_DETAILS_REQUEST,
  buildingId,
  pageNumber,
  searchQuery,
})

export const fetchAttendanceDetailsSuccess = (response) => ({
  type: FETCH_ATTENDANCE_DETAILS_SUCCESS,
  allAttendecesForSelectedDay: response,
})
export const fetchAttendanceFilteredSuccess = (response) => {
  return {
    type: FETCH_ATTENDANCE_FILTERED_SUCCESS,
    allAttendecesFiltered: response,
  }
}

export const clearPrevSearch = () => {
  return {
    type: CLEAR_SEARCH_STATE,
    allAttendecesFiltered: [],
  }
}

export const fetchAttendanceDetailsFailure = (message) => ({
  type: FETCH_ATTENDANCE_DETAILS_FAILURE,
  message,
})

export const setMessageToShow = (message) => ({
  type: SET_MESSAGE,
  message,
})
export const deleteMessageToShow = () => ({
  type: DELETE_MESSAGE,
})

export const fetchUniqueImages = (arrayIds) => ({
  type: FETCH_UNIQUE_IMAGES,
  arrayIds,
})

export const changeModalPosition = (newStyle = {}) => ({
  type: CHANGE_MODAL_STYLE,
  newStyle,
})

export const clearFlowPlannerState = () => ({
  type: CLEAR_STATE,
})

export const clearDailyState = () => ({
  type: CLEAR_DAILY_STATE,
})

export const openAttendancePopup = () => ({
  type: OPEN_ATTENDANCE_POPUP,
})

export const closeAttendancePopup = () => ({
  type: CLOSE_ATTENDANCE_POPUP,
})
