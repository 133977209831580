export const SHOW_DASHBOARD = 'containers/dashboard/SHOW_DASHBOARD'
export const HIDE_DASHBOARD = 'containers/dashboard/HIDE_DASHBOARD'

export const SET_START_TIME = 'containers/dashboard/SET_STARTTIME'

export const FETCH_SCHEDULES = 'containers/dashboard/FETCH_SCHEDULES'
export const FETCH_SCHEDULE_SUCCESS =
  'containers/dashboard/FETCH_SCHEDULE_SUCCESS'
export const FETCH_SCHEDULE_FAILURE =
  'containers/dashboard/FETCH_SCHEDULE_FAILURE'
