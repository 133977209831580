import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
  getCurrentFloorId,
  getUserIdsOnFloors,
  getUsers,
} from 'containers/app/selectors'
import ResultList from 'containers/searchPanel/results/ResultList'
import FlexSpacer from 'components/FlexSpacer'
import UserItem from 'containers/searchPanel/results/UserItem'
import { setActiveByIndex } from 'containers/searchPanel/results/actions'
import { getActiveByIndex } from 'containers/searchPanel/results/selectors'

class UserResults extends Component {
  render() {
    const { userIds, users, setActiveByIndex, activeIndex } = this.props

    return (
      <Fragment>
        <ResultList>
          {userIds &&
            userIds.map((id, index) => (
              <UserItem
                key={id}
                user={users.get(`${id}`)}
                active={index === activeIndex}
                onClick={() => setActiveByIndex(index)}
              />
            ))}
        </ResultList>
        <FlexSpacer />
      </Fragment>
    )
  }
}

UserResults.propTypes = {
  activeIndex: PropTypes.number,
  setActiveByIndex: PropTypes.func,
  userIds: PropTypes.arrayOf(PropTypes.number),
  users: PropTypes.object,
}

const mapStateToProps = (state) => {
  const currentFloorId = getCurrentFloorId(state)
  const userIds = getUserIdsOnFloors(state).get(`${currentFloorId}`)
  const users = getUsers(state)
  const activeIndex = getActiveByIndex(state)

  return {
    userIds,
    users,
    activeIndex,
  }
}

export default connect(mapStateToProps, { setActiveByIndex })(UserResults)
