import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import toJS from 'components/toJS'

const StyledStatisticsItem = styled.div`
  left: 0;
  right: 0;
  padding: 2px;
  width: 50%;
  background: ${(props) => props.theme.holidayStatistics};

  ${Icon} {
    font-size: 22px;
    margin: 0 0.2em 0 0;
    color: ${(props) => props.theme.statisticsItem};
  }

  span {
    font-size: 24px;
  }
`

const Span = styled.span`
  ${(props) =>
    props.status === 'free' &&
    css`
      color: ${(props) => props.theme.statusFree};
    `};

  ${(props) =>
    props.status === 'booked' &&
    css`
      color: ${(props) => props.theme.statusBooked};
    `};
`

class StatisticsItem extends PureComponent {
  render() {
    const { iconName, status, filteredSpaces } = this.props
    return (
      <StyledStatisticsItem>
        <Icon name={iconName} />
        <Span filteredSpaces={filteredSpaces} status={status}>
          {filteredSpaces != null ? filteredSpaces : ''}
        </Span>
      </StyledStatisticsItem>
    )
  }
}

StatisticsItem.propTypes = {
  filteredSpaces: PropTypes.number,
  iconName: PropTypes.string,
  status: PropTypes.string,
}

export default toJS(StatisticsItem)
