import styled, { css } from 'styled-components'

const FilterButton = styled.button`
  font: inherit;
  font-size: 12px;
  background: none;
  outline: none;
  border: 1px solid ${(props) => props.theme.panelBg};
  color: ${(props) => props.theme.darkText};
  padding: 4px 16px;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 8px;
  }

  ${(props) =>
    props.dark &&
    css`
      background-color: ${(props) => props.theme.darkText};
      color: ${(props) => props.theme.panelBgLight};
      border-color: transparent;
    `};
`

export default FilterButton
