import styled from 'styled-components'

const SearchInput = styled.input`
  background: transparent;
  border: none;
  color: inherit;
  flex: 1 1 auto;
  padding: 12px 18px;
  color: ${(props) => props.theme.darkText};
  font: inherit;
  outline: none;
  font-size: 14px;
  min-width: 1px;

  &::placeholder {
    color: ${(props) => props.theme.placeholder};
  }
`

export default SearchInput
