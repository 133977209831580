import styled, { css } from 'styled-components'

import Button from 'components/Button'
import Panel from 'components/Panel'
import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'

export const SelectButton = styled(Button)`
  width: 100%;
  background: none;
  color: #000;
  border: 1px solid #aba;
  padding: 8px;
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 8px;

  &:hover {
    background-color: #148368;
    color: white;
    border-color: #148368;
  }
  ${(props) =>
    props.selectSiteBtn &&
    css`
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      padding: 0;
      border: solid 1px #525252;
      color: #272626;
      border-radius: 0;
      font-weight: normal;
      text-transform: none;
      max-width: 510px;
    `};
`
export const BuildingSelectPanel = styled(Panel)`
  position: absolute;
  width: 544px;
  max-height: 680px;
  min-height: 680px;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.38);
`
export const CoverPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`
export const InnerBuildingSelector = styled(BuildingSelectPanel).attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  left: calc((100vw - 544px) / 2);
  top: -65vh;
  color: ${(props) => props.theme.darkText};
  z-index: 2;
`
export const BuildingSelectRow = styled.div`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 16px;

  ${(props) =>
    props.selectSitePopup &&
    css`
      margin: 0 0 0 16px;
    `};

  &::-webkit-scrollbar-thumb {
    background-color: #e1e1e1;
    border-radius: 70px;
    min-height: 70px;
    width: 5px;
    border: 5px solid white;
  }
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`

export const SelectHeader = styled.span`
  font-weight: 800;
  font-size: 24px;
  margin: 16px;
`
