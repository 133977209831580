import {
  FETCH_PERSONAL_CALENDAR_REQUEST,
  FETCH_PERSONAL_CALENDAR_SUCCESS,
  FETCH_PERSONAL_CALENDAR_FAILURE,
  DELETE_PERSONAL_EVENT_REQUEST,
  DELETE_PERSONAL_EVENT_FAILURE,
  DELETE_PERSONAL_EVENT_SUCCESS,
  OPEN_CONFIRMATION_POPUP,
  CLOSE_CONFIRMATION_POPUP,
  SEND_CONFIRMATION_POPUP,
} from 'containers/mainPanel/bookedResources/constants'

export const fetchPersonalCalendar = () => {
  return {
    type: FETCH_PERSONAL_CALENDAR_REQUEST,
  }
}

export const fetchPersonalCalendarSuccess = (response) => ({
  type: FETCH_PERSONAL_CALENDAR_SUCCESS,
  response,
})

export const fetchPersonalCalendarFailure = (message) => ({
  type: FETCH_PERSONAL_CALENDAR_FAILURE,
  message,
})

export const deletePersonalEvent = (eid) => ({
  type: DELETE_PERSONAL_EVENT_REQUEST,
  eid,
})

export const deletePersonalEventSuccess = () => ({
  type: DELETE_PERSONAL_EVENT_SUCCESS,
})

export const deletePersonalEventFailure = (message) => ({
  type: DELETE_PERSONAL_EVENT_FAILURE,
  message,
})

export const openConfirmationPopup = (bookingData) => ({
  type: OPEN_CONFIRMATION_POPUP,
  bookingData,
})

export const sendConfirmation = (bookingData) => ({
  type: SEND_CONFIRMATION_POPUP,
  bookingData,
})

export const closeConfirmationPopup = () => ({
  type: CLOSE_CONFIRMATION_POPUP,
})
