import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

import { getCurrentBuildingId } from 'containers/app/selectors'
import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import ResourceItem from 'containers/mainPanel/bookedResources/ResourceItem'
import {
  fetchPersonalCalendar,
  deletePersonalEvent,
} from 'containers/mainPanel/bookedResources/actions'
import { icons } from 'components/icon/icons'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import { getDeskBookings } from 'containers/mainPanel/bookingsList/selectors'
import { checkSlotInterval } from 'utils/utilsFunctions'
import { getSelfAttendances } from 'containers/flowPlanner/selectors'
import {
  getPersonalCalendar,
  getDeletionConfirmation,
} from 'containers/mainPanel/bookedResources/selectors'
import { deleteAttendance } from 'containers/flowPlanner/actions'
import { ORGANIZER } from 'utils/appVars'

const BookedResources = ({
  dateValue,
  fetchPersonalCalendar,
  personalCalendar,
  deletePersonalEvent,
  currentBuildingId,
  receivedConfirmation,
  translate,
}) => {
  const getBookings = React.useCallback(
    () =>
      personalCalendar.filter(
        (booking) =>
          checkSlotInterval(booking, dateValue) &&
          booking.resources &&
          booking.status === ORGANIZER &&
          booking.resources.length > 0,
      ),
    [dateValue, personalCalendar],
  )

  const [bookings, setBookings] = useState(getBookings())

  const deleteBooking = (eid) => {
    deletePersonalEvent(eid)
  }
  const size = bookings.length ?? 0
  const getPersonalCalendarData = true

  useEffect(() => {
    fetchPersonalCalendar()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (receivedConfirmation.id) deleteBooking(receivedConfirmation.id)
  }, [receivedConfirmation]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBookings(getBookings())
  }, [dateValue, getBookings])

  //used to make the dropdown menu with bigger max-height
  const heightIndex = 0.8
  return (
    <>
      <PanelDrawer
        iconName={icons.PROFILE}
        label={translate('flowPlanner.bookedResources')}
        allowToOpen={getPersonalCalendarData}
        fetchCalendarData={getPersonalCalendarData}
        showConfirmMessage
        heightIndex={heightIndex}
      >
        {!size && <ResourceItem />}
        {bookings.map((booking) => (
          <ResourceItem
            key={booking.id}
            id={booking.id}
            booking={booking}
            iconName={'DESK'}
          />
        ))}
      </PanelDrawer>
    </>
  )
}

BookedResources.propTypes = {
  selfAttendances: PropTypes.array,
  dateValue: PropTypes.instanceOf(Date),
  fetchPersonalCalendar: PropTypes.func,
  personalCalendar: PropTypes.array,
  deletePersonalEvent: PropTypes.func,
  deleteAttendance: PropTypes.func,
  currentBuildingId: PropTypes.number,
  receivedConfirmation: PropTypes.object,
  translate: PropTypes.func,
}

const mapStateToProps = (state) => ({
  selfAttendances: getSelfAttendances(state),
  dateValue: getDateFilter(state),
  personalCalendar: getPersonalCalendar(state),
  currentBuildingId: getCurrentBuildingId(state),
  receivedConfirmation: getDeletionConfirmation(state),
  translate: getTranslate(state.get('localize')),
})

export default connect(mapStateToProps, {
  fetchPersonalCalendar,
  deletePersonalEvent,
  deleteAttendance,
})(BookedResources)
