import isSameDay from 'date-fns/isSameDay'

import { makeSelector } from 'containers/app/selectors'

const attendanceCapacity = (state) =>
  state.getIn(['flowPlanner', 'attendanceCapacity'])
const attendanceDataset = (state) =>
  state.getIn(['flowPlanner', 'attendanceDataset'])
const attendanceDetails = (state) =>
  state.getIn(['flowPlanner', 'attendanceDetails'])
const selfAttendances = (state) =>
  state.getIn(['flowPlanner', 'selfAttendance'])
const attendanceDatasetDaysOnly = (state) =>
  state.getIn(['flowPlanner', 'attendanceDatasetDaysOnly'])
const attendanceDetailsUsersOnly = (state) =>
  state.getIn(['flowPlanner', 'attendanceDetailsUsersOnly'])
const filteredAttendace = (state) =>
  state.getIn(['flowPlanner', 'filteredAttendace'])
const filteredAttendaceUsersOnly = (state) =>
  state.getIn(['flowPlanner', 'filteredAttendaceUsersOnly'])
const showMessage = (state) => state.getIn(['flowPlanner', 'showMessage'])
const changeModalPosition = (state) =>
  state.getIn(['flowPlanner', 'changeModalPosition'])
const colleagueAttendanceCount = (state) =>
  state.getIn(['flowPlanner', 'colleagueAttendanceCount'])
const activeAttendancePopup = (state) =>
  state.getIn(['flowPlanner', 'activeAttendancePopup'])

export const getColleagueAttendanceCount = makeSelector(
  colleagueAttendanceCount,
)
export const getAttendanceCapacity = makeSelector(attendanceCapacity)
export const getAttendanceDataset = makeSelector(attendanceDataset)
export const getAttendanceDetails = makeSelector(attendanceDetails)
export const getSelfAttendances = makeSelector(selfAttendances)
export const getAttendanceDatasetDaysOnly = makeSelector(
  attendanceDatasetDaysOnly,
)
export const getAttendanceDetailsUsersOnly = makeSelector(
  attendanceDetailsUsersOnly,
)
export const getFilteredAttendace = makeSelector(filteredAttendace)
export const getFilteredAttendaceUsersOnly = makeSelector(
  filteredAttendaceUsersOnly,
)
export const getShowMessage = makeSelector(showMessage)
export const getChangeModalPosition = makeSelector(changeModalPosition)

export const getActiveAttendancePopup = makeSelector(activeAttendancePopup)
