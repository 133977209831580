const darkTheme = {
  globalBG: '#eef0f2',
  panelBg: 'rgba(48, 48, 48, 0.96)',
  panelBgLight: '#FFF',
  panelBorder: '#000',
  panelActiveBg: '#686868',
  panelColor: 'rgba(255, 255, 255, 0.7)',
  logoBg: '#212121',
  actionButton: '#FFF',
  loginButton: '#2979ff',
  accent: '#148368',
  statusBg: '#c0c0c0',
  statusFree: '#0F9D77',
  statusAway: '#FFD400',
  statusBusy: '#FFD400',
  statusBooked: '#E02020',
  statusClaimed: '#E02020',
  statusTaken: '#E02020',
  statusOOO: '#ACACAC',
  statusOffline: '#ACACAC',
  statusNone: '#ACACAC',
  keyboardBG: '#d6d8d9',
  darkText: '#2F3437 ',
  divider: 'rgba(0, 0, 0, 0.2)',
  darkerDivider: '#7a7a7a',
  innerDivider: 'rgba(0, 0, 0, 0.4)',
  disabledButtonBg: 'rgba(0, 0, 0, 0.1)',
  disabledButton: 'rgba(0, 0, 0, 0.26)',
  shadow:
    '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),0 1px 5px 0 rgba(0, 0, 0, 0.12)',
  placeholder: 'rgba(0, 0, 0, 0.54)',
  statisticsItem: '#FFF',
  shadow8dp:
    '0 8px 10px 1px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.14),0 5px 5px -3px rgba(0, 0, 0, 0.2)',
  holidayStatistics: 'transparent',
  holidayMainPanel: 'transparent',
  holidayPanelItem: 'transparent',
  holidayResult: '',
  inputColor: '#2F3437',
  buttonColor: '#424d61',
  darkBgr: '#323232',
}

export default darkTheme
