import {
  takeEvery,
  takeLatest,
  all,
  select,
  call,
  put,
  delay,
} from 'redux-saga/effects'
import Alert from 'react-s-alert'
import { getUnixTime } from 'date-fns'
import isEmpty from 'lodash/isEmpty'

import * as argus from 'api/argus'
import * as desks from 'api/desks'
import {
  MEETING_REQUEST,
  END_MEETING_REQUEST,
  MULTIPLE_BOOKING_REQUEST,
} from 'containers/quickView/spaceModal/bookingFlow/constants'
import {
  getCurrentFloorId,
  getSpaces,
  getMetaTypes,
  getFutureDeskbookingEnabled,
  getSelfId,
  getDefaultEnd,
  getDefaultStart,
} from 'containers/app/selectors'
import { authenticateFailure, updateRequest } from 'containers/app/actions'
import {
  meetingSuccess,
  meetingFailure,
  endMeetingFailure,
  endMeetingSuccess,
  fetchMeetingSlotsSuccess,
  fetchMeetingSlotsFailure,
  updateBookingsList,
  multipleBookingSuccess,
} from 'containers/quickView/spaceModal/bookingFlow/actions'
import {
  SELECT_SPACE_ID,
  MULTI_SELECT_REQUEST,
} from 'containers/quickView/constants'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import {
  BOOK_DESK_SUCCESS,
  CANCEL_BOOKING_SUCCESS,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import {
  getClaimOnBehalfPermission,
  getAssetCalendar,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import {
  setBookingMessageToShow,
  multiSelectFailure,
  multiSelectSuccess,
  multiSelectRequest,
} from 'containers/quickView/actions'
import { getEventsPerAsset } from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  convertToUnixMinutes,
  getDefaultTimeValues,
} from 'utils/utilsFunctions'
import {
  BOOKINGS_REGISTERED,
  INVALID_BOOKINGS,
  DOUBLE_BOOKING,
  WRONG_SLOT,
  ERROR_APPEARED,
  REACHED_CAPACITY,
  BOOKING_REGISTERED,
} from 'utils/infoMessages'
import { SECONDS_PER_MINUTE } from 'utils/appVars'

function* meetingRequest({ spaceId, slot, subject }) {
  const currentFloorId = yield select(getCurrentFloorId)
  const start = getUnixTime(slot.start.value) / SECONDS_PER_MINUTE
  const end = getUnixTime(slot.end.value) / SECONDS_PER_MINUTE

  try {
    const response = yield call(argus.postData, '/secure/calendar/create', {
      body: {
        spaceId,
        subject,
        start,
        end,
      },
    })
    const statusResponse = yield call(meetingStatus, response.ticket)
    yield put(getAssetCalendar(spaceId))

    yield put(meetingSuccess(statusResponse.item))
    const roomBookingSuccess = 'roomBookingSuccess'
    yield put(setBookingMessageToShow(BOOKING_REGISTERED))
    yield delay(500)
    yield put(updateRequest([currentFloorId]))
  } catch ({ error, response }) {
    if (error) {
      if (error.code === 400 || error.code === 409) {
        yield put(setBookingMessageToShow(WRONG_SLOT))
      }
      if (response.status === 401) {
        yield put(authenticateFailure())
      }
      if (error.code === 500) {
        yield put(setBookingMessageToShow(ERROR_APPEARED))
      }
      yield delay(500)
      yield put(meetingFailure(error.message))
    }
  }
}

function* meetingStatus(ticketId) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/calendar/status?ticket=${ticketId}`,
    )
    if (!response.status) {
      yield delay(250)
      return yield call(meetingStatus, ticketId)
    } else {
      return response
    }
  } catch ({ error, response }) {
    console.error(error.message)
    Alert.error(
      `ERROR: Failed to fetch meeting status from server: ${error.message}`,
    )
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* endMeetingRequest({ meetingBody }) {
  try {
    const response = yield call(argus.postData, '/secure/calendar/cancel', {
      body: meetingBody,
    })
    const statusResponse = yield call(meetingStatus, response.ticket)
    if (statusResponse.error) {
      yield put(endMeetingFailure(statusResponse.error))
    } else {
      yield put(endMeetingSuccess(statusResponse.item))
    }
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(endMeetingFailure(error.message))
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* meetingSlotsRoom({ id }) {
  const startMin = encodeURIComponent(
    Math.floor(new Date().getTime() / 1000 / 60),
  )

  try {
    const response = yield call(
      argus.fetchData,
      `/secure/available/timeslots?spaceId=${id}&startMin=${startMin}`,
      {
        body: {},
      },
    )
    yield all([
      put(getClaimOnBehalfPermission(id)),
      put(getAssetCalendar(id)),
      put(fetchMeetingSlotsSuccess(id, response)),
    ])
  } catch ({ error, response }) {
    yield put(fetchMeetingSlotsFailure(id))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* bookingSlotsDesk({ id }) {
  try {
    const response = yield call(desks.getDeskEvents, id)
    yield all([
      put(getClaimOnBehalfPermission(id)),
      put(getAssetCalendar(id)),
      put(fetchMeetingSlotsSuccess(id, response)),
    ])
  } catch ({ error, response }) {
    yield put(fetchMeetingSlotsFailure(id))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* meetingSlotsRequest({ id }) {
  const spaces = yield select(getSpaces)
  const spaceTypeId = spaces.getIn([`${id}`, 'typeId'])
  const types = yield select(getMetaTypes)
  const res = types.getIn([`${spaceTypeId}`, 'res'])
  //Dont fetch meeting slots for spaces that cant be booked
  if (res === 'type_room_book' || res === 'type_desk_take') {
    yield put(getAssetCalendar(id))
  }
  if (res === 'type_room_book') {
    yield call(meetingSlotsRoom, { id })
  } else if (res === 'type_desk_take') {
    const deskBookingEnabled = yield select(getFutureDeskbookingEnabled)
    if (!deskBookingEnabled) return
    yield call(bookingSlotsDesk, { id })
  } else {
    return null
  }
}

function* multiSelect({ ids }) {
  try {
    const response = yield all(ids.map((id) => call(desks.getDeskEvents, id)))
    const transformedResponse = response.reduce((acc, item, index) => {
      const id = ids[index]
      acc[id] = item.bookings
      return acc
    }, {})
    yield put(multiSelectSuccess(transformedResponse))
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(multiSelectFailure())
  }
}
function* updateMeetingSlotsRequest() {
  const currentSpaceId = yield select(getActiveSpaceId)
  if (!currentSpaceId) return
  yield call(meetingSlotsRequest, { id: currentSpaceId })
}

function* multipleBooking({ userId, multiSelectedSpaces, slot }) {
  const dateSelected = yield select(getDateFilter)
  const defaultStart = yield select(getDefaultStart)
  const defaultEnd = yield select(getDefaultEnd)
  const isFuture = yield select(getIsFuture)
  const defaultTimeValues = getDefaultTimeValues(
    dateSelected,
    defaultStart,
    defaultEnd,
    isFuture,
  )
  slot = convertToUnixMinutes(slot)
  const start = slot.start || defaultTimeValues.start
  const end = slot.end || defaultTimeValues.end

  const spaceIdObjects = Object.keys(multiSelectedSpaces).map((id) => ({
    id,
  }))
  try {
    const requestBody = {
      start,
      end,
      people: [],
      resources: spaceIdObjects,
      type: 'APPOINTMENT',
      subject: 'Multiple Booking',
      origin: 'NONE',
    }
    const userIdQueryParam = userId ? `?bobo=${userId}` : ''
    const url = `/secure/v2/calendar${userIdQueryParam}`
    const response = yield call(argus.postData, url, {
      body: requestBody,
    })
    yield put(updateBookingsList(response))
    yield put(multipleBookingSuccess())
    yield put(setBookingMessageToShow(BOOKINGS_REGISTERED))
  } catch ({ error, response }) {
    if (error.code === 400) {
      if (error.detailedCode === 'MULTI_DESKS') {
        yield put(setBookingMessageToShow(DOUBLE_BOOKING))
      } else {
        yield put(setBookingMessageToShow(INVALID_BOOKINGS))
      }
    }
    if (error.code === 409) {
      yield put(setBookingMessageToShow(REACHED_CAPACITY))
    }
    if (error.code === 500) {
      yield put(setBookingMessageToShow(ERROR_APPEARED))
    }
    yield put(meetingFailure(error.message))
  }
}

function* watchMeetingRequests() {
  yield takeEvery(MEETING_REQUEST, meetingRequest)
}

function* watchEndMeetingRequest() {
  yield takeEvery(END_MEETING_REQUEST, endMeetingRequest)
}

function* watchFetchMeetingSlotsRequest() {
  yield takeLatest(SELECT_SPACE_ID, meetingSlotsRequest)
}

function* watchUpdateDeskBookingSlotsRequests() {
  yield takeEvery(
    [BOOK_DESK_SUCCESS, CANCEL_BOOKING_SUCCESS],
    updateMeetingSlotsRequest,
  )
}

function* watchFetchBookingSlotsRequest() {
  yield takeLatest(MULTI_SELECT_REQUEST, multiSelect)
}

function* watchMultipleBookingRequests() {
  yield takeEvery(MULTIPLE_BOOKING_REQUEST, multipleBooking)
}

export default function* meetingSagas() {
  yield all([
    watchMeetingRequests(),
    watchEndMeetingRequest(),
    watchFetchMeetingSlotsRequest(),
    watchUpdateDeskBookingSlotsRequests(),
    watchMultipleBookingRequests(),
    watchFetchBookingSlotsRequest(),
  ])
}
