import {
  UNITY_INIT_REQUEST,
  UNITY_INIT_SUCCESS,
  UNITY_INIT_FAILURE,
} from 'containers/unityLoader/constants'

export const initUnity = () => ({
  type: UNITY_INIT_REQUEST,
})

export const initUnitySuccess = () => ({
  type: UNITY_INIT_SUCCESS,
})

export const initUnityFailure = () => ({
  type: UNITY_INIT_FAILURE,
})
