import englishLocale from 'date-fns/locale/en-US'
import * as locales from 'date-fns/locale'
import {
  isWithinInterval,
  fromUnixTime,
  isSameDay,
  isFuture,
  format,
  getHours,
  getMinutes,
  endOfDay,
  startOfDay,
  isEqual,
  getUnixTime,
} from 'date-fns'
import { useSelector } from 'react-redux'

import {
  DEFAULT_SPACE_START_TIME,
  DEFAULT_SPACE_END_TIME,
  DEFAULT_TIMESTAMP_INDEX,
  DEFAULT_TIME_FORMAT,
  MILLISECONDS_PER_MINUTE,
  ASSET_STATUSES,
  SECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
  DEFAULT_ROUND_MINUTES,
  LAST_TIME_PICKER_HOUR,
} from 'utils/appVars'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'

export const sortByString = (stringA, stringB) =>
  stringA.localeCompare(stringB, 'en', { numeric: true })

const localeMap = {
  en: englishLocale,
  sv: locales.sv,
  no: locales.nb,
  fr: locales.fr,
  de: locales.de,
  es: locales.es,
}

const dateFormatMap = {
  en: 'dd.MM.yy',
  sv: 'yy.MM.dd',
  no: 'yy.MM.dd',
  fr: 'dd.MM.yy',
  de: 'dd.MM.yy',
  es: 'dd.MM.yy',
}

export const getLocale = (languageCode) => localeMap[languageCode]

export const checkSlotInterval = (slot, dateToCheck) => {
  const slotStartInMs = slot.start * 60 * 1000
  const slotEndInMs = slot.end * 60 * 1000
  const startOfDayValue = startOfDay(dateToCheck)
  const endOfDayValue = endOfDay(dateToCheck)
  return startOfDayValue < slotEndInMs && endOfDayValue > slotStartInMs
}

export const checkIfCurrentBooking = (slot) => {
  const start = fromUnixTime(new Date(slot.start * 60))
  const now = Date.now()
  const currentYear = new Date().getFullYear()
  const yearsToAdd = 1
  let end
  slot.end
    ? (end = fromUnixTime(new Date(slot.end * 60)))
    : (end = new Date(currentYear + yearsToAdd, 0))

  return now >= start && now <= end
}

export const uniqueIds = (idsArray) => {
  const resultObj = {}
  idsArray.forEach((item) => {
    resultObj[item] = ''
  })
  return Object.keys(resultObj)
}

export const buildInitialsString = (fn, ln) => {
  if (fn && fn.length) {
    return `${fn.substring(0, 1)}${ln && ln.length ? ln.substring(0, 1) : ''}`
  } else if (ln && ln.length) {
    return `${ln.substring(0, 1)}`
  } else {
    return ''
  }
}

const getCurrentLocale = () => {
  const currentLanguage = localStorage.getItem('language')
  return localeMap[currentLanguage]
}

export const getLocalizedTime = (
  timestamp,
  timeFormat = DEFAULT_TIME_FORMAT,
  index = DEFAULT_TIMESTAMP_INDEX,
) => {
  if (!timestamp) return ''
  const currentLocale = getCurrentLocale()
  return format(new Date(timestamp * index), timeFormat, {
    locale: currentLocale,
  })
}
export const getLocalizedDateAndTime = (
  timestamp,
  timeFormat = DEFAULT_TIME_FORMAT,
  index = DEFAULT_TIMESTAMP_INDEX,
) => {
  if (!timestamp) return ''
  const currentLocale = getCurrentLocale()
  const currentLanguage = localStorage.getItem('language')
  return format(
    new Date(timestamp * index),
    `${dateFormatMap[currentLanguage]} - ${timeFormat}`,
    {
      locale: currentLocale,
    },
  )
}

export const checkIfToearlierThanFrom = (valueTo, valueFrom) => {
  if (valueTo) {
    return valueTo <= valueFrom
  }
  return true
}

export const getWorkingHour = (
  startHour = 0,
  startMinutes = 0,
  selectedDate,
  timeFormat = DEFAULT_TIME_FORMAT,
) => {
  const date = selectedDate ? new Date(selectedDate) : new Date()
  const hourToShow = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    startHour,
    startMinutes,
  )
  const currentLocale = getCurrentLocale()
  return {
    label: format(hourToShow, timeFormat, { locale: currentLocale }),
    value: hourToShow,
  }
}

export const timeOptions = (
  intervalInMinutes,
  selectedDate,
  startHour,
  startMinutes,
  timeFormat = DEFAULT_TIME_FORMAT,
) => {
  const options = []
  const coeff = MILLISECONDS_PER_MINUTE * intervalInMinutes
  const currentDate = selectedDate ? new Date(selectedDate) : new Date()
  const endOfDayTime = endOfDay(currentDate)
  const currentTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    startHour,
    startMinutes,
  )
  const minutesRemainder = currentTime.getMinutes() % DEFAULT_ROUND_MINUTES
  if (minutesRemainder) {
    const minutesToAdd = intervalInMinutes - minutesRemainder
    currentTime.setMinutes(currentTime.getMinutes() + minutesToAdd)
  }
  while (currentTime < endOfDayTime) {
    options.push(new Date(currentTime))
    currentTime.setTime(currentTime.getTime() + coeff)

    if (currentTime.getHours() > LAST_TIME_PICKER_HOUR) {
      break
    }
  }
  const uniqueOptions = [...new Set(options)]
  return uniqueOptions.map((date) =>
    getWorkingHour(date.getHours(), date.getMinutes(), date, timeFormat),
  )
}

export const getLocalDate = (dateString) => {
  const date = new Date(dateString)
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  return date
}

export const getStartMinutesOfInterval = (
  minutesToCompare,
  usedInterval = 30,
) => {
  const index = Math.trunc(minutesToCompare / usedInterval)
  //returns the start minute of an partial interval
  return index * usedInterval
}

export const getEndTimeValue = (start, end, selectedDate) => {
  const time = new Date(start)
  const toIsEarlierThenFrom = checkIfToearlierThanFrom(end, time)
  let endTime
  if (toIsEarlierThenFrom) {
    endTime = getEndOfDayTime(time)
    endTime = new Date(endTime)
    return endTime
  }
  if (!end) {
    endTime = getDefaultEndTime(selectedDate)
    endTime = new Date(endTime)
    return endTime
  }
  endTime = new Date(end)
  return endTime
}

export const getDefaultEndTime = (timeFormat = DEFAULT_TIME_FORMAT) => {
  const defaultEnd = DEFAULT_SPACE_END_TIME
  const endHour = Math.trunc(defaultEnd / 60)
  const endMinute = defaultEnd % 60
  const defaultEndTime = getWorkingHour(
    endHour,
    endMinute,
    this.selectedDate,
    timeFormat,
  )
  return defaultEndTime
}

export const selectedItemIndex = (options, searchedValue) => {
  const ignoreSecondsAndMiliSeconds =
    searchedValue && searchedValue.value
      ? searchedValue.value.setHours(
          searchedValue.value.getHours(),
          searchedValue.value.getMinutes(),
          0,
          0,
        )
      : null
  return options.findIndex((date) =>
    isEqual(date.value, ignoreSecondsAndMiliSeconds),
  )
}

export const scrollTheValueToTop = (id) => {
  setTimeout(() => {
    const selectedEl = document.getElementById(id)
    if (selectedEl) {
      selectedEl.parentNode.scroll({
        top: selectedEl.offsetTop,
      })
    }
  }, 5)
}

export const addDayIfPastMidday = (days) => {
  const currentDay = new Date()
  const isPastNoon = currentDay.getHours() >= 12
  days = isPastNoon ? days + 1 : days
  return days
}

export const compareNameAndQueryString = (name, str) => {
  const fn = name.split(' ')[0].toLowerCase()
  const ln = name.split(' ')[1].toLowerCase()
  str = str.toLowerCase()
  return fn.startsWith(str) || ln.startsWith(str)
}

export const convertToUnixTimeStamp = (time, dateSelected) => {
  return (
    getUnixTime(
      new Date(dateSelected).setHours(
        time.value.getHours(),
        time.value.getMinutes(),
        0,
        0,
      ),
    ) / 60
  )
}

const areIntervalsOverlapping = (intervalA, intervalB) => {
  const { start: intervalAStart, end: intervalAEnd } = intervalA || {}
  const { start: intervalBStart, end: intervalBEnd } = intervalB || {}
  const startWithinInterval = isWithinInterval(intervalBStart, {
    start: intervalAStart,
    end: intervalAEnd,
  })
  const endWithinInterval = isWithinInterval(intervalBEnd, {
    start: intervalAStart,
    end: intervalAEnd,
  })
  const intervalWithinStartAndEnd =
    intervalBStart <= intervalAStart && intervalBEnd >= intervalAEnd
  return startWithinInterval || endWithinInterval || intervalWithinStartAndEnd
}

export const convertToUnixMinutes = (slot) => {
  if (!slot || !slot.start || !slot.end) {
    return {}
  }
  return {
    start: Math.floor(slot.start.value.getTime() / MILLISECONDS_PER_MINUTE),
    end: Math.floor(slot.end.value.getTime() / MILLISECONDS_PER_MINUTE),
  }
}

export const checkMultiSelectAvailability = (multiSelectedSpaces, slot) => {
  if (!multiSelectedSpaces || Object.values(multiSelectedSpaces).length === 0) {
    return false
  }
  return Object.values(multiSelectedSpaces).some((spaceBookings) =>
    spaceBookings.some((bookingInterval) => {
      return areIntervalsOverlapping(bookingInterval, slot)
    }),
  )
}

export const getSpaceBookingStatus = (spaceBookings, slot) => {
  const slotInUnixMinutes = convertToUnixMinutes(slot)
  if (!spaceBookings || spaceBookings.length === 0) {
    return ASSET_STATUSES.FREE
  }
  return spaceBookings.some((bookingInterval) => {
    return areIntervalsOverlapping(bookingInterval, slotInUnixMinutes)
  })
    ? ASSET_STATUSES.BOOKED
    : ASSET_STATUSES.FREE
}

export const getDefaultTimeValues = (
  dateSelected,
  defaultStart,
  defaultEnd,
  isFuture,
) => {
  const currentTime = new Date()
  const currentHour = currentTime.getHours()
  const currentMinute = currentTime.getMinutes()
  const defaultStartHour = Math.floor(defaultStart / MINUTES_PER_HOUR)
  const defaultStartMinute = defaultStart % MINUTES_PER_HOUR
  const defaultEndHour = Math.floor(defaultEnd / MINUTES_PER_HOUR)
  const defaultEndMinute = defaultEnd % MINUTES_PER_HOUR
  const shouldUseCurrentTime = currentHour >= defaultStartHour && !isFuture
  const startHour = shouldUseCurrentTime ? currentHour : defaultStartHour
  const startMinute = shouldUseCurrentTime ? currentMinute : defaultStartMinute
  const newStartTime = dateSelected.setHours(startHour, startMinute, 0, 0)
  const newEndTime = dateSelected.setHours(
    defaultEndHour,
    defaultEndMinute,
    0,
    0,
  )
  const newStartTimeInMinutes = Math.floor(
    newStartTime / MILLISECONDS_PER_MINUTE,
  )
  const newEndTimeInMinutes = Math.floor(newEndTime / MILLISECONDS_PER_MINUTE)
  return { start: newStartTimeInMinutes, end: newEndTimeInMinutes }
}

export const checkForFullDayBooking = (bookings, dateSelected) => {
  return bookings.some((booking) => {
    const end = fromUnixTime(booking.end * SECONDS_PER_MINUTE)
    return isFuture(end) && isSameDay(end, dateSelected)
  })
    ? ASSET_STATUSES.BOOKED
    : ASSET_STATUSES.FREE
}

export const getEndOfDayTime = (time) => {
  return (
    time.setHours(getHours(endOfDay(time)), getMinutes(endOfDay(time))) || time
  )
}
