import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getBuildings } from 'containers/app/selectors'
import PanelButton from 'containers/mainPanel/PanelButton'
import toJS from 'components/toJS'

const BuildingItem = ({ building, active, onClick }) => (
  <PanelButton
    active={active}
    onClick={() =>
      !active && onClick(building.id, building.info.default_floor_id)
    }
  >
    {building.info.name}
  </PanelButton>
)

BuildingItem.propTypes = {
  active: PropTypes.bool,
  building: PropTypes.object,
  onClick: PropTypes.func,
}

BuildingItem.propTypes = {
  active: PropTypes.bool,
  building: PropTypes.object,
  onClick: PropTypes.func,
}

const mapStateToProps = (state, props) => ({
  building: getBuildings(state).get(`${props.id}`),
})

export default connect(mapStateToProps)(toJS(BuildingItem))
