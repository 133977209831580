import { createSelector } from 'reselect'

const unityHasLoaded = (state) => state.getIn(['unity', 'hasLoaded'])
const isWayfinding = (state) => state.getIn(['unity', 'isWayfinding'])

export const getUnityHasLoaded = createSelector(
  [unityHasLoaded],
  (unityHasLoaded) => unityHasLoaded,
)

export const getIsWayfinding = createSelector(
  [isWayfinding],
  (isWayfinding) => isWayfinding,
)
