import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import styled from 'styled-components'

import {
  searchUsersRequest,
  searchUsersClear,
} from 'containers/quickView/actions'
import { clearClaimSpaceError } from 'containers/quickView/spaceModal/claimFlow/actions'
import { getUsers } from 'containers/quickView/selectors'
import { getClaimError } from 'containers/quickView/spaceModal/claimFlow/selectors'
import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalButton from 'containers/quickView/modal/ModalButton'
import UserSearchAutocomplete from 'containers/quickView/components/UserSearchAutocomplete'

const SnackbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;

  & .MuiSnackbar-root {
    width: calc(100% - 40px);
  }
`

const AlertContainer = styled.div`
  font-size: 1.5rem !important;
`

const DeskSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DeskSelect = ({
  isFetching,
  users,
  claimError,
  onBooking,
  onClose,
  searchUsersRequest,
  searchUsersClear,
  clearClaimSpaceError,
  disableBookingButtonByDefault,
}) => {
  const [user, setUser] = useState(null)
  const [userInput, setUserInput] = useState(null)

  const search = (query) => {
    if (query.trim()) {
      if (query.length >= 3) {
        searchUsersRequest(query.trim())
      }
    } else {
      searchUsersClear()
    }
  }

  const handleInputValueChange = (value) => {
    setUserInput(value)
    search(value)
  }

  return (
    <DeskSelectContainer>
      <ModalRow flat middleButton>
        <Translate>
          {({ translate }) => (
            <UserSearchAutocomplete
              options={users || []}
              getOptionLabel={(option) => option.name}
              placeholder={translate('quickView.searchUsers.placeholder')}
              noResultsText={
                userInput?.length >= 3
                  ? translate('quickView.searchUsers.noResults')
                  : translate('quickView.searchUsers.empty')
              }
              onChange={(_, user) => setUser(user)}
              onDebouncedInputValueChange={handleInputValueChange}
            />
          )}
        </Translate>
      </ModalRow>
      <ModalRow noBorder bottomButton>
        <ModalButtonRow>
          <ModalButton
            wide
            primary
            disabled={(disableBookingButtonByDefault && !user) || isFetching}
            onClick={() => onBooking(user)}
          >
            {isFetching && <Icon name={icons.SPINNER} rotating />}
            <span>
              <Translate id="quickView.button.book" />
            </span>
          </ModalButton>
        </ModalButtonRow>
      </ModalRow>

      <SnackbarContainer>
        <Snackbar
          open={!!claimError}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={clearClaimSpaceError}
        >
          <AlertContainer as={Alert} severity="warning" elevation={6}>
            <Translate id="quickView.deskNotAvailable" />
          </AlertContainer>
        </Snackbar>
      </SnackbarContainer>
    </DeskSelectContainer>
  )
}

DeskSelect.propTypes = {
  isFetching: PropTypes.bool,
  onBooking: PropTypes.func,
  onClose: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  claimError: PropTypes.string,
  searchUsersRequest: PropTypes.func,
  searchUsersClear: PropTypes.func,
  clearClaimSpaceError: PropTypes.func,
  disableBookingButtonByDefault: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  users: getUsers(state),
  claimError: getClaimError(state),
})

export default connect(mapStateToProps, {
  searchUsersRequest,
  searchUsersClear,
  clearClaimSpaceError,
})(DeskSelect)
