import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'

import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import InfoSymbol from 'containers/quickView/InfoSymbol'
import InfoText from 'containers/quickView/InfoText'
import ProfilePicture from 'components/ProfilePicture'
import {
  selectUser,
  selectUserDetailsRequest,
} from 'containers/quickView/actions'
import { changeModalPosition } from 'containers/flowPlanner/actions'
import { getActiveUser } from 'containers/quickView/selectors'
import { getLocalizedTime } from 'utils/utilsFunctions'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'
import {
  ALLOW_TO_OPEN_MODAL,
  OTHER_BUILDING,
  ATTENDANCE_ICONS,
} from 'utils/appVars'
import Icon from 'components/Icon'
import { getSpaces } from 'containers/app/selectors'
import toJS from 'components/toJS'
import { icons } from 'components/icon/icons'

const Holder = styled.div.attrs({
  className: ALLOW_TO_OPEN_MODAL,
})`
  &:first-child {
    border-top: solid 1px #d5d5d7;
  }
  height: 75px;
  border-bottom: solid 1px #d5d5d7;
  display: flex;
  align-items: center;
  padding: 0 21px 0 9px;
  border-left: 2px solid #fff;
  :hover {
    cursor: pointer;
    background-color: #f5fafe;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: #f5fafe;
      border-left: 2px solid #0178d4;
    `};
`
const SmallerInfoSymbol = styled.div`
  height: 40px !important;
  width: 40px !important;
  margin-right: 16px;
  > div {
    width: 100%;
    height: 100%;
    font-size: 18px;
  }
`
const StyledIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  height: 0.8em;
  width: 1.5em;
  position: relative;
  background-repeat: no-repeat;
  margin: 0;
  ${(props) =>
    props.type &&
    css`
      background-image: url(./${ATTENDANCE_ICONS[props.type]}.svg);
    `};
  ${(props) =>
    props.type === OTHER_BUILDING &&
    css`
      left: -5px;
    `};
`
const InfoTextRow = styled.span`
  display: flex;
  align-items: baseline;
  padding-top: 1px;
`

const InfoCardText = styled(InfoText)`
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  span:first-child {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 3px;
  }
`

const SpaceIcon = styled(Icon)`
  margin: 0 10px 0 0;
  font-size: 11px;
`
const DetailedInfoCard = ({
  user,
  changeModalPosition,
  activeUser,
  location,
  selectUserDetailsRequest,
  type,
  translate,
  space,
}) => {
  const userModalPosition = {
    bottom: '390px',
    'z-index': 3,
  }
  const isActiveUser = activeUser ? activeUser.id === user.id : false
  const userFn = user?.name?.split(' ')[0]
  const userLn = user?.name?.split(' ')[1]
  return (
    <Holder
      selected={isActiveUser}
      onClick={() => {
        changeModalPosition(userModalPosition)
        selectUserDetailsRequest(user.id)
      }}
    >
      <SmallerInfoSymbol>
        <InfoSymbol>
          <ProfilePicture id={user.id} fn={userFn} ln={userLn} />
        </InfoSymbol>
      </SmallerInfoSymbol>
      <InfoCardText>
        <InfoTextRow>
          {userFn} {userLn}
        </InfoTextRow>
        <InfoTextRow>
          <StyledIcon type={type} /> {location}
        </InfoTextRow>
        {space?.name && (
          <InfoTextRow>
            <SpaceIcon name={icons.DESK} />
            {translate('filter.title.space')}: {space?.name}
          </InfoTextRow>
        )}
      </InfoCardText>
    </Holder>
  )
}

DetailedInfoCard.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  selectUser: PropTypes.func,
  user: PropTypes.object,
  activeUser: PropTypes.object,
  changeModalPosition: PropTypes.func,
  timeFormat: PropTypes.string,
  location: PropTypes.string,
  selectUserDetailsRequest: PropTypes.func,
  type: PropTypes.string,
  translate: PropTypes.func,
  space: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  return {
    selectedDate: getDateFilter(state),
    activeUser: getActiveUser(state),
    timeFormat: getTimeFormat(state),
    translate: getTranslate(state.get('localize')),
  }
}

export default connect(mapStateToProps, {
  selectUser,
  changeModalPosition,
  selectUserDetailsRequest,
})(toJS(DetailedInfoCard))
