import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Login from 'pages/Login'
import Billboard from 'pages/Billboard'
import OAuthSuccess from 'pages/login/OAuthSuccess'
import { getIsAuthenticated, getStartupFlow } from 'containers/app/selectors'
import PrivateRoute from 'containers/app/PrivateRoute'
import UserConsent from 'pages/UserConsent'
import BuildingSelector from 'pages/BuildingSelector'

class Routes extends Component {
  render() {
    const { startupFlow, isAuthenticated } = this.props
    const currentStep = startupFlow.currentStep
    return (
      <Switch>
        <Route path="/oauthsuccess" component={OAuthSuccess} />
        {(!isAuthenticated || currentStep === 'auth') && (
          <Route exact path="/" component={Login} />
        )}
        {currentStep === 'approve' && (
          <Route exact path="/" component={UserConsent} />
        )}
        {currentStep === 'buildingSelect' && (
          <Route exact path="/" component={BuildingSelector} />
        )}
        {currentStep === 'run' && (
          <PrivateRoute exact path="/" component={Billboard} />
        )}
        {/* TODO: Add 404 page */}
      </Switch>
    )
  }
}

Routes.propTypes = {
  startupFlow: PropTypes.object,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  startupFlow: getStartupFlow(state),
  isAuthenticated: getIsAuthenticated(state),
})

export default connect(mapStateToProps)(Routes)
