import styled from 'styled-components'

export const StyledStatisticsItem = styled.div`
  left: 0;
  right: 0;
  margin: auto;
  color: ${(props) => props.theme.statisticsItem};
  padding: 2px 2px 2px 0px;
  width: 50%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
`
