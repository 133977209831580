//mocked data
export const userTest = {
  user: {
    id: 12343,
    fn: 'FirstName',
    ln: 'LastName',
    phone: '1234567654',
    title: 'Test',
    mail: 'test',
    state: 'FREE',
  },
  start: 27215070,
  end: 27215070,
}
const addUsers = (usersCount, prefix) => {
  const returnArray = []
  for (let i = 0; i <= usersCount; i++) {
    const newUser = userTest
    newUser.user.fn = `${newUser.user.fn}`
    returnArray.push(newUser)
  }
  return returnArray
}
export const allAttendanceData = {
  data: addUsers(5, 'all'),
  page: 1,
  pages: 4,
  total: 10,
}

export const filteredAttendanceData = {
  data: addUsers(3, 'filtered'),
  page: 1,
  pages: 4,
  total: 10,
}
export const allFavoritesData = {
  favorites: addUsers(5, 'fav'),
}
