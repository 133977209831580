import styled, { css } from 'styled-components'

const ModalRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px;
  margin: 0 16px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.darkerDivider};
  }

  ${(props) =>
    props.flat &&
    css`
      &${ModalRow} {
        border: 0;
        padding-bottom: 0;
      }
    `};

  ${(props) =>
    props.noBorder &&
    css`
      border-bottom: none !important;
    `};

  ${(props) =>
    props.topButton &&
    css`
      padding: 25px 13px 5px 13px;
      border-bottom: none !important;
    `};
  ${(props) =>
    props.middleButton &&
    css`
      padding: 5px 13px 5px 13px !important;
      border-bottom: none !important;
    `};
  ${(props) =>
    props.bottomButton &&
    css`
      padding: 5px 13px 35px 13px;
      border-bottom: none !important;
    `};
  ${(props) =>
    props.rightPadding5px &&
    css`
      padding-right: 5px;
    `};

  ${(props) =>
    props.boboBtn &&
    css`
      padding: 15px 0 28px 0 !important;
    `};

  ${(props) =>
    props.paddingBottom0 &&
    css`
      padding-bottom: 0 !important;
    `};
  ${(props) =>
    props.paddingTop35 &&
    css`
      padding: 35px 0 0 0;
    `};
  ${(props) =>
    props.multiSelect &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.darkerDivider}; ;
    `};
`

export default ModalRow
