import styled from 'styled-components'

const TouchActionsWrapper = styled.nav`
  grid-area: TouchActions;
  display: flex;
  color: ${(props) => props.theme.panelColor};
  margin: 0 -8px;
`

export default TouchActionsWrapper
