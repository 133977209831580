import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { setBuilding } from 'containers/app/actions'
import {
  getActiveBuildingIds,
  getBuildings,
  getCurrentBuildingId,
} from 'containers/app/selectors'
import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import BuildingItem from 'containers/mainPanel/buildingPicker/BuildingItem'
import { sortByString } from 'utils/utilsFunctions'
import { icons } from 'components/icon/icons'

const BuildingPicker = ({
  buildings,
  activeBuildingIds,
  currentBuildingId,
  setBuilding,
}) => {
  const currentBuilding = buildings.get(`${currentBuildingId}`)

  if (!currentBuilding) return null

  const activeIndex = activeBuildingIds.findIndex(
    (id) => id === currentBuildingId,
  )

  const allBuildings = activeBuildingIds.map((buildingId) =>
    buildings.get(`${buildingId}`),
  )

  const buildingsWithMap = allBuildings.filter((building) =>
    building.getIn(['info', 'bundle_name']),
  )

  const getBuildingName = (building) => building.getIn(['info', 'name'])

  return (
    <PanelDrawer
      iconName={icons.BUILDING}
      label={currentBuilding.getIn(['info', 'name'])}
      activeIndex={activeIndex}
      size={buildingsWithMap.size}
    >
      {buildingsWithMap
        .sort((buildingA, buildingB) =>
          sortByString(getBuildingName(buildingA), getBuildingName(buildingB)),
        )
        .map((building) => (
          <BuildingItem
            key={building.get('id')}
            id={building.get('id')}
            active={building.get('id') === currentBuildingId}
            onClick={setBuilding}
          />
        ))}
    </PanelDrawer>
  )
}

BuildingPicker.propTypes = {
  activeBuildingIds: PropTypes.object,
  buildings: PropTypes.object,
  currentBuildingId: PropTypes.number,
  setBuilding: PropTypes.func,
}

BuildingPicker.propTypes = {
  activeBuildingIds: PropTypes.object,
  buildings: PropTypes.object,
  currentBuildingId: PropTypes.number,
  setBuilding: PropTypes.func,
}

const mapStateToProps = (state) => ({
  buildings: getBuildings(state),
  activeBuildingIds: getActiveBuildingIds(state),
  currentBuildingId: getCurrentBuildingId(state),
})

export default connect(mapStateToProps, { setBuilding })(BuildingPicker)
