import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { getFloors } from 'containers/app/selectors'
import PanelButton from 'containers/mainPanel/PanelButton'
import toJS from 'components/toJS'

const FloorItem = ({ floor, active, onClick }) => (
  <PanelButton active={active} onClick={() => !active && onClick(floor.id)}>
    {floor.info.name}
  </PanelButton>
)

FloorItem.propTypes = {
  active: PropTypes.bool,
  floor: PropTypes.object,
  onClick: PropTypes.func,
}

FloorItem.propTypes = {
  active: PropTypes.bool,
  floor: PropTypes.object,
  onClick: PropTypes.func,
}

const mapStateToProps = (state, props) => ({
  floor: getFloors(state).get(`${props.id}`),
})

export default connect(mapStateToProps)(toJS(FloorItem))
