import { store } from 'index'
import {
  UNITY_READY,
  UNITY_COMMAND_DONE,
  UNITY_CLICKED_ASSET,
  UNITY_SELECTED_FLOOR,
  UNITY_PANNED_FLOOR,
  UNITY_DESELECTED_ASSET,
  UNITY_LONG_CLICKED,
  UNITY_UNFOLLOWED,
  UNITY_WARNING,
} from 'containers/unityLoader/constants'

export const initInterface = () => {
  window.addEventListener('message', handleEvent)
}

export const removeInterface = () => {
  window.removeEventListener('message', handleEvent)
}

const handleEvent = ({ origin, data: { type, payload } }) => {
  if (origin === window.location.origin && type === 'fromUnity') {
    const action = actionFromEvent(payload)
    if (action) {
      store.dispatch(action)
    }
  }
}

const createActionMaker = (content) => (type) => ({
  type,
  payload: { ...content },
})

const actionFromEvent = ({ command, content }) => {
  const createAction = createActionMaker(content)
  switch (command) {
    case 'ready':
      return createAction(UNITY_READY)

    case 'command_done':
      return createAction(UNITY_COMMAND_DONE)

    case 'selected_floor':
      return createAction(UNITY_SELECTED_FLOOR)

    case 'panned_to_floor':
      return createAction(UNITY_PANNED_FLOOR)

    case 'profile':
      return createAction(UNITY_CLICKED_ASSET)

    case 'deselect':
      return createAction(UNITY_DESELECTED_ASSET)

    case 'long_click':
      return createAction(UNITY_LONG_CLICKED)

    case 'unfollow':
      return createAction(UNITY_UNFOLLOWED)

    case 'warning':
      return createAction(UNITY_WARNING)

    default:
      return null
  }
}
