import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import get from 'lodash/get'

import FilterItem from 'containers/searchPanel/filter/FilterItem'
import FilterItemPNG from 'containers/searchPanel/filter/FilterItemPNG'
import {
  setPropFilter,
  clearFilter,
  clearDataset,
} from 'containers/searchPanel/filter/actions'
import { getPropFilters } from 'containers/searchPanel/filter/selectors'
import {
  getEquipmentInfo,
  getEquipmentIcons,
} from 'containers/searchPanel/results/selectors'
import { getRoomFiltersEquip } from 'containers/app/selectors'
import toJS from 'components/toJS'
import FilterItemGrid from 'containers/searchPanel/filter/FilterItemGrid'
import FilterContainer from 'containers/searchPanel/filter/FilterContainer'
import FilterButtonRow from 'containers/searchPanel/filter/FilterButtonRow'
import FilterButton from 'containers/searchPanel/filter/FilterButton'
import MinSeatsFilter from 'containers/searchPanel/filter/MinSeatsFilter'
import DurationFilter from 'containers/searchPanel/filter/DurationFilter'
import BookableFilter from 'containers/searchPanel/filter/BookableFilter'
import { propsMap } from 'containers/searchPanel/filter/constants'

class RoomFilters extends Component {
  onPropClick = (id) => {
    const { setPropFilter, propFilters } = this.props
    const key = id
    const subFilter = get(propFilters, key)
    setPropFilter(key, !subFilter)
  }

  render() {
    const {
      propFilters,
      clearFilter,
      clearDataset,
      roomEquipment,
      icons,
    } = this.props

    return (
      <FilterContainer>
        <BookableFilter />
        <MinSeatsFilter />
        <DurationFilter />
        <Translate>
          {({ translate }) => (
            <FilterItemGrid>
              {roomEquipment.map((roomMetaProp) => (
                <FilterItemPNG
                  key={roomMetaProp.uid}
                  active={get(propFilters, roomMetaProp.uid)}
                  onClick={() => this.onPropClick(roomMetaProp.uid)}
                  value={roomMetaProp.uid}
                  icon={icons[roomMetaProp.uid]}
                  caption={
                    propsMap[roomMetaProp.name]
                      ? translate(propsMap[roomMetaProp.name])
                      : roomMetaProp.name
                  }
                />
              ))}
            </FilterItemGrid>
          )}
        </Translate>
        <FilterButtonRow>
          <FilterButton dark onClick={() => clearFilter()}>
            <Translate id="filter.title.clear" />
          </FilterButton>
          <FilterButton onClick={() => clearDataset()}>
            <Translate id="filter.title.hide" />
          </FilterButton>
        </FilterButtonRow>
      </FilterContainer>
    )
  }
}

RoomFilters.propTypes = {
  clearDataset: PropTypes.func,
  clearFilter: PropTypes.func,
  propFilters: PropTypes.object,
  setPropFilter: PropTypes.func,
  roomEquipment: PropTypes.array,
  icons: PropTypes.object,
}

const mapStateToProps = (state) => {
  const roomEquipmentIds = getRoomFiltersEquip(state)
  const equipment = getEquipmentInfo(state)
  const roomEquipment = roomEquipmentIds.map((id) => equipment.get(`${id}`))
  const icons = getEquipmentIcons(state)
  return {
    propFilters: getPropFilters(state),
    roomEquipment,
    icons,
  }
}

export default connect(mapStateToProps, {
  setPropFilter,
  clearFilter,
  clearDataset,
})(toJS(RoomFilters))
