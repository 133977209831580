import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { initUnity } from 'containers/unityLoader/actions'

const DevActions = ({ initUnity }) => (
  <Fragment>
    <button onClick={() => initUnity()}>start unity</button> <br />
  </Fragment>
)

DevActions.propTypes = {
  initUnity: PropTypes.func,
}

export default connect(null, { initUnity })(DevActions)
