export const SET_DATASET = 'containers/filter/SET_DATASET'
export const CLEAR_DATASET = 'containers/filter/CLEAR_DATASET'
export const SET_AVAILABILITY_FILTER =
  'containers/filter/SET_AVAILABILITY_FILTER'
export const SET_BOOKABLE_FILTER = 'containers/filter/SET_BOOKABLE_FILTER'
export const SET_PROP_FILTER = 'containers/filter/SET_PROP_FILTER'
export const CLEAR_FILTER = 'containers/filter/CLEAR_FILTER'
export const SET_NUM_SEATS_FILTER = 'containers/filter/SET_NUM_SEATS_FILTER'
export const SET_DURATION_FILTER = 'containers/filter/SET_DURATION_FILTER'
export const SET_DATE = 'containers/filter/SET_DATE'
export const SET_MAP_INTERVAL = 'containers/filter/SET_MAP_INTERVAL'

export const propsMap = {
  'Mac space': 'filter.props.macSpace',
  'Mac client': 'filter.props.macClient',
  'Single screen': 'filter.props.single',
  'Dual screen': 'filter.props.dual',
  'Ergonomic desk': 'filter.props.ergonomic',
  'Curved Screen': 'filter.props.curved',
  Parking: 'filter.props.parking',
  'Electrical Parking': 'filter.props.chargingPoint',
  Locker: 'filter.props.locker',
  Screen: 'filter.props.screen',
  Projector: 'filter.props.projector',
  Phone: 'filter.props.phone',
  Video: 'filter.props.video',
  Sofa: 'filter.props.sofa',
}
