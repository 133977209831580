import { makeSelector } from 'containers/app/selectors'

const isFetching = (state) =>
  state.getIn(['quickView', 'claimFlow', 'isFetching'])
const status = (state) => state.getIn(['quickView', 'claimFlow', 'status'])
const claimFlow = (state) => state.getIn(['quickView', 'claimFlow'])
const hasClaimOnBehalfPermission = (state) =>
  state.getIn(['quickView', 'claimFlow', 'hasClaimOnBehalfPermission'])
const claimError = (state) =>
  state.getIn(['quickView', 'claimFlow', 'claimError'])
const eventsPerAsset = (state) => {
  return state.getIn(['quickView', 'claimFlow', 'eventsPerAsset'])
}
const maxDurationAllowed = (state) =>
  state.getIn(['quickView', 'claimFlow', 'maxDurationAllowed'])
const maxDaysAllowed = (state) =>
  state.getIn(['quickView', 'claimFlow', 'maxDaysAllowed'])

const hasCancelOnBehalfPermission = (state) =>
  state.getIn(['quickView', 'claimFlow', 'hasCancelOnBehalfPermission'])

export const getIsFetching = makeSelector(isFetching)
export const getStatus = makeSelector(status)
export const getClaimFlow = makeSelector(claimFlow)
export const getHasClaimOnBehalfPermission = makeSelector(
  hasClaimOnBehalfPermission,
)
export const getClaimError = makeSelector(claimError)
export const getEventsPerAsset = makeSelector(eventsPerAsset)
export const getMaxDurationAllowed = makeSelector(maxDurationAllowed)
export const getMaxDaysAllowed = makeSelector(maxDaysAllowed)
export const getHasCancelOnBehalfPermission = makeSelector(
  hasCancelOnBehalfPermission,
)
