import React, { Component } from 'react'

import { config } from 'api/public'

class Login extends Component {
  state = {
    showCredentialsModal: false,
  }
  componentDidMount() {
    this.handleWebAppAuthValidation()
  }

  async handleWebAppAuthValidation() {
    const argus_url = await config('argus_url')
    const validateWebAppPath = '/public/login/webapp-validate'
    window.location = `${argus_url}${validateWebAppPath}`
  }

  render() {
    return <></>
  }
}

export default Login
