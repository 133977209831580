import styled, { css } from 'styled-components'

const CloseButton = styled.button`
  color: white;
  position: absolute;
  top: 19px;
  right: 19px;
  width: 22px;
  height: 22px;
  padding: 0;
  font-size: 26px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.backBtn &&
    css`
      top: 19px;
      left: 15px;
      height: 26px;
      line-height: 26px;
      font-family: Roboto;
      font-size: 14px;
      display: flex;
      width: auto;
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    `};
`

export default CloseButton
