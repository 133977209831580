import {
  MEETING_REQUEST,
  MEETING_SUCCESS,
  MEETING_FAILURE,
  MEETING_SELECT,
  CLOSE_BOOKING_UI,
  END_MEETING_REQUEST,
  END_MEETING_SUCCESS,
  END_MEETING_FAILURE,
  END_MEETING_CONFIRMED,
  FETCH_MEETING_SLOTS_SUCCESS,
  FETCH_MEETING_SLOTS_FAILURE,
  SET_MEETING_DURATION,
  MULTIPLE_BOOKING_REQUEST,
  MULTIPLE_BOOKING_SUCCESS,
  UPDATE_BOOKINGS_LIST,
} from 'containers/quickView/spaceModal/bookingFlow/constants'

export const fetchMeetingSlotsSuccess = (spaceId, meetingSlots) => ({
  type: FETCH_MEETING_SLOTS_SUCCESS,
  spaceId,
  meetingSlots,
})

export const fetchMeetingSlotsFailure = (spaceId) => ({
  type: FETCH_MEETING_SLOTS_FAILURE,
  spaceId,
})

export const openBookingUI = () => ({
  type: MEETING_SELECT,
})

export const closeBookingUI = () => ({
  type: CLOSE_BOOKING_UI,
})

export const meetingRequest = (spaceId, slot, subject) => ({
  type: MEETING_REQUEST,
  spaceId,
  slot,
  subject,
})

export const meetingSuccess = (meeting) => ({
  type: MEETING_SUCCESS,
  meeting,
})

export const meetingFailure = (error) => ({
  type: MEETING_FAILURE,
  error,
})

export const endMeeting = (meetingBody) => ({
  type: END_MEETING_REQUEST,
  meetingBody,
})

export const endMeetingSuccess = () => ({
  type: END_MEETING_SUCCESS,
})

export const endMeetingFailure = () => ({
  type: END_MEETING_FAILURE,
})

export const endMeetingConfirm = () => ({
  type: END_MEETING_CONFIRMED,
})

export const setBookingFlowMeetingDuration = (value) => ({
  type: SET_MEETING_DURATION,
  duration: value,
})

export const multipleBookingRequest = (userId, multiSelectedSpaces, slot) => {
  return {
    type: MULTIPLE_BOOKING_REQUEST,
    userId,
    multiSelectedSpaces,
    slot,
  }
}

export const multipleBookingSuccess = () => {
  return {
    type: MULTIPLE_BOOKING_SUCCESS,
  }
}

export const updateBookingsList = (spaceIds) => {
  return {
    type: UPDATE_BOOKINGS_LIST,
    spaceIds,
  }
}
