import React, { useState, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import blue from '@material-ui/core/colors/blue'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled from 'styled-components'
import { addDays, isPast, isAfter, isSameDay } from 'date-fns'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  Calendar as MuiCalendar,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import { getLocale, checkSlotInterval } from 'utils/utilsFunctions'

const getCustomTheme = (isCurrentDayBooked) => {
  return createTheme({
    transitions: {
      create: () => 'none',
    },
    overrides: {
      MuiPickersSlideTransition: {
        slideExit: {
          transition: 'none',
          opacity: '0',
        },
        transitionContainer: {
          textTransform: 'capitalize',
        },
      },
      MuiPickersDay: {
        hidden: {
          color: 'white !important',
          opacity: '40% !important',
        },
        day: {
          color: 'white',
          width: '27px',
          height: '27px',
          margin: '2px',
        },
        current: {
          color: isCurrentDayBooked ? 'white' : blue[300],
          '&$daySelected': {
            color: 'white',
          },
        },
        daySelected: {
          color: 'white',
          backgroundColor: blue[600],
          '&:hover': {
            backgroundColor: blue[400],
          },
        },
        dayDisabled: {
          color: 'white',
          opacity: '40%',
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: {
          color: 'white',
          opacity: '40%',
        },
        iconButton: {
          backgroundColor: 'transparent',
          color: 'white',
        },
        daysHeader: {
          textTransform: 'capitalize',
        },
      },
      MuiIconButton: {
        root: {
          '&[disabled]': {
            color: 'white',
            opacity: '40%',
          },
        },
      },
      MuiTypography: {
        root: {
          color: 'white',
        },
        body1: {
          'font-size': '1.7rem',
        },
        body2: {
          'font-size': '1.4rem',
        },
        caption: {
          'font-size': '1.5rem',
        },
      },
    },
  })
}

const BookedDay = styled.div`
  border-radius: 50%;
  background-color: ${(props) =>
    props.isBooked ? props.theme.statusFree : ''};
`

const Calendar = ({
  activeLanguage,
  bookingDaysAdvance,
  bookings,
  onChange,
  value,
  selfAttendances,
}) => {
  const getIsBooked = (day) => {
    return (
      bookings.some((booking) => {
        return checkSlotInterval(booking, new Date(day))
      }) ||
      selfAttendances.some((booking) => {
        return checkSlotInterval(booking, new Date(day))
      })
    )
  }
  const getDay = (day, selectedDate, dayComponent) => {
    if (
      isPast(addDays(day, 1)) ||
      isAfter(day, addDays(new Date(), bookingDaysAdvance))
    )
      return dayComponent
    if (isSameDay(day, selectedDate)) return dayComponent
    const isBooked = getIsBooked(day)

    return <BookedDay isBooked={isBooked}>{dayComponent}</BookedDay>
  }
  const today = new Date()
  const isCurrentDayBooked = getIsBooked(today)

  const [locale, setLocale] = useState(activeLanguage.code)
  useEffect(() => setLocale(activeLanguage.code), [activeLanguage])

  const customTheme = getCustomTheme(isCurrentDayBooked)
  return (
    <ThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
        <MuiCalendar
          date={value}
          disablePast
          allowKeyboardControl={false}
          maxDate={addDays(new Date(), bookingDaysAdvance)}
          onChange={(date) => onChange(date)}
          renderDay={(day, selectedDate, _, dayComponent) =>
            getDay(day, selectedDate, dayComponent)
          }
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

Calendar.propTypes = {
  activeLanguage: PropTypes.object,
  bookingId: PropTypes.number,
  bookings: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  date: PropTypes.instanceOf(Date),
  desk: PropTypes.shape({
    deskId: PropTypes.number,
    name: PropTypes.string,
  }),
  deskId: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  bookingDaysAdvance: PropTypes.number,
  value: PropTypes.instanceOf(Date),
  selfAttendances: PropTypes.array,
}

export default withLocalize(Calendar)
