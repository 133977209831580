import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import CoverImage from 'components/CoverImage'
import { fetchProfilePicture } from 'containers/searchPanel/results/actions'
import { getProfilePictures } from 'containers/searchPanel/results/selectors'
import { buildInitialsString } from 'utils/utilsFunctions'

class ProfilePicture extends Component {
  componentDidMount = () => {
    const { id, fetchProfilePicture, profilePicture } = this.props
    if (profilePicture === undefined && id && id !== 0) {
      fetchProfilePicture(id)
    }
  }

  render() {
    const { fn, ln, profilePicture } = this.props
    if (profilePicture) {
      return <CoverImage src={profilePicture} />
    }
    return buildInitialsString(fn, ln)
  }
}

ProfilePicture.propTypes = {
  fetchProfilePicture: PropTypes.func,
  fn: PropTypes.string,
  id: PropTypes.number,
  ln: PropTypes.string,
  profilePicture: PropTypes.string,
}

const mapStateToProps = (state, props) => ({
  profilePicture: getProfilePictures(state).get(`${props.id}`),
})

export default connect(mapStateToProps, { fetchProfilePicture })(ProfilePicture)
