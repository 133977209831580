import styled, { css } from 'styled-components'

const PanelHead = styled.h4`
  padding: 12px 16px;
  color: ${(props) => props.theme.panelBg};
  font-size: 2rem;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-font-smoothing: antialiased;
  background-color: ${(props) => props.theme.panelBg};
  color: ${(props) => props.theme.panelBgLight};

  b,
  strong {
    font-weight: 900;
  }

  ${(props) =>
    props.popup &&
    css`
      text-align: center;
      line-height: 60px;
      min-height: 60px;
      padding: 0;
      background-color: #3a3a3a;
      font-family: Roboto;
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
    `};
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `};
`

export default PanelHead
