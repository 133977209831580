import styled from 'styled-components'

const ErrorPopup = styled.div`
  width: 243px;
  height: 117px;
  padding: 23px 66.7px 31px 67.1px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: #343333;
  position: absolute;
  left: 36%;
  bottom: 35%;
  pointer-events: auto;
`

export default ErrorPopup
