import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'

import BookingCalendar from 'containers/mainPanel/bookingsList/BookingCalendar'
import { setDateFilter } from 'containers/searchPanel/filter/actions'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import { getDeskBookings } from 'containers/mainPanel/bookingsList/selectors'
import { getFutureDeskbookingDays } from 'containers/app/selectors'
import { getSelfAttendances } from 'containers/flowPlanner/selectors'
import { addDayIfPastMidday } from 'utils/utilsFunctions'
const BookingsList = ({ activeLanguage }) => {
  const dispatch = useDispatch()

  const dateValue = useSelector((state) => getDateFilter(state))
  const deskBookings = useSelector((state) => getDeskBookings(state))
  const selfAttendances = useSelector((state) => getSelfAttendances(state))

  let bookingDaysAdvance = useSelector((state) =>
    getFutureDeskbookingDays(state),
  )
  bookingDaysAdvance = addDayIfPastMidday(bookingDaysAdvance)

  const handleDateSelection = (value) => {
    dispatch(setDateFilter(value))
  }

  return (
    <BookingCalendar
      bookings={deskBookings}
      selfAttendances={selfAttendances}
      value={dateValue}
      onChange={handleDateSelection}
      bookingDaysAdvance={bookingDaysAdvance}
    />
  )
}

BookingsList.propTypes = {
  activeLanguage: PropTypes.object,
}

export default withLocalize(BookingsList)
