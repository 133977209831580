import styled from 'styled-components'

const UIWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: 220px 10% auto 10% 260px;
  grid-template-rows: auto 48px 60px;
  grid-template-areas:
    'MainPanel . . . SearchPanel'
    'MainPanel . TouchActions . SearchPanel'
    'MainPanel . . . SearchPanel';
  pointer-events: none;
  background: ${(props) => props.theme.holidayMainPanel};
`

export default UIWrapper
