import styled from 'styled-components'

const InfoText = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  color: ${(props) => props.theme.darkText};
  font-size: 16px;
  font-weight: 500;
  flex: 1;
`

export default InfoText
