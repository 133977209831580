import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { List } from 'immutable'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import StatisticsItem from 'containers/mainPanel/statistics/StatisticsItem'
import {
  getCurrentFloorId,
  getRoomIdsOnFloors,
  getDeskIdsOnFloors,
  getSpaces,
  getFutureDesks,
  getFutureRooms,
} from 'containers/app/selectors'
import { icons } from 'components/icon/icons'
import { StyledStatisticsItem } from 'containers/mainPanel/statistics/StyledStatisticsItem'
import StatisticsRow from 'containers/mainPanel/statistics/StatisticsRow'

class Statistics extends Component {
  applyAvailableFilter = (arr) => {
    const { spaces, isFuture } = this.props

    if (!arr) {
      return new List()
    }

    if (!isFuture) {
      return arr.filter((id) => {
        const space = spaces.get(`${id}`)
        return space.getIn(['status', 'state']) === 'FREE'
      })
    } else {
      return arr.filter((desk) => {
        return desk['state'] === 'FREE'
      })
    }
  }

  applyRoomBookedFilter = (arr) => {
    const { spaces } = this.props

    if (!arr) {
      return new List()
    }

    return arr.filter((id) => {
      const space = spaces.get(`${id}`)
      return space.getIn(['status', 'state']) === 'BOOKED'
    })
  }

  applyDeskBookedFilter = (arr) => {
    const { spaces, isFuture } = this.props

    if (!arr) {
      return new List()
    }

    if (!isFuture) {
      return arr.filter((id) => {
        const space = spaces.get(`${id}`)
        if (
          space.getIn(['status', 'state']) === 'CLAIMED' ||
          space.getIn(['status', 'state']) === 'BUSY'
        )
          return true
        else return false
      })
    } else {
      return arr.filter((desk) => {
        return desk['state'] === 'CLAIMED' || desk['state'] === 'BOOKED'
      })
    }
  }

  render() {
    const { roomIds, deskIds, isFuture, futureDesks, futureRooms } = this.props

    let availableDesks
    let bookedDesks
    let availableRooms
    let bookedRooms

    if (isFuture) {
      availableDesks = this.applyAvailableFilter(futureDesks)
      bookedDesks = this.applyDeskBookedFilter(futureDesks)
      availableRooms = this.applyAvailableFilter(futureRooms)
      bookedRooms = this.applyDeskBookedFilter(futureRooms)
    } else {
      availableDesks = this.applyAvailableFilter(deskIds)
      bookedDesks = this.applyDeskBookedFilter(deskIds)
      availableRooms = this.applyAvailableFilter(roomIds)
      bookedRooms = this.applyRoomBookedFilter(roomIds)
    }

    return (
      <>
        <StatisticsRow>
          <StyledStatisticsItem>
            <Translate id="space.status.free_2" />
          </StyledStatisticsItem>
          <StyledStatisticsItem>
            <Translate id="space.status.booked_2" />
          </StyledStatisticsItem>
        </StatisticsRow>
        <StatisticsRow>
          <StatisticsItem
            iconName={icons.ROOM}
            status="free"
            filteredSpaces={
              isFuture ? availableRooms.length : availableRooms.size
            }
          />
          <StatisticsItem
            iconName={icons.ROOM}
            status="booked"
            filteredSpaces={isFuture ? bookedRooms.length : bookedRooms.size}
          />
        </StatisticsRow>
        <StatisticsRow>
          <StatisticsItem
            iconName={icons.DESK}
            status="free"
            filteredSpaces={
              isFuture ? availableDesks.length : availableDesks.size
            }
          />
          <StatisticsItem
            iconName={icons.DESK}
            status="booked"
            filteredSpaces={isFuture ? bookedDesks.length : bookedDesks.size}
          />
        </StatisticsRow>
      </>
    )
  }
}

Statistics.propTypes = {
  deskIds: PropTypes.object,
  roomIds: PropTypes.object,
  spaces: PropTypes.object,
  isFuture: PropTypes.bool,
  futureDesks: PropTypes.array,
  futureRooms: PropTypes.array,
}

const mapStateToProps = (state) => {
  const currentFloorId = getCurrentFloorId(state)
  const roomIds = getRoomIdsOnFloors(state).get(`${currentFloorId}`)
  const deskIds = getDeskIdsOnFloors(state).get(`${currentFloorId}`)
  const spaces = getSpaces(state)

  return {
    roomIds,
    deskIds,
    spaces,
    futureDesks: getFutureDesks(state),
    futureRooms: getFutureRooms(state),
  }
}
export default connect(mapStateToProps)(Statistics)
