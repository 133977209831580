import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalRow from 'containers/quickView/modal/ModalRow'
import WayFindButton from 'containers/quickView/modal/WayFindButton'

class UserActions extends Component {
  render() {
    const { user } = this.props
    return (
      <ModalRow>
        <ModalButtonRow>
          <WayFindButton
            itemId={user.id}
            type="user"
            floorId={user.floorId}
            disabled={user.id === 0 || !user.loc || !user.id}
          />
        </ModalButtonRow>
      </ModalRow>
    )
  }
}

UserActions.propTypes = {
  user: PropTypes.object,
}

export default UserActions
