import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize, getTranslate, Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'react-toggle-button'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'

import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'

const DataContainer = styled.div.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  background-color: ${(props) => props.theme.logoBg};
  border-color: ${(props) => props.theme.panelBorder};
  color: ${(props) => props.theme.actionButton};
  padding: 0 16px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

class VisibilityPicker extends Component {
  render() {
    const { translate, theme, setVisibility, isVisible } = this.props
    return (
      <DataContainer>
        <p>
          <Translate id="billboard.mainPanel.visibilityMode" />
        </p>
        <ToggleButton
          value={isVisible}
          onToggle={(value) => {
            setVisibility(value)
          }}
          activeLabel={translate('filter.title.toggleOn')}
          inactiveLabel={translate('filter.title.toggleOff')}
          colors={{
            active: {
              base: theme.accent,
            },
            inactive: {
              base: theme.panelBg,
            },
          }}
        />
      </DataContainer>
    )
  }
}
VisibilityPicker.propTypes = {
  translate: PropTypes.func,
  theme: PropTypes.object,
  isVisible: PropTypes.bool,
  setVisibility: PropTypes.func,
}

const mapStateToProps = (state) => ({
  translate: getTranslate(state.get('localize')),
})

const withConnect = connect(mapStateToProps, {})

export default withLocalize(compose(withConnect, withTheme)(VisibilityPicker))
