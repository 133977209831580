import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Translate, getTranslate } from 'react-localize-redux'
import { format } from 'date-fns'
import englishLocale from 'date-fns/locale/en-US'
import swedishLocale from 'date-fns/locale/sv'
import norwegianLocale from 'date-fns/locale/nb'
import frenchLocale from 'date-fns/locale/fr'
import germanLocale from 'date-fns/locale/de'
import { connect } from 'react-redux'
import _ from 'lodash'

import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import ColleaguesList from 'containers/flowPlanner/ColleaguesList'
import FavoritesList from 'containers/flowPlanner/FavoritesList'
import ColleagueSearchList from 'containers/flowPlanner/ColleagueSearchList'
import Icon from 'components/Icon'
import {
  getAttendanceDetails,
  getAttendanceDetailsUsersOnly,
  getFilteredAttendace,
  getFilteredAttendaceUsersOnly,
  getColleagueAttendanceCount,
} from 'containers/flowPlanner/selectors'
import {
  fetchAttendanceDetails,
  clearPrevSearch,
} from 'containers/flowPlanner/actions'
import SearchComp from 'containers/flowPlanner/SearchComp'
import {
  OTHER_BUILDING,
  SAME_BUILDING,
  MILLISECONDS_PER_MINUTE,
  typesMap,
} from 'utils/appVars'
import { compareNameAndQueryString } from 'utils/utilsFunctions'
import {
  sortByTypeAndName,
  mapAttendanceTypes,
} from 'containers/flowPlanner/flowplannerServices'
import { getBuildings } from 'containers/app/selectors'

const localeMap = {
  en: englishLocale,
  sv: swedishLocale,
  no: norwegianLocale,
  fr: frenchLocale,
  de: germanLocale,
}

const DetailsHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 72px);
  width: 100%;
  background-color: #fff;
  padding-top: 72px;
  z-index: 2;
`

const BackHolder = styled.div`
  position: absolute;
  top: 27px;
  left: 25px;
  font-size: 14px;
  display: flex;
  color: ${(props) => props.theme.darkText};
  i {
    font-size: 20px;
  }
  span {
    display: block;
    line-height: 1.5;
  }
  :hover {
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
`
const DateHeader = styled.p`
  margin: 30px 0 0 28px;
  font-size: 16px;
  text-transform: capitalize;
`
const DetailedInfoListHolder = styled.div`
  margin: 0 12px;
`
const LocationHeader = styled.p`
  margin: 5px 0 0 28px;
  font-size: 14px;
`
const DayDetails = ({
  selectedDate,
  currentLocale,
  hideDetails,
  translate,
  dailyAttendaceData,
  buildingId,
  fetchAttendanceDetails,
  attendanceDetails,
  attendanceDetailsUsersOnly,
  filteredAttendace,
  filteredAttendaceUsersOnly,
  clearPrevSearch,
  buildings,
}) => {
  const date = format(selectedDate, 'MMMM dd, yyyy', {
    locale: currentLocale,
  })
  const topListLength = dailyAttendaceData.selectedAttendance.length
  const bottomListLength = attendanceDetailsUsersOnly.length

  const [loadMorePages, setLoadMorePages] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [showLists, setShowLists] = useState(true)
  const [isTopListOpen, setIsTopListOpen] = useState(true)
  const [isBottomListOpen, setIsBottomListOpen] = useState(false)
  const [topListHeight, setTopListHeight] = useState(350)
  const [bottomListHeight, setBottomListHeight] = useState(0)

  const calculateListHeights = () => {
    const rowHeight = 77
    const minItems = 3
    const small = 80
    const medium = 225
    const large = 440
    let newTopListHeight = large
    let newBottomListHeight = small

    if (isTopListOpen && isBottomListOpen) {
      newTopListHeight = medium
      newBottomListHeight = medium
    } else if (isTopListOpen && !isBottomListOpen) {
      newTopListHeight = large
      newBottomListHeight = small
    } else if (!isTopListOpen && isBottomListOpen) {
      newTopListHeight = small
      newBottomListHeight = large
    }
    if (topListLength < minItems && isTopListOpen) {
      newTopListHeight = topListLength * rowHeight
      newBottomListHeight = medium + (minItems - topListLength) * rowHeight
    }
    if (bottomListLength < minItems && isBottomListOpen) {
      newBottomListHeight = bottomListLength * rowHeight
      newTopListHeight = medium + (minItems - bottomListLength) * rowHeight
    }
    setTopListHeight(newTopListHeight)
    setBottomListHeight(newBottomListHeight)
  }

  useEffect(() => {
    calculateListHeights()
  }, [isTopListOpen, isBottomListOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = (currentPage, currentSearch) => {
    currentPage < attendanceDetails.pages
      ? fetchAttendanceDetails(buildingId, currentPage + 1, currentSearch)
      : setLoadMorePages(false)
  }

  const fetchMoreData = (filtered = false) => {
    const currentPage = filtered
      ? filteredAttendace.page
      : attendanceDetails.page

    loadData(currentPage, searchQuery)
  }
  const searchColleague = (val) => {
    clearPrevSearch()

    if (val.length > 1) {
      const currentPage = 0
      setSearchQuery(val)
      setShowLists(false)
      loadData(currentPage, val)
    } else {
      setSearchQuery('')
      setShowLists(true)
    }
  }
  const unfilteredFavoriteData = dailyAttendaceData.selectedAttendance
  const userSearchResults = [
    ...filteredAttendaceUsersOnly,
    ...unfilteredFavoriteData.filter((attendance) =>
      compareNameAndQueryString(attendance.user.name, searchQuery),
    ),
  ]
  const uniqueUserSearchResults = [
    ...new Map(
      userSearchResults.map((attendance) => [attendance.user.id, attendance]),
    ).values(),
  ]
  const getAttendanceLocation = () => {
    const { selfAttendance } = dailyAttendaceData
    const mappedSelfAttendance = mapAttendanceTypes(
      selfAttendance,
      selfAttendance.buildingId,
    )
    const currentTimeMs = Date.now()
    const activeSelfAttendance = mappedSelfAttendance.find(
      (attendance) => attendance.end * MILLISECONDS_PER_MINUTE > currentTimeMs,
    )
    if (!activeSelfAttendance) return ''
    const { buildingId, type } = activeSelfAttendance
    if (type === SAME_BUILDING || type === OTHER_BUILDING) {
      const building = buildings.get(`${buildingId}`)
      const buildingName = building?.getIn(['info', 'name']) ?? ''
      return buildingName
    } else {
      const attendanceTypeName = typesMap[type]
      const localizedAttendanceType = translate(attendanceTypeName) ?? ''
      return localizedAttendanceType
    }
  }

  const mappedAttendance = mapAttendanceTypes(
    dailyAttendaceData.selectedAttendance,
    buildingId,
  )
  const sortedAttendance = sortByTypeAndName(mappedAttendance)
  const location = getAttendanceLocation()
  const localizedWorkLocationTitle = translate('flowPlanner.workLocation')
  const locationString = `${localizedWorkLocationTitle}: ${location}`
  return (
    <DetailsHolder>
      <BackHolder onClick={hideDetails}>
        <Icon backBtn name="back" />
        <span>
          <Translate id="space.status.back" />
        </span>
      </BackHolder>
      <SearchComp searchColleague={searchColleague} />
      <DateHeader>{date}</DateHeader>
      <LocationHeader>{locationString}</LocationHeader>
      {showLists && (
        <DetailedInfoListHolder>
          <FavoritesList
            isOpen={isTopListOpen}
            fetchMoreData={fetchMoreData}
            favoritesAttendance={sortedAttendance}
            toggle={setIsTopListOpen}
            height={topListHeight}
          />
          <ColleaguesList
            isOpen={isBottomListOpen}
            fetchMoreData={fetchMoreData}
            toggle={setIsBottomListOpen}
            buildingAttendance={attendanceDetailsUsersOnly}
            loadMorePages={loadMorePages}
            height={bottomListHeight}
          />
        </DetailedInfoListHolder>
      )}
      {!showLists && (
        <DetailedInfoListHolder>
          <ColleagueSearchList
            fetchMoreData={() => fetchMoreData(true)}
            buildingAttendance={uniqueUserSearchResults}
            loadMorePages={loadMorePages}
          />
        </DetailedInfoListHolder>
      )}
    </DetailsHolder>
  )
}

DayDetails.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  currentLocale: PropTypes.object,
  hideDetails: PropTypes.func,
  translate: PropTypes.func,
  dailyAttendaceData: PropTypes.object,
  fetchAttendanceDetails: PropTypes.func,
  buildingId: PropTypes.number,
  attendanceDetails: PropTypes.object,
  attendanceDetailsUsersOnly: PropTypes.array,
  filteredAttendace: PropTypes.array,
  filteredAttendaceUsersOnly: PropTypes.array,
  clearPrevSearch: PropTypes.func,
  colleagueAttendanceCount: PropTypes.number,
  buildings: PropTypes.object,
}
const mapStateToProps = (state) => ({
  translate: getTranslate(state.get('localize')),
  selectedDate: getDateFilter(state),
  attendanceDetails: getAttendanceDetails(state),
  attendanceDetailsUsersOnly: getAttendanceDetailsUsersOnly(state),
  filteredAttendace: getFilteredAttendace(state),
  filteredAttendaceUsersOnly: getFilteredAttendaceUsersOnly(state),
  colleagueAttendanceCount: getColleagueAttendanceCount(state),
  buildings: getBuildings(state),
})

export default connect(mapStateToProps, {
  fetchAttendanceDetails,
  clearPrevSearch,
})(DayDetails)
