import styled from 'styled-components'

import { ALLOW_TO_OPEN_MODAL } from 'utils/appVars'

const QuickViewBackdrop = styled.div.attrs({
  className: ALLOW_TO_OPEN_MODAL,
})`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.divider};
`

export default QuickViewBackdrop
