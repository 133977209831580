import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import InfoText from 'containers/quickView/InfoText'
import ModalRow from 'containers/quickView/modal/ModalRow'
import Icon from 'components/Icon'
import {
  getFloors,
  getBuildings,
  getSpaces,
  getCurrentFloorId,
  getCurrentBuildingId,
} from 'containers/app/selectors'
import { selectSpaceId } from 'containers/quickView/actions'
import { locate } from 'api/unity'
import { icons } from 'components/icon/icons'
import { getActiveUserBookings } from 'containers/quickView/selectors'
import { checkSlotInterval, getLocalizedTime } from 'utils/utilsFunctions'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import { setFloor, setBuilding, startLocate } from 'containers/app/actions'

const Info = styled.div`
  ${Icon} {
    font-size: 32px;
    margin-left: 8px;
    margin-right: 16px;
  }
`

const RowLinkIndicator = styled.div`
  ${Icon} {
    font-size: 32px;
    padding: 0;
    transition: opacity 0.2s, transform 0.2s;
    &:active {
      transform: scale(1.1);
    }
    :hover {
      cursor: pointer;
    }
  }
`

class UserSpaces extends Component {
  didClickSpace = (floorId, booking) => {
    const { startLocate } = this.props
    startLocate(floorId, 'space', booking.desk.id)
  }

  render() {
    const {
      floors,
      buildings,
      activeUserBookings,
      spaces,
      dateValue,
    } = this.props

    if (!activeUserBookings) return null

    const claimedSpacesIds = {}

    return activeUserBookings.map((booking) => {
      if (!checkSlotInterval(booking, dateValue)) return null

      const space = booking.desk

      const fullSpace = spaces.toJS()[space.id] || null

      const floor = floors.get(`${space.floorId}`)
      const floorName = floor.getIn(['info', 'name'])
      const floorId = floor.getIn(['id'])

      const buildingId = floor.get('buildingId')
      const buildingName = buildings
        .get(`${buildingId}`)
        .getIn(['info', 'name'])

      claimedSpacesIds[space.id]
        ? (claimedSpacesIds[space.id] += 1)
        : (claimedSpacesIds[space.id] = 1)

      if (claimedSpacesIds[space.id] > 1) return null
      return (
        <ModalRow
          key={space.id}
          onClick={() => this.didClickSpace(floorId, booking)}
        >
          <Info>
            <Icon name={icons.DESK} />
          </Info>
          <InfoText>
            <span>{space.name}</span>
            <span>
              {getLocalizedTime(booking.start)} -{' '}
              {getLocalizedTime(booking.end)}
            </span>
            <span>
              {buildingName}, {floorName}
            </span>
          </InfoText>

          <RowLinkIndicator>
            <Icon name={icons.CIRCLED_RIGHT} />
          </RowLinkIndicator>
        </ModalRow>
      )
    })
  }
}

UserSpaces.propTypes = {
  selectSpaceId: PropTypes.func,
  activeUserBookings: PropTypes.array,
  spaces: PropTypes.object,
  floors: PropTypes.object,
  buildings: PropTypes.object,
  dateValue: PropTypes.instanceOf(Date),
  setBuilding: PropTypes.func,
  setFloor: PropTypes.func,
  currentFloorId: PropTypes.number,
  currentBuildingId: PropTypes.number,
  startLocate: PropTypes.func,
}

const mapStateToProps = (state, { bookings }) => {
  return {
    buildings: getBuildings(state),
    floors: getFloors(state),
    spaces: getSpaces(state),
    activeUserBookings: getActiveUserBookings(state),
    dateValue: getDateFilter(state),
    currentFloorId: getCurrentFloorId(state),
    currentBuildingId: getCurrentBuildingId(state),
  }
}

export default connect(mapStateToProps, {
  selectSpaceId,
  setBuilding,
  setFloor,
  startLocate,
})(UserSpaces)
