import { makeSelector } from 'containers/app/selectors'

const showDashboard = (state) => state.getIn(['dashboard', 'showDashboard'])
const meetingTime = (state) => state.getIn(['dashboard', 'meetingTime'])
const schedules = (state) => state.getIn(['dashboard', 'scheduleData'])
const renderSwimlanes = (state) => state.getIn(['dashboard', 'renderSwimlanes'])

export const getShowDashboard = makeSelector(showDashboard)
export const getMeetingTime = makeSelector(meetingTime)
export const getSchedules = makeSelector(schedules)
export const getRenderSwimlanes = makeSelector(renderSwimlanes)
