import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import {
  setAvailablityFilter,
  setBookableFilter,
} from 'containers/searchPanel/filter/actions'
import {
  getAvailabilityFilter,
  getBookableFilter,
} from 'containers/searchPanel/filter/selectors'
import toJS from 'components/toJS'
import FilterItem from 'containers/searchPanel/filter/FilterItem'
import FilterItemGrid from 'containers/searchPanel/filter/FilterItemGrid'
import { icons } from 'components/icon/icons'

const BookableContainer = styled.div`
  padding-bottom: 16px;
`

class BookableFilter extends Component {
  render() {
    const {
      availabilityFilter,
      bookableFilter,
      setAvailablityFilter,
      setBookableFilter,
    } = this.props

    const toggleAllOff = () => {
      setAvailablityFilter(false)
      setBookableFilter(false)
    }

    const toggleAvailableFilter = () => {
      setAvailablityFilter(!availabilityFilter)
    }

    const toggleBookableFilter = () => {
      setBookableFilter(!bookableFilter)
    }

    return (
      <BookableContainer>
        <FilterItemGrid>
          <Translate>
            {({ translate }) => (
              <FilterItem
                onClick={toggleAllOff}
                active={!availabilityFilter && !bookableFilter}
                caption={translate('filter.title.all')}
                iconName={icons.SHOW_ALL}
              />
            )}
          </Translate>
          <Translate>
            {({ translate }) => (
              <FilterItem
                onClick={toggleAvailableFilter}
                active={availabilityFilter}
                caption={translate('filter.title.available')}
                iconName={icons.ROOM}
              />
            )}
          </Translate>
          <Translate>
            {({ translate }) => (
              <FilterItem
                onClick={toggleBookableFilter}
                active={bookableFilter}
                caption={translate('filter.title.bookable')}
                iconName={icons.BOOKABLE}
              />
            )}
          </Translate>
        </FilterItemGrid>
      </BookableContainer>
    )
  }
}

BookableFilter.propTypes = {
  availabilityFilter: PropTypes.bool,
  bookableFilter: PropTypes.bool,
  setAvailablityFilter: PropTypes.func,
  setBookableFilter: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    availabilityFilter: getAvailabilityFilter(state),
    bookableFilter: getBookableFilter(state),
  }
}

const withConnect = connect(mapStateToProps, {
  setAvailablityFilter,
  setBookableFilter,
})

export default compose(withConnect, withTheme)(toJS(BookableFilter))
