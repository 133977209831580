import React from 'react'

import CoverImage from 'components/CoverImage'

export const SignSVGDictionary = {
  STAIRS: 'Stairs-min.svg',
  ELEVATOR: 'Elevator-min.svg',
  WC: 'Toilet-min.svg',
  DEFIBRILLATOR: 'Defibrillator-min.svg',
  'COFFEE AREA': 'Coffee-min.svg',
  COFFEE: 'Coffee-min.svg',
  RESTAURANT: 'Restaurant-min.svg',
  INFODESK: 'Information-desk-min.svg',
  LOCKER: 'Locker-min.svg',
  'MEETING ROOM': 'Meeting-room-min.svg',
  MEETINGROOM: 'Meeting-room-min.svg',
  'MASSAGE ROOM': 'Massage-room-min.svg',
  MASSAGEROOM: 'Massage-room-min.svg',
  'PRINTER AREA': 'Printer-min.svg',
  PRINTINGROOM: 'Printer-min.svg',
  'PHONE ROOM': 'Phone-room-min.svg',
  PHONEROOM: 'Phone-room-min.svg',
  'RELAX ROOM': 'Resting-room-min.svg',
  RELAXROOM: 'Resting-room-min.svg',
  EMERGENCY: 'Emergency-exit-min.svg',
  'FIRE EXTUINGUISHER': 'Fire-extinguisher-min.svg',
  FIREEXTUINGUISHER: 'Fire-extinguisher-min.svg',
  FIREEXTINGUISHER: 'Fire-extinguisher-min.svg',
  'FIRST AID': 'First-aid-min.svg',
  FIRSTAID: 'First-aid-min.svg',
  INVALID: '',
  GENERAL: 'General-min.svg',
}

export const getSignSVG = (sign) => {
  if (sign && typeof sign === 'string') {
    return SignSVGDictionary[sign]
  }
  return ''
}

export const getSignImage = (sign) => (
  <CoverImage
    src={`${process.env.PUBLIC_URL}/facility-icons/${getSignSVG(sign)}`}
    alt={sign.toLowerCase()}
  />
)
