import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

const ModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 16px;
  font-weight: 500;
  background: none;
  outline: none;
  border: 1px solid ${(props) => props.theme.panelBg};
  border-radius: 5px;
  color: ${(props) => props.theme.darkText};
  padding: 12px 24px;
  cursor: pointer;

  ${Icon} {
    font-size: 18px;
    margin: 0 8px 0 0;

    &:last-child {
      margin: 0 0 0 8px;
    }
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }
  ${(props) =>
    props.greybgr &&
    css`
      &:disabled {
        opacity: 1;
        cursor: default;
        pointer-events: none;
        color: black !important;
        background-color: #c3c3c3;
      }
    `};
  ${(props) =>
    props.primary &&
    css`
      flex: 1 0;
      background-color: ${(props) => props.theme.buttonColor};
      color: ${(props) => props.theme.panelBgLight};
      border-color: transparent;
    `};

  ${(props) =>
    props.secondary &&
    css`
      flex: 1 0;
      background-color: ${(props) => props.theme.panelBg};
      color: ${(props) => props.theme.panelBgLight};
      border-color: transparent;
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 8px 12px;
      font-size: 12px;
    `};

  ${(props) =>
    props.wide &&
    css`
      flex: 1 0;
    `};

  ${(props) =>
    props.text &&
    css`
      background: none;
      border-color: transparent;
      color: ${(props) => props.theme.darkText};

      &:hover {
        border-color: ${(props) => props.theme.panelBg};
      }
    `};

  ${(props) =>
    props.boboBtn &&
    css`
      opacity: 1 !important;
      background: none;
      color: #424d61;
      font-weight: normal;
      border: 1px solid #424d61 !important;

      &:hover {
        background-color: #424d61;
        border-color: #424d61;
        color: white;
      }
      &:disabled {
        color: #acacac;
        border-color: #acacac;
      }
    `};

  ${(props) =>
    props.owner &&
    css`
      background-color: black;
      border-color: black;
      color: white;
    `};
`

export default ModalButton
