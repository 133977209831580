import fetch from 'isomorphic-fetch'

export const fetchConfig = async () => {
  const response = await fetch(`${process.env.PUBLIC_URL}/config.json`)
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject(error)
}

export const memoizedConfig = () => {
  let cache = {}
  return async (key) => {
    if (key in cache) {
      return cache[key]
    } else {
      if (process.env.NODE_ENV === 'production') {
        cache = await fetchConfig()
      } else {
        const { config } = await import('config.js')
        cache = config
      }
      return cache[key]
    }
  }
}

export const config = memoizedConfig()
