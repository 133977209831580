export const AUTH_REQUEST = 'containers/app/AUTH_REQUEST'
export const AUTH_SUCCESS = 'containers/app/AUTH_SUCCESS'
export const AUTH_FAILURE = 'containers/app/AUTH_FAILURE'

export const LOGOUT_REQUEST = 'containers/app/LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'containers/app/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'containers/app/LOGOUT_FAILURE'

export const FETCH_UPDATE_REQUEST = 'containers/app/FETCH_UPDATE_REQUEST'
export const FETCH_UPDATE_SUCCESS = 'containers/app/FETCH_UPDATE_SUCCESS'
export const FETCH_UPDATE_FAILURE = 'containers/app/FETCH_UPDATE_FAILURE'

export const FETCH_LOGO_REQUEST = 'containers/app/FETCH_LOGO_REQUEST'
export const FETCH_LOGO_SUCCESS = 'containers/app/FETCH_LOGO_SUCCESS'
export const FETCH_LOGO_FAILURE = 'containers/app/FETCH_LOGO_FAILURE'

export const SET_BUILDING = 'containers/app/SET_BUILDING'
export const SET_FLOOR = 'containers/app/SET_FLOOR'

export const CLEAR_FLOOR_STAMPS = 'containers/app/CLEAR_FLOOR_STAMPS'
export const CLEAR_UPDATES = 'containers/app/CLEAR_UPDATES'

export const TOGGLE_POLLING = 'containers/app/TOGGLE_POLLING'

export const START_WAYFINDING_REQUEST =
  'containers/app/START_WAYFINDING_REQUEST'
export const START_WAYFINDING_SUCCESS =
  'containers/app/START_WAYFINDING_SUCCESS'
export const START_WAYFINDING_FAILURE =
  'containers/app/START_WAYFINDING_FAILURE'

export const STOP_WAYFINDING_REQUEST = 'containers/app/STOP_WAYFINDING_REQUEST'
export const STOP_WAYFINDING_SUCCESS = 'containers/app/STOP_WAYFINDING_SUCCESS'
export const STOP_WAYFINDING_FAILURE = 'containers/app/STOP_WAYFINDING_FAILURE'

export const FETCH_CLIENT_CONFIG_REQUEST =
  'containers/app/FETCH_CLIENT_CONFIG_REQUEST'
export const FETCH_CLIENT_CONFIG_SUCCESS =
  'containers/app/FETCH_CLIENT_CONFIG_SUCCESS'
export const FETCH_CLIENT_CONFIG_FAILURE =
  'containers/app/FETCH_CLIENT_CONFIG_FAILURE'

export const FETCH_PENDING_CONSENT_REQUEST =
  'containers/app/FETCH_PENDING_CONSENT_REQUEST'
export const FETCH_PENDING_CONSENT_SUCCESS =
  'containers/app/FETCH_PENDING_CONSENT_SUCCESS'
export const FETCH_PENDING_CONSENT_FAILURE =
  'containers/app/FETCH_PENDING_CONSENT_FAILURE'

export const APPROVE_CONSENT_REQUEST = 'containers/app/APPROVE_CONSENT_REQUEST'
export const APPROVE_CONSENT_SUCCESS = 'containers/app/APPROVE_CONSENT_SUCCESS'
export const APPROVE_CONSENT_FAILURE = 'containers/app/APPROVE_CONSENT_FAILURE'

export const LOCATE_REQUEST = 'containers/app/LOCATE_REQUEST'
export const LOCATE_FAILURE = 'containers/app/LOCATE_FAILURE'
export const LOCATE_SUCCESS = 'containers/app/LOCATE_SUCCESS'

export const FILTER_REQUEST = 'containers/app/FILTER_REQUEST'
export const FILTER_FAILURE = 'containers/app/FILTER_FAILURE'
export const FILTER_SUCCESS = 'containers/app/FILTER_SUCCESS'

export const FETCH_UNITY_TOKEN_REQUEST =
  'containers/app/FETCH_UNITY_TOKEN_REQUEST'
export const FETCH_UNITY_TOKEN_SUCCESS =
  'containers/app/FETCH_UNITY_TOKEN_SUCCESS'

export const MISSING_LOCATION = 'containers/app/MISSING_LOCATION'

export const FETCH_DESK_BOOKINGS_REQUEST =
  'containers/app/FETCH_DESK_BOOKINGS_REQUEST'
export const FETCH_DESK_BOOKINGS_SUCCESS =
  'containers/app/FETCH_DESK_BOOKINGS_SUCCESS'
export const FETCH_DESK_BOOKINGS_FAILURE =
  'containers/app/FETCH_DESK_BOOKINGS_FAILURE'

export const UPDATE_AVAILABLE_DESKS = 'containers/app/UPDATE_AVAILABLE_DESKS'

export const FETCH_FUTURE_VIEW_REQUEST =
  'containers/app/FETCH_FUTURE_VIEW_REQUEST'
export const FETCH_FUTURE_VIEW_SUCCESS =
  'containers/app/FETCH_FUTURE_VIEW_SUCCESS'
