import styled from 'styled-components'

const MainPanelWrapper = styled.aside`
  grid-area: MainPanel;
  background-color: ${(props) => props.theme.panelBg};
  color: ${(props) => props.theme.panelColor};
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.shadow8dp};
`

export default MainPanelWrapper
