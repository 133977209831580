import { combineReducers } from 'redux-immutable'

import { SAVE_TOKEN } from 'pages/login/constants'

const base64Token = (state = null, action) => {
  if (action.type === SAVE_TOKEN) {
    return action.token
  }

  return state
}

export default combineReducers({
  base64Token,
})
