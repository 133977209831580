import styled from 'styled-components'

const SpacePropGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  flex: 1;
`

export default SpacePropGrid
