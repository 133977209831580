import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import debounce from 'lodash/debounce'

import {
  searchRequest,
  searchClear,
  clearQuery,
  setQuery,
} from 'containers/searchPanel/search/actions'
import Icon from 'components/Icon'
import SearchButton from 'containers/searchPanel/search/SearchButton'
import SearchContainer from 'containers/searchPanel/search/SearchContainer'
import SearchInput from 'containers/searchPanel/search/SearchInput'
import SearchForm from 'containers/searchPanel/search/SearchForm'
import { getSearchQuery } from 'containers/searchPanel/search/selectors'
import { icons } from 'components/icon/icons'
import { getFutureUsers } from 'containers/app/selectors'
class Search extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.debouncedSearch = debounce(this.makeSearch, 400)
  }

  handleChange = (e) => {
    const { setQuery } = this.props
    setQuery(e.target.value)
    this.debouncedSearch()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.makeSearch()
  }

  handleClear = () => {
    const { clearQuery } = this.props
    clearQuery()
    this.debouncedSearch()
  }

  makeSearch = () => {
    const { searchRequest, searchClear, query } = this.props
    if (query.trim()) {
      if (query.length >= 3) {
        searchRequest(query.trim())
      }
    } else {
      searchClear()
    }
  }

  render() {
    const { query } = this.props

    return (
      <SearchContainer>
        <SearchForm onSubmit={this.handleSubmit} ref={this.form}>
          <Translate>
            {({ translate }) => (
              <SearchInput
                value={query}
                onChange={this.handleChange}
                placeholder={translate('billboard.searchPanel.search')}
                onFocus={this.handleOnFocus}
                onBlur={this.handleOnBlur}
                ref={(node) => (this.input = node)}
              />
            )}
          </Translate>
          {query === '' ? (
            <SearchButton clickthrough type="button">
              <Icon name={icons.SEARCH} />
            </SearchButton>
          ) : (
            <SearchButton
              onClick={this.handleClear}
              onMouseDown={(e) => e.preventDefault()}
              type="button"
            >
              <Icon name={icons.CLOSE} />
            </SearchButton>
          )}
        </SearchForm>
      </SearchContainer>
    )
  }
}

Search.propTypes = {
  clearQuery: PropTypes.func,
  query: PropTypes.string,
  searchClear: PropTypes.func,
  searchRequest: PropTypes.func,
  setQuery: PropTypes.func,
}

const mapStateToProps = (state) => ({
  query: getSearchQuery(state),
  futureUsers: getFutureUsers(state),
})

export default connect(mapStateToProps, {
  searchRequest,
  searchClear,
  setQuery,
  clearQuery,
})(Search)
