import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_CLEAR,
  SEARCH_CLEAR_QUERY,
  SEARCH_SET_QUERY,
  FUTURE_SEARCH_REQUEST,
  FUTURE_SEARCH_SUCCESS,
} from 'containers/searchPanel/search/constants'

export const searchRequest = (query) => ({
  type: SEARCH_REQUEST,
  query,
})

export const futureSearchRequest = (query) => ({
  type: FUTURE_SEARCH_REQUEST,
  query,
})

export const futureSearchSuccess = (result) => ({
  type: FUTURE_SEARCH_SUCCESS,
  result,
})

export const searchSuccess = (response) => ({
  type: SEARCH_SUCCESS,
  response,
})

export const searchFailure = (message) => ({
  type: SEARCH_FAILURE,
  message,
})

export const searchClear = () => ({
  type: SEARCH_CLEAR,
})

export const setQuery = (query) => ({
  type: SEARCH_SET_QUERY,
  query,
})

export const clearQuery = () => ({
  type: SEARCH_CLEAR_QUERY,
})
