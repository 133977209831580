import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import InfoText from 'containers/quickView/InfoText'
import { getFloors, getBuildings, getMetaTypes } from 'containers/app/selectors'
import ModalRow from 'containers/quickView/modal/ModalRow'
import { getSignImage } from 'containers/quickView/signModal/signIconHelper'
import InfoSymbol from 'containers/quickView/InfoSymbol'

const SignInfo = ({ sign, floorName, buildingName, metaType }) => (
  <ModalRow>
    <InfoSymbol>{metaType && getSignImage(metaType.get('res'))}</InfoSymbol>
    <InfoText>
      <span>{sign.info.name}</span>
      <span>{floorName}</span>
      <span>{buildingName}</span>
    </InfoText>
  </ModalRow>
)

SignInfo.propTypes = {
  buildingName: PropTypes.string,
  floorName: PropTypes.string,
  metaType: PropTypes.object,
  sign: PropTypes.object,
}

const mapStateToProps = (state, { sign }) => {
  const floor = getFloors(state).get(`${sign.floorId}`)
  const buildingId = floor.get('buildingId')
  const building = getBuildings(state).get(`${buildingId}`)
  const metaType = getMetaTypes(state).get(`${sign.typeId}`)

  return {
    floorName: floor.getIn(['info', 'name']),
    buildingName: building.getIn(['info', 'name']),
    metaType,
  }
}

export default connect(mapStateToProps)(SignInfo)
