import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import {
  getCurrentFloorId,
  getSpaces,
  getMetaTypes,
  getActiveDeskIds,
  getActiveRoomIds,
} from 'containers/app/selectors'
import {
  getSchedules,
  getRenderSwimlanes,
} from 'containers/dashboard/selectors'
import Calendar from 'containers/dashboard/swimlanes/Calendar'
import { selectSpaceId } from 'containers/quickView/actions'
import { openBookingUI } from 'containers/quickView/spaceModal/bookingFlow/actions'
import { setMeetingTime, refreshSchedules } from 'containers/dashboard/actions'
import { sortByString } from 'utils/utilsFunctions'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'

const SwimlanesContainer = styled.div`
  overflow: hidden;
  height: 100%;
  color: #ffffff;
  z-index: 9999;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Center = styled.div`
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > button {
    margin-top: 1em;
  }
`
const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 4px solid grey;
  border-right: 4px solid grey;
  border-bottom: 4px solid grey;
  border-left: 8px solid black;
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

const Swimlanes = (props) => {
  const dispatch = useDispatch()

  const {
    currentFloorId,
    schedules,
    spaces,
    types,
    renderSwimlanes,
    selectedDate,
  } = props

  if (renderSwimlanes === 'no')
    return (
      <Center>
        <Spinner />
      </Center>
    )

  const refresh = () => {
    dispatch(refreshSchedules())
  }

  if (renderSwimlanes === 'error')
    return (
      <Center>
        <Translate id="dashboard.fetchDataFail" />
        <button onClick={() => refresh()}>
          <Translate id="dashboard.refreshButton" />
        </button>
      </Center>
    )
  const spacesJS = Object.values(spaces.toJS())
  const bookableSpaces = []

  spacesJS.forEach((item) => {
    if (item.floorId !== currentFloorId) return
    const typeId = item.typeId
    const type = types.getIn([`${typeId}`, 'res'])
    if (type !== 'type_room_book') return
    if (item.status.state === 'OFFLINE') return
    bookableSpaces.push({
      value: item.id,
      text: item.info.name,
      capacity: item.info.cap,
    })
  })

  bookableSpaces.sort((spaceA, spaceB) =>
    sortByString(spaceA.text, spaceB.text, 'sv'),
  )

  let events = []

  if (schedules !== 'error')
    schedules.forEach((floor) => {
      if (floor.floorId !== currentFloorId) return
      floor.rooms.forEach((room) => {
        room.events.forEach((meeting) => {
          events.push({
            eventId: meeting.id,
            roomId: room.id,
            subject: meeting.subject,
            start: new Date(meeting.start * 60000),
            end: new Date(meeting.end * 60000),
            movable: false,
            resizable: false,
          })
        })
      })
    })

  events = events.sort((a, b) => a.start - b.start)

  return (
    <SwimlanesContainer>
      <Calendar
        resources={{ events, rooms: bookableSpaces }}
        selectedDate={selectedDate}
      />
    </SwimlanesContainer>
  )
}

Swimlanes.propTypes = {
  deskIds: PropTypes.object,
  currentFloorId: PropTypes.number,
  roomIds: PropTypes.object,
  spaces: PropTypes.object,
  types: PropTypes.object,
  item: PropTypes.object,
  itemContext: PropTypes.object,
  timelineContext: PropTypes.object,
  getItemProps: PropTypes.object,
  getResizeProps: PropTypes.object,
  group: PropTypes.object,
  schedules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renderSwimlanes: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selectedDate: PropTypes.instanceOf(Date),
}

const mapStateToProps = (state) => ({
  deskIds: getActiveDeskIds(state),
  currentFloorId: getCurrentFloorId(state),
  roomIds: getActiveRoomIds(state),
  spaces: getSpaces(state),
  types: getMetaTypes(state),
  schedules: getSchedules(state),
  renderSwimlanes: getRenderSwimlanes(state),
  selectedDate: getDateFilter(state),
})

export default connect(mapStateToProps, {
  selectSpaceId,
  openBookingUI,
  setMeetingTime,
})(Swimlanes)
