import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getTranslate } from 'react-localize-redux'

import InfiniteScrollHolder from 'containers/flowPlanner/InfiniteScrollHolder'
import OpenList from 'containers/flowPlanner/OpenList'
import ListHolder from 'containers/flowPlanner/ListHolder'
import MetaHolder from 'containers/flowPlanner/MetaHolder'
import StatisticsItem from 'containers/flowPlanner/StatisticsItem'
import Icon from 'components/Icon'
import DetailedInfoCard from 'containers/flowPlanner/DetailedInfoCard'
import { getBuildings, getCurrentBuildingId } from 'containers/app/selectors'
import { getColleagueAttendanceCount } from 'containers/flowPlanner/selectors'
import { transformData } from 'containers/flowPlanner/flowplannerServices'

const DetailedInfoList = ({
  buildingAttendance,
  fetchMoreData,
  loadMorePages,
  isOpen,
  buildings,
  translate,
  toggle,
  currentBuilding,
  height,
  colleagueAttendanceCount,
}) => {
  const toggleList = () => {
    toggle(!isOpen)
  }
  const getBuildingsName = (buildingId) => {
    return buildings.get(`${buildingId}`).getIn(['info', 'name'])
  }

  buildingAttendance = buildingAttendance
    ? transformData(buildingAttendance, currentBuilding)
    : []
  return (
    <ListHolder isOpen={isOpen}>
      <MetaHolder onClick={toggleList}>
        <StatisticsItem
          value={`${translate(
            'flowPlanner.colleagues',
          )} (${colleagueAttendanceCount})`}
          hasIcon
          iconName={'USER'}
        />
        <OpenList isOpen={isOpen}>
          <Icon name="back" />
        </OpenList>
      </MetaHolder>
      {isOpen && (
        <InfiniteScrollHolder height={`${height}px`}>
          <InfiniteScroll
            dataLength={buildingAttendance.length}
            next={fetchMoreData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            hasMore={loadMorePages}
            scrollableTarget="scrollableDiv"
          >
            {buildingAttendance.map((attendance, index) => {
              return (
                <DetailedInfoCard
                  key={index}
                  user={attendance.userInfo}
                  location={getBuildingsName(attendance.buildingId)}
                  type={attendance.type}
                  space={attendance.space}
                />
              )
            })}
          </InfiniteScroll>
        </InfiniteScrollHolder>
      )}
    </ListHolder>
  )
}

DetailedInfoList.propTypes = {
  icon: PropTypes.string,
  buildingAttendance: PropTypes.array,
  fetchMoreData: PropTypes.func,
  loadMorePages: PropTypes.bool,
  isOpen: PropTypes.bool,
  buildings: PropTypes.object,
  translate: PropTypes.func,
  toggle: PropTypes.func,
  currentBuilding: PropTypes.number,
  height: PropTypes.number,
  colleagueAttendanceCount: PropTypes.number,
}
const mapStateToProps = (state) => ({
  buildings: getBuildings(state),
  translate: getTranslate(state.get('localize')),
  currentBuilding: getCurrentBuildingId(state),
  colleagueAttendanceCount: getColleagueAttendanceCount(state),
})

export default connect(mapStateToProps, {})(DetailedInfoList)
