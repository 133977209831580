import React, { Component, Fragment } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { ThemeProvider } from 'styled-components'
import Alert from 'react-s-alert'
import PropTypes from 'prop-types'

import Main from 'containers/app/Main'
import Routes from 'containers/app/Routes'
import {
  authenticateRequest,
  fetchClientConfig,
  fetchPendingUserConsent,
  fetchUnityToken,
} from 'containers/app/actions'
import { setTimeFormat } from 'containers/mainPanel/timeFormats/actions'
import { clearDrawer } from 'containers/mainPanel/panelDrawer/actions'
import darkTheme from 'themes/dark'
import english from 'locales/en-en.json'
import swedish from 'locales/se-sv.json'
import norwegian from 'locales/no-no.json'
import french from 'locales/fr-fr.json'
import german from 'locales/de-de.json'
import spanish from 'locales/es-es.json'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/scale.css'
import GlobalStyle from 'global-styles'
import {
  ALLOW_TO_OPEN_DRAWER,
  DEFAULT_TIME_FORMAT,
  OUTDATED_TIME_FORMAT,
  KEEP_ROOM_OVERVIEW_OPEN_CLASS,
  ALLOW_TO_OPEN_ATTENDANCE_POPUP,
  ALLOW_TO_OPEN_MODAL,
} from 'utils/appVars'
import { clearQuickView } from 'containers/quickView/actions'
import { hideDashboard } from 'containers/dashboard/actions'
import { closeAttendancePopup } from 'containers/flowPlanner/actions'

class App extends Component {
  constructor(props) {
    super(props)

    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Svenska', code: 'sv' },
        { name: 'Norsk', code: 'no' },
        { name: 'Français', code: 'fr' },
        { name: 'Deutsch', code: 'de' },
        { name: 'Español', code: 'es' },
      ],
      translation: english,
      options: { renderToStaticMarkup },
    })
    this.props.addTranslationForLanguage(swedish, 'sv')
    this.props.addTranslationForLanguage(norwegian, 'no')
    this.props.addTranslationForLanguage(french, 'fr')
    this.props.addTranslationForLanguage(german, 'de')
    this.props.addTranslationForLanguage(spanish, 'es')
  }

  componentDidMount() {
    const {
      fetchClientConfig,
      fetchPendingUserConsent,
      setActiveLanguage,
      setTimeFormat,
      fetchUnityToken,
    } = this.props

    const preSelectedLanguage = window.localStorage.getItem('language')
    const selectedLanguage = preSelectedLanguage
      ? preSelectedLanguage
      : navigator.language || navigator.userLanguage
    let timeFormat = localStorage.getItem('timeFormat')
    if (!timeFormat || timeFormat === OUTDATED_TIME_FORMAT) {
      localStorage.setItem('timeFormat', DEFAULT_TIME_FORMAT)
      timeFormat = DEFAULT_TIME_FORMAT
    }
    setActiveLanguage(this.getLanguageFile(selectedLanguage))
    if (!preSelectedLanguage)
      window.localStorage.setItem(
        'language',
        this.getLanguageFile(selectedLanguage),
      )
    fetchPendingUserConsent()
    fetchClientConfig()
    fetchUnityToken()
    setTimeFormat(timeFormat)
  }

  getLanguageFile = (code) => {
    switch (code) {
      case 'sv-SE':
      case 'sv':
        return 'sv'
      case 'en-EN':
      case 'en':
        return 'en'
      case 'fr-FR':
      case 'fr':
        return 'fr'
      case 'no-NO':
      case 'no':
        return 'no'
      case 'de-DE':
      case 'de':
        return 'de'
      case 'es-ES':
      case 'es':
        return 'es'
      default:
        return 'en'
    }
  }
  showDrawer = (event, className) => {
    return (
      !event.target.classList.contains(className) &&
      !event.target.closest(`.${className}`)
    )
  }
  closeOpenMenus = (e) => {
    const {
      clearDrawer,
      hideDashboard,
      closeAttendancePopup,
      clearQuickView,
    } = this.props

    if (this.showDrawer(e, ALLOW_TO_OPEN_DRAWER)) {
      clearDrawer()
      if (this.showDrawer(e, KEEP_ROOM_OVERVIEW_OPEN_CLASS)) {
        hideDashboard()
      }
    }
    if (this.showDrawer(e, ALLOW_TO_OPEN_ATTENDANCE_POPUP)) {
      closeAttendancePopup()
    }
  }

  render() {
    return (
      <Fragment>
        <Alert stack={{ limit: 1 }} position="top-right" effect="scale" />
        <ThemeProvider theme={darkTheme}>
          <Main onClick={this.closeOpenMenus} role="main">
            <Routes />
          </Main>
          <GlobalStyle />
        </ThemeProvider>
      </Fragment>
    )
  }
}

App.propTypes = {
  initialize: PropTypes.func,
  fetchClientConfig: PropTypes.func,
  fetchPendingUserConsent: PropTypes.func,
  setActiveLanguage: PropTypes.func,
  addTranslationForLanguage: PropTypes.func,
  fetchSelfAttendance: PropTypes.func,
  clearDrawer: PropTypes.func,
  setTimeFormat: PropTypes.func,
  clearQuickView: PropTypes.func,
  hideDashboard: PropTypes.func,
  closeAttendancePopup: PropTypes.func,
  fetchUnityToken: PropTypes.func,
}
const withConnect = connect(null, {
  authenticateRequest,
  fetchClientConfig,
  fetchPendingUserConsent,
  clearDrawer,
  setTimeFormat,
  clearQuickView,
  hideDashboard,
  closeAttendancePopup,
  fetchUnityToken,
})

export default compose(withRouter, withLocalize, withConnect)(App)
