import styled from 'styled-components'

const BookingInnerDivider = styled.div`
  margin: 0 12px;
  border-bottom: 1px solid ${(props) => props.theme.innerDivider};
`

const BookingOuterDivider = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.divider};
`

export { BookingInnerDivider, BookingOuterDivider }
