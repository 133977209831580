import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalRow from 'containers/quickView/modal/ModalRow'
import WayFindButton from 'containers/quickView/modal/WayFindButton'

class SignActions extends Component {
  render() {
    const { sign } = this.props

    return (
      <ModalRow>
        <ModalButtonRow>
          <WayFindButton itemId={sign.id} type="sign" floorId={sign.floorId} />
        </ModalButtonRow>
      </ModalRow>
    )
  }
}

SignActions.propTypes = {
  sign: PropTypes.object,
}

export default SignActions
