import styled from 'styled-components'

const ErrorMessage = styled.div`
  width: 250px;
  height: 57px;
  margin: 0 0 17px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`

export default ErrorMessage
