import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'

const StyledButton = styled.button.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  width: 100%;
  background-color: ${(props) => props.theme.logoBg};
  border-color: ${(props) => props.theme.panelBorder};
  border-style: solid;
  border-width: 1px 0;
  text-align: left;
  padding: 0 16px;
  height: 36px;
  color: ${(props) => props.theme.actionButton};
  font: inherit;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: pre;

  ${Icon} {
    margin: 0 10px 0 0;
    font-size: 16px;
    flex-shrink: 0;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.panelActiveBg};
      border-color: transparent;
    `};
`

const PanelButton = ({ iconName, children, ...rest }) => (
  <StyledButton {...rest}>
    {iconName && <Icon name={iconName} />}
    {children}
  </StyledButton>
)

PanelButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  iconName: PropTypes.string,
}

export default PanelButton
