import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import {
  openClaimOnBehalfUI,
  closeClaimUI,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  getIsFetching,
  getStatus,
  getHasClaimOnBehalfPermission,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  getClientFeatures,
  getDisableTimeSelection,
} from 'containers/app/selectors'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import toJS from 'components/toJS'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import DeskActionsButton from 'containers/quickView/spaceModal/claimFlow/DeskActionsButton'
import DeskSelect from 'containers/quickView/spaceModal/claimFlow/DeskSelect'
import DeskClaimOnBehalfButton from 'containers/quickView/spaceModal/claimFlow/DeskClaimOnBehalfButton'

const DeskClaimingFlow = ({
  isBooked,
  isFetching,
  status,
  hasClaimOnBehalfPermission,
  dateSelected,
  onBooking,
  openClaimOnBehalfUI,
  closeClaimUI,
  clientFeatures,
  disableTimeSelection,
  spaceStatus,
}) => {
  const handleClaimOnBehalf = () => {
    if (hasClaimOnBehalfPermission) {
      openClaimOnBehalfUI()
    }
  }
  const bookingOnBehalfEnabled =
    clientFeatures?.deskFinder?.meta?.bobo === 'true'

  return (
    <>
      {status === 'selectOnBehalf' ? (
        <DeskSelect
          isFetching={isFetching}
          onBooking={(user) => onBooking(user.id)}
          onClose={closeClaimUI}
        />
      ) : disableTimeSelection &&
        ['BOOKED', 'CLAIMED'].includes(spaceStatus) ? (
        <div />
      ) : (
        <>
          {hasClaimOnBehalfPermission ? (
            <>
              <ModalRow middleButton>
                <ModalButtonRow>
                  <DeskActionsButton
                    primary
                    handleClick={onBooking}
                    translation={<Translate id="quickView.button.reserve" />}
                  />
                </ModalButtonRow>
              </ModalRow>
              <DeskClaimOnBehalfButton
                isFetching={isFetching}
                isBooked={isBooked}
                hasPermission={hasClaimOnBehalfPermission}
                dateSelected={dateSelected}
                onClaim={handleClaimOnBehalf}
              />
            </>
          ) : (
            <ModalRow bottomButton>
              <ModalButtonRow>
                <DeskActionsButton
                  primary
                  handleClick={onBooking}
                  translation={<Translate id="quickView.button.reserve" />}
                />
              </ModalButtonRow>
            </ModalRow>
          )}
        </>
      )}
    </>
  )
}

DeskClaimingFlow.propTypes = {
  isBooked: PropTypes.bool,
  isFetching: PropTypes.bool,
  status: PropTypes.string,
  hasClaimOnBehalfPermission: PropTypes.bool,
  dateSelected: PropTypes.instanceOf(Date),
  onBooking: PropTypes.func,
  openClaimOnBehalfUI: PropTypes.func,
  closeClaimUI: PropTypes.func,
  clientFeatures: PropTypes.object,
  disableTimeSelection: PropTypes.bool,
  spaceStatus: PropTypes.string,
}

const mapStateToProps = (state) => ({
  isFetching: getIsFetching(state),
  status: getStatus(state),
  hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
  dateSelected: getDateFilter(state),
  clientFeatures: getClientFeatures(state),
  disableTimeSelection: getDisableTimeSelection(state),
})

export default connect(mapStateToProps, {
  openClaimOnBehalfUI,
  closeClaimUI,
})(toJS(DeskClaimingFlow))
