export const FETCH_PROFILE_PICTURE_REQUEST =
  'containers/results/FETCH_PROFILE_PICTURE_REQUEST'
export const FETCH_PROFILE_PICTURE_SUCCESS =
  'containers/results/FETCH_PROFILE_PICTURE_SUCCESS'
export const FETCH_PROFILE_PICTURE_FAILURE =
  'containers/results/FETCH_PROFILE_PICTURE_FAILURE'
export const SET_ACTIVE_BY_INDEX = 'containers/results/SET_ACTIVE_BY_INDEX'
export const CLEAR_ACTIVE_BY_INDEX = 'containers/results/CLEAR_ACTIVE_BY_INDEX'
export const RESULT_ASSET_CLICK = 'containers/results/RESULT_ASSET_CLICK'
export const SELECT_A_COLLEAGUE = 'containers/results/SELECT_A_COLLEAGUE'
export const DELETE_SELECTED_COLLEAGUE =
  'containers/results/DELETE_SELECTED_COLLEAGUE'
export const GET_SELECTED_COLLEAGUES =
  'containers/results/GET_SELECTED_COLLEAGUES'
export const GET_SELECTED_COLLEAGUES_SUCCESS =
  'containers/results/GET_SELECTED_COLLEAGUES_SUCCESS'
export const FETCH_EQUIPMENT_PICTURE_REQUEST =
  'containers/results/FETCH_EQUIPMENT_PICTURE_REQUEST'
export const FETCH_EQUIPMENT_PICTURE_SUCCESS =
  'containers/results/FETCH_EQUIPMENT_PICTURE_SUCCESS'
export const FETCH_EQUIPMENT_PICTURE_FAILURE =
  'containers/results/FETCH_EQUIPMENT_PICTURE_FAILURE'
export const FETCH_EQUIPMENT_INFO_REQUEST =
  'containers/results/FETCH_EQUIPMENT_INFO_REQUEST'
export const FETCH_EQUIPMENT_INFO_SUCCESS =
  'containers/results/FETCH_EQUIPMENT_INFO_SUCCESS'
export const FETCH_EQUIPMENT_INFO_FAILURE =
  'containers/results/FETCH_EQUIPMENT_INFO_FAILURE'
