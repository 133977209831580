import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import QuickViewBackdrop from 'containers/quickView/QuickViewBackdrop'
import Modal from 'containers/quickView/Modal'
import { clearQuickView } from 'containers/quickView/actions'
import { getInfo } from 'containers/quickView/selectors'
import ModalRow from 'containers/quickView/modal/ModalRow'
import InfoText from 'containers/quickView/InfoText'
import ModalStatus from 'containers/quickView/modal/ModalStatus'

class InfoModal extends Component {
  handleClose = () => {
    const { clearQuickView } = this.props
    clearQuickView()
  }

  render() {
    const { info } = this.props

    return (
      <Fragment>
        <QuickViewBackdrop />
        <Modal>
          <ModalStatus status="INFO" onClick={this.handleClose} />
          <ModalRow>
            <InfoText>
              <span>Position</span>
              <span>
                X: {info.x} Y: {info.y} R:{info.rotation}
              </span>
            </InfoText>
          </ModalRow>
        </Modal>
      </Fragment>
    )
  }
}

InfoModal.propTypes = {
  clearQuickView: PropTypes.func,
  info: PropTypes.object,
}

const mapStateToProps = (state) => ({
  info: getInfo(state),
})

export default connect(mapStateToProps, { clearQuickView })(InfoModal)
