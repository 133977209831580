import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize, getTranslate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import {
  getSpaces,
  getMetaTypes,
  getFutureDesks,
  getFutureRooms,
  getFutureUsers,
} from 'containers/app/selectors'
import toJS from 'components/toJS'
import Icon from 'components/Icon'
import ResultSymbol from 'containers/searchPanel/results/ResultSymbol'
import ResultCapacity from 'containers/searchPanel/results/ResultCapacity'
import ResultContainer from 'containers/searchPanel/results/ResultContainer'
import { clickAsset } from 'containers/searchPanel/results/actions'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { icons } from 'components/icon/icons'

const statusMap = {
  OFFLINE: 'space.status.offline',
  FREE: 'space.status.free',
  CLAIMED: 'space.status.claimed',
  BOOKED: 'space.status.booked',
  NONE: 'space.status.none',
  BUSY: 'space.status.busy',
  TAKEN: 'space.status.busy',
}
const descToIconMap = {
  ROOM: icons.ROOM,
  DESK: icons.DESK,
}

const HeaderContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const TopHeader = styled.span`
  overflow-wrap: break-word;
  hyphens: auto;
  word-break: keep-all;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.panelBgLight};
  padding-right: 5px;
`

const BottomHeader = styled.span`
  overflow-wrap: break-word;
  hyphens: auto;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.panelColor};
  padding-right: 5px;
`

class ResultItem extends Component {
  getFutureSpace = () => {
    const { isFuture, futureDesks, futureRooms } = this.props

    if (isFuture) {
      const futureSpaces = [...futureDesks, ...futureRooms]
      return futureSpaces.find((space) => space.id === this.props.id)
    }
  }
  getSpaceStatus = () => {
    const { currentSpace } = this.props
    const futureSpace = this.getFutureSpace()
    if (futureSpace) {
      return futureSpace.state
    } else if (currentSpace) {
      return currentSpace.status.state
    } else {
      return null
    }
  }

  getFutureDeskUser = (desk) => {
    const { futureUsers } = this.props
    if (desk) {
      const userId = desk.state === 'BOOKED' ? desk.events[0].userId : null
      if (userId) {
        const u = futureUsers.find((user) => user.id === userId)
        return `${u.name[0]}. ${u.lName}`
      }
      return (
        <Translate>
          {({ translate }) => `${translate('space.status.booked')}`}
        </Translate>
      )
    }
  }

  render() {
    const {
      currentSpace,
      type,
      isFuture,
      translate,
      active,
      clickAsset,
      activeLanguage,
    } = this.props
    const spaceStatus = this.getSpaceStatus()
    const spaceName = currentSpace.info.name
    const topHeaderTxt = spaceName
    let botHeaderTxt
    try {
      const occupantName = currentSpace.status.occupant
      const mappedStatus = statusMap[spaceStatus]
      const localizedStatus = translate(mappedStatus)
      botHeaderTxt = !isFuture && occupantName ? occupantName : localizedStatus
    } catch (error) {
      console.error(error)
      botHeaderTxt = ''
    }
    return (
      <ResultContainer onClick={() => clickAsset(currentSpace)} active={active}>
        <ResultSymbol status={spaceStatus}>
          <Icon name={descToIconMap[type.space.desc]} />
        </ResultSymbol>
        <HeaderContainer>
          <TopHeader lang={activeLanguage.code}>{topHeaderTxt}</TopHeader>
          <BottomHeader lang={activeLanguage.code}>{botHeaderTxt}</BottomHeader>
        </HeaderContainer>
        {currentSpace.info.cap >= 1 && (
          <ResultCapacity>
            <Icon name={icons.USERS} /> {currentSpace.info.cap}
          </ResultCapacity>
        )}
      </ResultContainer>
    )
  }
}
ResultItem.propTypes = {
  id: PropTypes.number,
  activeId: PropTypes.number,
  clickAsset: PropTypes.func,
  currentSpace: PropTypes.object,
  type: PropTypes.object,
  active: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  isFuture: PropTypes.bool,
  futureUsers: PropTypes.array,
  futureDesks: PropTypes.array,
  futureRooms: PropTypes.array,
  translate: PropTypes.func,
  activeLanguage: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  const currentSpace = getSpaces(state).get(`${props.id}`)
  const spaceTypeId = currentSpace ? currentSpace.get('typeId') : null
  const type = spaceTypeId ? getMetaTypes(state).get(`${spaceTypeId}`) : null

  return {
    activeId: getActiveSpaceId(state),
    currentSpace,
    type,
    selectedDate: getDateFilter(state),
    isFuture: getIsFuture(state),
    futureDesks: getFutureDesks(state),
    futureRooms: getFutureRooms(state),
    futureUsers: getFutureUsers(state),
    translate: getTranslate(state.get('localize')),
  }
}

export default withLocalize(
  connect(mapStateToProps, { clickAsset })(toJS(ResultItem)),
)
