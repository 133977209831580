import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PanelButton from 'containers/mainPanel/PanelButton'
import Icon from 'components/Icon'
import DrawerContainer from 'containers/mainPanel/panelDrawer/DrawerContainer'
import DrawerContent from 'containers/mainPanel/panelDrawer/DrawerContent'
import ButtonContent from 'containers/mainPanel/panelDrawer/ButtonContent'
import ButtonText from 'containers/mainPanel/panelDrawer/ButtonText'
import {
  getActiveDrawer,
  getInnerActiveDrawer,
} from 'containers/mainPanel/panelDrawer/selectors'
import {
  openDrawer,
  clearDrawer,
  openInnerDrawer,
  clearInnerDrawer,
} from 'containers/mainPanel/panelDrawer/actions'
import { hideDashboard } from 'containers/dashboard/actions'
import { fetchPersonalCalendar } from 'containers/mainPanel/bookedResources/actions'
import { icons } from 'components/icon/icons'
import BuildingSelectorPopup from 'pages/BuildingSelectorPopup'
import { fetchSelfAttendance } from 'containers/flowPlanner/actions'
import FlowPlanner from 'containers/FlowPlanner'
import { buildingView, showFloor } from 'api/unity'
import { getCurrentFloorId } from 'containers/app/selectors'
import { innerDrawersIcons } from 'utils/appVars'

class PanelDrawer extends PureComponent {
  componentDidUpdate() {
    const { iconName, activeDrawer, innerActiveDrawer } = this.props
    const open = activeDrawer === iconName || innerActiveDrawer === iconName
    if (!open && this.fetchBookings) {
      clearInterval(this.fetchBookings)
      this.fetchBookings = false
    }
  }

  handleClick = () => {
    const {
      iconName,
      activeDrawer,
      openDrawer,
      clearDrawer,
      fetchCalendarData,
      fetchPersonalCalendar,
      fetchSelfAttendance,
      openInnerDrawer,
      clearInnerDrawer,
      innerActiveDrawer,
      currentFloorId,
      hideDashboard,
    } = this.props
    hideDashboard()
    if (activeDrawer === iconName) {
      clearDrawer()
      clearInnerDrawer()
      showFloor(currentFloorId)
    } else if (innerActiveDrawer === iconName) {
      clearInnerDrawer()
    } else {
      //Handle opening in building view and selecting floor drawer
      if (iconName === icons.FLOORS) {
        buildingView(currentFloorId)
      } else {
        showFloor(currentFloorId)
      }
      //
      //Closing Settings drawer when is clicked in an outer drawer
      if (
        activeDrawer === icons.SETTINGS &&
        !innerDrawersIcons.some((icon) => iconName === icon)
      ) {
        clearInnerDrawer()
        clearDrawer()
      }
      //
      //Handle innerdrawer opening
      if (activeDrawer === icons.SETTINGS) {
        openInnerDrawer(iconName)
      } else {
        openDrawer(iconName)
      }
      //
      if (fetchCalendarData) {
        fetchPersonalCalendar()
        fetchSelfAttendance()
        //set interval for fetching updated bookings through 3 seconds
        const pollInterval = 3000 //3 seconds
        this.fetchBookings = setInterval(fetchPersonalCalendar, pollInterval)
      }
    }
  }

  setOpenToClose = () => {
    const { clearDrawer } = this.props
    clearDrawer()
  }

  render() {
    const {
      iconName,
      label,
      children,
      activeDrawer,
      allowToOpen,
      showConfirmMessage,
      innerActiveDrawer,
      width,
      heightIndex,
    } = this.props

    const open = activeDrawer === iconName || innerActiveDrawer === iconName
    const multipleOptions = React.Children.count(children) > 1 || allowToOpen

    return (
      <DrawerContainer width={width}>
        <PanelButton
          iconName={iconName}
          onClick={this.handleClick}
          active={open}
          disabled={!multipleOptions}
        >
          <ButtonContent>
            <ButtonText>{label}</ButtonText>
            {multipleOptions && (
              <Icon name={open ? icons.PREVIOUS : icons.FORWARD} />
            )}
          </ButtonContent>
        </PanelButton>
        {open && iconName === icons.BUILDING && (
          <BuildingSelectorPopup closePopup={this.setOpenToClose} />
        )}
        {open && iconName === icons.FLOWPLANNER && <FlowPlanner />}

        {open && iconName !== icons.BUILDING && iconName !== icons.FLOWPLANNER && (
          <DrawerContent
            innerMenu={!!innerActiveDrawer}
            fetchCalendarData
            showConfirmEventMessage={showConfirmMessage}
            heightIndex={heightIndex}
          >
            {children}
          </DrawerContent>
        )}
      </DrawerContainer>
    )
  }
}

PanelDrawer.propTypes = {
  activeDrawer: PropTypes.string,
  innerActiveDrawer: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  clearDrawer: PropTypes.func,
  iconName: PropTypes.string,
  label: PropTypes.string,
  openDrawer: PropTypes.func,
  openInnerDrawer: PropTypes.func,
  clearInnerDrawer: PropTypes.func,
  allowToOpen: PropTypes.bool,
  fetchCalendarData: PropTypes.bool,
  fetchPersonalCalendar: PropTypes.func,
  fetchSelfAttendance: PropTypes.func,
  showConfirmMessage: PropTypes.bool,
  width: PropTypes.string,
  onBtnSelect: PropTypes.func,
  onClose: PropTypes.func,
  currentFloorId: PropTypes.number,
  heightIndex: PropTypes.number,
  hideDashboard: PropTypes.func,
}

const mapStateToProps = (state) => ({
  activeDrawer: getActiveDrawer(state),
  innerActiveDrawer: getInnerActiveDrawer(state),
  currentFloorId: getCurrentFloorId(state),
})

export default connect(mapStateToProps, {
  openDrawer,
  clearDrawer,
  fetchSelfAttendance,
  fetchPersonalCalendar,
  openInnerDrawer,
  clearInnerDrawer,
  hideDashboard,
})(PanelDrawer)
