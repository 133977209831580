import fetch from 'isomorphic-fetch'

import { config } from 'api/public'

export const getPersonalCalendar = async () => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}/secure/v2/calendar`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const deletePersonalEvent = async (eid) => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}/secure/v2/event/${eid}`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    return Promise.resolve(response)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}
