import { combineReducers } from 'redux-immutable'

import { CHANGE_VISIBILITY_SUCCESS } from 'containers/mainPanel/visibility/constants'

const changedVisibility = (state = '', action) => {
  switch (action.type) {
    case CHANGE_VISIBILITY_SUCCESS:
      return action.visibility
    default:
      return state
  }
}

export default combineReducers({
  changedVisibility,
})
