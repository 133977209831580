import React from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InfoSymbol from 'containers/quickView/InfoSymbol'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { getDisableTimeSelection, getSelfId } from 'containers/app/selectors'
import { icons } from 'components/icon/icons'
import { getHasCancelOnBehalfPermission } from 'containers/quickView/spaceModal/claimFlow/selectors'
import ProfilePicture from 'components/ProfilePicture'
import { getLocalizedDateAndTime } from 'utils/utilsFunctions'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'

const SpaceBookingContainer = styled.div`
  display: flex;
  min-height: 48px;
  padding: 6px 0;
  align-items: center;
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.holidayPanelItem};
  font-size: 1.5em;
`
const SpaceBookingText = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 22px;
  flex: 1;
  max-width: 150px;
  color: ${(props) => props.theme.darkText};
  > span:first-child {
    font-size: 16px !important;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const PopoverHolder = styled.div`
  position: absolute;
  height: 100px;
  left: 100%;
  top: calc((100% - 90px) / 2);
`

const ConfirmationText = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

const ConfirmationButton = styled(Button)`
  min-width: 12rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 100px;
  text-transform: none;
  font-weight: normal;
  :disabled {
    filter: brightness(0.4);
    cursor: wait;
    color: #fff;
  }
`
const StyledInfoSymbol = styled(InfoSymbol)`
  align-self: flex-start;
`
const StyledIcon = styled(Icon)`
  margin-left: 15px;
  position: absolute;
  right: -10px;
  top: 8px;
  font-size: 0.8em;
  font-weight: 900;
`

const ConfirmButton = styled(ConfirmationButton)`
  border: 1px solid ${(props) => props.theme.statusBooked};
  background-color: ${(props) => props.theme.statusBooked};
  :disabled {
    border: 1px solid ${(props) => props.theme.statusBooked};
    background-color: ${(props) => props.theme.statusBooked};
  }
`

const CancelButton = styled(ConfirmationButton)`
  border: 1px solid ${(props) => props.theme.panelBG};
  margin-right: 15px;
  background-color: transparent;
  :disabled {
    border: 1px solid ${(props) => props.theme.panelBG};
  }
`
const SpaceBooking = ({
  booking,
  isRoom,
  selfId,
  space,
  showConfirmationPopup,
  timeFormat,
}) => {
  const hasCOBOPermission = useSelector((state) =>
    getHasCancelOnBehalfPermission(state),
  )
  const disableTimeSelection = useSelector((state) =>
    getDisableTimeSelection(state),
  )
  const locateDeskFromBooking = () => {
    if (!booking) return
  }
  const showRemoveDeskBookingConfirmation = (e) => {
    e.stopPropagation()
    showConfirmationPopup({
      id: booking.id,
      start: booking.start,
      end: booking.end,
      reservationName: space.info.name,
    })
  }

  return (
    <SpaceBookingContainer onClick={() => locateDeskFromBooking()}>
      <StyledInfoSymbol medium>
        {booking.organizer ? (
          <ProfilePicture
            fn={booking.organizer.name}
            ln={booking.organizer.name.split(' ')[1]}
            id={booking.organizer.id}
          />
        ) : (
          ''
        )}
      </StyledInfoSymbol>
      <SpaceBookingText>
        <span>{booking.name}</span>
        {(!disableTimeSelection || isRoom) && (
          <>
            <span>{getLocalizedDateAndTime(booking.start, timeFormat)}</span>
            <span>{getLocalizedDateAndTime(booking.end, timeFormat)}</span>
          </>
        )}
      </SpaceBookingText>
      {(booking?.organizer?.id === selfId || hasCOBOPermission) && (
        <StyledIcon
          name={icons.CLOSE}
          clickable
          onClick={(e) => showRemoveDeskBookingConfirmation(e)}
        />
      )}
    </SpaceBookingContainer>
  )
}

const mapStateToProps = (state) => ({
  selfId: getSelfId(state),
  timeFormat: getTimeFormat(state),
})

SpaceBooking.propTypes = {
  activeLanguage: PropTypes.object,
  booking: PropTypes.object,
  space: PropTypes.object,
  isRoom: PropTypes.bool,
  selfId: PropTypes.number,
  showConfirmationPopup: PropTypes.func,
  timeFormat: PropTypes.string,
}

export default connect(mapStateToProps, {})(SpaceBooking)
