import { combineReducers } from 'redux-immutable'

import { SET_TIME_FORMAT } from 'containers/mainPanel/timeFormats/constants'

const timeFormat = (state = '', action) => {
  switch (action.type) {
    case SET_TIME_FORMAT:
      return action.timeFormat
    default:
      return state
  }
}

export default combineReducers({
  timeFormat,
})
