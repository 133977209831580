import styled from 'styled-components'

import Icon from 'components/Icon'

const ResultCapacity = styled.p`
  display: flex;
  flex: 0 0 34px;
  color: ${(props) => props.theme.panelBgLight};

  ${Icon} {
    margin: 0 4px 0 0;
  }
`

export default ResultCapacity
