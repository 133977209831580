import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { getBuildings, getBuildingIdsOnSites } from 'containers/app/selectors'
import { SelectButton } from 'pages/buildingSelector/BuildingUI'
import { sortByString } from 'utils/utilsFunctions'

class Country extends Component {
  render() {
    const {
      siteName,
      onClick,
      buildings,
      buildingsForSite,
      selectSiteBtn,
      searchBy,
    } = this.props
    if (!buildingsForSite) return null

    const getBuildingNameById = (buildingId) =>
      buildings.get(`${buildingId}`).getIn(['info', 'name'])

    return (
      <Fragment>
        <h2>{siteName}</h2>
        {buildingsForSite
          .sort((buildingIdA, buildingIdB) =>
            sortByString(
              getBuildingNameById(buildingIdA),
              getBuildingNameById(buildingIdB),
            ),
          )
          .filter((buildingId) => {
            const searchCriteria = searchBy || ''
            return (
              buildings
                .getIn([`${buildingId}`])
                .getIn(['info', 'name'])
                .toUpperCase()
                .indexOf(searchCriteria.toUpperCase()) > -1 ||
              siteName.toUpperCase().indexOf(searchCriteria.toUpperCase()) > -1
            )
          })
          .map((buildingId) => {
            if (!buildings.get(`${buildingId}`).getIn(['info', 'bundle_name']))
              return null
            return (
              <SelectButton
                selectSiteBtn={selectSiteBtn}
                onClick={() => onClick(buildingId)}
                key={buildingId}
              >
                {getBuildingNameById(buildingId)}
              </SelectButton>
            )
          })}
      </Fragment>
    )
  }
}

Country.propTypes = {
  buildings: PropTypes.object,
  buildingsForSite: PropTypes.array,
  onClick: PropTypes.func,
  siteName: PropTypes.string,
  selectSiteBtn: PropTypes.bool,
  searchBy: PropTypes.string,
}

const mapStateToProps = (state, props) => ({
  buildings: getBuildings(state),
  siteName: props.siteName,
})

export default connect(mapStateToProps)(Country)
