import styled from 'styled-components'

import { KEEP_ROOM_OVERVIEW_OPEN_CLASS } from 'utils/appVars'

export const DashboardContainer = styled.div`
  background: ${(props) => props.theme.panelBgLight};
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  font-size: 16px;
  height: 100%;
`

export const Modal = styled.div.attrs({
  className: KEEP_ROOM_OVERVIEW_OPEN_CLASS,
})`
  background: ${(props) => props.theme.panelBgLight};
  position: absolute;
  left: 220px;
  top: 7%;
  height: 80%;
  width: calc(100vw - 480px);
  max-width: calc(100vw - 480px);
  overflow: hidden;
  padding: 8px 0;
  pointer-events: none;
  touch-events: none;
  box-shadow: 0 2px 4px 0 rgba(212, 212, 212, 0.5);
  z-index: 2;
`
