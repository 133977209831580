import styled from 'styled-components'

const MetaHolder = styled.div`
  display: flex;
  height: 70px;
  line-height: 70px;
  justify-content: space-between;
  padding-left: 13px;
  padding-right: 15px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  > div > span:first-child {
    width: 19px;
    height: 19px;
    background-size: 18px;
    display: inline-block;
    margin: 0 0.2em;
  }
`
export default MetaHolder
