import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import StatusIndicator from 'components/StatusIndicator'

const Wrapper = styled.div`
  position: relative;
  margin-right: ${(props) => (props.small || props.medium ? 1 : 2)}em;
  width: ${(props) => (props.hideIcon ? '25px' : '2em')};
  height: 2em;
  font-size: ${(props) => (props.small ? 16 : props.medium ? 20 : 24)}px;
  font-weight: 500;
  margin-right: 16px;

  ${Icon} {
    margin: 0;
    font-size: 1em;
  }

  ${StatusIndicator} {
    height: 0.6em;
    width: 0.6em;
    position: absolute;
    right: 14.58%;
    bottom: 14.58%;
    transform: translate(50%, 50%);
    border-radius: 50%;
  }
  ${(props) =>
    props.hideIcon &&
    css`
      ${StatusIndicator} {
        top: -20%;
        right: 5px;
      }
    `};
  ${(props) =>
    props.multiSelect &&
    css`
      ${StatusIndicator} {
        top: -3%;
        right: 5px;
      }
    `};
`

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${(props) =>
    props.hideIcon ? 'none' : props.theme.panelBg};
  color: ${(props) => props.theme.actionButton};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  i {
    display: ${(props) => (props.hideIcon ? 'none' : 'initial')};
  }
`

class InfoSymbol extends Component {
  render() {
    const {
      status,
      small,
      children,
      medium,
      hideIcon,
      className,
      multiSelect,
    } = this.props

    return (
      <Wrapper
        small={small}
        medium={medium}
        hideIcon={hideIcon}
        className={className}
        multiSelect={multiSelect}
      >
        <Circle hideIcon={hideIcon}>{children}</Circle>
        {status && <StatusIndicator status={status} />}
      </Wrapper>
    )
  }
}

InfoSymbol.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  status: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  className: PropTypes.string,
  hideIcon: PropTypes.bool,
  multiSelect: PropTypes.bool,
}

export default InfoSymbol
