import { combineReducers } from 'redux-immutable'

import { AUTH_SUCCESS, AUTH_FAILURE } from 'containers/app/constants'

const isAuthenticated = (
  state = !!window.localStorage.getItem('oauthSuccess'),
  action,
) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return true
    case AUTH_FAILURE:
      window.localStorage.setItem('oauthSuccess', false)
      return false
    default:
      return state
  }
}

const token = (state = '') => {
  return state
}

export default combineReducers({
  isAuthenticated,
  token,
})
