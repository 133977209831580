import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

const DarkPaper = withStyles(() => ({
  root: {
    borderRadius: 4,
    color: 'white',
  },
}))(Paper)

const PopoverContainer = styled.div`
  position: relative;
  transition: all 2s;
  height: 100%;
`

const PopoverContent = styled(DarkPaper)`
  position: absolute;
  top: 50%;
  left: ${(props) =>
    props.deleteReservationMessage ? `calc(100% + 10px)` : `calc(100% + 40px)`};
  transform: translateY(-50%);
  background-color: ${(props) => props.theme.panelBg} !important;
  padding: 20px 40px;

  &:after {
    display: ${(props) => (props.deleteReservationMessage ? `none` : ``)};
    content: '';
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid ${(props) => props.theme.panelBg};
    clear: both;
  }
`

const Fade = styled.div`
  height: 100%;
  opacity: ${(props) => {
    switch (props.state) {
      case 'entering':
      case 'entered':
        return 1
      default:
        return 0.01
    }
  }};
  transform: translate(
    ${(props) => {
      switch (props.state) {
        case 'entering':
        case 'entered':
          return 0
        default:
          return 10
      }
    }}px,
    -12px
  );
  transition: all ${(props) => props.timeout}ms ease-in-out;
  transition-property: opacity, transform;
`

// eslint-disable-next-line react/prop-types
const TransitionFade = ({ show, children }) => (
  <Transition
    in={show}
    timeout={200}
    appear
    mountOnEnter
    unmountOnExit
    onEnter={(e) => e.clientHeight}
  >
    {(state) => (
      <Fade state={state} timeout={200}>
        {children}
      </Fade>
    )}
  </Transition>
)

const Popover = ({
  show,
  content,
  children,
  onHide,
  deleteReservationMessage,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (e) => {
    if (ref.current?.contains(e.target)) {
      return
    }
    onHide()
  }

  return (
    <PopoverContainer ref={ref}>
      {children}
      <TransitionFade show={show} timeout={500}>
        <PopoverContent deleteReservationMessage={deleteReservationMessage}>
          {content}
        </PopoverContent>
      </TransitionFade>
    </PopoverContainer>
  )
}

Popover.propTypes = {
  show: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  children: PropTypes.node,
  onHide: PropTypes.func.isRequired,
  deleteReservationMessage: PropTypes.bool,
}

export default Popover
