import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'

export const StyledFilterItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: 0;
  font: inherit;
  font-size: 12px;
  cursor: pointer;
  border: none;
  padding: 4px;
  background: none;
  color: ${(props) => props.theme.darkText};

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.panelBg};
      color: ${(props) => props.theme.panelBgLight};
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 40%;
      pointer-events: none;
    `}

  ${Icon} {
    display: block;
    width: 100%;
    margin: 0 0 4px 0;
    font-size: 28px;
    pointer-events: none;
  }

  span {
    display: block;
    pointer-events: none;
  }
`

class FilterItem extends PureComponent {
  render() {
    const { iconName, caption, ...rest } = this.props
    return (
      <StyledFilterItem {...rest}>
        <Icon name={iconName} />
        <span>{caption}</span>
      </StyledFilterItem>
    )
  }
}

FilterItem.propTypes = {
  iconName: PropTypes.string,
  caption: PropTypes.string,
}

export default FilterItem
