import styled from 'styled-components'

const InfiniteScrollHolder = styled.div.attrs({
  id: 'scrollableDiv',
})`
  overflow: auto;
  display: flex;
  flexdirection: column-reverse;
  max-height: ${(props) => props.height};
  div:last-child {
    border-bottom: none;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fff;
    background-color: #d8d8d8;
    border-radius: 7px;
  }

  > div {
    width: 100%;
  }
  h4 {
    padding-left: 18px;
  }
`
export default InfiniteScrollHolder
