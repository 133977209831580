import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

import InfoSymbol from 'containers/quickView/InfoSymbol'
import StatusIndicator from 'components/StatusIndicator'
import Icon from 'components/Icon'
import InfoText from 'containers/quickView/InfoText'
import SpaceCapacity from 'containers/quickView/spaceModal/SpaceCapacity'
import { getFloors, getBuildings } from 'containers/app/selectors'
import ModalRow from 'containers/quickView/modal/ModalRow'
import { icons } from 'components/icon/icons'

const InfoHeader = styled.div`
  font-weight: 500;
  font-size: 18px;
`
const StyledInfoText = styled(InfoText)`
  font-weight: normal;
`

const descToIconMap = {
  ROOM: icons.ROOM,
  DESK: icons.DESK,
}

const SpaceInfo = ({
  space,
  type,
  floorName,
  buildingName,
  status,
  className,
}) => (
  <ModalRow className={className}>
    <InfoSymbol hideIcon>
      <Icon name={descToIconMap[type.space.desc]} />
      <StatusIndicator status={status} />
    </InfoSymbol>
    <Translate>
      {({ translate }) => (
        <StyledInfoText>
          <InfoHeader>
            <span>{space.info.name}</span>
          </InfoHeader>
          <span>
            {floorName}, {buildingName}
          </span>
          <span>
            {space.info.cap}{' '}
            {space.info.cap > 1
              ? translate('quickView.form.seats')
              : translate('quickView.form.seat')}
          </span>
        </StyledInfoText>
      )}
    </Translate>
  </ModalRow>
)

SpaceInfo.propTypes = {
  buildingName: PropTypes.string,
  floorName: PropTypes.string,
  space: PropTypes.object,
  type: PropTypes.object,
  className: PropTypes.string,
  status: PropTypes.oneOf([
    'FREE',
    'BUSY',
    'AWAY',
    'BOOKED',
    'CLAIMED',
    'TAKEN',
    'OOO',
    'OFFLINE',
    'INFO',
    'NONE',
  ]),
}

const mapStateToProps = (state, { space }) => {
  const floor = getFloors(state).get(`${space.floorId}`)

  if (!floor) return {}

  const buildingId = floor.get('buildingId')
  const building = getBuildings(state).get(`${buildingId}`)

  return {
    floorName: floor.getIn(['info', 'name']),
    buildingName: building.getIn(['info', 'name']),
  }
}

export default connect(mapStateToProps)(SpaceInfo)
