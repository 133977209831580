import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import ToggleButton from 'react-toggle-button'

import { setAvailablityFilter } from 'containers/searchPanel/filter/actions'
import { getAvailabilityFilter } from 'containers/searchPanel/filter/selectors'
import toJS from 'components/toJS'
import { searchClear, clearQuery } from 'containers/searchPanel/search/actions'

const AvailabilityFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  background-color: ${(props) => props.theme.panelBgLight};
  color: ${(props) => props.theme.panelActiveBg};
  padding: 12px;

  span {
    font-size: 14px;
    font-weight: 600;
  }
`

class RoomFilters extends Component {
  render() {
    const {
      availabilityFilter,
      setAvailablityFilter,
      theme,
      clearQuery,
      searchClear,
    } = this.props

    return (
      <AvailabilityFilterContainer>
        <span>
          <Translate id="filter.availableRoomsOnly" />
        </span>
        <Translate>
          {({ translate }) => (
            <ToggleButton
              value={availabilityFilter}
              onToggle={(value) => {
                setAvailablityFilter(!availabilityFilter)
                value = !value
                clearQuery()
                searchClear()
              }}
              activeLabel={translate('filter.title.toggleOn')}
              inactiveLabel={translate('filter.title.toggleOff')}
              colors={{
                active: {
                  base: theme.accent,
                },
                inactive: {
                  base: theme.panelBg,
                },
              }}
            />
          )}
        </Translate>
      </AvailabilityFilterContainer>
    )
  }
}

RoomFilters.propTypes = {
  availabilityFilter: PropTypes.bool,
  setAvailablityFilter: PropTypes.func,
  theme: PropTypes.object,
  clearQuery: PropTypes.func,
  searchClear: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    availabilityFilter: getAvailabilityFilter(state),
  }
}

const withConnect = connect(mapStateToProps, {
  setAvailablityFilter,
  clearQuery,
  searchClear,
})

export default compose(withConnect, withTheme)(toJS(RoomFilters))
