import {
  CHANGE_VISIBILITY,
  CHANGE_VISIBILITY_SUCCESS,
} from 'containers/mainPanel/visibility/constants'

export const changeVisibility = (visibility) => ({
  type: CHANGE_VISIBILITY,
  visibility,
})

export const visibilityChanged = (visibility) => {
  return {
    type: CHANGE_VISIBILITY_SUCCESS,
    visibility,
  }
}
