import styled from 'styled-components'

import { ALLOW_TO_OPEN_MODAL } from 'utils/appVars'

const ResultList = styled.div.attrs({
  className: ALLOW_TO_OPEN_MODAL,
})`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.panelBg} !important;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid ${(props) => props.theme.panelBg};
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`

export default ResultList
