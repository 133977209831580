import styled from 'styled-components'

const ErrorDialogButton = styled.button`
  width: 101px;
  height: 31px;
  margin: 8px 77px 0;
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background: none;
  color: #fff;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #e8e8e8;
    color: #000;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e8e8e8;
  }
`

export default ErrorDialogButton
