import { combineReducers } from 'redux-immutable'
import { reducer as formReducer } from 'redux-form'
import { localizeReducer } from 'react-localize-redux'

import app from 'containers/app/reducers'
import login from 'pages/login/reducers'
import unity from 'containers/unityLoader/reducers'
import search from 'containers/searchPanel/search/reducers'
import filter from 'containers/searchPanel/filter/reducers'
import quickView from 'containers/quickView/reducers'
import drawer from 'containers/mainPanel/panelDrawer/reducers'
import results from 'containers/searchPanel/results/reducers'
import userSettings from 'containers/userSettings/reducers'
import flowPlanner from 'containers/flowPlanner/reducers'
import personalData from 'containers/mainPanel/bookedResources/reducers'
import timeFormats from 'containers/mainPanel/timeFormats/reducers'
import dashboard from 'containers/dashboard/reducers'

const appReducer = combineReducers({
  app,
  login,
  unity,
  search,
  filter,
  quickView,
  drawer,
  results,
  flowPlanner,
  userSettings,
  personalData,
  timeFormats,
  dashboard,
  form: formReducer,
  localize: localizeReducer,
})

export default appReducer
