import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'

const StyledInput = styled.input`
  padding: 12px 10px;
  border-radius: 3px;
  font-size: 14px;
  font-family: inherit;
  border: 1px solid ${(props) => props.theme.inputColor};
  color: ${(props) => props.theme.inputColor};
  box-sizing: border-box;
  letter-spacing: 0.03em;
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.panelBgLight};
  margin: 0 0 1em 0;
  display: flex;
  position: relative;

  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 1;
    color: #2f3437;
  }
  :-ms-input-placeholder {
    opacity: 1;
    color: #2f3437;
  }
`

const QuickViewInput = (props) => <StyledInput id={props.name} {...props} />

QuickViewInput.propTypes = {
  name: PropTypes.string,
}

export default QuickViewInput
