import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'

import DataHolder from 'containers/flowPlanner/DataHolder'
import OpenList from 'containers/flowPlanner/OpenList'
import ListHolder from 'containers/flowPlanner/ListHolder'
import MetaHolder from 'containers/flowPlanner/MetaHolder'
import StatisticsItem from 'containers/flowPlanner/StatisticsItem'
import Icon from 'components/Icon'
import DetailedInfoCard from 'containers/flowPlanner/DetailedInfoCard'
import { getBuildings, getCurrentBuildingId } from 'containers/app/selectors'
import { SAME_BUILDING, OTHER_BUILDING, typesMap } from 'utils/appVars'
import { transformData } from 'containers/flowPlanner/flowplannerServices'

const FavoritesList = ({
  favoritesAttendance,
  isOpen,
  buildings,
  currentBuilding,
  translate,
  toggle,
  height,
}) => {
  const toggleList = () => {
    toggle(!isOpen)
  }
  const getAttendanceLocation = ({ type, buildingId }) => {
    if (!type || type === SAME_BUILDING || type === OTHER_BUILDING) {
      const buildingName = buildings
        .get(`${buildingId}`)
        .getIn(['info', 'name'])
      return buildingName
    }
    const localizedAttendanceType = translate(typesMap[type])
    return localizedAttendanceType
  }
  favoritesAttendance = favoritesAttendance
    ? transformData(favoritesAttendance, currentBuilding)
    : []
  return (
    <ListHolder>
      <MetaHolder onClick={toggleList}>
        <StatisticsItem
          value={`${translate('flowPlanner.selected_colleagues')} (${
            favoritesAttendance.length
          })`}
          iconName={'star'}
        />
        <OpenList isOpen={isOpen}>
          <Icon name="back" />
        </OpenList>
      </MetaHolder>
      {isOpen && (
        <DataHolder height={`${height}px`}>
          {favoritesAttendance.map((attendance, index) => {
            return (
              <DetailedInfoCard
                key={index}
                user={attendance.userInfo}
                location={getAttendanceLocation(attendance)}
                type={attendance.type}
                space={attendance.space}
              />
            )
          })}
        </DataHolder>
      )}
    </ListHolder>
  )
}

FavoritesList.propTypes = {
  icon: PropTypes.string,
  favoritesAttendance: PropTypes.array,
  isOpen: PropTypes.bool,
  buildings: PropTypes.object,
  currentBuilding: PropTypes.number,
  translate: PropTypes.func,
  toggle: PropTypes.func,
  height: PropTypes.number,
}
const mapStateToProps = (state) => ({
  buildings: getBuildings(state),
  currentBuilding: getCurrentBuildingId(state),
  translate: getTranslate(state.get('localize')),
})

export default connect(mapStateToProps, {})(FavoritesList)
