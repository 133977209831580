import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

import PanelButton from 'containers/mainPanel/PanelButton'
import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'
import { showDashboard, hideDashboard } from 'containers/dashboard/actions'
import { getShowDashboard } from 'containers/dashboard/selectors'
import ButtonContent from 'containers/mainPanel/panelDrawer/ButtonContent'
import ButtonText from 'containers/mainPanel/panelDrawer/ButtonText'
import { clearDrawer } from 'containers/mainPanel/panelDrawer/actions'

const DashboardPicker = (props) => {
  const { showDashboard, hideDashboard, getShowDashboard, clearDrawer } = props
  const [open, setOpen] = useState(false)

  const handleChangeDashboardVisibility = () => {
    if (!open) {
      showDashboard('swimlanes')
      clearDrawer()
    } else {
      hideDashboard()
    }
    setOpen(!open)
  }

  useEffect(() => {
    if (!getShowDashboard) {
      setOpen(false)
    }
  }, [getShowDashboard])

  return (
    <PanelButton
      iconName={icons.ROOM}
      onClick={handleChangeDashboardVisibility}
      active={open}
    >
      <ButtonContent>
        <ButtonText>
          <Translate id="billboard.mainPanel.roomOverview" />
        </ButtonText>
        <Icon name={open ? icons.PREVIOUS : icons.FORWARD} />
      </ButtonContent>
    </PanelButton>
  )
}

DashboardPicker.propTypes = {
  showDashboard: PropTypes.func,
  hideDashboard: PropTypes.func,
  getShowDashboard: PropTypes.string,
  clearDrawer: PropTypes.func,
}
const mapStateToProps = (state) => ({
  getShowDashboard: getShowDashboard(state),
})

export default connect(mapStateToProps, {
  showDashboard,
  hideDashboard,
  clearDrawer,
})(DashboardPicker)
