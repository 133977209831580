import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'

const IconHolder = styled.div`
  // height: 13px;
`
const NoIcon = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px 13px;
  ${(props) =>
    props.desk &&
    css`
      left: 50;
      position: relative;
    `};
  ${(props) =>
    props.building &&
    css`
      left: 50;
      position: relative;
    `};
  ${(props) =>
    props.icon === 'star' &&
    css`
      background-image: url(./star_filled.svg);
    `};
  ${(props) =>
    props.icon === 'building' &&
    css`
      width: 12px;
      height: 12x;
      background-size: 12px 12px;
      background-image: url(./building2.svg);
    `};
`

const StatisticsItem = ({ value, hasIcon, iconName }) => {
  return (
    <>
      {hasIcon && (
        <IconHolder>
          <Icon name={icons[iconName]} /> {value}
        </IconHolder>
      )}
      {!hasIcon && (
        <div>
          <NoIcon icon={iconName} />
          <span> {value}</span>
        </div>
      )}
    </>
  )
}

StatisticsItem.propTypes = {
  value: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconName: PropTypes.string,
}

export default StatisticsItem
