import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import { shadow6dp } from 'utils/elevations'

const StyledButton = styled.button`
  margin: 0 8px;
  color: ${(props) => props.theme.actionButton};
  background-color: ${(props) => props.theme.logoBg};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 18px;
  padding: 0;
  border: 0;
  pointer-events: auto;
  outline: 0;
  cursor: pointer;
  ${shadow6dp};

  ${(props) =>
    props.type === 'cancel' &&
    css`
      border-radius: 5px;
      font-weight: 600;
      width: 240px;
      height: 70px;
      font-size: 20px;
      background-color: ${(props) => props.theme.statusBooked};
    `};

  ${(props) =>
    props.visible === false &&
    css`
      display: none;
    `};
`

const FloatingActionButton = ({
  iconName,
  onClick,
  type,
  children,
  visible = true,
}) => (
  <StyledButton onClick={onClick} type={type} visible={visible}>
    <Icon name={iconName} />
    {children}
  </StyledButton>
)

FloatingActionButton.propTypes = {
  children: PropTypes.object,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  visible: PropTypes.bool,
}

export default FloatingActionButton
