import {
  OPEN_DRAWER,
  CLEAR_DRAWER,
  OPEN_INNER_DRAWER,
  CLEAR_INNER_DRAWER,
} from 'containers/mainPanel/panelDrawer/constants'

export const openDrawer = (name) => ({
  type: OPEN_DRAWER,
  name,
})

export const clearDrawer = () => ({
  type: CLEAR_DRAWER,
})

export const openInnerDrawer = (name) => ({
  type: OPEN_INNER_DRAWER,
  name,
})

export const clearInnerDrawer = () => ({
  type: CLEAR_INNER_DRAWER,
})
