import { createSelector } from 'reselect'

export const makeSelector = (selector) =>
  createSelector([selector], (selector) => selector)

const makeFlatEdgeSelector = (edge) =>
  createSelector([edge], (edge) => edge.valueSeq().flatten(true).toList())

const makeMetaTypeSelector = (ids, lookup, filterString) =>
  createSelector([ids, lookup], (ids, lookup) =>
    ids.filter((id) => {
      const item = lookup.get(`${id}`)
      return item.getIn(['space', 'desc']) === filterString
    }),
  )

const makeMetaPropSelector = (ids, lookup, filterStrings) =>
  createSelector([ids, lookup], (ids, lookup) =>
    ids.filter((id) => {
      const item = lookup.get(`${id}`)
      return filterStrings.includes(item.get('asset_type'))
    }),
  )

const makeTypeFromSpacesSelector = (typeIds, spaceIdsOnFloors, spaces) =>
  createSelector(
    [typeIds, spaceIdsOnFloors, spaces],
    (typeIds, spaceIdsOnFloors, spaces) =>
      spaceIdsOnFloors.map((spaceIdsOnFloor) =>
        spaceIdsOnFloor.filter((id) => {
          const space = spaces.get(`${id}`)
          return typeIds.find((deskId) => space.get('typeId') === deskId)
        }),
      ),
  )
//----------- UTILITY

const baseUrl = (state) => state.getIn(['app', 'baseUrl'])
export const getBaseUrl = makeSelector(baseUrl)

//----------- GENERIC

const isFetching = (state) => state.getIn(['app', 'updates', 'isFetching'])
const hasFetched = (state) => state.getIn(['app', 'updates', 'hasFetched'])
const pollingActive = (state) =>
  state.getIn(['app', 'updates', 'pollingActive'])
export const getIsFetching = makeSelector(isFetching)
export const getHasFetched = makeSelector(hasFetched)
export const getPollingActive = makeSelector(pollingActive)

//----------- AUTH

const isAuthenticated = (state) =>
  state.getIn(['app', 'auth', 'isAuthenticated'])
const token = (state) => state.getIn(['app', 'auth', 'token'])
const stamp = (state) => state.getIn(['app', 'updates', 'stamp'])
const floorStamps = (state) => state.getIn(['app', 'updates', 'floorStamps'])
const clientFeatures = (state) =>
  state.getIn(['app', 'clientConfig', 'features'])
const clientAuthentication = (state) =>
  state.getIn(['app', 'clientConfig', 'authentication'])
const userConsent = (state) => state.getIn(['app', 'userConsent'])
const tokenForUnity = (state) => state.getIn(['app', 'tokenForUnity'])
const startupFlow = (state) => state.getIn(['app', 'startupFlow'])
const futureDeskBookingDays = (state) => {
  const features = clientFeatures(state)
  if (!features) return undefined
  const amountOfDaysStr = features.getIn([
    'deskFinder',
    'meta',
    'futureBooking',
  ])
  return parseInt(amountOfDaysStr, 10)
}
const futureDeskbookingEnabled = (state) => futureDeskBookingDays(state) > 0

export const getIsAuthenticated = makeSelector(isAuthenticated)
export const getAuthToken = makeSelector(token)
export const getStamp = makeSelector(stamp)
export const getFloorStamps = makeSelector(floorStamps)
export const getClientFeatures = makeSelector(clientFeatures)
export const getClientAuthentication = makeSelector(clientAuthentication)
export const getUserConsent = makeSelector(userConsent)
export const getTokenForUnity = makeSelector(tokenForUnity)
export const getStartupFlow = makeSelector(startupFlow)
export const getFutureDeskbookingDays = makeSelector(futureDeskBookingDays)
export const getFutureDeskbookingEnabled = makeSelector(
  futureDeskbookingEnabled,
)

//----------- LOGO
const logo = (state) =>
  state.getIn(['app', 'clientConfig', 'branding', 'webApp', 'logo'])
export const getLogo = makeSelector(logo)

//----------- SITES
const sites = (state) => state.getIn(['app', 'updates', 'sites', 'byId'])
const siteIdsOnCompany = (state) =>
  state.getIn(['app', 'updates', 'sites', 'sitesOnCompany'])

export const getSites = makeSelector(sites)
export const getSiteIdsOnCompany = makeSelector(siteIdsOnCompany)
export const getActiveSiteIds = makeFlatEdgeSelector(getSiteIdsOnCompany)

//----------- BUILDINGS

const currentBuildingId = (state) => state.getIn(['app', 'currentBuilding'])
const buildings = (state) =>
  state.getIn(['app', 'updates', 'buildings', 'byId'])
const buildingIdsOnSites = (state) =>
  state.getIn(['app', 'updates', 'buildings', 'buildingsOnSites'])

export const getCurrentBuildingId = makeSelector(currentBuildingId)
export const getBuildings = makeSelector(buildings)
export const getBuildingIdsOnSites = makeSelector(buildingIdsOnSites)
export const getActiveBuildingIds = makeFlatEdgeSelector(getBuildingIdsOnSites)

//----------- FLOORS

const currentFloorId = (state) => state.getIn(['app', 'currentFloor'])
const floors = (state) => state.getIn(['app', 'updates', 'floors', 'byId'])
const floorIdsOnBuildings = (state) =>
  state.getIn(['app', 'updates', 'floors', 'floorsOnBuildings'])
export const getCurrentFloorId = createSelector(
  [currentFloorId],
  (currentFloorId) => currentFloorId,
)

export const getFloors = makeSelector(floors)
export const getFloorIdsOnBuildings = makeSelector(floorIdsOnBuildings)
export const getActiveFloorIds = makeFlatEdgeSelector(getFloorIdsOnBuildings)

//----------- SELF

const selfLocation = (state) => state.getIn(['app', 'updates', 'self', 'loc'])
const selfId = (state) => state.getIn(['app', 'updates', 'self', 'id'])
const selfCalendarItems = (state) =>
  state.getIn(['app', 'updates', 'self', 'calendar', 'items'])
const selfClaims = (state) =>
  state.getIn(['app', 'updates', 'self', 'status', 'claims'])
const selfVisibility = (state) =>
  state.getIn(['app', 'updates', 'self', 'visibility'])
export const getSelfLocation = makeSelector(selfLocation)
export const getSelfId = makeSelector(selfId)
export const getSelfClaims = makeSelector(selfClaims)
export const getSelfCalendarItems = makeSelector(selfCalendarItems)
export const getSelfVisibility = makeSelector(selfVisibility)

//----------- USERS

const users = (state) => state.getIn(['app', 'updates', 'users', 'byId'])
const userIdsOnFloors = (state) =>
  state.getIn(['app', 'updates', 'users', 'usersOnFloors'])

export const getUsers = makeSelector(users)
export const getUserIdsOnFloors = makeSelector(userIdsOnFloors)
export const getActiveUserIds = makeFlatEdgeSelector(getUserIdsOnFloors)

//----------- SIGNS

const signs = (state) => state.getIn(['app', 'updates', 'signs', 'byId'])
const signIdsOnFloors = (state) =>
  state.getIn(['app', 'updates', 'signs', 'signsOnFloors'])

export const getSigns = makeSelector(signs)
export const getSignIdsOnFloors = makeSelector(signIdsOnFloors)
export const getActiveSignIds = makeFlatEdgeSelector(getSignIdsOnFloors)

//----------- SPACES

const spaces = (state) => state.getIn(['app', 'updates', 'spaces', 'byId'])
const futureDesks = (state) => state.getIn(['app', 'future', 'deskStatus'])
const futureRooms = (state) => state.getIn(['app', 'future', 'roomStatus'])
const futureUsers = (state) => state.getIn(['app', 'future', 'users'])
const spaceIdsOnFloors = (state) =>
  state.getIn(['app', 'updates', 'spaces', 'spacesOnFloors'])
const allowMultipleDesks = (state) =>
  state.getIn([
    'app',
    'clientConfig',
    'features',
    'deskFinder',
    'meta',
    'allowMultipleDesks',
  ]) === 'true'
const disableTimeSelection = (state) =>
  state.getIn([
    'app',
    'clientConfig',
    'features',
    'deskFinder',
    'meta',
    'disableTimeSelection',
  ]) === 'true'
const defaultStart = (state) =>
  state.getIn([
    'app',
    'clientConfig',
    'features',
    'deskFinder',
    'meta',
    'defaultStart',
  ])
const defaultEnd = (state) =>
  state.getIn([
    'app',
    'clientConfig',
    'features',
    'deskFinder',
    'meta',
    'defaultEnd',
  ])
export const getFutureDesks = makeSelector(futureDesks)
export const getFutureRooms = makeSelector(futureRooms)
export const getFutureUsers = makeSelector(futureUsers)
export const getSpaces = makeSelector(spaces)
export const getSpaceIdsOnFloors = makeSelector(spaceIdsOnFloors)
export const getActiveSpaceIds = makeFlatEdgeSelector(getSpaceIdsOnFloors)
export const getAllowMultipleDesks = makeSelector(allowMultipleDesks)
export const getDisableTimeSelection = makeSelector(disableTimeSelection)
export const getDefaultStart = makeSelector(defaultStart)
export const getDefaultEnd = makeSelector(defaultEnd)

//----------- META PROPS

const metaProps = (state) =>
  state.getIn(['app', 'updates', 'metaProps', 'byId'])
const activeMetaPropIds = (state) =>
  state.getIn(['app', 'updates', 'metaProps', 'active'])

export const getMetaProps = makeSelector(metaProps)
export const getActiveMetaPropIds = makeSelector(activeMetaPropIds)
export const getDeskMetaPropsIds = makeMetaPropSelector(
  getActiveMetaPropIds,
  getMetaProps,
  ['DESK', 'ALL'],
)
export const getRoomMetaPropsIds = makeMetaPropSelector(
  getActiveMetaPropIds,
  getMetaProps,
  ['ROOM', 'ALL'],
)

const equipment = (state) => state.getIn(['app', 'updates', 'equipment'])
export const getEquipment = makeSelector(equipment)

//----------- META TYPES

const metaTypes = (state) =>
  state.getIn(['app', 'updates', 'metaTypes', 'byId'])
const activeMetaTypeIds = (state) =>
  state.getIn(['app', 'updates', 'metaTypes', 'active'])

export const getMetaTypes = makeSelector(metaTypes)
export const getActiveMetaTypeIds = makeSelector(activeMetaTypeIds)
export const getDeskTypeIds = makeMetaTypeSelector(
  getActiveMetaTypeIds,
  getMetaTypes,
  'DESK',
)
export const getRoomTypeIds = makeMetaTypeSelector(
  getActiveMetaTypeIds,
  getMetaTypes,
  'ROOM',
)

//----------- DESKS

export const getDeskIdsOnFloors = makeTypeFromSpacesSelector(
  getDeskTypeIds,
  getSpaceIdsOnFloors,
  getSpaces,
)
export const getActiveDeskIds = makeFlatEdgeSelector(getDeskIdsOnFloors)

//----------- ROOMS

export const getRoomIdsOnFloors = makeTypeFromSpacesSelector(
  getRoomTypeIds,
  getSpaceIdsOnFloors,
  getSpaces,
)
export const getActiveRoomIds = makeFlatEdgeSelector(getRoomIdsOnFloors)

//----------- FILTER FILTERS
const makeFilterFromFiltersSelector = (
  metaPropsIds,
  spaceIds,
  spaces,
  currentFloorId,
) =>
  createSelector(
    [metaPropsIds, spaceIds, spaces, currentFloorId],
    (metaPropsIds, spaceIds, spaces, currentFloorId) =>
      metaPropsIds.filter((metaPropsId) =>
        spaceIds.find((id) => {
          const spaceFloorId = spaces.getIn([`${id}`, 'floorId'])
          const spaceProps = spaces.getIn([`${id}`, 'info', 'props'])
          if (!spaceProps) return null
          return spaceFloorId === currentFloorId
            ? spaceProps.find(
                (spaceProp) => metaPropsId === spaceProp.get('id'),
              )
            : null
        }),
      ),
  )

const makeFilterFromFiltersSelectorEquip = (
  equipmentIds,
  spaceIds,
  spaces,
  currentFloorId,
) =>
  createSelector(
    [equipmentIds, spaceIds, spaces, currentFloorId],
    (equipmentIds, spaceIds, spaces, currentFloorId) =>
      [...equipmentIds.add].filter((metaPropsId) =>
        spaceIds.find((id) => {
          const spaceFloorId = spaces.getIn([`${id}`, 'floorId'])
          const spaceEquips = spaces.getIn([`${id}`, 'info', 'equips'])
          if (!spaceEquips) return null
          return spaceFloorId === currentFloorId
            ? spaceEquips.find((spaceProp) => metaPropsId === spaceProp)
            : null
        }),
      ),
  )

export const getDeskFilters = makeFilterFromFiltersSelector(
  getDeskMetaPropsIds,
  getActiveDeskIds,
  getSpaces,
  getCurrentFloorId,
)

export const getDeskFiltersEquip = makeFilterFromFiltersSelectorEquip(
  getEquipment,
  getActiveDeskIds,
  getSpaces,
  getCurrentFloorId,
)
export const getRoomFiltersEquip = makeFilterFromFiltersSelectorEquip(
  getEquipment,
  getActiveRoomIds,
  getSpaces,
  getCurrentFloorId,
)
export const getRoomFilters = makeFilterFromFiltersSelector(
  getRoomMetaPropsIds,
  getActiveRoomIds,
  getSpaces,
  getCurrentFloorId,
)
