import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setFloor } from 'containers/app/actions'
import {
  getCurrentFloorId,
  getCurrentBuildingId,
  getFloorIdsOnBuildings,
  getFloors,
} from 'containers/app/selectors'
import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import FloorItem from 'containers/mainPanel/floorPicker/FloorItem'
import { icons } from 'components/icon/icons'

class FloorPicker extends Component {
  changeFloor = (floorId) => {
    const { currentFloorId, setFloor } = this.props

    if (floorId !== currentFloorId) setFloor(floorId)
  }

  render() {
    const {
      floors,
      currentFloorId,
      floorIdsOnBuildings,
      currentBuildingId,
    } = this.props

    const currentFloor = floors.get(`${currentFloorId}`)
    const floorIdsOnCurrentBuilding = floorIdsOnBuildings.get(
      `${currentBuildingId}`,
    )

    if (!currentFloor || !floorIdsOnCurrentBuilding) return null

    const activeIndex = floorIdsOnCurrentBuilding.findIndex(
      (id) => id === currentFloorId,
    )

    const floorData = floorIdsOnCurrentBuilding.map((floorId) =>
      floors.get(`${floorId}`),
    )

    const sortedFloorIds = floorData
      .sort((a, b) => b.getIn(['info', 'level']) - a.getIn(['info', 'level']))
      .map((floor) => {
        return floor.get('id')
      })

    return (
      <PanelDrawer
        iconName={icons.FLOORS}
        label={currentFloor ? currentFloor.getIn(['info', 'name']) : null}
        activeIndex={activeIndex}
        size={sortedFloorIds.size}
        allowToOpen
      >
        {sortedFloorIds.map((id) => (
          <FloorItem
            key={id}
            id={id}
            active={id === currentFloorId}
            onClick={this.changeFloor}
          />
        ))}
      </PanelDrawer>
    )
  }
}

FloorPicker.propTypes = {
  currentBuildingId: PropTypes.number,
  currentFloorId: PropTypes.number,
  floorIdsOnBuildings: PropTypes.object,
  floors: PropTypes.object,
  setFloor: PropTypes.func,
}

const mapStateToProps = (state) => ({
  floors: getFloors(state),
  currentFloorId: getCurrentFloorId(state),
  floorIdsOnBuildings: getFloorIdsOnBuildings(state),
  currentBuildingId: getCurrentBuildingId(state),
})

export default connect(mapStateToProps, { setFloor })(FloorPicker)
