import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { format } from 'date-fns'
import englishLocale from 'date-fns/locale/en-US'
import swedishLocale from 'date-fns/locale/sv'
import norwegianLocale from 'date-fns/locale/nb'
import frenchLocale from 'date-fns/locale/fr'
import germanLocale from 'date-fns/locale/de'
import spanishLocale from 'date-fns/locale/es'
import InfiniteScroll from 'react-infinite-scroll-component'

import { setDateFilter } from 'containers/searchPanel/filter/actions'
import Icon from 'components/Icon'
import { getIsFetching } from 'containers/quickView/spaceModal/claimFlow/selectors'
import { getBuildings, getCurrentBuildingId } from 'containers/app/selectors'
import {
  fetchAttendanceОverviewRequest,
  registerAttendanceRequest,
  fetchSelfAttendance,
  deleteAttendance,
  fetchAttendanceCapacityRequest,
  fetchAttendanceDetails,
  fetchUniqueImages,
  changeModalPosition,
  clearFlowPlannerState,
  clearDailyState,
  openAttendancePopup,
  closeAttendancePopup,
} from 'containers/flowPlanner/actions'
import {
  getAttendanceDataset,
  getAttendanceCapacity,
  getAttendanceDatasetDaysOnly,
  getShowMessage,
  getAttendanceDetailsUsersOnly,
  getActiveAttendancePopup,
} from 'containers/flowPlanner/selectors'
import MainInfoCard from 'containers/flowPlanner/MainInfoCard'
import MainStatistics from 'containers/flowPlanner/MainStatistics'
import BookingPopup from 'containers/flowPlanner/BookingPopup'
import DayDetails from 'containers/flowPlanner/DayDetails'
import { icons } from 'components/icon/icons'
import {
  clearDrawer,
  openDrawer,
} from 'containers/mainPanel/panelDrawer/actions'
import { getActiveDrawer } from 'containers/mainPanel/panelDrawer/selectors'
import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'
import { getLocalDate } from 'utils/utilsFunctions'

const localeMap = {
  en: englishLocale,
  sv: swedishLocale,
  no: norwegianLocale,
  fr: frenchLocale,
  de: germanLocale,
  es: spanishLocale,
}

const OpenPlanner = styled.div.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  z-index: 1;
  height: 91%;
  position: fixed;
  border: 1px solid #fff;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 2px 4px 0 rgba(212, 212, 212, 0.5);
  background-color: #fff;
  pointer-events: auto;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  box-sizing: border-box;
  top: 4%;
  left: 224px;
  overflow: hidden;
  padding-left: 5px;
  color: ${(props) => props.theme.darkText};
  ${(props) =>
    props.buttonVariant &&
    css`
      display: none;
    `}
  ${(props) =>
    props.windowVariant &&
    css`
      width: 500px;
      max-height: 775px;
    `}
`

const PlannerHeader = styled.p`
  display: inline-block;
  margin: 16px 0 0 0;
`
const ActionButton = styled.button`
  position: absolute;
  top: 18px;
  right: 5px;
  border: none;
  outline: none;
  font-size: 18px;
  background-color: transparent;
  z-index: 2;
  :hover {
    cursor: pointer;
  }
  i {
    font-weight: 800;
  }
  ${(props) =>
    props.closedMenu &&
    css`
      top: 0;
      height: 100%;
      i {
        transform: rotate(180deg);
      }
    `};
  ${(props) =>
    !props.closedMenu &&
    css`
      i {
        font-size: 23px;
    `};
`
const InfoHolder = styled.div`
  width: 100%;
  height: 78%;
`

const BuildingInfoHolder = styled.div`
  font-family: Roboto;
  font-size: 14px;
  margin: 18px 0 0 0;
  line-height: 0.5;
  color: ${(props) => props.theme.darkText};
  p {
    margin: 10px 0 0 0;
  }
  p:last-child {
    font-weight: 300;
  }
`
const DateHeader = styled.p`
  display: inline-block;
  margin: 40px 0 15px 0;
  font-size: 16px;
  text-transform: capitalize;
`

const CardHolder = styled.div.attrs({
  id: 'cardHolder',
})`
  height: 100%;
  width: auto;
  overflow: auto;
  display: flex;
  flexdirection: column-reverse;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fff;
    background-color: #d8d8d8;
    border-radius: 7px;
  }
  > div {
    width: 100%;
  }
  > div > div {
    overflow-x: hidden !important;
  }
  .infinite-scroll-component {
    overflow: hidden !important;
  }
`
const flowPlannerView = icons.FLOWPLANNER
class FlowPlanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openWindow: false,
      openRegisterForm: false,
      dateToShow: '',
      showDayDetails: false,
      dailyAttendaceData: {},
      hasMoreDays: true,
    }
    this.changeDate = this.changeDate.bind(this)
    this.changeWindowVisibility = this.changeWindowVisibility.bind(this)
    this.openBookingPopup = this.openBookingPopup.bind(this)
    this.hidePopup = this.hidePopup.bind(this)
    this.setDataInState = this.hidePopup.bind(this)
    this.openDayDetails = this.openDayDetails.bind(this)
    this.hideDetails = this.hideDetails.bind(this)
    this.deleteRegistration = this.deleteRegistration.bind(this)
    this.getNextDaysPage = this.getNextDaysPage.bind(this)
  }
  componentDidMount() {
    const {
      fetchAttendanceDetails,
      fetchSelfAttendance,
      fetchAttendanceОverviewRequest,
      fetchAttendanceCapacityRequest,
      currentBuildingId,
    } = this.props
    fetchAttendanceDetails(currentBuildingId)
    fetchSelfAttendance()
    fetchAttendanceОverviewRequest(currentBuildingId)
    fetchAttendanceCapacityRequest(currentBuildingId)
  }
  componentDidUpdate() {
    const { fetchUniqueImages, attendanceDatasetDaysOnly } = this.props
    let allFavorites = []

    if (attendanceDatasetDaysOnly.length > 0)
      attendanceDatasetDaysOnly.forEach((day) => {
        allFavorites = [
          ...allFavorites,
          ...day.selectedAttendance.map((attendance) => attendance.user.id),
        ]
      })

    fetchUniqueImages(allFavorites)
  }
  componentWillUnmount() {
    this.changeWindowVisibility()
  }

  changeDate(newDate) {
    const { setDateFilter } = this.props
    setDateFilter(newDate)
  }

  changeWindowVisibility() {
    const { clearFlowPlannerState, clearDrawer } = this.props

    this.setState({
      openRegisterForm: false,
      showDayDetails: false,
      hasMoreDays: true,
    })
    clearFlowPlannerState()
    clearDrawer()
  }

  openBookingPopup(dateToShow) {
    const { openAttendancePopup } = this.props
    openAttendancePopup()
    this.setState({
      dateToShow,
    })
  }
  deleteRegistration(eid) {
    const { deleteAttendance, currentBuildingId } = this.props
    deleteAttendance(eid, currentBuildingId)
  }
  hidePopup() {
    const { closeAttendancePopup } = this.props
    closeAttendancePopup()
  }

  openDayDetails(detailsCounts) {
    const {
      fetchAttendanceDetails,
      currentBuildingId,
      clearDailyState,
    } = this.props
    this.changeDate(detailsCounts.date)
    clearDailyState()
    fetchAttendanceDetails(currentBuildingId)

    this.setState({
      showDayDetails: true,
      dailyAttendaceData: detailsCounts,
    })
  }

  hideDetails() {
    const { changeModalPosition } = this.props
    changeModalPosition()
    this.setState({
      showDayDetails: false,
    })
  }

  getNextDaysPage() {
    const {
      fetchAttendanceОverviewRequest,
      currentBuildingId,
      attendanceDataset,
    } = this.props
    const currentPage = attendanceDataset.page

    currentPage < attendanceDataset.pages
      ? fetchAttendanceОverviewRequest(currentBuildingId, currentPage + 1)
      : this.setState({
          hasMoreDays: false,
        })
  }

  render() {
    const {
      buildings,
      currentBuildingId,
      activeLanguage,
      buildingCapacity,
      attendanceDatasetDaysOnly,
      showMessage,
      activeDrawer,
      timeFormat,
      isFetching,
      isAttendancePopupOpen,
    } = this.props
    const openWindow = activeDrawer === flowPlannerView
    const currentLocale = activeLanguage
      ? localeMap[activeLanguage.code]
      : englishLocale

    const currentBuilding = buildings.get(`${currentBuildingId}`)
    if (!currentBuilding || !openWindow) return null

    const currentMonthYear = format(new Date(), 'MMMM yyyy', {
      locale: currentLocale,
    })
    return (
      <OpenPlanner buttonVariant={!openWindow} windowVariant={openWindow}>
        <PlannerHeader>FlowPlanner</PlannerHeader>

        <ActionButton
          closedMenu={!openWindow}
          onClick={(e) => {
            this.changeWindowVisibility()
            return e.stopPropagation()
          }}
        >
          <Icon name={icons.CLOSE} />
        </ActionButton>
        {openWindow && (
          <InfoHolder>
            <BuildingInfoHolder>
              <p>
                {currentBuilding
                  ? currentBuilding.getIn(['info', 'name'])
                  : null}
              </p>
              <MainStatistics values={buildingCapacity} />
            </BuildingInfoHolder>
            <DateHeader>{currentMonthYear}</DateHeader>
            <CardHolder>
              <InfiniteScroll
                dataLength={attendanceDatasetDaysOnly.length}
                next={this.getNextDaysPage}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                hasMore={this.state.hasMoreDays}
                loader={<h4>Loading...</h4>}
                scrollableTarget="cardHolder"
              >
                {attendanceDatasetDaysOnly.map((item, index) => (
                  <MainInfoCard
                    registerAttendanceAction={this.openBookingPopup}
                    deleteRegistration={this.deleteRegistration}
                    key={index}
                    dailyAttendaceData={item}
                    showMessage={showMessage}
                    openDetails={this.openDayDetails}
                    selectDate={this.changeDate}
                    currentLocale={currentLocale}
                    buildingId={currentBuildingId}
                    capacity={{
                      desks: buildingCapacity.desks,
                      people: buildingCapacity.capacity,
                    }}
                    disabled={isFetching}
                  />
                ))}
              </InfiniteScroll>
            </CardHolder>
          </InfoHolder>
        )}
        {openWindow && isAttendancePopupOpen && (
          <BookingPopup
            selectedDate={this.state.dateToShow}
            cancel={this.hidePopup}
            currentLocale={currentLocale}
            buildingId={currentBuildingId}
            timeFormat={timeFormat}
          />
        )}
        {openWindow && this.state.showDayDetails && (
          <DayDetails
            dailyAttendaceData={this.state.dailyAttendaceData}
            hideDetails={this.hideDetails}
            currentLocale={currentLocale}
            buildingId={currentBuildingId}
            attendanceDatasetDaysOnly={attendanceDatasetDaysOnly}
          />
        )}
      </OpenPlanner>
    )
  }
}

FlowPlanner.propTypes = {
  setDateFilter: PropTypes.func,
  buildings: PropTypes.object,
  currentBuildingId: PropTypes.number,
  fetchAttendanceОverviewRequest: PropTypes.func,
  attendanceDataset: PropTypes.object,
  activeLanguage: PropTypes.object,
  isFetching: PropTypes.bool,
  fetchSelfAttendance: PropTypes.func,
  deleteAttendance: PropTypes.func,
  fetchAttendanceCapacityRequest: PropTypes.func,
  buildingCapacity: PropTypes.object,
  attendanceDatasetDaysOnly: PropTypes.array,
  fetchAttendanceDetails: PropTypes.func,
  showMessage: PropTypes.string,
  fetchUniqueImages: PropTypes.func,
  changeModalPosition: PropTypes.func,
  clearFlowPlannerState: PropTypes.func,
  clearDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  clearDailyState: PropTypes.func,
  timeFormat: PropTypes.string,
  activeDrawer: PropTypes.string,
  attendanceDetailsUsersOnly: PropTypes.array,
  openAttendancePopup: PropTypes.func,
  isAttendancePopupOpen: PropTypes.bool,
  closeAttendancePopup: PropTypes.func,
}

const mapStateToProps = (state) => ({
  buildings: getBuildings(state),
  currentBuildingId: getCurrentBuildingId(state),
  isFetching: getIsFetching(state),
  attendanceDataset: getAttendanceDataset(state),
  buildingCapacity: getAttendanceCapacity(state),
  attendanceDatasetDaysOnly: getAttendanceDatasetDaysOnly(state),
  showMessage: getShowMessage(state),
  activeDrawer: getActiveDrawer(state),
  timeFormat: getTimeFormat(state),
  attendanceDetailsUsersOnly: getAttendanceDetailsUsersOnly(state),
  isAttendancePopupOpen: getActiveAttendancePopup(state),
})

export default withLocalize(
  connect(mapStateToProps, {
    setDateFilter,
    fetchAttendanceОverviewRequest,
    registerAttendanceRequest,
    fetchSelfAttendance,
    deleteAttendance,
    fetchAttendanceCapacityRequest,
    fetchAttendanceDetails,
    fetchUniqueImages,
    changeModalPosition,
    clearFlowPlannerState,
    clearDrawer,
    openDrawer,
    clearDailyState,
    openAttendancePopup,
    closeAttendancePopup,
  })(FlowPlanner),
)
