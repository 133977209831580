import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  getEquipmentInfo,
  getEquipmentIcons,
} from 'containers/searchPanel/results/selectors'

const StyledProp = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.darkText};

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
  }
`

const StyledIcon = styled.img`
  height: 25px;
  background-color: transparent;
  background-position: center;
  margin-bottom: 5px;
`

const SpaceProp = ({ caption, icons, id }) => {
  return (
    <StyledProp>
      <StyledIcon src={icons[id]} alt="icon" />
      <span>{caption}</span>
    </StyledProp>
  )
}

SpaceProp.propTypes = {
  caption: PropTypes.string,
  icons: PropTypes.object,
  id: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const equipment = getEquipmentInfo(state)
  const equipmentItem = equipment.get(`${props.id}`)

  return {
    caption: equipmentItem.name,
    icons: getEquipmentIcons(state),
  }
}

export default connect(mapStateToProps)(SpaceProp)
