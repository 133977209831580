import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import { BrowserRouter } from 'react-router-dom'

import App from 'containers/App'

const Root = ({ store }) => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={store}>
      <LocalizeProvider
        store={store}
        getState={(state) => state.get('localize')}
      >
        <App />
      </LocalizeProvider>
    </Provider>
  </BrowserRouter>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
