import styled from 'styled-components'

import Icon from 'components/Icon'

const SpaceCapacity = styled.p`
  display: flex;
  flex: 0 0 24px;
  font-size: 16px;
  margin-left: 16px;

  ${Icon} {
    margin: 0 8px 0 0;
  }
`

export default SpaceCapacity
