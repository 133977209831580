import {
  takeEvery,
  select,
  all,
  call,
  put,
  take,
  delay,
} from 'redux-saga/effects'
import Alert from 'react-s-alert'

import * as personal from 'api/personal'
import {
  FETCH_PERSONAL_CALENDAR_REQUEST,
  FETCH_PERSONAL_CALENDAR_SUCCESS,
  FETCH_PERSONAL_CALENDAR_FAILURE,
  DELETE_PERSONAL_EVENT_REQUEST,
  DELETE_PERSONAL_EVENT_FAILURE,
  DELETE_PERSONAL_EVENT_SUCCESS,
} from 'containers/mainPanel/bookedResources/constants'
import {
  fetchPersonalCalendar,
  fetchPersonalCalendarSuccess,
  fetchPersonalCalendarFailure,
  deletePersonalEventFailure,
  closeConfirmationPopup,
  deletePersonalEventSuccess,
} from 'containers/mainPanel/bookedResources/actions'
import {
  authenticateFailure,
  startLocate,
  startFilter,
} from 'containers/app/actions'

function* fetchPersonalCalendarRequest() {
  try {
    const response = yield call(personal.getPersonalCalendar)
    yield put(fetchPersonalCalendarSuccess(response.events))
  } catch ({ error, response }) {
    yield put(fetchPersonalCalendarFailure(error.message))
    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* deletePersonalEvent({ eid }) {
  try {
    const response = yield call(personal.deletePersonalEvent, eid)
    yield delay(1000)
    yield all([
      put(fetchPersonalCalendar()),
      put(closeConfirmationPopup()),
      put(deletePersonalEventSuccess()),
    ])
  } catch ({ error, response }) {
    yield put(deletePersonalEventFailure(error.message))
    if (response.status === 401) {
      yield put(authenticateFailure())
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* watchFetchPersonalCalendar() {
  yield takeEvery(FETCH_PERSONAL_CALENDAR_REQUEST, fetchPersonalCalendarRequest)
}

function* watchDeletePersonalEvent() {
  yield takeEvery(DELETE_PERSONAL_EVENT_REQUEST, deletePersonalEvent)
}

function* personalData() {
  yield all([watchFetchPersonalCalendar(), watchDeletePersonalEvent()])
}
export default personalData
