import styled, { css, keyframes } from 'styled-components'
import 'components/icon/flowyfont.css'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Icon = styled.i.attrs(({ name }) => ({
  className: `icon-${name}`,
}))`
  width: 1em;
  display: inline-block;
  margin: 0 0.2em;
  text-align: center;
  cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  ${(props) =>
    props.rotating &&
    css`
      animation: ${rotate} 1.5s linear infinite;
    `};

  ${(props) =>
    props.backBtn &&
    css`
      display: block;
      margin: 0 5px 0 0;
      font-size: 24px;
    `};
`

export default Icon
