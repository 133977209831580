import styled from 'styled-components'

const ResultBar = styled.div`
  background-color: ${(props) => props.theme.logoBg};
  color: ${(props) => props.theme.panelColor};
  padding: 8px 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  span {
    margin: 0 8px;
  }
`

export default ResultBar
