import React, { Component } from 'react'
import { withLocalize, Translate } from 'react-localize-redux'
import styled from 'styled-components'

import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'

const VersionContainer = styled.div.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  background-color: ${(props) => props.theme.darkBgr};
  border-color: ${(props) => props.theme.panelBorder};
  color: ${(props) => props.theme.actionButton};
  padding: 0 16px;
  height: 30px;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
`

class VersionInformation extends Component {
  render() {
    return (
      <VersionContainer>
        <p>
          <Translate id="billboard.mainPanel.version" />:
          {` ${process.env.REACT_APP_VERSION}`}
        </p>
      </VersionContainer>
    )
  }
}
VersionInformation.propTypes = {}

export default withLocalize(VersionInformation)
