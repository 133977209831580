const defaultSettings = {
  debug: false,
  color: {
    background: {
      backgroundColor: {
        r: 255,
        g: 252,
        b: 250,
        a: 255,
      },
      backgroundImageURL: './holiday.jpg',
    },
    building: {
      wallSolid: {
        r: 100,
        g: 100,
        b: 100,
        a: 255,
      },
      wallTransparent: {
        r: 100,
        g: 100,
        b: 100,
        a: 120,
      },
      floor: {
        r: 255,
        g: 255,
        b: 255,
        a: 255,
      },
      furniture: {
        r: 180,
        g: 180,
        b: 180,
        a: 255,
      },
      door: {
        r: 220,
        g: 220,
        b: 220,
        a: 255,
      },
    },
    space: {
      free_nonBookable: {
        r: 10,
        g: 188,
        b: 139,
        a: 2555,
      },
      blocked: {
        r: 152,
        b: 152,
        g: 152,
        a: 255,
      },
      free_bookable: {
        r: 10,
        g: 188,
        b: 139,
        a: 255,
      },
      busy: {
        r: 255,
        g: 212,
        b: 0,
        a: 255,
      },
      booked: {
        r: 255,
        g: 62,
        b: 62,
        a: 255,
      },
      offline: {
        r: 152,
        g: 152,
        b: 152,
        a: 255,
      },
      filterAlpha: 80,
    },
  },
  UI: {
    signScale: 0.75,
    labelScale: 0.7,
    areaLabelScale: 0.7,
    coloredUserLabel: false,
  },
  camera: {
    defaultAngle: '3d',
    buildingYaw: 35,
    defaultRot: 1,
    floorYaw: 60,
    sidesPadding: 736,
  },
  input: {
    canRotate: true,
    swipeMinDelta: 0.65,
    minMoveDelta: 0.01,
    minMoveDistance: 0.05,
    maxRotationDelta: 5,
    panFriction: 5,
    padding: {
      debug: false,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  application: {
    quality: 'LOW',
    defaultFramerate: 60,
    meshSelectAnimation: true,
    fixedIcons: false,
    wallHeight: 0.7,
    showAreas: true,
    showUsers: true,
    showDesks: true,
    showRooms: true,
    showSigns: true,
  },
}

export default defaultSettings
