import styled from 'styled-components'

import { shadow8dp } from 'utils/elevations'

const SearchPanelWrapper = styled.aside`
  grid-area: SearchPanel;
  background-color: ${(props) => props.theme.panelBg};
  color: ${(props) => props.theme.panelColor};
  pointer-events: auto;
  ${shadow8dp};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
`

export default SearchPanelWrapper
