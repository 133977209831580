import {
  FETCH_PROFILE_PICTURE_REQUEST,
  FETCH_PROFILE_PICTURE_SUCCESS,
  FETCH_PROFILE_PICTURE_FAILURE,
  SET_ACTIVE_BY_INDEX,
  CLEAR_ACTIVE_BY_INDEX,
  RESULT_ASSET_CLICK,
  GET_SELECTED_COLLEAGUES,
  GET_SELECTED_COLLEAGUES_SUCCESS,
  SELECT_A_COLLEAGUE,
  DELETE_SELECTED_COLLEAGUE,
  FETCH_EQUIPMENT_PICTURE_REQUEST,
  FETCH_EQUIPMENT_PICTURE_SUCCESS,
  FETCH_EQUIPMENT_PICTURE_FAILURE,
  FETCH_EQUIPMENT_INFO_REQUEST,
  FETCH_EQUIPMENT_INFO_SUCCESS,
  FETCH_EQUIPMENT_INFO_FAILURE,
} from 'containers/searchPanel/results/constants'

export const fetchProfilePicture = (userId) => ({
  type: FETCH_PROFILE_PICTURE_REQUEST,
  userId,
})

export const fetchProfilePictureFailure = (userId) => ({
  type: FETCH_PROFILE_PICTURE_FAILURE,
  userId,
})

export const fetchProfilePictureSuccess = (objectUrl, userId) => ({
  type: FETCH_PROFILE_PICTURE_SUCCESS,
  imageUrl: objectUrl,
  userId,
})

export const fetchEquipment = (equipId) => ({
  type: FETCH_EQUIPMENT_INFO_REQUEST,
  equipId,
})

export const fetchEquipmentFailure = (equipId) => ({
  type: FETCH_EQUIPMENT_INFO_FAILURE,
  equipId,
})

export const fetchEquipmentSuccess = (equipInfo, equipId) => ({
  type: FETCH_EQUIPMENT_INFO_SUCCESS,
  equipInfo,
  equipId,
})

export const fetchEquipmentIcon = (equipId) => ({
  type: FETCH_EQUIPMENT_PICTURE_REQUEST,
  equipId,
})

export const fetchEquipmentIconFailure = (equipId) => ({
  type: FETCH_EQUIPMENT_PICTURE_FAILURE,
  equipId,
})

export const fetchEquipmentIconSuccess = (equipIcon, equipId) => ({
  type: FETCH_EQUIPMENT_PICTURE_SUCCESS,
  equipIcon,
  equipId,
})

export const setActiveByIndex = (index) => ({
  type: SET_ACTIVE_BY_INDEX,
  index,
})

export const clearActiveByIndex = () => ({
  type: CLEAR_ACTIVE_BY_INDEX,
})

export const clickAsset = (space) => ({
  type: RESULT_ASSET_CLICK,
  space,
})

export const getSelectedColleagues = () => ({
  type: GET_SELECTED_COLLEAGUES,
})
export const getSelectedColleaguesSuccess = (users) => ({
  type: GET_SELECTED_COLLEAGUES_SUCCESS,
  users,
})

export const addUserToSelected = (userId) => ({
  type: SELECT_A_COLLEAGUE,
  userId,
})

export const deleteUserFromSelected = (userId) => ({
  type: DELETE_SELECTED_COLLEAGUE,
  userId,
})
