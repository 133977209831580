import styled from 'styled-components'

const DataHolder = styled.div`
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${(props) => props.height};

  div:last-child {
    border-bottom: none;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fff;
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`

export default DataHolder
