export class UpdateCache {
  constructor() {
    this.cache = []
  }

  add = (value) => {
    this.cache.push(value)
  }

  load = (callback) => {
    this.cache.map(callback)
    this.cache = []
  }
}
