import {
  takeEvery,
  takeLatest,
  all,
  select,
  call,
  put,
  take,
  delay,
} from 'redux-saga/effects'
import { getUnixTime } from 'date-fns'
import Alert from 'react-s-alert'

import * as argus from 'api/argus'
import * as desks from 'api/desks'
import {
  SET_FLOOR,
  FETCH_UPDATE_REQUEST,
  SET_BUILDING,
  FETCH_DESK_BOOKINGS_REQUEST,
  FETCH_UPDATE_SUCCESS,
  FETCH_CLIENT_CONFIG_SUCCESS,
  FETCH_UNITY_TOKEN_REQUEST,
} from 'containers/app/constants'
import {
  getIsFuture,
  getDateFilter,
} from 'containers/searchPanel/filter/selectors'
import {
  updateRequest,
  updateSuccess,
  updateFailure,
  authenticateFailure,
  clearUpdates,
  updateDeskBookingsSuccess,
  updateDeskBookingsFailure,
  fetchFutureViewRequest,
  fetchUnityTokenSuccess,
} from 'containers/app/actions'
import {
  getStamp,
  getFloorStamps,
  getFutureDeskbookingEnabled,
  getEquipment,
  getCurrentFloorId,
} from 'containers/app/selectors'
import { UNITY_PANNED_FLOOR } from 'containers/unityLoader/constants'
import { clearQuickView } from 'containers/quickView/actions'
import {
  clearFilter,
  clearDataset,
} from 'containers/searchPanel/filter/actions'
import {
  getSelectedColleagues,
  fetchEquipment,
  fetchEquipmentIcon,
} from 'containers/searchPanel/results/actions'
import { CHANGE_VISIBILITY } from 'containers/mainPanel/visibility/constants'
import { visibilityChanged } from 'containers/mainPanel/visibility/actions'

function* update({ floorIds, resetMap, isPrevDateInFuture }) {
  const stamp = yield select(getStamp)
  const floorStamps = yield select(getFloorStamps)
  try {
    const response = yield call(
      argus.fetchUpdate,
      stamp,
      floorIds,
      floorIds.map((id) => floorStamps[id] || 0),
      resetMap,
    )
    const floorsWithSpaces = {}
    const mergedSpacesResponse = response
      ? JSON.parse(JSON.stringify(response))
      : null
    if (response && response.spaces) {
      response.spaces.forEach((spaceType) => {
        if (floorsWithSpaces[spaceType.parentId]) {
          floorsWithSpaces[spaceType.parentId].add.push(...spaceType.add)
          floorsWithSpaces[spaceType.parentId].remove.push(...spaceType.remove)
          floorsWithSpaces[spaceType.parentId].update.push(...spaceType.update)
        } else {
          floorsWithSpaces[spaceType.parentId] = {
            ...spaceType,
          }
        }

        mergedSpacesResponse.spaces = []
        for (const floor in floorsWithSpaces) {
          mergedSpacesResponse.spaces.push(floorsWithSpaces[floor])
        }
      })
    }

    //select unique equipment:
    const currentAvailableEquipment = yield select(getEquipment)

    const equipment = {
      floorId: null,
      add: new Set(),
      remove: new Set(),
      update: new Set(),
      existing: new Set(),
    }
    if (mergedSpacesResponse.spaces) {
      equipment.floorId = mergedSpacesResponse.spaces[0].floorId
      //add equipment
      const tempArr = []
      mergedSpacesResponse.spaces[0].add.forEach((item) => {
        if (item.info.equips && item.info.equips.length) {
          tempArr.push(...item.info.equips)
        }
      })
      equipment.add = new Set(tempArr)
    }

    //keeps only the equipment that is not removed
    equipment.existing = equipment.add

    for (const element of equipment.remove) {
      equipment.existing.delete(element)
    }

    //find differences in the prev saved equipment and the new one:
    let difference = new Set()
    if (currentAvailableEquipment.add && currentAvailableEquipment.add.size) {
      equipment.add.forEach((element) => {
        if (!currentAvailableEquipment.add.has(element)) {
          difference.add(element)
        }
      })
    } else {
      difference = equipment.add
    }

    for (const id of difference) {
      yield all([put(fetchEquipment(id)), put(fetchEquipmentIcon(id))])
    }
    // //

    yield all([
      put(updateSuccess(mergedSpacesResponse, floorIds, equipment)),
      put(getSelectedColleagues()),
    ])
    if (isPrevDateInFuture && !mergedSpacesResponse) {
      yield put(fetchFutureViewRequest())
    }
    if (process.env.NODE_ENV === 'development') {
      console.info('-- NEW UPDATE -----------------------------')
    }
  } catch ({ error, response }) {
    const message = error
      ? error.message
      : 'Could not fetch update from the server.'
    yield put(updateFailure(message))
    console.error(message)
    if (response && response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* updateDeskBookings() {
  const deskBookingEnabled = yield select(getFutureDeskbookingEnabled)
  if (!deskBookingEnabled) return null
  try {
    const response = yield call(desks.getAllUserBookings)
    yield put(updateDeskBookingsSuccess(response))
  } catch ({ error, response }) {
    const message = error
      ? error.message
      : 'Could not fetch update from the server.'
    yield put(updateDeskBookingsFailure(message))
    console.error(message)
  }
}

function* fetchUnityToken() {
  try {
    const response = yield call(argus.fetchData, `/secure/self/session`)
    yield put(fetchUnityTokenSuccess(response))
  } catch ({ error, response }) {
    const message = error
      ? error.message
      : 'Could not fetch self information from the server.'
    yield put(updateFailure(message))
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
    console.error(message)
  }
}

function* changeVisibility({ visibility }) {
  const dataToSend = {
    state: visibility,
  }
  const expectedData = false

  try {
    const response = yield call(
      argus.postData,
      '/secure/self/visibility',
      {
        body: dataToSend,
      },
      expectedData,
    )
    yield put(visibilityChanged(visibility))
  } catch ({ error, response }) {
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
    Alert.error(`ERROR: ${error.message}`)
  }
}

function* changeFloor({ floorId }) {
  yield put(clearFilter())
  const isFuture = yield select(getIsFuture)
  yield put(updateRequest([floorId]))
  yield take(FETCH_UPDATE_SUCCESS)
  if (isFuture) {
    yield put(fetchFutureViewRequest())
  }
}

function* changeBuilding() {
  yield put(clearQuickView())
  yield put(clearFilter())
  yield put(clearDataset())
  yield put(clearUpdates())
}

function* panFloor({ payload }) {
  yield put(updateRequest([payload.floorId]))
}
function* watchUpdate() {
  yield takeLatest(FETCH_UPDATE_REQUEST, update)
}

function* watchChangeFloor() {
  yield takeEvery([SET_FLOOR, SET_BUILDING], changeFloor)
}

function* watchChangeBuilding() {
  yield takeEvery([SET_BUILDING], changeBuilding)
}

function* watchPanFloor() {
  yield takeEvery(UNITY_PANNED_FLOOR, panFloor)
}
function* watchUnityToken() {
  yield takeEvery(FETCH_UNITY_TOKEN_REQUEST, fetchUnityToken)
}

function* watchDesksBooking() {
  yield takeEvery([FETCH_DESK_BOOKINGS_REQUEST], updateDeskBookings)
}

function* watchConfigSuccess() {
  yield all([take(FETCH_UPDATE_SUCCESS), take(FETCH_CLIENT_CONFIG_SUCCESS)])
  yield call(updateDeskBookings)
}

function* watchVisibilityChange() {
  yield takeEvery([CHANGE_VISIBILITY], changeVisibility)
}

function* updateSagas() {
  yield all([
    watchChangeFloor(),
    watchChangeBuilding(),
    watchUpdate(),
    watchPanFloor(),
    watchDesksBooking(),
    watchConfigSuccess(),
    watchVisibilityChange(),
    watchUnityToken(),
  ])
}
export default updateSagas
