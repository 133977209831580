import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'

import ResultContainer from 'containers/searchPanel/results/ResultContainer'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const HeaderText = styled.p`
  display: flex;
  flex-direction: row;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.theme.panelBgLight};
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const SpanText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

const ResultHeader = ({ children }) => {
  const [showChildren, setShowChildren] = useState(true)

  return (
    <>
      <ResultContainer active onClick={() => setShowChildren(!showChildren)}>
        <HeaderText>
          <SpanText>
            <Translate id="filter.title.colleagues" />
          </SpanText>
          <Icon name={showChildren ? icons.DOWN : icons.RIGHT} />
        </HeaderText>
      </ResultContainer>
      {showChildren && children}
    </>
  )
}

ResultHeader.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
}

export default ResultHeader
