import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import {
  setDataset,
  clearDataset,
  clearFilter,
} from 'containers/searchPanel/filter/actions'
import { searchClear } from 'containers/searchPanel/search/actions'
import {
  getActiveDataset,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import DeskFilters from 'containers/searchPanel/filter/DeskFilters'
import RoomFilters from 'containers/searchPanel/filter/RoomFilters'
import DatasetContainer from 'containers/searchPanel/filter/DatasetContainer'
import FilterItemGrid from 'containers/searchPanel/filter/FilterItemGrid'
import DatasetItem from 'containers/searchPanel/filter/DatasetItem'
import { getClientFeatures } from 'containers/app/selectors'
import AvailabilityFilter from 'containers/searchPanel/filter/AvailabilityFilter'
import { icons } from 'components/icon/icons'

const DisabledTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '1.2rem',
    marginBottom: '0px',
  },
}))(Tooltip)

const DatasetItemWrapper = styled.span`
  display: grid;
`

class Filter extends Component {
  handleClick = (e) => {
    const {
      setDataset,
      clearDataset,
      activeDataset,
      searchClear,
      clearFilter,
    } = this.props
    const value = e.target.value
    if (value !== activeDataset) {
      clearFilter()
      searchClear()
      setDataset(value)
    } else {
      clearDataset()
    }
  }

  render() {
    const { activeDataset, clientFeatures, isFuture } = this.props

    if (!clientFeatures) return null

    const colleagueFinderEnabled = clientFeatures.getIn([
      'colleagueFinder',
      'enabled',
    ])
    const deskFinderEnabled = clientFeatures.getIn(['deskFinder', 'enabled'])
    const roomFinderEnabled = clientFeatures.getIn(['roomFinder', 'enabled'])

    if (!colleagueFinderEnabled && !deskFinderEnabled && !roomFinderEnabled)
      return null

    return (
      <Fragment>
        <DatasetContainer>
          <FilterItemGrid>
            {deskFinderEnabled && (
              <Translate>
                {({ translate }) => (
                  <DatasetItem
                    active={activeDataset === 'desks'}
                    onClick={this.handleClick}
                    iconName={icons.DESK}
                    value="desks"
                    caption={translate('filter.title.spaces')}
                  />
                )}
              </Translate>
            )}
            {roomFinderEnabled && (
              <Translate>
                {({ translate }) => (
                  <DatasetItemWrapper>
                    <DatasetItem
                      active={activeDataset === 'rooms'}
                      onClick={this.handleClick}
                      iconName={icons.ROOM}
                      value="rooms"
                      caption={translate('filter.title.rooms')}
                    />
                  </DatasetItemWrapper>
                )}
              </Translate>
            )}
            {colleagueFinderEnabled && (
              <Translate>
                {({ translate }) => (
                  <DisabledTooltip
                    title={isFuture ? translate('filter.notAvailable') : ''}
                    aria-label="not available"
                    placement="top"
                  >
                    <DatasetItemWrapper>
                      <DatasetItem
                        active={activeDataset === 'users'}
                        disabled={isFuture}
                        onClick={this.handleClick}
                        iconName={icons.USER}
                        value="users"
                        caption={translate('filter.title.colleagues')}
                      />
                    </DatasetItemWrapper>
                  </DisabledTooltip>
                )}
              </Translate>
            )}
          </FilterItemGrid>
        </DatasetContainer>
        {activeDataset !== 'users' && activeDataset !== 'rooms' && (
          <AvailabilityFilter />
        )}
        {activeDataset === 'desks' && <DeskFilters />}
        {activeDataset === 'rooms' && <RoomFilters />}
      </Fragment>
    )
  }
}

Filter.propTypes = {
  setDataset: PropTypes.func,
  clearDataset: PropTypes.func,
  activeDataset: PropTypes.oneOf(['desks', 'rooms', 'users', '']),
  searchClear: PropTypes.func,
  clientFeatures: PropTypes.object,
  isFuture: PropTypes.bool,
  clearFilter: PropTypes.func,
}

const mapStateToProps = (state) => ({
  activeDataset: getActiveDataset(state),
  clientFeatures: getClientFeatures(state),
  isFuture: getIsFuture(state),
})

export default connect(mapStateToProps, {
  setDataset,
  clearDataset,
  searchClear,
  clearFilter,
})(Filter)
